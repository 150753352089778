/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IChannelModel,
  IErrorModel,
  IMediaCategoryListModel,
  IMediaListModel,
  IMediaListOptionsModel,
  IMediaModel,
  IMediaOptionsModel,
  IMediaSearchFilterModel,
  IMediaSearchStateModel,
  IMultiSearchStateModel,
} from "../../models";
import * as Consts from "./consts";
import {
  IAddToFarovitesListAction,
  IDeleteAllMediaAction,
  IGetChannelOnNowAction,
  IGetChannelOnNowFailureAction,
  IGetChannelOnNowSuccessAction,
  IGetMediaAction,
  IGetMediaCategoriesAction,
  IGetMediaCategoriesFailureAction,
  IGetMediaCategoriesSuccessAction,
  IGetMediaFailureAction,
  IGetMediaListAction,
  IGetMediaListFailureAction,
  IGetMediaListFromCacheAction,
  IGetMediaListFromCacheFailureAction,
  IGetMediaListFromCacheSuccessAction,
  IGetMediaListSuccessAction,
  IGetMediaProgressAction,
  IGetMediaProgressFailureAction,
  IGetMediaProgressSuccessAction,
  IGetMediaSuccessAction,
  IRemoveFromFarovitesListAction,
  ISearchMediaAction,
  ISearchMediaFailureAction,
  ISearchMediaSuccessAction,
  ISearchMultiAction,
  ISearchMultiFailureAction,
  ISearchMultiSuccessAction,
} from "./types";

export const getMedia = (options: IMediaOptionsModel): IGetMediaAction => {
  return {
    options,
    type: Consts.GET_MEDIA,
  };
};

export const getMediaSuccess = (data: IMediaModel): IGetMediaSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_MEDIA_SUCCESS,
  };
};

export const getMediaFailure = (
  mediaId: string | number,
  error?: IErrorModel
): IGetMediaFailureAction => {
  return {
    mediaId,
    error,
    type: Consts.GET_MEDIA_FAILURE,
  };
};

export const getMediaProgressAction = (
  media: IMediaModel
): IGetMediaProgressAction => {
  return {
    type: Consts.GET_MEDIA_PROGRESS,
    payload: media,
  };
};

export const getMediaProgressSuccessAction = (
  media: IMediaModel
): IGetMediaProgressSuccessAction => {
  return {
    type: Consts.GET_MEDIA_PROGRESS_SUCCESS,
    payload: media,
  };
};

export const getMediaProgressFailureAction = (
  error?: IErrorModel
): IGetMediaProgressFailureAction => {
  return {
    type: Consts.GET_MEDIA_PROGRESS_FAILURE,
    error,
  };
};

export const searchMedia = (
  filter: IMediaSearchFilterModel
): ISearchMediaAction => {
  return {
    filter,
    type: Consts.SEARCH_MEDIA,
  };
};

export const searchMediaSuccess = (
  data: IMediaSearchStateModel
): ISearchMediaSuccessAction => {
  return {
    payload: data,
    type: Consts.SEARCH_MEDIA_SUCCESS,
  };
};

export const searchMediaFailure = (
  error?: IErrorModel
): ISearchMediaFailureAction => {
  return {
    error,
    type: Consts.SEARCH_MEDIA_FAILURE,
  };
};

export const searchMulti = (
  filter: IMediaSearchFilterModel
): ISearchMultiAction => {
  return {
    filter,
    type: Consts.SEARCH_MULTI,
  };
};

export const searchMultiSuccess = (
  data: IMultiSearchStateModel
): ISearchMultiSuccessAction => {
  return {
    payload: data,
    type: Consts.SEARCH_MULTI_SUCCESS,
  };
};

export const searchMultiFailure = (
  error?: IErrorModel
): ISearchMultiFailureAction => {
  return {
    error,
    type: Consts.SEARCH_MULTI_FAILURE,
  };
};

export const getMediaList = (
  options: IMediaListOptionsModel
): IGetMediaListAction => {
  return {
    options,
    type: Consts.GET_MEDIA_LIST,
  };
};

export const getMediaListSuccess = (
  mediaListId: string | number,
  data: IMediaListModel
): IGetMediaListSuccessAction => {
  return {
    mediaListId,
    payload: data,
    type: Consts.GET_MEDIA_LIST_SUCCESS,
  };
};

export const getMediaListFailure = (
  mediaListId: string | number,
  error?: IErrorModel
): IGetMediaListFailureAction => {
  return {
    mediaListId,
    error,
    type: Consts.GET_MEDIA_LIST_FAILURE,
  };
};

export const getMediaListFromCache = (
  options: IMediaListOptionsModel
): IGetMediaListFromCacheAction => {
  return {
    options,
    type: Consts.GET_MEDIA_LIST_FROM_CACHE,
  };
};

export const getMediaListFromCacheSuccess = (
  mediaListId: string | number,
  data: IMediaListModel
): IGetMediaListFromCacheSuccessAction => {
  return {
    mediaListId,
    payload: data,
    type: Consts.GET_MEDIA_LIST_FROM_CACHE_SUCCESS,
  };
};

export const getMediaListFromCacheFailure = (
  mediaListId: string | number,
  error?: IErrorModel
): IGetMediaListFromCacheFailureAction => {
  return {
    mediaListId,
    error,
    type: Consts.GET_MEDIA_LIST_FROM_CACHE_FAILURE,
  };
};

export const getMediaCategories = (
  tagType?: boolean
): IGetMediaCategoriesAction => {
  return {
    type: Consts.GET_MEDIA_CATEGORIES,
    tagType,
  };
};

export const getMediaCategoriesSuccess = (
  data: IMediaCategoryListModel,
  tagType?: boolean
): IGetMediaCategoriesSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_MEDIA_CATEGORIES_SUCCESS,
    tagType,
  };
};

export const getMediaCategoriesFailure = (
  error?: IErrorModel
): IGetMediaCategoriesFailureAction => {
  return {
    error,
    type: Consts.GET_MEDIA_CATEGORIES_FAILURE,
  };
};

export const getChannelOnNow = (channelId: string): IGetChannelOnNowAction => {
  return {
    type: Consts.GET_CHANNEL_ON_NOW,
    channelId,
  };
};

export const getChannelOnNowSuccess = (
  data: IChannelModel
): IGetChannelOnNowSuccessAction => {
  return {
    type: Consts.GET_CHANNEL_ON_NOW_SUCCESS,
    payload: data,
  };
};

export const getChannelOnNowFailure = (
  channelId: string,
  error?: IErrorModel
): IGetChannelOnNowFailureAction => {
  return {
    type: Consts.GET_CHANNEL_ON_NOW_FAILURE,
    channelId,
    error,
  };
};

export const deleteAllMediaAction = (): IDeleteAllMediaAction => {
  return {
    type: Consts.DELETE_ALL_MEDIA,
  };
};

export const removeFromFavoritesListAction = (
  mediaId: string | number
): IRemoveFromFarovitesListAction => {
  return {
    type: Consts.REMOVE_FROM_FAVORITES_LIST,
    mediaId: mediaId,
  };
};

export const addToFavoritesListAction = (
  media: IMediaModel
): IAddToFarovitesListAction => {
  return {
    type: Consts.ADD_TO_FAVORITES_LIST,
    media: media,
  };
};

export const Actions = {
  getMedia,
  getMediaProgressAction,
  getMediaList,
  getMediaListSuccess,
  getMediaListFromCache,
  getMediaCategories,
  getChannelOnNow,
  searchMedia,
  searchMulti,
  deleteAllMediaAction,
  removeFromFavoritesListAction,
  addToFavoritesListAction,
};
