import { LocaleHelper } from "@nf/common";
import { EXTERNAL_LINKS } from "@nf/constants";
import { useMemo } from "react";
import { useCountry } from "./useCountry";
import { useLanguage } from "./useLanguage";

const useGetLocalizedAuthUrl = (url: string): string => {
  const country = useCountry();
  const language = useLanguage();

  return useMemo(
    function buildLocalizedUrl() {
      return LocaleHelper.getLocalizedAuthUrl(url, {
        Language: language,
        Country: country,
      });
    },
    [url, country, language]
  );
};

export const useSignUpUrl = (): string => {
  return useGetLocalizedAuthUrl(EXTERNAL_LINKS.SIGNUP);
};

export const useForgotPasswordLink = (): string => {
  return useGetLocalizedAuthUrl(EXTERNAL_LINKS.FORGOT_PASSWORD);
};
