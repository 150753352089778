export const uniqueBy = <T extends Record<string, any>, Key extends keyof T>(array: T[], key: Key) => {
    const elements: Record<string, boolean> = {};
    return array.filter((element) => {
        if (element === undefined || element === null || element[key] === undefined) {
            return true
        }

        if (elements[element[key]]) {
            return false;
        }

        elements[element[key]] = true;
        return true;
    });
}