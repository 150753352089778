/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export enum Locale {
  Danish = "da_DK",
  English = "en_GB",
}

export enum LanguageCode {
  Danish = "da",
  English = "en",
}

export enum CountryCode {
  Danish = "DK",
}

export const DEFAULT_LOCALE = Locale.Danish;
export const DEFAULT_LANGUAGE_CODE = LanguageCode.Danish;
export const DEFAULT_COUNTRY_CODE = CountryCode.Danish;
export const DEFAULT_COUNTRY_CODE_LOWERCASE =
  DEFAULT_COUNTRY_CODE.toLowerCase() as Lowercase<typeof DEFAULT_COUNTRY_CODE>;

export const LOCALES = {
  [Locale.Danish]: {
    locale: Locale.Danish,
    nativeName: "Dansk",
    englishName: "Danish",
  },
  [Locale.English]: {
    locale: Locale.English,
    nativeName: "English",
    englishName: "English",
  },
} as const;

export const LOCALE_LIST: `${Locale}`[] = Object.values(Locale);

export const COUNTRY_LIST: `${CountryCode}`[] = Object.values(CountryCode);

export const VALID_COUNTRY = COUNTRY_LIST.map(
  (country) => country.toLowerCase() as Lowercase<CountryCode>
);

export const VALID_LANGUAGE = {
  [CountryCode.Danish.toLowerCase()]: [
    LanguageCode.Danish,
    LanguageCode.English,
  ],
} as Record<Lowercase<CountryCode>, LanguageCode[]>;
