/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "@nf/constants";
import {
  ICustomerModel,
  ILoginRequestModel,
  ILoginResponseModel,
  ILogoutRequestModel,
  IRefreshTokenRequestModel,
  IRefreshTokenResponseModel,
  ISignupRequestModel,
  ISignupResponseModel,
  ISignupVerifyRequestModel,
  ISignupVerifyResponseModel,
  IChangePasswordModel,
  IChangePasswordResponseModel,
  ILoginViaTokenRequestModel,
  IVerifyLoginCodeResponseModel,
  ILoginCodeResponseModel,
  ILoginCodeModel,
  IGetLoginTokenFromCodeModel,
} from "../models";

import { AxiosSubscriber } from "./AxiosSubscriber";

export class AuthService {
  get url(): string {
    return "/v1";
  }

  public login = (data: ILoginRequestModel): Observable<ILoginResponseModel> =>
    new Observable((observer: Observer<ILoginResponseModel>) => {
      return new AxiosSubscriber(observer, {
        data,
        method: HTTP_METHOD.POST,
        url: `${this.url}/login`,
      });
    });

  public loginViaToken = (
    dataToSend: ILoginViaTokenRequestModel
  ): Observable<ILoginResponseModel> =>
    new Observable((observer: Observer<ILoginResponseModel>) => {
      const { loginToken, ...data } = dataToSend;
      return new AxiosSubscriber(observer, {
        data,
        method: HTTP_METHOD.POST,
        url: `${this.url}/login/${loginToken}`,
      });
    });

  public logout = (data: ILogoutRequestModel): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/logout`,
        })
    );

  public refreshToken = (
    data: IRefreshTokenRequestModel
  ): Observable<IRefreshTokenResponseModel> =>
    new Observable(
      (observer: Observer<IRefreshTokenResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/refresh-tokens`,
        })
    );

  public signup = (
    data: ISignupRequestModel
  ): Observable<ISignupResponseModel> =>
    new Observable((observer: Observer<ISignupResponseModel>) => {
      return new AxiosSubscriber(observer, {
        data,
        method: HTTP_METHOD.POST,
        url: `${this.url}/signup`,
      });
    });

  public signupVerify = (
    customerToken: string,
    data: ISignupVerifyRequestModel
  ): Observable<ISignupVerifyResponseModel> =>
    new Observable(
      (observer: Observer<ISignupVerifyResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/signup/${customerToken}`,
        })
    );

  public customer = (): Observable<ICustomerModel> =>
    new Observable(
      (observer: Observer<ICustomerModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/customer`,
        })
    );

  public changePassword = (
    data: IChangePasswordModel
  ): Observable<IChangePasswordResponseModel> =>
    new Observable(
      (observer: Observer<IChangePasswordResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PATCH,
          url: `${this.url}/password`,
        })
    );

  public getLoginCode = (): Observable<ILoginCodeResponseModel> =>
    new Observable(
      (observer: Observer<ILoginCodeResponseModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/big-screen`,
        })
    );

  public verifyCode = (
    data: ILoginCodeModel
  ): Observable<IVerifyLoginCodeResponseModel> =>
    new Observable(
      (observer: Observer<IVerifyLoginCodeResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/big-screen`,
        })
    );

  public getLoginTokenFromCode = (
    data: ILoginCodeModel
  ): Observable<IGetLoginTokenFromCodeModel> =>
    new Observable(
      (observer: Observer<IGetLoginTokenFromCodeModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}/big-screen`,
        })
    );
}
