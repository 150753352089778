/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaImageType {
  Any = "ANY",
  Cover = "COVER",
  Frame = "FRAME",
  Highlights = "HIGHLIGHTS",
  Background = "BACKGROUND",
  Promo = "PROMO",
  Highlight_Asset_Frontpage = "HIGHLIGHT_ASSET_FRONTPAGE",
  Hero_Frontpage = "HERO_FRONTPAGE",
  Banner = "BANNER",
  Poster = "POSTER",
  Square = "SQUARE",
  Hero_Asset_Details = "HERO_ASSET_DETAILS",
  Hover_Asset = "HOVER_ASSET",
  Thumbnail = "THUMBNAIL",
  Highlight_Asset_Details = "HIGHLIGHT_ASSET_DETAILS",
  Logo = "LOGO",
}
