/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { RouteHelper, StorageHelper } from "../../../../helpers";
import { IErrorModel, ITokenModel } from "../../../../models";
import { AuthStore, dispatch } from "../../../../store";
import { AppConfig } from "@nf/constants";
import { authStorage } from "../../../../services";

let isRefreshing = false;
let failedQueue: {
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const processQueue = (error?: IErrorModel, token?: string) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

export const HttpRequestFulfilledInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const session = authStorage.getSessionBehavior() as ITokenModel;

  if (config?.headers?.hasOwnProperty("Authorization")) {
    const authorization = config.headers["Authorization"];
    // Delete empty Authorization header
    if (!authorization) {
      delete config.headers["Authorization"];
    }
  } else {
    if (!config.headers) {
      config.headers = {};
    }
    if (session && session.DataToken) {
      config.headers["Authorization"] = `Bearer ${session.DataToken}`;
    }
  }

  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json; charset=UTF-8";
  }

  config.url = config.url?.replace("{customer}", AppConfig.Customer);
  config.url = config.url?.replace("{businessUnit}", AppConfig.BusinessUnit);

  return Promise.resolve(config);
};

export const HttpRequestRejectedInterceptor = (
  error: IErrorModel
): Promise<any> => {
  return Promise.reject(error);
};

export const HttpResponseFulfilledInterceptor = (
  response: AxiosResponse
): AxiosResponse => response;

export const HttpResponseRejectedInterceptor = async (
  error: IErrorModel | any
): Promise<any> => {
  const { config, response } = error;
  const originalRequest = config;

  if ((!response || response?.status === 401) && !originalRequest?._retry) {
    // Check if refresh token request failed
    if (response?.config?.url === "/v1/refresh-tokens") {
      isRefreshing = false;
      authStorage.deleteSession();
      if (typeof window !== "undefined") {
        await StorageHelper.clearUserData();
        // Remove user data from store
        dispatch(AuthStore.Actions.refreshTokenFailure());
        RouteHelper.goToBase();
      }

      return Promise.reject(new Error("Unable to refresh token"));
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;

          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    try {
      const session = authStorage.getSessionBehavior() as ITokenModel;
      if (session) {
        if (!response) {
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${session.DataToken}`;
          processQueue(undefined, session.DataToken);

          return Promise.resolve(axios(originalRequest));
        }

        const originalAuthorization = originalRequest.headers["Authorization"];
        const currentAuthorization = `Bearer ${session.DataToken}`;

        if (
          session.DataToken &&
          currentAuthorization !== originalAuthorization
        ) {
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${session.DataToken}`;
          processQueue(undefined, session.DataToken);

          return Promise.resolve(axios(originalRequest));
        }
        authStorage.deleteSession();
        if (typeof window !== "undefined") {
          await StorageHelper.clearUserData();
          RouteHelper.goToLogin(true);
        }
      }

      return Promise.reject(new Error("Unable to load data"));
    } catch (error) {
      processQueue(error as IErrorModel);
      return Promise.reject(error);
    } finally {
      isRefreshing = false;
    }
  }

  return Promise.reject(error);
};
