/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { map, mergeMap, switchMap } from "rxjs/operators";
import {
  IErrorModel,
  IMediaCategoryListModel,
  IMediaModel,
} from "../../models";
import { DataProvider } from "../../providers";
import * as Actions from "./actions";
import * as Consts from "./consts";
import { addToUserFavorites, removeFromUserFavorites } from "../user/actions";
import {
  IAddToFarovitesListAction,
  IGetChannelOnNowAction,
  IGetMediaAction,
  IGetMediaCategoriesAction,
  IGetMediaListAction,
  IGetMediaProgressAction,
  IRemoveFromFarovitesListAction,
  ISearchMediaAction,
  ISearchMultiAction,
} from "./types";
import { IAppState } from "../";

const getMediaEpic = (action$: Observable<IGetMediaAction>) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA),
    switchMap((action: IGetMediaAction) =>
      DataProvider.getMedia(action.options)
        .then((data: IMediaModel) => {
          return Actions.getMediaSuccess(data);
        })
        .catch((error) => {
          return Actions.getMediaFailure(action.options.MediaId, error);
        })
    )
  );

const getMediaProgressEpic = (action$: Observable<IGetMediaProgressAction>) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_PROGRESS),
    switchMap((action: IGetMediaProgressAction) =>
      DataProvider.getMediaProgress(action.payload)
        .then((data: IMediaModel) => {
          return Actions.getMediaProgressSuccessAction(data);
        })
        .catch((error) => {
          return Actions.getMediaProgressFailureAction(error);
        })
    )
  );

const searchMediaEpic = (
  action$: Observable<ISearchMediaAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_MEDIA),
    switchMap(async (action: ISearchMediaAction) => {
      try {
        const ParentalRatings =
          state$.value.user.subprofiles.Data?.currentSubprofile?.ProfileType;

        const response = await DataProvider.searchMedia({
          ...action.filter,
          ParentalRatings,
        });
        response.Filter = action.filter;

        return Actions.searchMediaSuccess(response);
      } catch (error) {
        return Actions.searchMediaFailure(error as IErrorModel);
      }
    })
  );

const searchMultiEpic = (
  action$: Observable<ISearchMultiAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_MULTI),
    switchMap(async (action: ISearchMultiAction) => {
      try {
        const ParentalRatings =
          state$.value.user.subprofiles.Data?.currentSubprofile?.ProfileType;

        const response = await DataProvider.searchMulti({
          ...action.filter,
          ParentalRatings,
        });

        return Actions.searchMultiSuccess(response);
      } catch (error) {
        return Actions.searchMultiFailure(error as IErrorModel);
      }
    })
  );

const getMediaListEpic = (
  action$: Observable<IGetMediaListAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_LIST),
    map((action: IGetMediaListAction) => ({
      ParentalRatings:
        state$.value.user.subprofiles.Data?.currentSubprofile?.ProfileType,
      options: action.options,
    })),
    mergeMap(async ({ ParentalRatings, options }) => {
      try {
        const response = await DataProvider.getMediaList({
          ...options,
          ParentalRatings,
        });

        const { SourceUrl, ...filter } = options;
        response.Filter = filter;
        response.SourceUrl = SourceUrl;

        return Actions.getMediaListSuccess(options.MediaListId, response);
      } catch (error) {
        return Actions.getMediaListFailure(
          options.MediaListId,
          error as IErrorModel
        );
      }
    })
  );

const getMediaCategoriesEpic = (
  action$: Observable<IGetMediaCategoriesAction>
) =>
  action$.pipe(
    ofType(Consts.GET_MEDIA_CATEGORIES),
    switchMap((_action: IGetMediaCategoriesAction) =>
      DataProvider.getMediaCategories(_action.tagType)
        .then((data: IMediaCategoryListModel) => {
          return Actions.getMediaCategoriesSuccess(data, _action.tagType);
        })
        .catch((error) => {
          return Actions.getMediaCategoriesFailure(error);
        })
    )
  );

const getChannelOnNowEpic = (action$: Observable<IGetChannelOnNowAction>) =>
  action$.pipe(
    ofType(Consts.GET_CHANNEL_ON_NOW),
    switchMap((action: IGetChannelOnNowAction) =>
      DataProvider.getChannelOnNow(action.channelId)
        .then((data) => Actions.getChannelOnNowSuccess(data))
        .catch((error) =>
          Actions.getChannelOnNowFailure(action.channelId, error)
        )
    )
  );

export const addToFavoritesListEpic = (
  action$: Observable<IAddToFarovitesListAction>
) =>
  action$.pipe(
    ofType(Consts.ADD_TO_FAVORITES_LIST),
    switchMap(async (action: IAddToFarovitesListAction) =>
      addToUserFavorites(action.media.Id)
    )
  );

export const removeFromFavoritesListEpic = (
  action$: Observable<IRemoveFromFarovitesListAction>
) =>
  action$.pipe(
    ofType(Consts.REMOVE_FROM_FAVORITES_LIST),
    switchMap(async (action: IRemoveFromFarovitesListAction) =>
      removeFromUserFavorites(action.mediaId)
    )
  );

export const mediaEpics = [
  getMediaEpic,
  getMediaProgressEpic,
  getChannelOnNowEpic,
  searchMediaEpic,
  searchMultiEpic,
  getMediaListEpic,
  getMediaCategoriesEpic,
  addToFavoritesListEpic,
  removeFromFavoritesListEpic,
];
