/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppConfig } from "../app";
import { ROUTES } from "./routes";

export const paymentPaths = [
  ROUTES.PAYMENT_DETAILS,
  ROUTES.PAYMENT_SUBSCRIPTION,
  ROUTES.PAYMENT_VOUCHER,
  ROUTES.SIGN_UP_PAYMENT,
];

export const pathsToOmitSubprofiles = [
  ...paymentPaths,
  ROUTES.UPDATED_TERMS_SCREEN,
  ROUTES.ASSIGNING_SUBSCRIPTION_SCREEN,
  ROUTES.SETTINGS_ADD_SUBRPOFILE,
  ROUTES.SUBPROFILES_FORGOT_PIN,
  ROUTES.TV_CODE_LOGIN,
];
export const mainChannels = [AppConfig.ChannelHome, AppConfig.ChannelDansk];
