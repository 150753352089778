/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { createReduxEnhancer } from "@sentry/react";
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Dispatch,
  Middleware,
  Store,
} from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { connectRouter, routerMiddleware } from "connected-react-router";
import * as AuthStore from "./auth";
import * as ConfigurationStore from "./configuration";
import * as CoreStore from "./core";
import * as MediaStore from "./media";
import * as PaymentStore from "./payments";
import * as PeopleStore from "./people";
import { IAppState } from "./types";
import * as UserStore from "./user";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispach,
  useSelector as useReduxSelector,
} from "react-redux";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

export * from "./types";
export {
  AuthStore,
  ConfigurationStore,
  MediaStore,
  UserStore,
  CoreStore,
  PaymentStore,
  PeopleStore,
};

export type AppActions =
  | AuthStore.Types.AuthActionsTypes
  | CoreStore.Types.CoreActionsTypes
  | ConfigurationStore.Types.ConfigurationActionsTypes
  | MediaStore.Types.MediaActionsTypes
  | UserStore.Types.UserActionsTypes
  | PaymentStore.Types.PaymentActionsTypes
  | PeopleStore.Types.PeopleActionsTypes;

export let appStore: Store<IAppState, AppActions>;

export function dispatch(action: AnyAction) {
  if (appStore && appStore.dispatch) {
    appStore.dispatch(action);
  }
}
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => useReduxDispach<Dispatch<AppActions>>();
export const useSelector: TypedUseSelectorHook<IAppState> = useReduxSelector;

const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
});

const epicMiddleware = createEpicMiddleware<
  AppActions,
  AppActions,
  IAppState
>();

const reducers = {
  core: CoreStore.Reducers.coreReducer,
  configuration: ConfigurationStore.Reducers.configurationReducer,
  media: MediaStore.Reducers.mediaReducer,
  people: PeopleStore.Reducers.peopleReducer,
  auth: AuthStore.Reducers.authReducer,
  user: UserStore.Reducers.userReducer,
  payment: PaymentStore.Reducers.paymentsReducer,
};

export const rootEpics = combineEpics<any, any, IAppState>(
  ...ConfigurationStore.Epics.configurationEpics,
  ...MediaStore.Epics.mediaEpics,
  ...UserStore.Epics.userEpics,
  ...PaymentStore.Epics.paymentsEpics,
  ...PeopleStore.Epics.peopleEpics,
  ...AuthStore.Epics.authEpics
);

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export class ReduxStoreConfigurator {
  private middlewares: Middleware[] = [epicMiddleware];

  private initialState: IAppState = {
    core: CoreStore.Reducers.initialState,
    configuration: ConfigurationStore.Reducers.initialState,
    media: MediaStore.Reducers.initialState,
    auth: AuthStore.Reducers.initialState,
    user: UserStore.Reducers.initialState,
    payment: PaymentStore.Reducers.initialState,
    people: PeopleStore.Reducers.initialState,
  };

  private history: History | undefined = undefined;

  constructor(
    middlewares?: Middleware[],
    history?: History,
    initialState?: IAppState
  ) {
    this.history = history;

    if (middlewares && middlewares.length > 0) {
      this.middlewares = this.middlewares.concat(...middlewares);
    }

    this.initialState = initialState ? initialState : this.initialState;
  }

  public initStore() {
    appStore = this.configureStore();
    epicMiddleware.run(rootEpics as any);

    return appStore;
  }

  private createRootReducer(history?: any) {
    let appReducer: any = combineReducers(reducers);

    if (history) {
      appReducer = combineReducers({
        router: connectRouter(history),
        ...reducers,
      });
    }

    return (state: IAppState | undefined, action: AppActions) => {
      if (action.type === AuthStore.Consts.SIGN_OUT_SUCCESS) {
        state = undefined;
      }

      return appReducer(state, action);
    };
  }

  private createEnhancer(middlewares: Middleware[], history?: any) {
    let enhancer = composeEnhancers(
      applyMiddleware(...middlewares),
      sentryReduxEnhancer
    );

    if (history) {
      enhancer = composeEnhancers(
        applyMiddleware(routerMiddleware(history), ...middlewares),
        sentryReduxEnhancer
      );
    }

    return enhancer;
  }

  private configureStore() {
    const enhancer = this.createEnhancer(this.middlewares, this.history);
    const rootReducer = this.createRootReducer(this.history);
    return createStore(rootReducer, this.initialState as any, enhancer);
  }
}
