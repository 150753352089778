/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { HTTP_METHOD } from "@nf/constants";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../../../../services";
import {
  ISelectUserSubprofileResponseModel,
  IUserSubprofileListModel,
  IUserSubprofileRequestModel,
  IUserSubprofileResponseModel,
} from "../models";
import { AppConfig } from "@nf/constants";

export class UserSubprofilesService {
  get url(): string {
    return `/v2/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/user/profile`;
  }

  public getUserSubprofiles = (): Observable<IUserSubprofileListModel> => {
    return new Observable(
      (observer: Observer<IUserSubprofileListModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}`,
        })
    );
  };

  public createUserSubprofile = (
    data: IUserSubprofileRequestModel
  ): Observable<IUserSubprofileListModel> => {
    return new Observable(
      (observer: Observer<IUserSubprofileListModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.url}`,
        })
    );
  };

  public updateUserSubprofile = (
    data: IUserSubprofileRequestModel,
    id: string | number
  ): Observable<IUserSubprofileResponseModel> => {
    return new Observable(
      (observer: Observer<IUserSubprofileResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/${id}`,
        })
    );
  };

  public deleteUseSubprofile = (
    subprofileId: string | number
  ): Observable<void> => {
    return new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/${subprofileId}`,
        })
    );
  };

  public selectUseSubprofile = (
    subprofileId: string | number
  ): Observable<ISelectUserSubprofileResponseModel> => {
    return new Observable(
      (observer: Observer<ISelectUserSubprofileResponseModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/${subprofileId}/select`,
        })
    );
  };
}
