/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { StorageContent } from "../../models";
import { IStorageManager } from "./IStorageManager";

class StorageManager implements IStorageManager {
  private hasLocalStorage() {
    return typeof localStorage !== "undefined";
  }

  private warnIfNoLocalStorage() {
    typeof window !== "undefined" &&
      console.warn("StorageManager: localStorage is not defined");
  }

  public setValue = async (key: keyof StorageContent, val: any) => {
    if (!this.hasLocalStorage()) {
      this.warnIfNoLocalStorage();
      return;
    }
    // Let's convert `undefined` values to `null` to get the value consistent
    if (val === undefined) {
      val = null;
    } else {
      val = JSON.stringify(val);
    }

    return localStorage.setItem(key, val);
  };

  public getValue = async <T = any>(key: keyof StorageContent) => {
    if (!this.hasLocalStorage()) {
      this.warnIfNoLocalStorage();
      return;
    }

    const item = localStorage ? localStorage.getItem(key) : null;
    if (!item || item === "null") {
      return undefined;
    }
    try {
      return JSON.parse(item) as T;
    } catch (e) {
      return undefined;
    }
  };

  public getLogoValue = (key: keyof StorageContent) => {
    if (!this.hasLocalStorage()) {
      this.warnIfNoLocalStorage();
      return;
    }

    const item = localStorage ? localStorage.getItem(key) : null;
    if (!item || item === "null") {
      return undefined;
    }
    return JSON.parse(item);
  };

  public deleteValue = async (name: keyof StorageContent) => {
    if (!this.hasLocalStorage()) {
      this.warnIfNoLocalStorage();
      return;
    }

    localStorage.removeItem(name);
  };

  public getLanguageKey = (key: keyof StorageContent) => {
    if (!this.hasLocalStorage()) {
      this.warnIfNoLocalStorage();
      return;
    }

    const item = localStorage ? localStorage.getItem(key) : null;
    if (!item || item === "null") {
      return undefined;
    }
    return item;
  };

  public setLanguageKey = (key: keyof StorageContent, val: any) => {
    if (!this.hasLocalStorage()) {
      this.warnIfNoLocalStorage();
      return;
    }
    // Let's convert `undefined` values to `null` to get the value consistent
    if (val === undefined) {
      val = null;
    }
    return localStorage.setItem(key, val);
  };
}

export default new StorageManager();
