/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import { IUserAvailabilityKeyModel } from "../models";
import { AppConfig } from "@nf/constants";

export class EntitlementService {
  get url(): string {
    return `/v2/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/entitlement`;
  }

  public getAvailabilityKeys = (): Observable<IUserAvailabilityKeyModel> => {
    return new Observable(
      (observer: Observer<IUserAvailabilityKeyModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/availabilitykey`,
        })
    );
  };
}
