import { useEffect, useMemo } from "react";
import { getConsentsToAccept, useConsentsSelector } from "@nf/common";
import { useSyncCompleted } from "./useSyncWebUser";
import { ROUTES } from "@nf/constants";
import { useReplacePage } from "./useReplacePage";

export const useRedirectToConsentPage = (isLoggedIn?: boolean) => {
  const { isLoading, consents } = useConsentsSelector();
  const redirectToPage = useReplacePage();
  const isAuthProcessCompleted = useSyncCompleted();

  const shouldUpdateConsents = useMemo(
    () =>
      isAuthProcessCompleted &&
      isLoggedIn &&
      !!getConsentsToAccept(consents)?.length,
    [consents, isAuthProcessCompleted, isLoggedIn]
  );

  useEffect(() => {
    if (!isLoading && shouldUpdateConsents) {
      redirectToPage(ROUTES.UPDATED_TERMS_SCREEN);
    }
  }, [shouldUpdateConsents, isLoading, redirectToPage]);
};
