import { catchError, of, OperatorFunction } from "rxjs";
import { captureMessage } from "@sentry/nextjs";

export function toError<T, P>(
  mapToErrorActionFunction: Function
): OperatorFunction<T, P> {
  return (source$) =>
    source$.pipe(
      catchError((error) => {
        captureMessage("request error", {
          level: "info",
          extra: {
            error,
          },
        });

        const errorModel = {
          Message: error?.message || "Unknown error",
          StatusCode: error?.status || 500,
        };

        return of(mapToErrorActionFunction(errorModel));
      })
    );
}
