/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ANONYMOUS_ID,
  BACKEND_VERSION_KEY,
  CONFIGURATION_KEY,
  CURRENT_SUB_PROFILE_ID_KEY,
  DEVICE_KEY,
  PRODUCTS_KEY,
  PROFILES_KEY,
  SESSION_KEY,
  SIGN_IN_TOKEN_KEY,
  SOURCE_KEY,
  SUBTITLE_SETTINGS,
  TV_TOKENS_KEY,
  USER_ID_KEY,
  USERS_KEY,
  VOLUME_KEY,
} from "@nf/constants";
import {
  IMediaListModel,
  ITokenModel,
  IUserDeviceModel,
  IUserInfoModel,
  SubtitlesSettingsStorage,
  UsersStorage,
} from "../models";
import { authStorage, StorageManager } from "../services";
import { PlatformHelper } from "./platformHelper";
import { CookieHelper } from "./CookieHelper";
import { BrowserHelper } from "./browserHelper";

export class StorageHelper {
  static getUser(): Promise<IUserInfoModel> {
    return BrowserHelper.isTV()
      ? StorageManager.getValue(USER_ID_KEY).then(async (userId) => {
          const users = await StorageManager.getValue(USERS_KEY);
          if (users && userId) {
            return users[userId];
          }
        })
      : CookieHelper.getUser()
          .then(async (userId) => {
            const users = await CookieHelper.getUsers();
            return users.find((user) => user?.Id === userId);
          })
          .catch((e) => {
            console.error(e);
            return undefined;
          });
  }

  static getSession(): ITokenModel | undefined {
    return authStorage.getSessionBehavior();
  }

  static setSession(session?: ITokenModel): void {
    session && authStorage.setSessionBehavior(session);
    if (BrowserHelper.isTV()) {
      StorageManager.setValue(SESSION_KEY, session);
    }
  }

  static deleteSession(): Promise<void> {
    authStorage.deleteSession();
    return BrowserHelper.isTV()
      ? StorageManager.deleteValue(SESSION_KEY)
      : CookieHelper.deleteSession();
  }

  static getSource(): Promise<IMediaListModel | undefined> {
    return StorageManager.getValue(SOURCE_KEY);
  }
  static deleteSource(): Promise<void> {
    return StorageManager.deleteValue(SOURCE_KEY);
  }

  static getBrandingLogo(): string | undefined {
    const configuration = StorageManager.getLogoValue(CONFIGURATION_KEY);

    if (configuration) {
      return configuration.Branding?.WebHeaderLogoUrl;
    }
    return undefined;
  }

  static async setUser(user?: IUserInfoModel): Promise<void> {
    if (!user) {
      return Promise.resolve();
    }
    return StorageManager.getValue(USERS_KEY)
      .then((users: UsersStorage) => {
        if (!users) {
          users = {};
        }
        if (user.Id) {
          // keep assetProperties
          const assetsProperties = users[user.Id]?.assetsProperties;
          users[user.Id] = { ...user, assetsProperties };
        }
        return users;
      })
      .then((users) => {
        return BrowserHelper.isTV()
          ? StorageManager.setValue(USERS_KEY, users)
          : CookieHelper.setUsers(users);
      })
      .then(() => {
        return BrowserHelper.isTV()
          ? StorageManager.setValue(USER_ID_KEY, user.Id)
          : CookieHelper.setUser(user);
      });
  }

  static deleteUser(): Promise<void> {
    return BrowserHelper.isTV()
      ? StorageManager.setValue(USER_ID_KEY, ANONYMOUS_ID)
      : CookieHelper.deleteUser();
  }

  static getTvRefreshTokens(): Promise<string | undefined> {
    return StorageManager.getValue(TV_TOKENS_KEY);
  }
  static setTvRefreshTokens(tokens: string): Promise<void> {
    return StorageManager.setValue(TV_TOKENS_KEY, tokens);
  }

  static clearTvRefreshTokens(): Promise<void> {
    return StorageManager.deleteValue(TV_TOKENS_KEY);
  }

  static async clearUserData(): Promise<void[]> {
    return Promise.all([
      StorageManager.deleteValue(USER_ID_KEY),
      StorageManager.deleteValue(USERS_KEY),
      StorageManager.deleteValue(CURRENT_SUB_PROFILE_ID_KEY),
      StorageManager.deleteValue(TV_TOKENS_KEY),
      StorageHelper.clearProfiles(),
      CookieHelper.deleteUser(),
    ]);
  }

  static clearLocalStorage(): Promise<void[]> {
    authStorage.deleteSession();
    return Promise.all([
      StorageManager.deleteValue(USER_ID_KEY),
      StorageManager.deleteValue(USERS_KEY),
      StorageHelper.deleteSession(),
      StorageManager.deleteValue(SOURCE_KEY),
      StorageManager.deleteValue(BACKEND_VERSION_KEY),
      StorageManager.deleteValue(SIGN_IN_TOKEN_KEY),
      StorageManager.deleteValue(CURRENT_SUB_PROFILE_ID_KEY),
      StorageManager.deleteValue(TV_TOKENS_KEY),
      StorageHelper.clearProfiles(),
      CookieHelper.deleteUser(),
    ]);
  }

  static getConfiguration(): Promise<any> {
    return StorageManager.getValue(CONFIGURATION_KEY);
  }

  static setConfiguration(configuration: any): Promise<void> {
    const config = PlatformHelper.isTV() ? configuration : {};
    return StorageManager.setValue(CONFIGURATION_KEY, config);
  }

  static async getDevice(): Promise<IUserDeviceModel> {
    return BrowserHelper.isTV()
      ? await StorageManager.getValue(DEVICE_KEY)
      : await CookieHelper.getDevice();
  }
  static setDevice(device: any): Promise<void> {
    return BrowserHelper.isTV()
      ? StorageManager.setValue(DEVICE_KEY, device)
      : CookieHelper.setDevice(device);
  }

  static setSignInToken(token?: string): Promise<void> {
    return StorageManager.setValue(SIGN_IN_TOKEN_KEY, token);
  }

  static getCurrentSubprofileId(): Promise<string | undefined> {
    return StorageManager.getValue(CURRENT_SUB_PROFILE_ID_KEY);
  }

  static setCurrentSubprofileId(subprofileId?: string): Promise<void> {
    return StorageManager.setValue(CURRENT_SUB_PROFILE_ID_KEY, subprofileId);
  }

  static deleteCurrentSubprofileId(): Promise<void> {
    return StorageManager.deleteValue(CURRENT_SUB_PROFILE_ID_KEY);
  }

  static setPlayerVolume(volume?: number): Promise<void> {
    const roundedVolume = Math.round(volume ?? 0);
    return StorageManager.setValue(VOLUME_KEY, roundedVolume);
  }

  static getPlayerVolume() {
    return StorageManager.getValue<number>(VOLUME_KEY);
  }

  static setProfiles(profiles: any): Promise<void> {
    return StorageManager.setValue(PROFILES_KEY, profiles);
  }

  static getProfiles(): Promise<any | undefined> {
    return StorageManager.getValue(PROFILES_KEY);
  }

  static clearProfiles(): Promise<void> {
    return StorageManager.deleteValue(PROFILES_KEY);
  }

  static hasProfiles(): Promise<boolean> {
    return StorageManager.getValue(PROFILES_KEY).then((profiles) => {
      return profiles && profiles.length > 0;
    });
  }

  static setProducts(value: string): Promise<void> {
    return StorageManager.setValue(PRODUCTS_KEY, value);
  }

  static getProducts(): Promise<string | undefined> {
    return StorageManager.getValue(PRODUCTS_KEY);
  }

  static deleteProducts(): Promise<void> {
    return StorageManager.deleteValue(PRODUCTS_KEY);
  }

  static getSubtitleSettings() {
    return StorageManager.getValue<SubtitlesSettingsStorage>(SUBTITLE_SETTINGS);
  }

  static setSubtitleSettings(settings: SubtitlesSettingsStorage) {
    return StorageManager.setValue(SUBTITLE_SETTINGS, settings);
  }
}
