import { IComponentConfigModel, IImageModel } from "../models";
import { IConfigOptionModel } from "../models/Config/IConfigOptionModel";
import {
  IHeroComponentModel,
  IMediaImageModel,
  IMediaModel,
} from "../../../../models";
import {
  AssetType,
  ComponentType,
  MediaImageType,
  MediaType,
  PlatformType,
} from "../../../../enums";
import { ModelsMapperHelper } from "./modelsMapperHelper";
import {
  ASSET_IMAGE_ORIENTATION,
  ASSET_IMAGE_TAG,
  COMPONENT_COLECTIONS,
  COMPONENT_SUB_TYPE,
} from "../constants";
import { UNDEFINED_MEDIA_ID } from "@nf/constants";
import { TimeHelper } from "../../../../helpers";

const applyImageProps = (
  image: IMediaImageModel,
  heroBannerItemContentImage: IImageModel
): IMediaImageModel => {
  if (
    heroBannerItemContentImage.tags &&
    heroBannerItemContentImage.tags.includes(ASSET_IMAGE_TAG.BANNER)
  ) {
    image.ImageTypeCode = MediaImageType.Highlights;
  }

  if (
    heroBannerItemContentImage.tags &&
    heroBannerItemContentImage.tags.includes(ASSET_IMAGE_TAG.HERO_FRONTPAGE)
  ) {
    image.ImageTypeCode = MediaImageType.Hero_Frontpage;
  }

  if (
    heroBannerItemContentImage.tags &&
    heroBannerItemContentImage.tags.includes(ASSET_IMAGE_TAG.HERO_ASSET_DETAILS)
  ) {
    image.ImageTypeCode = MediaImageType.Hero_Asset_Details;
  }

  return image;
};

const generateMedia = (
  config: IComponentConfigModel,
  options: IConfigOptionModel
): IMediaModel[] => {
  if (!config.components[COMPONENT_COLECTIONS.HERO_BANNER_ITEMS]) {
    return [];
  }

  return config.components[COMPONENT_COLECTIONS.HERO_BANNER_ITEMS].map(
    (heroBannerItem) => {
      const heroBannerItemFallback = ModelsMapperHelper.getLocalizedFallback(
        heroBannerItem,
        options
      );
      const heroBannerItemLocalized =
        heroBannerItem?.presentation?.localized?.[options.lang] || {};

      const heroBannerItemAssetType =
        heroBannerItem.asset?.type || MediaType.Custom;

      const heroBannerParentalRatings = heroBannerItem.asset?.parentalRatings;

      const media: IMediaModel = {
        Id: heroBannerItem.actions?.default?.assetId || UNDEFINED_MEDIA_ID,
        Title: heroBannerItemFallback.title || heroBannerItemLocalized.title,
        Description:
          heroBannerItemFallback.body || heroBannerItemLocalized.body,
        MediaTypeCode:
          heroBannerItemAssetType === AssetType.Series
            ? MediaType.Series
            : heroBannerItemAssetType,
        TrailerMediaId:
          heroBannerItemLocalized.trailerAssetId ||
          heroBannerItemFallback.trailerAssetId,
        ParentalRatings: heroBannerParentalRatings,
        Name: heroBannerItem.name,
      };

      if (heroBannerItem.actions?.default) {
        media.Action = ModelsMapperHelper.toActionModel(
          heroBannerItem.actions?.default,
          options
        );
      }

      const heroBannerItemImages =
        heroBannerItemLocalized.images || heroBannerItemFallback.images;

      if (heroBannerItemImages) {
        media.Images = heroBannerItemImages.map((image) => ({
          ImageTypeCode:
            image.orientation === ASSET_IMAGE_ORIENTATION.LANDSCAPE
              ? MediaImageType.Highlights
              : MediaImageType.Thumbnail,
          PlatformCode: PlatformType.Any,
          Url: image.url,
          Width: image.width,
          Height: image.height,
        }));
      }

      if (
        heroBannerItem.appSubType === COMPONENT_SUB_TYPE.ASSET &&
        heroBannerItem.content
      ) {
        const heroBannerItemContentFallback =
          heroBannerItem?.content?.presentation?.fallback ||
          heroBannerItem?.content?.presentation?.localized?.[
            options.defaultLocale
          ] ||
          {};
        const heroBannerItemContentLocalized =
          heroBannerItem?.content?.presentation?.localized?.[options.lang] ||
          {};

        const heroBannerAsset = heroBannerItem.asset?.tags;

        if (heroBannerAsset && heroBannerAsset.length > 0) {
          media.Categories = ModelsMapperHelper.toMediaCategoriesModel(
            heroBannerAsset,
            options
          );
        }

        const heroBannerYear = heroBannerItem.asset?.productionYear;
        if (heroBannerYear) {
          media.Year = heroBannerYear;
        }

        if (
          heroBannerItem.asset?.publications &&
          heroBannerItem.asset.publications.length > 0
        ) {
          const heroBannerPublications = heroBannerItem.asset?.publications[0];
          media.IsPlayable = TimeHelper.isBeforeCurrent(
            heroBannerPublications.fromDate
          );
          media.Products = heroBannerPublications.products.map((productId) => ({
            Id: productId,
          }));
        }

        if (!media.Title) {
          media.Title =
            heroBannerItemContentLocalized.title ||
            heroBannerItemContentFallback.title;
        }

        if (!media.Description) {
          media.Description =
            heroBannerItemContentLocalized.body ||
            heroBannerItemContentFallback.body;
        }

        if (!media.TrailerMediaId) {
          media.TrailerMediaId =
            heroBannerItemContentLocalized.trailerAssetId ||
            heroBannerItemContentFallback.trailerAssetId;
        }

        const heroBannerItemAssetContentLocalized =
          heroBannerItem.asset?.localized.find(
            (row) => row.locale === options.defaultLocale
          );

        if (!media.ExtendedDescription) {
          media.ExtendedDescription =
            heroBannerItemAssetContentLocalized?.extendedDescription;
        }

        const heroBannerItemContentImages =
          heroBannerItemContentLocalized.images ||
          heroBannerItemContentFallback.images;

        if (heroBannerItemContentImages) {
          if (!media.Images) {
            media.Images = [];
          }

          for (const heroBannerItemContentImage of heroBannerItemContentImages) {
            const image: IMediaImageModel = {
              ImageTypeCode:
                heroBannerItemContentImage.orientation ===
                ASSET_IMAGE_ORIENTATION.PORTRAIT
                  ? MediaImageType.Cover
                  : MediaImageType.Thumbnail,
              PlatformCode: PlatformType.Any,
              Url: heroBannerItemContentImage.url,
              Width: heroBannerItemContentImage.width,
              Height: heroBannerItemContentImage.height,
            };

            media.Images.push(
              applyImageProps(image, heroBannerItemContentImage)
            );
          }
        }
      }

      return media;
    }
  );
};

export const toHeroComponentModel = (
  modelConfig: IComponentConfigModel,
  options: IConfigOptionModel
): IHeroComponentModel => {
  const heroComponent: IHeroComponentModel = {
    IsVisible: true,
    MediaList: [],
    Id: modelConfig.id,
    ComponentTypeCode: ComponentType.HeroBanner,
    Name: modelConfig.name,
    HasAuthorizedContent: modelConfig.hasAuthorizedContent || false,
  };

  heroComponent.MediaList = generateMedia(modelConfig, options);

  return heroComponent;
};
