/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export enum PlayerSubtitleStyle {
  Default = "default",
  Contrast = "contrast",
  NoBackground = "noBackground",
}
