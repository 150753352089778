globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"ca118d384b853076a5a4ff163b914180a0669139"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { AppConfig } from "@nf/constants";
import {
  init,
  replayIntegration,
  thirdPartyErrorFilterIntegration,
} from "@sentry/browser";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  AppConfig.SentryDsn;

init({
  dsn:
    SENTRY_DSN ||
    "https://78bc7dd719bd4daf8c50c12521af2d11@o1062064.ingest.sentry.io/6365637",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  ignoreErrors: ["getConsentGivenFor", "exceeded the quota"],
  release: "nordiskClient-web@" + process.env.NEXT_PUBLIC_VERSION,
  environment:
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    thirdPartyErrorFilterIntegration({
      filterKeys: ["nordiskfilmplus"],
      behaviour: "apply-tag-if-contains-third-party-frames",
    }),
  ],
  beforeSend(event) {
    const browserErrors = [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
    ];

    if (event.exception && event.exception.values) {
      const message = event.exception.values[0].value;
      if (message && browserErrors.includes(message)) {
        return null;
      }
    }

    return event; // Allow all other events
  },
});
