import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useChannelByIdSelector } from "../../selectors";
import { MediaStore } from "../../store";

export const useChannelOnNowDispatch = () => {
  const dispatch = useDispatch();
  return (channelId: string) =>
    dispatch(MediaStore.Actions.getChannelOnNow(channelId));
};

export const useChannelOnNow = (channelId: string) => {
  const channelOnNow = useChannelOnNowDispatch();
  const data = useChannelByIdSelector(channelId);

  useEffect(() => {
    if (channelId) {
      channelOnNow(channelId);
    }
  }, [channelId]);

  return { data: data.Media, loading: data.IsLoading };
};
