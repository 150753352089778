/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AxiosSubscriber, offeringIdsService } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import {
  IAssetListModel,
  IAssetModel,
  IAssetSeasonListModel,
  ISearchListModel,
  ISearchMultiListModel,
} from "../models";
import { Observable, Observer } from "rxjs";
import { AppConfig } from "@nf/constants";
export class AssetService {
  get url(): string {
    return `/v1/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}`;
  }

  public get = async ({
    assetId,
    includeSeasons,
    includeEpisodes,
    includeCollections,
    parentalRatings,
    onlyPublished,
  }: {
    assetId: string | number;
    includeSeasons?: boolean;
    includeEpisodes?: boolean;
    includeCollections?: boolean;
    parentalRatings?: string;
    onlyPublished?: boolean;
  }): Promise<Observable<IAssetModel>> => {
    const params = {
      includeSeasons,
      includeEpisodes,
      includeCollections,
      parentalRatings,
      onlyPublished,
      products: await offeringIdsService.getOfferingIds(),
    };
    return new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/asset/${assetId}`,
        })
    );
  };

  public getNext = (assetId: string | number): Observable<IAssetModel> => {
    return new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/asset/${assetId}/next`,
        })
    );
  };

  public getPrev = (assetId: string | number): Observable<IAssetModel> => {
    return new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/asset/${assetId}/previous`,
        })
    );
  };

  public getCollectionEntries = (
    assetId: string | number
  ): Observable<IAssetListModel> => {
    const params = {
      fieldSet: "ALL",
      pageNumber: 1,
      pageSize: 200,
    };
    return new Observable(
      (observer: Observer<IAssetListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/asset/${assetId}/collectionentries`,
        })
    );
  };

  public getNextCollectionEntry = (
    assetId: string | number,
    collectionId: string | number
  ): Observable<IAssetModel> => {
    return new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/asset/${collectionId}/collectionentries/${assetId}/next`,
        })
    );
  };

  public getPrevCollectionEntry = (
    assetId: string | number,
    collectionId: string | number
  ): Observable<IAssetModel> => {
    return new Observable(
      (observer: Observer<IAssetModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/asset/${collectionId}/collectionentries/${assetId}/previous`,
        })
    );
  };

  public seasons = (
    seasonIds: string[],
    includeEpisodes: boolean = false
  ): Observable<IAssetSeasonListModel> => {
    const params = {
      seasonIds: seasonIds.join(","),
      includeEpisodes,
      pageNumber: 1,
      pageSize: 150,
      fieldSet: "PARTIAL",
    };

    return new Observable(
      (observer: Observer<IAssetSeasonListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/season`,
        })
    );
  };

  public recommended = (
    assetId: string | number
  ): Observable<IAssetListModel> =>
    new Observable(
      (observer: Observer<IAssetListModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/recommend/watchNext/${assetId}`,
        })
    );

  public search = async (
    pageSize: number = 50,
    pageNumber: number = 1,
    sort?: string,
    locales?: string,
    query?: string,
    parentalRatings?: string,
    people?: string
  ): Promise<Observable<ISearchListModel>> => {
    const params = {
      pageSize,
      pageNumber,
      sort,
      query,
      locales,
      fieldSet: "ALL",
      parentalRatings,
      products: await offeringIdsService.getOfferingIds(),
      addParticipantIds: people,
    };
    return new Observable(
      (observer: Observer<ISearchListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `/v3/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/content/search/asset/query/${query}`,
        })
    );
  };

  public searchMulti = async (
    pageSize: number = 30,
    pageNumber: number = 1,
    locale?: string,
    query?: string,
    parentalRatings?: string
  ): Promise<Observable<ISearchMultiListModel>> => {
    const params = {
      pageSize,
      pageNumber,
      query,
      locale,
      fieldSet: "ALL",
      parentalRatings,
      products: await offeringIdsService.getOfferingIds(),
    };
    return new Observable(
      (observer: Observer<ISearchMultiListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `/v3/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/content/search/query/${query}`,
        })
    );
  };

  public assetsList = (
    assetIds: string[],
    pageSize = 50,
    pageNumber = 1,
    fieldSet = "ALL",
    onlyPublished = false,
    parentalRatings?: string
  ) => {
    const params = {
      assetIds: assetIds.join(","),
      fieldSet,
      pageSize,
      pageNumber,
      onlyPublished,
      parentalRatings,
    };

    return new Observable(
      (observer: Observer<IAssetListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/asset`,
        })
    );
  };

  public searchV1 = async (
    pageSize: number = 50,
    pageNumber: number = 1,
    query?: string,
    sort?: string,
    assetTypes?: string,
    parentalRatings?: string
  ): Promise<Observable<IAssetListModel>> => {
    const params = {
      pageSize,
      pageNumber,
      query,
      sort,
      assetTypes,
      fieldSet: "ALL",
      parentalRatings,
      products: await offeringIdsService.getOfferingIds(),
    };
    return new Observable(
      (observer: Observer<IAssetListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/asset`,
        })
    );
  };

  public searchByUrl = async (
    url: string,
    parentalRatings?: string
  ): Promise<Observable<IAssetListModel>> => {
    let params: { parentalRatings?: string; products?: string } = {};

    if (parentalRatings) {
      params.parentalRatings = parentalRatings;
    }

    params = await offeringIdsService.includeProductsIdsParam(params);

    return new Observable(
      (observer: Observer<IAssetListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: { params },
          method: HTTP_METHOD.GET,
          url: url,
        })
    );
  };
}
