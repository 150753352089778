import { useState } from "react";
import { useInterval } from "./useInterval";

const DEFAULT_INTERVAL = 1000;

interface useTimerOptions {
  autoStart?: boolean;
  interval?: number;
  time: number;
  onExpire?: () => void;
}

export function useTimer({
  autoStart = true,
  interval: intervalProp = DEFAULT_INTERVAL,
  time,
  onExpire,
}: useTimerOptions) {
  const [remainingTime, setRemainingTime] = useState(time);
  const [interval, setInterval] = useState<number | null>(intervalProp);
  const [didStart, setDidStart] = useState(autoStart);
  const [isRunning, setIsRunning] = useState(autoStart);

  function handleExpire() {
    onExpire?.();
    setIsRunning(false);
    setInterval(null);
  }

  function pause() {
    setIsRunning(false);
  }

  function restart(newTime = time, newAutoStart = true) {
    setInterval(newAutoStart ? intervalProp : null);
    setDidStart(newAutoStart);
    setIsRunning(newAutoStart);
    setRemainingTime(newTime);
  }

  function resume() {
    restart(remainingTime);
  }

  function start() {
    if (didStart) {
      setIsRunning(true);
    } else {
      resume();
    }
  }

  useInterval(
    () => {
      const newRemainingTime = remainingTime - (interval || DEFAULT_INTERVAL);
      setRemainingTime(newRemainingTime);

      if (newRemainingTime <= 0) {
        handleExpire();
      }
    },
    isRunning ? interval : null
  );

  return {
    remainingTime,
    start,
    pause,
    resume,
    restart,
    isRunning,
  };
}
