/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { push, replace } from "connected-react-router";
import { ofType, StateObservable } from "redux-observable";
import {
  Observable,
  from,
  of,
  catchError,
  tap,
  delay,
  lastValueFrom,
  retry,
} from "rxjs";
import { map, mapTo, switchMap, mergeMap } from "rxjs/operators";
import { ROUTES } from "@nf/constants";
import { AuthResult } from "../../enums";
import {
  DeviceHelper,
  PlatformHelper,
  RouteHelper,
  StorageHelper,
} from "../../helpers";
import {
  IAuthResponseModel,
  IApiChangePasswordResponseModel,
  IErrorModel,
  IUserDeviceModel,
  IUserFavoritesModel,
} from "../../models";

import { DataProvider } from "../../providers";
import { authStorage, eventService, offeringIdsService } from "../../services";
import { IAppState } from "../types";
import {
  getUserFavorites,
  getUserConsents,
  getUserSubscriptions,
  getUserAvailabilityKeys,
  getUserSubprofiles,
  getProductOfferingIds,
} from "../user/actions";
import { updateApplicationMenu } from "../configuration/actions";
import { dispatch } from "../index";
import * as Actions from "./actions";
import * as Consts from "./consts";
import * as Types from "./types";
import * as UserConst from "../user/consts";
import { IGetUserSubprofilesSuccessAction } from "../user/types";
import { captureMessage } from "@sentry/nextjs";
import { ajax } from "rxjs/internal/ajax/ajax";
import { AjaxResponse } from "rxjs/internal/ajax/AjaxResponse";
import { ISessionModel } from "../../providers/DataProvider/RedBeeMedia/models";
import axios from "axios";

export const signInEpic = (action$: Observable<Types.ISignInAction>) =>
  action$.pipe(
    ofType(Consts.SIGN_IN),
    switchMap(async (action: Types.ISignInAction) => {
      const payload = { ...action.data };
      if (!payload.Device) {
        payload.Device = await DeviceHelper.getDeviceInfo();
      }
      return payload;
    }),
    map(({ Device, Password, Username }) => ({
      Device,
      Password: Password?.trim(),
      Username: Username?.trim(),
    })),
    switchMap((payload) => {
      return from(DataProvider.signIn(payload)).pipe(
        mergeMap(async (response) => {
          await StorageHelper.setUser(response.User);
          return response;
        }),
        switchMap((response) =>
          from(DataProvider.getUserSessionDetails()).pipe(
            map((userSession) => ({ response, userSession }))
          )
        ),
        tap(() => {
          if (PlatformHelper.isTV()) {
            dispatch(replace(ROUTES.HOME));
          }
        }),
        mergeMap(({ response, userSession }) =>
          of(
            Actions.signInSuccess(response.User, {
              ...response.AuthorizationToken,
              IsOverTheDeviceLimit: userSession.overTheDeviceLimit,
            })
          )
        ),
        catchError((error: IErrorModel) => {
          captureMessage("Log In error", {
            level: "info",
            tags: {
              error: JSON.stringify(error),
            },
          });
          return of(Actions.signInFailure(error as IErrorModel));
        })
      );
    })
  );

export const signInViaTokenTVEpic = (
  action$: Observable<Types.ISignInViaTokenTVAction>
) =>
  action$.pipe(
    ofType(Consts.SIGN_IN_VIA_TOKEN_TV),
    switchMap(async (action: Types.ISignInViaTokenTVAction) => {
      const device = await DeviceHelper.getDeviceInfo();
      const payload = { ...action.data };
      payload.Device = device;
      return payload;
    }),
    switchMap((payload) => {
      return DataProvider.signInViaToken(payload).pipe(
        map((authResponse: IAuthResponseModel) => {
          return {
            authResponse,
            tvRefreshToken: authResponse?.AuthorizationToken?.RefreshToken,
          };
        }),
        switchMap((data) =>
          from(DataProvider.getUserSessionDetails()).pipe(
            map((session) => ({ ...data, session }))
          )
        )
      );
    }),
    switchMap(async (data) => {
      await StorageHelper.setUser(data.authResponse?.User);
      return Actions.signInViaTokenSuccess(data.authResponse.User, {
        ...data.authResponse.AuthorizationToken,
        IsOverTheDeviceLimit: data.session.overTheDeviceLimit,
      });
    }),
    catchError((error: IErrorModel) =>
      of(Actions.signInViaTokenFailure(error as IErrorModel))
    )
  );

export const signInViaTokenEpic = (
  action$: Observable<Types.ISignInViaTokenAction>
) =>
  action$.pipe(
    ofType(Consts.SIGN_IN_VIA_TOKEN),
    switchMap(async (action: Types.ISignInViaTokenAction) => {
      const device = await DeviceHelper.getDeviceInfo();
      const payload = { ...action.data };
      payload.Device = device;
      return payload;
    }),
    switchMap((payload) =>
      from(
        ajax({
          url: ROUTES.API_SIGN_IN_VIA_TOKEN,
          method: "POST",
          body: payload,
        })
      ).pipe(
        map(
          ({ response }: AjaxResponse<any>) =>
            response as unknown as {
              session: ISessionModel;
              authResponse: IAuthResponseModel;
              tvRefreshToken: string | undefined;
            }
        ),
        map((response) => {
          StorageHelper.setSession(response.authResponse.AuthorizationToken);
          return Actions.signInViaTokenSuccess(response.authResponse.User, {
            ...response.authResponse.AuthorizationToken,
            IsOverTheDeviceLimit: response.session.overTheDeviceLimit,
          });
        }),
        retry(2),
        catchError((error: IErrorModel) =>
          of(Actions.signInViaTokenFailure(error as IErrorModel))
        )
      )
    )
  );

export const signInSuccessEpic = (
  action$: Observable<
    Types.ISignInSuccessAction | Types.ISignInViaTokenSuccessAction
  >
) =>
  action$.pipe(
    ofType(Consts.SIGN_IN_SUCCESS, Consts.SIGN_IN_VIA_TOKEN_SUCCESS),
    tap((data) => {
      if (
        !PlatformHelper.isTV() &&
        data.payload.session?.AuthResult === AuthResult.OK
      ) {
        const tokensArr = data.payload.session?.RefreshToken;
        tokensArr && StorageHelper.setTvRefreshTokens(tokensArr);
      }
    }),
    mergeMap(
      (
        action: Types.ISignInSuccessAction | Types.ISignInViaTokenSuccessAction
      ) => {
        if (!action.payload.session?.IsOverTheDeviceLimit) {
          if (!PlatformHelper.isTV()) {
            return [
              getUserSubscriptions(),
              getUserSubprofiles(),
              getUserFavorites(),
              getUserAvailabilityKeys(),
              getUserConsents(),
            ];
          }
          return [
            getUserSubprofiles(),
            getUserFavorites(),
            getUserAvailabilityKeys(),
            getProductOfferingIds(),
          ];
        } else
          return [
            getUserSubprofiles(),
            getUserConsents(),
            getUserAvailabilityKeys(),
          ];
      }
    )
  );

export const afterSignIn = (
  action$: Observable<
    | Types.ISignInSuccessAction
    | Types.ISignInViaTokenSuccessAction
    | IGetUserSubprofilesSuccessAction
    | Types.ISignInWebSuccessAction
  >
) =>
  action$.pipe(
    ofType(Consts.SIGN_IN_VIA_TOKEN_SUCCESS, Consts.SIGN_IN_WEB_SUCCESS),
    switchMap((action) =>
      action$.pipe(
        ofType(UserConst.GET_USER_SUBPROFILES_SUCCESS),
        delay(100),
        tap(() => {
          eventService.publish(Consts.SIGN_IN_SUCCESS_EVENT, {});
        }),
        map(() => ({ type: Consts.SIGN_IN_SUCCESS_EVENT }))
      )
    )
  );

export const signInAnonymousEpic = (
  action$: Observable<Types.ISignInAnonymousAction>
) =>
  action$.pipe(
    ofType(Consts.SIGN_IN_ANONYMOUS),
    switchMap((action: Types.ISignInAnonymousAction) =>
      DeviceHelper.getDeviceInfo()
        .then((deviceInfo: IUserDeviceModel) => {
          const payload = { ...action.data };

          if (!payload.Device) {
            payload.Device = deviceInfo;
          }

          return lastValueFrom(DataProvider.signIn(payload));
        })
        .then((response: IAuthResponseModel) => {
          return Actions.signInAnonymousSuccess(
            response.User,
            response.AuthorizationToken
          );
        })
        .catch((error: IErrorModel) => {
          return Actions.signInAnonymousFailure(error);
        })
    )
  );

const signInAnonymousSuccessEpic = (
  action$: Observable<Types.ISignInAnonymousSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.SIGN_IN_ANONYMOUS_SUCCESS),
    switchMap(async (action: Types.ISignInAnonymousSuccessAction) => {
      await StorageHelper.setUser(action.payload.user);
      StorageHelper.setSession(action.payload.session);
      return Actions.setAuthenticated();
    })
  );

const refreshUserEpic = (action$: Observable<Types.IRefreshUserAction>) =>
  action$.pipe(
    ofType(Consts.REFRESH_USER),
    switchMap(async (action: Types.IRefreshUserAction) => {
      try {
        const user = await StorageHelper.getUser();
        user.AvatarUrl = action.payload.AvatarUrl;
        user.FullName = action.payload.FullName;
        user.PhoneNumber = action.payload.PhoneNumber;
        if (action.payload.Email) user.Email = action.payload.Email;
        await StorageHelper.setUser(user);
        return { type: "NEVER" };
      } catch {
        return { type: "NEVER" };
      }
    })
  );

const syncUserEpic = (action$: Observable<Types.ISyncUserAction>) =>
  action$.pipe(
    ofType(Consts.SYNC_USER),
    switchMap(async () => await StorageHelper.getUser()),
    switchMap(async (userInfo) => {
      if (!userInfo) {
        return Actions.syncUserFailure();
      }

      try {
        const session = StorageHelper.getSession();
        const userSession =
          session && (await DataProvider.getUserSessionDetails());

        return Actions.syncUserSuccess(userInfo, {
          ...session,
          IsOverTheDeviceLimit: userSession?.overTheDeviceLimit,
        });
      } catch (error) {
        return Actions.syncUserFailure();
      }
    }),
    catchError(() => {
      return of(Actions.syncUserFailure());
    })
  );

const syncWebUserEpic = (action$: Observable<Types.ISyncWebUserAction>) =>
  action$.pipe(
    ofType(Consts.SYNC_WEB_USER),
    switchMap(() =>
      from(ajax({ url: ROUTES.API_SYNC_DATA, method: "POST" })).pipe(
        map(
          ({ response }: AjaxResponse<any>) =>
            response as {
              usersData: any;
              session: any;
              userId: string;
              loggedIn?: boolean;
              isChild?: boolean;
              favorites: IUserFavoritesModel[];
            }
        )
      )
    ),
    switchMap(
      ({ usersData, session, userId, isChild, loggedIn, favorites }) => {
        if (loggedIn && usersData && session && userId) {
          authStorage.setSessionValues({
            users: usersData,
            session,
            userId: userId,
          });
        }
        return of({ usersData, session, userId, isChild, loggedIn, favorites });
      }
    ),
    map((data) => {
      if (data.loggedIn && data.usersData && data.session && data.userId) {
        return Actions.syncWebUserPending(
          data.session,
          data.isChild ?? false,
          data.userId,
          data.usersData,
          data.favorites
        );
      } else {
        return Actions.syncUserFailure();
      }
    })
  );

const syncUserSuccessEpic = (
  action$: Observable<Types.ISyncUserSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.SYNC_USER_SUCCESS),
    mergeMap((action: Types.ISyncUserSuccessAction) => {
      if (!action.payload.session?.IsOverTheDeviceLimit) {
        if (!PlatformHelper.isTV()) {
          return [
            getUserFavorites(),
            getUserConsents(),
            getUserAvailabilityKeys(),
            getUserSubprofiles(),
          ];
        }
        return [
          getUserFavorites(),
          getUserAvailabilityKeys(),
          getUserSubprofiles(),
        ];
      } else
        return [
          getUserConsents(),
          getUserAvailabilityKeys(),
          getUserSubscriptions(),
          getUserSubprofiles(),
        ];
    })
  );

const signOutEpic = (action$: Observable<Types.ISignOutAction>) =>
  action$.pipe(
    ofType(Consts.SIGN_OUT),
    mergeMap(async (action: Types.ISignOutAction) => {
      const device = await DeviceHelper.getDeviceInfo();
      await DataProvider.signOut(device);
      await StorageHelper.clearLocalStorage();
      return action;
    }),
    switchMap(() => of({ type: Consts.SIGN_OUT_SUCCESS })),
    tap((action) => {
      PlatformHelper.isTV()
        ? dispatch(RouteHelper.goToLoginViaCode(true))
        : eventService.publish(Consts.SIGN_OUT_SUCCESS_EVENT, action);
    }),
    catchError((error) => of(Actions.signOutFailure(error as IErrorModel)))
  );

const signOutSuccessEpic = (action$: Observable<Types.ISignOutSuccessAction>) =>
  action$.pipe(
    ofType(Consts.SIGN_OUT_SUCCESS),
    tap(() => {
      PlatformHelper.isTV() && offeringIdsService.deleteOfferingIds();
    }),
    mapTo(updateApplicationMenu(false))
  );

const getSessionDetailsEpic = (
  action$: Observable<Types.IGetSessionDetailsAction>
) =>
  action$.pipe(
    ofType(Consts.GET_SESSION_DETAILS),
    switchMap(async () => {
      try {
        const sessionDetails = await DataProvider.getUserSessionDetails();
        return Actions.getSessionDetailsSuccess(sessionDetails);
      } catch (error) {
        return Actions.getSessionDetailsFailure(error as IErrorModel);
      }
    })
  );

export const refreshTokenEpic = (
  action$: Observable<Types.IRefreshTokenAction>,
  state: StateObservable<IAppState>
) => {
  return action$.pipe(
    ofType(Consts.REFRESH_TOKEN),
    switchMap(() => {
      const token = state.value.auth.session?.RefreshToken as string;

      return DeviceHelper.getDeviceInfo()
        .then((deviceInfo: IUserDeviceModel) => {
          return DataProvider.refreshToken(token, deviceInfo);
        })
        .then((response: IAuthResponseModel) => {
          if (
            response.AuthorizationToken &&
            response.AuthorizationToken.AuthResult === AuthResult.OK
          ) {
            return Actions.refreshTokenSuccess(
              response.AuthorizationToken,
              response.User
            );
          }
          return Actions.refreshTokenFailure();
        })
        .catch(() => {
          return Actions.refreshTokenFailure();
        });
    })
  );
};

const refreshTokenSuccessEpic = (
  action$: Observable<Types.IRefreshTokenSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.REFRESH_TOKEN_SUCCESS),
    switchMap((action: Types.IRefreshTokenSuccessAction) => {
      return StorageHelper.setUser(action.payload.user).then(() => {
        StorageHelper.setSession(action.payload.session);
        return { type: "NEVER" };
      });
    })
  );

const refreshTokenFailureEpic = (
  action$: Observable<Types.IRefreshTokenFailureAction>
) =>
  action$.pipe(
    ofType(Consts.REFRESH_TOKEN_FAILURE),
    map((_action: Types.IRefreshTokenFailureAction) => {
      return StorageHelper.deleteUser().then(() => {
        return StorageHelper.deleteSession();
      });
    }),
    mapTo(push(ROUTES.BASE))
  );

export const changePasswordEpic = (
  action$: Observable<Types.IChangePasswordAction>
) =>
  action$.pipe(
    ofType(Consts.CHANGE_PASSWORD),
    switchMap((action: Types.IChangePasswordAction) =>
      from(DeviceHelper.getDeviceInfo()).pipe(
        map((Device) => ({
          Device,
          Passwords: action.payload,
        })),
        switchMap(
          ({
            Device,
            Passwords,
          }): Observable<IApiChangePasswordResponseModel> =>
            from(
              axios.post(ROUTES.API_CHANGE_PASSWORD, {
                Device,
                Passwords,
              })
            ).pipe(map(({ data }) => data))
        ),

        map((data) => {
          authStorage.updateSessionValues({
            session: data.authResponse.AuthorizationToken,
          });
          return Actions.changePasswordSuccess(
            data.authResponse.AuthorizationToken
          );
        }),
        catchError((error: IErrorModel) =>
          of(Actions.changePasswordFailure(error))
        )
      )
    )
  );

export const getLoginCodeEpic = (action$: Observable<Types.IGetLoginCode>) =>
  action$.pipe(
    ofType(Consts.GET_ACTIVATION_CODE),
    switchMap(() =>
      DataProvider.getLoginCode()
        .then((data) => {
          return Actions.getLoginCodeSuccess(data);
        })
        .catch((error: IErrorModel) => Actions.getLoginCodeFailure(error))
    )
  );

export const verifyLoginCodeEpic = (
  action$: Observable<Types.IVerifyLoginCode>
) =>
  action$.pipe(
    ofType(Consts.VALIDATE_ACTIVATION_CODE),
    switchMap(async (action: Types.IVerifyLoginCode) => {
      try {
        const loginCode = await DataProvider.verifyLogin(action.payload.code);
        return Actions.verifyLoginCodeSuccess(loginCode);
      } catch (error) {
        return Actions.verifyLoginCodeFailure(error as IErrorModel);
      }
    })
  );

export const getTokenFromCodeEpic = (
  action$: Observable<Types.IGetLoginTokenFromCode>
) =>
  action$.pipe(
    ofType(Consts.GET_LOGIN_TOKEN_FROM_CODE),
    switchMap((action: Types.IGetLoginTokenFromCode) =>
      DataProvider.getLoginTokenFromCode(action.payload.code)
        .then((data) => {
          return Actions.getLoginTokenFromCodeSuccess(data);
        })
        .catch((error: IErrorModel) =>
          Actions.getLoginTokenFromCodeFailure(error)
        )
    )
  );

export const authEpics = [
  syncUserEpic,
  syncWebUserEpic,
  syncUserSuccessEpic,
  signInEpic,
  signInViaTokenEpic,
  signInSuccessEpic,
  signInAnonymousEpic,
  signInAnonymousSuccessEpic,
  signOutEpic,
  signOutSuccessEpic,
  getSessionDetailsEpic,
  refreshTokenEpic,
  refreshTokenSuccessEpic,
  refreshTokenFailureEpic,
  changePasswordEpic,
  refreshUserEpic,
  signOutSuccessEpic,
  verifyLoginCodeEpic,
  getLoginCodeEpic,
  afterSignIn,
  signInViaTokenTVEpic,
];
