import { useEffect } from "react";
import { useRouter } from "next/router";

const EVENT = "routeChangeComplete";

export const useGetSortedList = (getList: () => void) => {
  const router = useRouter();

  const getSortedList = (url: string, { shallow }: { shallow: boolean }) => {
    if (shallow) {
      getList();
    }
  };

  useEffect(() => {
    router.events.on(EVENT, getSortedList);
    return () => {
      router.events.off(EVENT, getSortedList);
    };
  }, [router.query]);
};
