import { useEffect } from "react";
import { dispatch, useConsentsSelector, UserStore } from "@nf/common";
import { useSyncCompleted } from "./useSyncWebUser";

export const useUpdateUserConsent = () => {
  const { completedSuccess, initialized } = useConsentsSelector();
  const isAuthProcessCompleted = useSyncCompleted();
  useEffect(
    function updateUserConsents() {
      isAuthProcessCompleted &&
        !initialized &&
        !completedSuccess &&
        dispatch(UserStore.Actions.getUserConsents());
    },
    [isAuthProcessCompleted, completedSuccess, initialized]
  );
};
