export const SESSION_KEY = "session";
export const TV_TOKENS_KEY = "tvTokens";
export const USERS_KEY = "users";
export const USER_ID_KEY = "userId";
export const SOURCE_KEY = "source";
export const CURRENT_SUB_PROFILE_ID_KEY = "currentSubprofileId";
export const SIGN_IN_TOKEN_KEY = "signInToken";
export const CONFIGURATION_KEY = "configuration";
export const BACKEND_VERSION_KEY = "backendVersion";
export const DEVICE_KEY = "device";
export const VOLUME_KEY = "playerVolume";
export const PROFILES_KEY = "profiles";
export const PRODUCTS_KEY = "nf_products";
export const SUBTITLE_SETTINGS = "subtitles";
