/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { UserConsentsNames } from "../enums";
import { useSelector } from "../store";

export const useConsentsSelector = () => {
  const consents = useSelector((state) => state.user.consents);
  return {
    isLoading: consents.IsProcessing,
    error: consents.Error,
    consents: consents.Data,
    completedSuccess: consents.Completed,
    initialized: consents.Initialized,
  };
};

export const useConsentSelector = (type: UserConsentsNames) => {
  const consents = useSelector((state) => state.user.consents);
  return {
    consent: consents.Data?.find((consent) => consent.ConsentName === type),
    isLoading: consents.IsProcessing,
    error: consents.Error,
  };
};
