import { useMemo } from "react";
import { useCountry } from "./useCountry";
import { useLanguage } from "./useLanguage";

export const useRoutePathBuild = (): string => {
  const country = useCountry();
  const language = useLanguage();

  return useMemo(() => `/${country}/${language}/`, [country, language]);
};
