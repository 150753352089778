import { useCallback } from "react";
import { useRouter } from "next/router";
import { useIsLoading } from "../helpers/setIsLoadingContext";
import { useRoutePathBuild } from "./useRoutePathBuild";
import { UrlObject } from "url";
import { UrlHelper, userProfileHelper } from "@nf/common";

export const useReplacePage = () => {
  const router = useRouter();
  const { setIsLoading } = useIsLoading();
  const routePrefix = useRoutePathBuild();

  return useCallback(
    (urlObject: UrlObject | string, cover?: boolean, callback?: () => void) => {
      const removeDoubleSlashes = (url: string) => {
        url.replace(/([^:])(\/\/+)/g, "$1/");
        return url;
      };
      let url = urlObject;

      if (typeof url === "string") {
        const [pathname, query] = url.split("?");
        const queryObject = query ? UrlHelper.parse(query) : {};

        url = {
          pathname: removeDoubleSlashes(
            `${routePrefix}${userProfileHelper.cleanUp(pathname)}`
          ),
          query: queryObject,
        };
      }

      cover && setIsLoading(cover);
      router.replace(url, undefined, { shallow: false }).then(() => {
        callback && callback();
        setIsLoading(false);
      });
    },
    [routePrefix, router, setIsLoading]
  );
};
