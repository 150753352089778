import { useCallback } from "react";
import { IUserSubprofileModel, userProfileHelper } from "@nf/common";
import { ROUTES } from "@nf/constants";
import { useIsLoading } from "../helpers/setIsLoadingContext";
import { useReplacePage } from "./useReplacePage";
import { useRouter } from "next/router";
import { useRedirectUrlParam } from "./useRedirectUrlParam";

const selectDestinationPage = (subProfile?: IUserSubprofileModel): string =>
  subProfile?.Child
    ? (userProfileHelper.cleanUp(ROUTES.KIDS) as string)
    : (userProfileHelper.getInitialHomePage(subProfile) as string);

export const useSwitchProfileWithRedirection = () => {
  const router = useRouter();
  const { setIsLoading } = useIsLoading();
  const redirectToPage = useReplacePage();
  const redirectUrl = useRedirectUrlParam();

  return useCallback(
    (subProfile?: IUserSubprofileModel) => {
      if (
        router.asPath.includes(ROUTES.SETTINGS_SCREEN) &&
        !subProfile?.Child
      ) {
        const settingPath = router.asPath.split("/").slice(-2).join("/");
        redirectToPage(`/${settingPath}`, true);
      } else if (redirectUrl) {
        redirectToPage(`/${redirectUrl}`, true);
      } else {
        redirectToPage(selectDestinationPage(subProfile), true);
      }
    },
    [redirectToPage, setIsLoading, router.asPath]
  );
};
