/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { getRandomString } from "@nf/helpers";

export class GuidHelper {
  static empty = "00000000-0000-0000-0000-000000000000";

  static maxValue = "FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF";

  static newGuid() {
    return getRandomString().substring(0, GuidHelper.maxValue.length);
  }
}
