/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useSelector } from "../store";
import { ANONYMOUS_ID } from "@nf/constants";

export const useIsLoggedIn = (): boolean =>
  useSelector((state) =>
    state.auth.user?.Id ? state.auth.user.Id !== ANONYMOUS_ID : false
  );
