/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppConfig, HTTP_METHOD } from "@nf/constants";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../../../../services";
import { IChannelModel } from "../models";

export class ChannelService {
  get url(): string {
    return `/v1/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}`;
  }

  public onNow = (channelId: string): Observable<IChannelModel> => {
    return new Observable(
      (observer: Observer<IChannelModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/channel/onnow/${channelId}`,
        })
    );
  };
}
