export const API = {
  SEARCH_PAGE_SIZE: 20,
  ITEMS_PAGE_SIZE: 10,
};

export const CONFIGURATION = {
  FOOTER_SCREEN_ID: "footer",
  MENU_SCREEN_ID: "menu",
  HOME_SCREEN_ID: "page",
  SEARCH_SCREEN_ID: "search",
};

export const ACTION = {
  NAVIGATE_TO_PAGE: "NavigateToPage",
  NAVIGATE_TO_DETAILS: "NavigateToDetails",
  EXTERNAL_LINK: "ExternalLink",
  DEFAULT: "default",
};

export const COMPONENT_TYPE = {
  HERO_BANNER: "herobanner",
  CAROUSEL: "carousel",
  MENU_ITEM: "menu_item",
  IMAGE_COMPONENT: "image",
  HEROBANNER_ITEM: "herobanner_item",
  TEXT: "text",
  IFRAME: "iframe",
  TAG_TYPE: "tagtype",
};

export const COMPONENT_SUB_TYPE = {
  CUSTOM: "Custom",
  ASSET: "Asset",
};

export const COMPONENT_COLECTIONS = {
  HERO_BANNER_ITEMS: "heroBannerItems",
  MENU_ITEMS: "menuItems",
};

export const ASSET_IMAGE_ORIENTATION = {
  LANDSCAPE: "LANDSCAPE",
  PORTRAIT: "PORTRAIT",
  SQUARE: "SQUARE",
};

export const ASSET_IMAGE_TAG = {
  FRAME: "frame",
  THUMBNAIL: "thumbnail",
  HIGHLIGHT: "highlight",
  COVER: "cover",
  POSTER: "poster",
  BANNER: "banner",
  HERO_FRONTPAGE: "hero_frontpage",
  HERO_ASSET_DETAILS: "hero_asset_details",
  HERO_ASSET_DETAIL: "hero_asset_detail",
  HIGHLIGHT_ASSET_DETAILS: "highlight_asset_details",
  HOVER_ASSET: "hover_asset",
  PROMO: "promo",
  LOGO: "logo",
};

export const ASSET_TYPE = {
  COLLECTION: "COLLECTION",
  EPISODE: "EPISODE",
  TV_SHOW: "TV_SHOW",
  TV_CHANNEL: "TV_CHANNEL",
  TRAILER: "TRAILER",
  EXTRA: "EXTRA",
  RELATED: "RELATED",
};

export const IMAGE_ORIENTATION = {
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
};

export const CAROUSEL_LAYOUT = {
  GRID: "grid",
  CAROUSEL: "carousel",
  LIST: "list",
};

export const APP_IMAGES_TYPES = {
  LOGO: "logo",
  BACKGROUND: "background",
  FAV_ICON: "favIcon",
};

export const BLACKLISTED_URL = {
  EMPTY_CAROUSEL: "/content/asset?fieldSet=ALL",
};
