import { useEffect } from "react";
import { Subscription } from "rxjs";

export const useSubscription = (subscription: Subscription) => {
  useEffect(() => {
    return () => {
      subscription.unsubscribe();
    };
  }, []);
};
