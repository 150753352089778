/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum AssetType {
  Series = "TV_SHOW",
  Video = "VOD",
  Episode = "EPISODE",
  Season = "SEASON",
  Live = "LIVE",
  Intro = "INTRO",
  Channel = "CHANNEL",
  Program = "PROGRAM",
  Package = "PACKAGE",
  Premiere = "VIDEO_PREMIERE",
  Category = "CATEGORY",
  Movie = "MOVIE",
  Collection = "COLLECTION",
}
