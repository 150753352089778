/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const GET_USER_FAVORITES = "GET_USER_FAVORITES";
export const GET_USER_FAVORITES_SUCCESS = "GET_USER_FAVORITES_SUCCESS";
export const GET_USER_FAVORITES_FAILURE = "GET_USER_FAVORITES_FAILURE";
export const ADD_TO_USER_FAVORITES = "ADD_TO_USER_FAVORITES";
export const REMOVE_FROM_USER_FAVORITES = "REMOVE_FROM_USER_FAVORITES";
export const GET_USER_CONSENTS = "GET_USER_CONSENTS";
export const GET_USER_CONSENTS_SUCCESS = "GET_USER_CONSENTS_SUCCESS";
export const GET_USER_CONSENTS_FAILURE = "GET_USER_CONSENTS_FAILURE";
export const UPDATE_USER_CONSENTS = "UPDATE_USER_CONSENTS";
export const UPDATE_USER_CONSENTS_SUCCESS = "UPDATE_USER_CONSENTS_SUCCESS";
export const UPDATE_USER_CONSENTS_FAILURE = "UPDATE_USER_CONSENTS_FAILURE";
export const GET_USER_DEVICES = "GET_USER_DEVICES";
export const GET_USER_DEVICES_SUCCESS = "GET_USER_DEVICES_SUCCESS";
export const GET_USER_DEVICES_FAILURE = "GET_USER_DEVICES_FAILURE";
export const DELETE_USER_DEVICE = "DELETE_USER_DEVICE";
export const DELETE_USER_DEVICE_SUCCESS = "DELETE_USER_DEVICE_SUCCESS";
export const DELETE_USER_DEVICE_FAILURE = "DELETE_USER_DEVICE_FAILURE";
export const GET_WATCH_HISTORY = "GET_WATCH_HISTORY";
export const GET_WATCH_HISTORY_SUCCESS = "GET_WATCH_HISTORY_SUCCESS";
export const GET_WATCH_HISTORY_FAILURE = "GET_WATCH_HISTORY_FAILURE";
export const DELETE_WATCH_HISTORY = "DELETE_WATCH_HISTORY";
export const DELETE_WATCH_HISTORY_SUCCESS = "DELETE_WATCH_HISTORY_SUCCESS";
export const DELETE_WATCH_HISTORY_FAILURE = "DELETE_WATCH_HISTORY_FAILURE";
export const GET_USER_SUBSCRIPTIONS = "GET_USER_SUBSCRIPTIONS";
export const GET_USER_SUBSCRIPTIONS_SUCCESS = "GET_USER_SUBSCRIPTIONS_SUCCESS";
export const GET_USER_SUBSCRIPTIONS_FAILURE = "GET_USER_SUBSCRIPTIONS_FAILURE";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";
export const PAUSE_SUBSCRIPTION = "PAUSE_SUBSCRIPTION";
export const PAUSE_SUBSCRIPTION_SUCCESS = "PAUSE_SUBSCRIPTION_SUCCESS";
export const PAUSE_SUBSCRIPTION_FAILURE = "PAUSE_SUBSCRIPTION_FAILURE";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_PENDING = "UPDATE_SUBSCRIPTION_PENDING";
export const UPDATE_SUBSCRIPTION_FAILURE = "UPDATE_SUBSCRIPTION_FAILURE";
export const GET_USER_AVAILABILITY_KEYS = "GET_USER_AVAILABILITY_KEYS";
export const GET_USER_AVAILABILITY_KEYS_SUCCESS =
  "GET_USER_AVAILABILITY_KEYS_SUCCESS";
export const GET_USER_AVAILABILITY_KEYS_FAILURE =
  "GET_USER_AVAILABILITY_KEYS_FAILURE";
export const GET_PRODUCT_OFFERING_IDS = "GET_PRODUCT_OFFERING_IDS";
export const GET_PRODUCT_OFFERING_IDS_SUCCESS =
  "GET_PRODUCT_OFFERING_IDS_SUCCESS";
export const GET_PRODUCT_OFFERING_IDS_FAILURE =
  "GET_PRODUCT_OFFERING_IDS_FAILURE";
export const GET_USER_SUBPROFILES = "GET_SUBPROFILES";
export const GET_USER_SUBPROFILES_SUCCESS = "GET_SUBPROFILES_SUCCESS";
export const GET_USER_SUBPROFILES_FAILURE = "GET_SUBPROFILES_FAILURE";
export const CREATE_USER_SUBPROFILES = "CREATE_USER_SUBPROFILES";
export const CREATE_USER_SUBPROFILES_SUCCESS =
  "CREATE_USER_SUBPROFILES_SUCCESS";
export const CREATE_USER_SUBPROFILES_FAILURE =
  "CREATE_USER_SUBPROFILES_FAILURE";
export const EDIT_USER_SUBPROFILES = "EDIT_USER_SUBPROFILES";
export const EDIT_USER_SUBPROFILES_SUCCESS = "EDIT_USER_SUBPROFILES_SUCCESS";
export const EDIT_USER_SUBPROFILES_FAILURE = "EDIT_USER_SUBPROFILES_FAILURE";
export const DELETE_USER_SUBPROFILES = "DELETE_USER_SUBPROFILES";
export const DELETE_USER_SUBPROFILES_SUCCESS =
  "DELETE_USER_SUBPROFILES_SUCCESS";
export const DELETE_USER_SUBPROFILES_FAILURE =
  "DELETE_USER_SUBPROFILES_FAILURE";
export const SELECT_USER_SUBPROFILE = "SELECT_USER_SUBPROFILE";
export const SELECT_USER_SUBPROFILE_SUCCESS = "SELECT_USER_SUBPROFILE_SUCCESS";
export const SELECT_USER_SUBPROFILE_FAILURE = "SELECT_USER_SUBPROFILE_FAILURE";
export const SELECT_USER_SUBPROFILE_COMPLETED =
  "SELECT_USER_SUBPROFILE_COMPLETED";
export const GET_USER_PARENTAL_RATINGS = "GET_USER_PARENTAL_RATINGS";
export const GET_USER_PARENTAL_RATINGS_SUCCESS =
  "GET_USER_PARENTAL_RATINGS_SUCCESS";
export const GET_USER_PARENTAL_RATINGS_FAILURE =
  "GET_USER_PARENTAL_RATINGS_FAILURE";
export const SET_USER_SUBPROFILE_PIN_CODE = "SET_USER_SUBPROFILE_PIN_CODE";
export const SET_USER_SUBPROFILE_PIN_CODE_SUCCESS =
  "SET_USER_SUBPROFILE_PIN_CODE_SUCCESS";
export const SET_USER_SUBPROFILE_PIN_CODE_FAILURE =
  "SET_USER_SUBPROFILE_PIN_CODE_FAILURE";
export const DELETE_USER_SUBPROFILE_PIN_CODE =
  "DELETE_USER_SUBPROFILE_PIN_CODE";
export const DELETE_USER_SUBPROFILE_PIN_CODE_SUCCESS =
  "DELETE_USER_SUBPROFILE_PIN_CODE_SUCCESS";
export const DELETE_USER_SUBPROFILE_PIN_CODE_FAILURE =
  "DELETE_USER_SUBPROFILE_PIN_CODE_FAILURE";
export const SWITCH_USER_INITIAL_HOME_PAGE_EVENT =
  "SWITCH_USER_INITIAL_HOME_PAGE_EVENT";

export const CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS =
  "CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS";
export const CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS_SUCCESS =
  "CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS_SUCCESS";
export const CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS_FAILURE =
  "CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS_FAILURE";
