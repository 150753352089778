/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IPersonModel } from "../../models";
import * as Consts from "./consts";
import { IPeopleState, PeopleActionsTypes } from "./types";

export const initialState: IPeopleState = {
  search: {
    Entities: [],
    TotalCount: 0,
  },
};

export const peopleReducer = (
  state = initialState,
  action: PeopleActionsTypes
): IPeopleState => {
  switch (action.type) {
    case Consts.SEARCH_PEOPLE: {
      return {
        ...state,
        search: {
          ...state.search,
          Filter: action.filter,
          Error: undefined,
          IsLoading: true,
        },
      };
    }
    case Consts.SEARCH_PEOPLE_SUCCESS: {
      const pageNumber = action.payload?.Filter?.PageNumber;
      let entities: IPersonModel[] = [];

      if (pageNumber === 1) {
        entities = action.payload.Entities;
      } else if (pageNumber && pageNumber > 1) {
        entities = [
          ...(state.search?.Entities || []),
          ...action.payload.Entities,
        ];
      }

      return {
        ...state,
        search: {
          ...state.search,
          Entities: entities,
          Error: undefined,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_PEOPLE_FAILURE: {
      return {
        ...state,
        search: {
          ...state.search,
          Entities: [],
          TotalCount: 0,
          Error: action.error,
          IsLoading: false,
        },
      };
    }
    default:
      return state;
  }
};
