/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAuthResponseModel, ITokenModel } from "./Auth";
import { IConfigurationModel } from "./Configuration";
import { IMediaListModel } from "./Media";
import {
  IUserDeviceModel,
  IUserStorageModel,
  IUserSubprofileModel,
} from "./User";

import { PlayerSubtitleSize, PlayerSubtitleStyle } from "../enums";

export type UsersStorage = { [userId: string | number]: IUserStorageModel };

export type SourceStorage = { [sourceId: string]: IMediaListModel };

export type SubtitlesSettingsStorage = {
  size?: PlayerSubtitleSize;

  style?: PlayerSubtitleStyle;
};

export enum LanguageStorage {
  defaultKey = "defaultLng",
  defaultValue = "defaultLanguageChanged",
  i18nextKey = "i18nextLng",
}

export interface StorageContent {
  configuration?: IConfigurationModel;

  session?: ITokenModel;

  backendVersion?: IAuthResponseModel;

  userId?: string | number;

  users?: UsersStorage;

  language?: string;

  source?: SourceStorage;

  eventsMonth?: string;

  device?: IUserDeviceModel;

  i18nextLng?: LanguageStorage;

  defaultLng?: LanguageStorage;

  currentSubprofileId?: string;

  pinCode?: number;

  tvTokens?: string[];

  signInToken?: string;

  playerVolume?: number;

  profiles?: Partial<
    Pick<
      IUserSubprofileModel,
      "UserId" | "InitialHomePage" | "DisplayName" | "CurrentProfile"
    >
  >;

  nf_products?: string;

  subtitles?: SubtitlesSettingsStorage;
}
