/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { lastValueFrom } from "rxjs";
import { RouteHelper, StorageHelper } from "../../../../helpers";
import { IErrorModel, ITokenModel } from "../../../../models";
import { AuthStore, dispatch } from "../../../../store";
import { AppConfig } from "@nf/constants";
import { AuthService } from "./AuthService";
import { authStorage } from "../../../../services";

const INVALID_TOKEN_MESSAGE = "INVALID_SESSION_TOKEN";
let isRefreshing = false;
let isTokenInvalidAfterRefresh = false;
let failedQueue: {
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const authService: AuthService = new AuthService();

const processQueue = (error?: IErrorModel, token?: string) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

export const HttpRequestFulfilledInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const session = authStorage.getSessionBehavior();
  const loginRegex = /\/login/gi;

  if (!config.headers) {
    config.headers = {};
  }
  if (
    session &&
    session.Token &&
    !config.headers.hasOwnProperty("Authorization") &&
    config?.url &&
    !loginRegex.test(config.url)
  ) {
    config.headers["Authorization"] = `Bearer ${session.Token}`;
  }

  if (!config.headers?.["Content-Type"]) {
    config.headers["Content-Type"] = "application/json; charset=UTF-8";
  }

  config.url = config.url?.replace("{customer}", AppConfig.Customer);
  config.url = config.url?.replace("{businessUnit}", AppConfig.BusinessUnit);

  return config;
};

export const HttpRequestRejectedInterceptor = (error: IErrorModel): any => {
  return Promise.reject(error);
};

const LOGIN_URL = "/v1/login";

export const HttpResponseFulfilledInterceptor = (
  response: AxiosResponse
): AxiosResponse => response;

export const HttpResponseRejectedInterceptor = async (
  error: IErrorModel | any
): Promise<any> => {
  const { config, response } = error;
  const originalRequest = config;

  if (response?.config?.url === LOGIN_URL || originalRequest === undefined) {
    return Promise.reject(error);
  }

  if ((!response || response?.status === 401) && !originalRequest?._retry) {
    // Check if refresh token request failed
    if (response?.config?.url === "/v1/refresh-tokens") {
      isRefreshing = false;

      authStorage.deleteSession();
      // Remove user data from store
      if (typeof window !== "undefined") {
        // Clear session data as it is invalid
        await StorageHelper.deleteSession();
        dispatch(AuthStore.Actions.refreshTokenFailure());
        RouteHelper.goToBase();
      }

      return Promise.reject(new Error("Unable to refresh token"));
    }

    if (isTokenInvalidAfterRefresh) {
      if (
        response.data.message === INVALID_TOKEN_MESSAGE &&
        (response?.status === 401 || response?.status === 403)
      ) {
        isTokenInvalidAfterRefresh = false;
        isRefreshing = false;

        if (typeof window !== "undefined") {
          await StorageHelper.clearUserData();
          dispatch(AuthStore.Actions.refreshTokenFailure());
          RouteHelper.goToLogin(true);
        }
      }
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;

          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const session = authStorage.getSessionBehavior() as ITokenModel;

    try {
      let newSession: ITokenModel | undefined = undefined;
      const refreshToken = session?.RefreshToken;

      if (refreshToken) {
        // Try to refresh token
        const refreshTokenResponse = await lastValueFrom(
          authService.refreshToken({ refresh_token: refreshToken })
        );

        if (refreshTokenResponse.idToken) {
          session.Token = refreshTokenResponse.idToken;
          authStorage.setSessionBehavior(session);
          newSession = session;
          // refresh user products if have changed since the last update
          if (typeof window !== "undefined") {
            StorageHelper.setSession(session);
            dispatch(AuthStore.Actions.refreshTokenSuccess(newSession));
          }
        }
      }

      if (!newSession) {
        authStorage.deleteSession();
        if (typeof window !== "undefined") {
          // Clear session data as it is invalid
          await StorageHelper.clearUserData();
          RouteHelper.goToLogin(true);
        }

        return Promise.reject(error);
      }

      originalRequest.headers["Authorization"] = "Bearer " + newSession.Token;
      processQueue(undefined, newSession.Token);

      return Promise.resolve(axios(originalRequest));
    } catch (error) {
      processQueue(error as IErrorModel);
      return Promise.reject(error);
    } finally {
      isRefreshing = false;
      isTokenInvalidAfterRefresh = true;
    }
  }

  return Promise.reject(error);
};
