/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum UserSubscriptionStatus {
  ACTIVE = "active",
  CANCELLED = "cancelled",
  HOLD = "hold",
  TERMINATED = "terminated"
}
