import { useMemo } from "react";
import { DEFAULT_LANGUAGE_CODE, LANGUAGE_COOKIE } from "@nf/constants";
import { useRouter } from "next/router";
import { getCookie } from "cookies-next";

export const useLanguage = () => {
  const router = useRouter();
  const languageCookie = getCookie(LANGUAGE_COOKIE);
  const { language: languageQuery } = router.query;

  const language: string = useMemo(() => {
    if (languageQuery) {
      return languageQuery as string;
    }

    if (languageCookie) {
      const lang = languageCookie as string;

      return lang || DEFAULT_LANGUAGE_CODE;
    }

    return DEFAULT_LANGUAGE_CODE;
  }, [languageQuery, languageCookie]);

  return language;
};
