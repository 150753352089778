/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum ImageSize {
  FRAME_ITEM_WIDTH = 624,
  FRAME_ITEM_HEIGHT = 351,

  FRAME_TV_ITEM_WIDTH = 400,
  FRAME_TV_ITEM_HEIGHT = 218,

  COVER_ITEM_WIDTH = 340,
  COVER_ITEM_HEIGHT = 510,

  HOVER_ITEM_WIDTH = 790,
  HOVER_ITEM_HEIGHT = 526,
}
