/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AuthResult,
  LoginCodeStatusTypes,
  UserConsentsState,
  UserSubscriptionStatus,
} from "../../../../enums";
import { HTTP_RESPONSE_TYPE } from "@nf/constants";
import {
  IAuthResponseModel,
  IErrorModel as ICommonErrorModel,
  IPaymentDetailsModel,
  IPaymentInvoiceModel,
  IPaymentOfferModel,
  IPaymentOrderModel,
  IUserConsentModel as ICommonUserConsentModel,
  IUserInfoModel,
  IUserModel,
  IUserSubscriptionModel,
  IAuthSignUpResponseModel,
  IValidCouponModel,
  IOrderStatusModel,
} from "../../../../models";
import {
  IConsentListModel,
  IConsentModel,
  ICustomerModel,
  ILoginResponseModel,
  IOfferResponseModel,
  IOfferSwitchModel,
  IOfferSwitchModelResponse,
  IOrderStatusResponseModel,
  IPaymentDetailsResponseModel,
  IPaymentInvoiceResponseModel,
  IPaymentOrderResponseModel,
  ISignupResponseModel,
  ISubscriptionModel,
  ISubscriptionSwitchModelResponse,
  IUserConsentModel,
  IValidateCouponResponseModel,
} from "../models";
import { TimeHelper } from "../../../../helpers";

export class ModelsMapperHelper {
  static toAuthResponseModel(data: ILoginResponseModel): IAuthResponseModel {
    return {
      AuthorizationToken: {
        AuthResult: AuthResult.OK,
        Token: data.idToken,
        RefreshToken: data.refreshToken,
        DataToken: data.ottToken,
      },
    };
  }

  static toAuthSignUpResponseModel(
    data: ISignupResponseModel
  ): IAuthSignUpResponseModel {
    return {
      Message: data.message,
    };
  }

  static toUserInfoModel(data: ICustomerModel): IUserInfoModel {
    return {
      Id: data.id,
      UserName: data.email,
      FullName: `${data.first_name} ${data.last_name}`,
      Email: data.email,
      PhoneNumber: data.phone,
      Locale: data.locale,
      Products: data.subscriptions
        ? data.subscriptions.map((sub) => {
            return { Id: sub.id };
          })
        : undefined,
    };
  }

  static toUserModel(data: ICustomerModel): IUserModel {
    const result: IUserModel = {
      Id: data.id,
      FullName: `${data.first_name} ${data.last_name}`,
      FirstName: data.first_name,
      LastName: data.last_name,
      PhoneNumber: data.phone,
    };
    if (data?.email) result.Email = data.email;

    return result;
  }

  static toErrorModel(error: any): ICommonErrorModel {
    if (error === LoginCodeStatusTypes.expired)
      return {
        Message: "web_token_expired",
        ResultType: HTTP_RESPONSE_TYPE.ERROR,
        Data: error?.data,
      };

    //disabled because of usage in server side
    // if (DOMException) {
    //   if (
    //     (error instanceof DOMException && error.code === 22) ||
    //     error.code === 1014 ||
    //     error.name === "QuotaExceededError" ||
    //     error.name === "NS_ERROR_DOM_QUOTA_REACHED"
    //   ) {
    //     return {
    //       Message: "Login failed. Please clean cache",
    //       ResultType: HTTP_RESPONSE_TYPE.ERROR,
    //     };
    //   }
    // }

    if (error?.message) {
      return {
        Message: error.message,
        ResultType: HTTP_RESPONSE_TYPE.ERROR,
        Data: error?.data,
      };
    } else if (error.errors?.[0]?.message) {
      return {
        Message: error?.errors?.[0]?.message,
        ResultType: HTTP_RESPONSE_TYPE.ERROR,
        Data: error?.data,
      };
    } else {
      return {
        Message: error?.errors?.[0]?.message,
        ResultType: HTTP_RESPONSE_TYPE.ERROR,
        Data: error?.data,
      };
    }
  }

  static toConsentModel(
    response: [
      IConsentListModel<IConsentModel[]>,
      IConsentListModel<IUserConsentModel[]>
    ],
    locale: string
  ) {
    const userLocale = locale.split("_")[1].toLocaleLowerCase();

    const consents: ICommonUserConsentModel[] = response[0].consents.map(
      (newestConsent) => {
        const consent = response[1].consents.find(
          (consent) => consent.name === newestConsent.name
        );

        if (!consent)
          // NEW CONSENT
          return {
            Accepted: false,
            ConsentName: newestConsent.name,
            ConsentVersion: newestConsent.version,
            ConsentRequired: newestConsent.is_required,
            ConsentContentUrl: newestConsent.value,
            ConsentContent: newestConsent.label,
            NeedUpdate: newestConsent.is_required,
          };
        else
          return {
            Accepted: consent.state === UserConsentsState.Accepted,
            ConsentName: newestConsent.name,
            ConsentVersion: newestConsent.version,
            ConsentDateOfAccept: consent.date,
            ConsentRequired: newestConsent.is_required,
            ConsentContentUrl: newestConsent.value,
            ConsentContent: newestConsent.label,
            NeedUpdate: consent.is_update_needed,
          };
      }
    );
    return consents.reduce((arr: ICommonUserConsentModel[], consent) => {
      const [consentLocale, consentName] = consent.ConsentName.split("_");
      if (consentLocale === userLocale)
        arr.push({ ...consent, ConsentName: consentName });
      return arr;
    }, []);
  }

  static toSubscriptionModel(data: ISubscriptionModel): IUserSubscriptionModel {
    const ExpirationDate = data.expires_at * 1000;
    const isExpired = ExpirationDate < Date.now();
    const Period = data?.offer_period ?? "";
    const IsActive = data.status === UserSubscriptionStatus.ACTIVE; // IsActive on false means canceled
    const NextPaymentCurrency = data?.next_payment_currency;
    const NextPaymentPrice = data?.next_payment_price;
    const CampaignName = data?.campaign_name;
    const DiscountPeriod = data?.discount_period;
    const DiscountAmount = data?.discount_amount;
    const SwitchToOfferId = data?.switch_to_offer_id;
    const SwitchToOfferTitle = data?.switch_to_offer_title;
    const SwitchToOfferPeriod = data?.switch_to_offer_period;
    const SubscriptionStatus = data.status;

    if (data?.discount_amount) {
      const percentageDiscount = data?.discount_amount
        ? data.discount_amount * 100
        : 0;

      return {
        Id: data.id,
        OfferId: data?.offer_id,
        IsActive,
        ExpirationDate,
        IsDiscount: !!percentageDiscount,
        CouponCode: data?.coupon_code,
        PercentageDiscount: percentageDiscount,
        PartnerName: data.partner_name,
        NextPaymentCurrency,
        NextPaymentPrice,
        Period,
        isExpired,
        CampaignName,
        DiscountPeriod,
        DiscountAmount,
        SwitchToOfferId,
        SwitchToOfferTitle,
        SwitchToOfferPeriod,
        SubscriptionStatus,
      };
    }

    return JSON.parse(
      // Remove all fields with undefined so as not to delete the previously saved values
      JSON.stringify({
        Id: data.id,
        OfferId: data.offer_id,
        OfferTitle: data.offer_title,
        IsActive,
        ExpirationDate,
        IsPartnerSubscription: data?.is_partner_subscription,
        NextPaymentCurrency,
        NextPaymentPrice,
        PartnerName: data.partner_name,
        IsGiftCard: false,
        Period,
        isExpired,
        CampaignName,
        DiscountPeriod,
        DiscountAmount,
        SwitchToOfferId,
        SwitchToOfferTitle,
        SwitchToOfferPeriod,
        SubscriptionStatus,
      })
    );
  }

  static toPaymentOfferModel(data: IOfferResponseModel): IPaymentOfferModel {
    return {
      Id: data.id,
      Title: data.title,
      Price: data.price,
      Period: data.period,
      Currency: data.currency,
      IsTaxIncluded: data.is_tax_included,
      Handle: data.handle,
      DefaultOffer: false,
    };
  }

  static toPaymentOrderModel(
    data: IPaymentOrderResponseModel
  ): IPaymentOrderModel {
    return {
      CheckoutUrl: data.checkout_url,
      OrderId: data.order_id,
    };
  }

  static toPaymentDetailsModel(
    data: IPaymentDetailsResponseModel
  ): IPaymentDetailsModel {
    return {
      Id: data.id,
      CardType: data.card?.card_type,
      PaymentMethod: data.payment_type,
      ExpirationDate: data.card?.exp_date,
      MaskedCard: data.card?.masked_card,
    };
  }

  static toInvoiceModel(
    data: IPaymentInvoiceResponseModel
  ): IPaymentInvoiceModel {
    return {
      TransationId: data.transaction_id,
      OfferId: data.offer_id,
      OfferTitle: data.offer_title,
      OfferPeriod: data.offer_period,
      CustomerEmail: data.customer_email,
      CustomerCountry: data.customer_country,
      CustomerCurrency: data.customer_currency,
      TransationPrice: data.transaction_price_incl_tax,
      PaymentSuccess: data.payment_status === "paid",
      TransationDate: TimeHelper.format(
        data.transaction_date * 1000,
        "DD/MM-YY"
      ).toLowerCase(),
      PeriodFrom: TimeHelper.format(
        data.period_from * 1000,
        "DD/MM-YY"
      ).toLowerCase(),
      PeriodTo: TimeHelper.format(
        data.period_to * 1000,
        "DD/MM-YY"
      ).toLowerCase(),
      PaymentMethod: data.payment_method,
    };
  }

  static toOfferSwitchModel(
    data: IOfferSwitchModelResponse | ISubscriptionSwitchModelResponse
  ): IOfferSwitchModel {
    return {
      Currency: data.currency,
      Direction: data.direction,
      NextPaymentPrice: data.next_payment_price,
      Period: data.period,
      SavingsPrice: data.savings_price,
      Title: data.title,
      Price: data.next_payment_price,
      ToOfferId: data.to_offer_id,
    };
  }

  static toValidCouponModel(
    data: IValidateCouponResponseModel
  ): IValidCouponModel {
    return {
      Coupon: {
        Code: data.coupon.code,
        ValidUntil: data.coupon.valid_until,
        RedemptionStrategy: data.coupon.redemption_strategy,
      },
      Discount: {
        Currency: data.discount.currency,
        Handle: data.discount.handle,
        FixedPeriodUnit: data.discount.fixed_period_unit,
        FixedPeriod: data.discount.fixed_period,
        Percentage: data.discount.percentage,
        Amount: data.discount.amount,
        Forever: data.discount.fixed_period === null,
        IsFullDiscount: data.discount.percentage === 100,
      },
      Offers: data.offers.map((offer) => ({
        Id: `${offer.id}`,
        Handle: offer.handle,
        Name: offer.name,
        DiscountAmount: offer.discount_amount,
        DiscountedPrice: offer.discounted_price,
        TaxValue: offer.tax_value,
        Price: parseFloat(offer.price),
        Currency: offer.currency,
        Period: offer.period,
        RequiredPaymentDetails: offer.required_payment_details,
      })),
    };
  }

  static toOrderStatusModel(
    data: IOrderStatusResponseModel
  ): IOrderStatusModel {
    const orderStatus: IOrderStatusModel = {
      Order: {
        Status: data.order.status,
        RedbeeStatus: data.order.redbee_status,
        AgillicStatus: data.order.agillic_status,
      },
      Subscription: null,
    };

    if (data.error) {
      orderStatus.Error = {
        Message: data.error.message,
        Stack: data.error.stack,
      };
    }

    if (data.subscription) {
      orderStatus.Subscription = {
        Id: data.subscription.id,
        Status: data.subscription.status,
      };
    }

    if (data.reepay_payment_method) {
      orderStatus.PaymentMethod = {
        Id: data.reepay_payment_method.id,
        PaymentType: data.reepay_payment_method.payment_type,
        CardType: data.reepay_payment_method.card.card_type,
        MaskedCard: data.reepay_payment_method.card.masked_card,
        ExpirationDate: data.reepay_payment_method.card.exp_date,
      };
    }

    return orderStatus;
  }
}
