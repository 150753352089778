/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const sortByKey = <T, Key extends keyof T>(items: T[], key: Key) =>
  items.sort((a, b) => {
    if (a[key] && b[key]) {
      if (a[key] > b[key]) {
        return 1;
      } else if (a[key] < b[key]) {
        return -1;
      }
    }
    return 0;
  });
