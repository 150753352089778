import { MediaImageType } from "../enums";
import { IMediaImageModel } from "../models";
import { UrlHelper } from "./url.helper";
import { ImageSize } from "@nf/constants";

export class ImageHelper {
  static getImageByType(
    images?: IMediaImageModel[],
    imageType?: MediaImageType
  ) {
    return images?.find((img) => img.ImageTypeCode === imageType);
  }

  static getHighlightsImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Highlights);

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Thumbnail);
    }

    return image;
  }

  static getHighlightsImageUrl(
    images?: IMediaImageModel[],
    format: string | null = "webp"
  ): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getHighlightsImage(images);

    if (image) {
      return `${image.Url}${format ? `?format=${format}` : ""}`;
    }

    return undefined;
  }

  static getSquareImage(images?: IMediaImageModel[]) {
    const image = this.getImageByType(images, MediaImageType.Square);

    return image;
  }

  static getSquareImageUrl(images?: IMediaImageModel[]) {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getSquareImage(images);

    if (image) {
      return image.Url;
    }
  }

  static getBackgroundImageUrl(
    images?: IMediaImageModel[]
  ): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getBackgroundImage(images);

    if (image) {
      return `${image.Url}?format=webp`;
    }

    return undefined;
  }

  static getBackgroundImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Background);

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Thumbnail);
    }

    return image;
  }

  static getFrameImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Frame);

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Thumbnail);
    }

    return image;
  }

  static getFrameImageUrl(images?: IMediaImageModel[]) {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getFrameImage(images);

    if (image && image.Url) {
      return UrlHelper.parametrize(image.Url, { w: 600 });
    }
  }

  static getPromoImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Promo);

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Thumbnail);

      return image;
    }
    return image;
  }

  static getPromoImageUrl(images?: IMediaImageModel[]) {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getPromoImage(images);
    if (image) {
      return `${image.Url}?format=webp`;
    }
  }

  static getCircleImage(images?: IMediaImageModel[]) {
    const image = this.getImageByType(images, MediaImageType.Logo);

    return image;
  }

  static getCircleImageUrl(images?: IMediaImageModel[]) {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getCircleImage(images);

    if (image) {
      return `${image.Url}?format=webp`;
    }
  }

  static getCoverImage(images?: IMediaImageModel[]) {
    const image = this.getImageByType(images, MediaImageType.Cover);

    return image;
  }

  static getCoverImageUrl(images?: IMediaImageModel[]): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getCoverImage(images);

    if (image && image.Url) {
      return UrlHelper.parametrize(image.Url, { w: 500 });
    }

    return undefined;
  }

  static getHighlightAssetFrontpageImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(
      images,
      MediaImageType.Highlight_Asset_Frontpage
    );

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Thumbnail);

      return image;
    }
    return image;
  }

  static getHighlightAssetFrontpagelUrl(
    images?: IMediaImageModel[]
  ): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }
    const image = this.getHighlightAssetFrontpageImage(images);
    if (image) {
      return `${image.Url}?h=1080&w=1920&format=webp`;
    }
  }

  static getHeroFrontpageImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Hero_Frontpage);
    if (!image) {
      image = this.getImageByType(images, MediaImageType.Thumbnail);
      if (!image) {
        image = this.getImageByType(images, MediaImageType.Frame);
        return image;
      }
      return image;
    }

    return image;
  }

  static getHeroFrontpageUrl(images?: IMediaImageModel[]): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }
    const image = this.getHeroFrontpageImage(images);
    if (image) {
      return `${image.Url}?h=1080&w=1920&format=webp`;
    }
  }

  static getBannerImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Banner);

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Thumbnail);
    }

    return image;
  }

  static getBannerImageUrl(images?: IMediaImageModel[]): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getBannerImage(images);

    if (image) {
      return `${image.Url}?h=${ImageSize.FRAME_ITEM_HEIGHT}&w=${ImageSize.FRAME_ITEM_WIDTH}&format=webp`;
    }

    return undefined;
  }

  static getBannerTVImageUrl(images?: IMediaImageModel[]): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getBannerImage(images);

    if (image) {
      return `${image.Url}?h=${ImageSize.FRAME_TV_ITEM_HEIGHT}&w=${ImageSize.FRAME_TV_ITEM_WIDTH}&format=webp&d=000`;
    }

    return undefined;
  }

  static getPosterImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Poster);

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Cover);
    }

    return image;
  }

  static getPosterImageUrl(images?: IMediaImageModel[]): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getPosterImage(images);

    if (image) {
      return `${image.Url}?h=${ImageSize.COVER_ITEM_HEIGHT}&w=${ImageSize.COVER_ITEM_WIDTH}&format=webp`;
    }

    return undefined;
  }

  static getHeroAssetDetailsImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Hero_Asset_Details);

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Thumbnail);
    }

    return image;
  }

  static getHeroAssetDetailsImageUrl(
    images?: IMediaImageModel[]
  ): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getHeroAssetDetailsImage(images);

    if (image) {
      return `${image.Url}?h=1080&w=1920&format=webp`;
    }

    return undefined;
  }

  static getHoverAssetImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Hover_Asset);

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Thumbnail);
    }

    return image;
  }

  static getHoverAssetImageUrl(
    images?: IMediaImageModel[]
  ): string | undefined {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getHoverAssetImage(images);

    if (image) {
      return `${image.Url}?h=${ImageSize.HOVER_ITEM_HEIGHT}&w=${ImageSize.HOVER_ITEM_WIDTH}&format=webp`;
    }

    return undefined;
  }

  static getThumbnailImage(images?: IMediaImageModel[]) {
    let image = this.getImageByType(images, MediaImageType.Thumbnail);

    if (!image) {
      image = this.getImageByType(images, MediaImageType.Frame);
    }

    return image;
  }

  static getThumbnailImageUrl(images?: IMediaImageModel[]) {
    if (!images || images.length === 0) {
      return undefined;
    }

    const image = this.getThumbnailImage(images);

    if (image && image.Url) {
      return UrlHelper.parametrize(image.Url, { w: 600 });
    }
  }
}
