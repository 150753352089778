/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import { IUserLocationModel } from "../models";

export class LocationService {
  get url(): string {
    return "/v2/location";
  }

  public get = (): Observable<IUserLocationModel> => {
    return new Observable(
      (observer: Observer<IUserLocationModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}`,
        })
    );
  };
}
