import { useEffect } from "react";
import { useGetMediaCategories } from "../";
import { useMediaGenreListSelector } from "../../selectors";

export const useMediaGenreList = () => {
  const getMediaCategories = useGetMediaCategories();
  const data = useMediaGenreListSelector();

  useEffect(() => {
    getMediaCategories(true);
  }, []);

  return { data };
};
