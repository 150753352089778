/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { ReactElement, ReactNode, useEffect } from "react";
import { Provider, ReactReduxContext } from "react-redux";
import { createLogger } from "redux-logger";
import type { AppProps } from "next/app";
import type { NextPage } from "next";

import { SSRConfig, appWithTranslation } from "next-i18next";

import "../i18n";

import { DataProvider, ReduxStoreConfigurator } from "@nf/common";

import dynamic from "next/dynamic";

const Root = dynamic(() => import("./root"));
const Layout = dynamic(() => import("layouts/Layout"));
const RouteProtector = dynamic(() => import("../components/RouteProtector"));
const DefaultSEO = dynamic(() => import("../components/SEO/DefaultSEO"));
const CookieConsent = dynamic(() => import("../components/CookieConsent"));

import "../styles/globals.scss";
import i18n from "../i18n";

import { IsLoadingContextProvider } from "helpers/setIsLoadingContext";
import { PageOverlay } from "../components/PageOverlay/PageOverlay";
import { applyPreloadStylesheetLinks } from "../helpers/applyPreloadStylesheetLinks";

// Set up HttpFactory
DataProvider.initHttpFactory();

const middlewares = [];

if (process.env.NODE_ENV === "development") {
  middlewares.push(createLogger({ diff: true, collapsed: true }));
}
const reduxStoreConfigurator = new ReduxStoreConfigurator(middlewares);
const appStore = reduxStoreConfigurator.initStore();

interface CustomPageProps {
  configuration?: any;
  disableCookieConsentModule?: boolean;
}

type NextPageWithLayout = NextPage<CustomPageProps> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<CustomPageProps> & {
  Component: NextPageWithLayout;
};

type AppPropsWithTranslations = AppProps<CustomPageProps> & {
  pageProps: SSRConfig;
};

console.log(
  `%cVERSION: %c${process.env.NEXT_PUBLIC_VERSION}`,
  "color: green;font-weight: bold;",
  "color: red;font-weight: bold;"
);

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const configuration = pageProps.configuration;

  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ??
    ((page) => <Layout config={configuration}>{page}</Layout>);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      applyPreloadStylesheetLinks();
    }
  }, []);
  return (
    <>
      {!pageProps?.disableCookieConsentModule ? <CookieConsent /> : null}
      <DefaultSEO />
      <Provider store={appStore} context={ReactReduxContext}>
        <IsLoadingContextProvider>
          {getLayout(
            <Root config={configuration}>
              <RouteProtector>
                <PageOverlay>
                  <Component {...pageProps} />
                </PageOverlay>
              </RouteProtector>
            </Root>
          )}
        </IsLoadingContextProvider>
      </Provider>
    </>
  );
}

export default appWithTranslation<AppPropsWithTranslations>(MyApp, i18n);
