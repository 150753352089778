import { useEffect } from "react";
import { useDeepMemo } from "../";
import { IMediaListOptionsModel } from "../../models";
import { useMediaListByIdSelector } from "../../selectors";
import { useGetMediaList } from "./useGetMediaList";

export const useMediaList = (options: IMediaListOptionsModel) => {
  const memoizedOptions = useDeepMemo(options);
  const data = useMediaListByIdSelector(memoizedOptions.MediaListId);
  const getMediaList = useGetMediaList();

  useEffect(() => {
    if (memoizedOptions.MediaListId) {
      getMediaList(memoizedOptions);
    }
  }, [memoizedOptions]);

  return { data: data.Entities, loading: data.IsLoading };
};
