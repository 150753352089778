/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum ScreenType {
  Custom = "CUSTOM",
  ApplicationMenu = "APPLICATION_MENU",
  ApplicationHeader = "APPLICATION_HEADER",
  ApplicationFooter = "APPLICATION_FOOTER",
  Home = "HOME",
  Splash = "SPLASH",
  Login = "LOGIN",
  LoginViaCode = " LOGIN_VIA_CODE",
  Help = "HELP",
  About = "ABOUT",
  MovieDetails = "MOVIE_DETAILS",
  SeriesDetails = "SERIES_DETAILS",
  LiveDetails = "LIVE_DETAILS",
  Profile = "PROFILE",
  Social = "SOCIAL",
  Messages = "MESSAGES",
  Settings = "SETTINGS",
  Search = "SEARCH",
  Notifications = "NOTIFICATIONS",
  Events = "EVENTS",
  Friends = "FRIENDS",
  Community = "COMMUNITY",
  Explore = "EXPLORE",
  CreatorProfiles = "CREATOR_PROFILES",
  Intro = "INTRO",
  Catalog = "CATALOG",
  MyList = "MY_LIST",
  Category = "CATEGORY",
  Playlist = "PLAYLIST",
  Regulations = "REGULATIONS",
  PrivacyPolicy = "PRIVACY_POLICY",
  MyConsents = "MY_CONSENTS",
  ReadMore = "READ_MORE",
  Movies = "MOVIES",
  Kids = "KIDS",
  Hjem = "HJEM",
  Danskfilmskat = "DANSKFILMSKAT",
  Boern = "BOERN",
}
