/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const GET_MEDIA_LIST = "GET_MEDIA_LIST";
export const GET_MEDIA_LIST_SUCCESS = "GET_MEDIA_LIST_SUCCESS";
export const GET_MEDIA_LIST_FAILURE = "GET_MEDIA_LIST_FAILURE";
export const GET_MEDIA_LIST_FROM_CACHE = "GET_MEDIA_LIST_FROM_CACHE";
export const GET_MEDIA_LIST_FROM_CACHE_SUCCESS =
  "GET_MEDIA_LIST_FROM_CACHE_SUCCESS";
export const GET_MEDIA_LIST_FROM_CACHE_FAILURE =
  "GET_MEDIA_LIST_FROM_CACHE_FAILURE";
export const GET_MEDIA = "GET_MEDIA";
export const GET_MEDIA_SUCCESS = "GET_MEDIA_SUCCESS";
export const GET_MEDIA_FAILURE = "GET_MEDIA_FAILURE";
export const GET_MEDIA_PROGRESS = "GET_MEDIA_PROGRESS";
export const GET_MEDIA_PROGRESS_SUCCESS = "GET_MEDIA_PROGRESS_SUCCESS";
export const GET_MEDIA_PROGRESS_FAILURE = "GET_MEDIA_PROGRESS_FAILURE";
export const GET_CHANNEL_ON_NOW = "GET_CHANNEL_ON_NOW";
export const GET_CHANNEL_ON_NOW_SUCCESS = "GET_CHANNEL_ON_NOW_SUCCESS";
export const GET_CHANNEL_ON_NOW_FAILURE = "GET_CHANNEL_ON_NOW_FAILURE";
export const SEARCH_MEDIA = "SEARCH_MEDIA";
export const SEARCH_MEDIA_SUCCESS = "SEARCH_MEDIA_SUCCESS";
export const SEARCH_MEDIA_FAILURE = "SEARCH_MEDIA_FAILURE";
export const SEARCH_MULTI = "SEARCH_MULTI";
export const SEARCH_MULTI_SUCCESS = "SEARCH_MULTI_SUCCESS";
export const SEARCH_MULTI_FAILURE = "SEARCH_MULTI_FAILURE";
export const GET_MEDIA_CATEGORIES = "GET_MEDIA_CATEGORIES";
export const GET_MEDIA_CATEGORIES_SUCCESS = "GET_MEDIA_CATEGORIES_SUCCESS";
export const GET_MEDIA_CATEGORIES_FAILURE = "GET_MEDIA_CATEGORIES_FAILURE";
export const MEDIA_LIST_SEARCH_KEY = "MEDIA_LIST_SEARCH_KEY";
export const DELETE_ALL_MEDIA = "DELETE_ALL_MEDIA";
export const REMOVE_FROM_FAVORITES_LIST = "REMOVE_FROM_FAVORITES_LIST";
export const ADD_TO_FAVORITES_LIST = "ADD_TO_FAVORITES_LIST";
