/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaChannelType {
  Dansk_Filmskat = "Dansk_Filmskat_929372c",
  Kids = "Kids_929372c",
  NFplus = "NFplus_929372c"
}
