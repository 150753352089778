/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React from "react";
import logos from "resources/app";
import dynamic from "next/dynamic";
import { useBrandingLogo } from "../../hooks";

const DotsLoader = dynamic(() => import("components/DotsLoader"), {
  ssr: false,
});

import styles from "./Splash.module.scss";

const defaultLogo = logos.logoTransparent;

export const Splash = () => {
  const brandingLogo = useBrandingLogo();

  return (
    <div className={styles.splash}>
      <div className={styles.container}>
        <div className={styles.branding}>
          <img
            className={styles.logo}
            src={brandingLogo || defaultLogo}
            alt="app logo"
          />
          <div className={styles.spinner}>
            <DotsLoader width={56} />
          </div>
        </div>
      </div>
    </div>
  );
};
