import { MediaType } from "../../enums";
import { getPlayable, TimeHelper } from "../../helpers";
import { IMediaModel } from "../../models";

type PlayableInfo = {
  isPlayable: boolean;
  availableFrom?: string;
  availableTo?: string;
};

const DAYS_TO_EXPIRE = 14;

const formatDate = (date?: Date) =>
  TimeHelper.format(date || 0, "DD. MMMM")?.toLowerCase();

const getAvailableFrom = (date?: Date) => {
  return TimeHelper.isAfterCurrent(date) ? formatDate(date) : undefined;
};

const getAvailableTo = (date?: Date) => {
  if (!date) return;

  const daysToExpire = TimeHelper.diff(date, TimeHelper.getCurrentDateTime());
  const disappearSoon = daysToExpire >= 0 && daysToExpire <= DAYS_TO_EXPIRE;

  return disappearSoon ? formatDate(date) : undefined;
};

const getInfo = (isPlayable?: boolean, from?: Date, to?: Date) => {
  return {
    isPlayable: !!isPlayable,
    availableFrom: getAvailableFrom(from),
    availableTo: getAvailableTo(to),
  };
};

export const useIsPlayableWithDate = (media?: IMediaModel): PlayableInfo => {
  if (!media) {
    return { isPlayable: false };
  }

  const { IsPlayable, AvailableFrom, AvailableTo, MediaTypeCode } = media;
  const isCollection = MediaTypeCode === MediaType.Collection;
  const isMovie = MediaTypeCode === MediaType.Video;
  const isChannel = MediaTypeCode === MediaType.Channel;
  const isSeries = MediaTypeCode === MediaType.Series;
  const isEpisode = MediaTypeCode === MediaType.Episode;

  if (isMovie || isChannel || isEpisode || isSeries) {
    return getInfo(IsPlayable, AvailableFrom, AvailableTo);
  }

  if (isCollection) {
    if (media.Media) {
      const firstPlayableMediaInCollection = media.Media?.find(getPlayable);
      const isPlayable = firstPlayableMediaInCollection?.IsPlayable || false;
      return getInfo(isPlayable, AvailableFrom, AvailableTo);
    }

    return getInfo(IsPlayable, AvailableFrom, AvailableTo);
  }

  return { isPlayable: false };
};
