/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IUserInfoModel } from "../../models";
import * as Consts from "./consts";
import { AuthActionsTypes, IAuthState } from "./types";

export const initialState: IAuthState = {
  isAnonymous: true,
  isAuthenticated: false,
  isLoading: false,
  isProcessing: false,
  isTokenValid: false,
  isCodeVerified: false,
  isInitialized: false,
  isCompleted: false,
  isChild: false,
};

export const authReducer = (
  state = initialState,
  action: AuthActionsTypes
): IAuthState => {
  state.action = action;

  switch (action.type) {
    case Consts.SIGN_IN:
    case Consts.SIGN_IN_VIA_TOKEN:
    case Consts.SIGN_IN_ANONYMOUS:
    case Consts.GET_SESSION_DETAILS: {
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isInitialized: true,
      };
    }
    case Consts.VALIDATE_ACTIVATION_CODE: {
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isAuthenticated: false,
        isCodeVerified: false,
        isCompleted: false,
      };
    }
    case Consts.SIGN_IN_SUCCESS:
    case Consts.SIGN_IN_WEB_SUCCESS:
    case Consts.SIGN_IN_VIA_TOKEN_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        session: action.payload.session,
        error: undefined,
        isAnonymous: false,
        isAuthenticated: true,
        isInitialized: true,
        isLoading: false,
        isCompleted: true,
      };
    }
    case Consts.VALIDATE_ACTIVATION_CODE_SUCCESS: {
      return {
        ...state,
        error: undefined,
        isAnonymous: false,
        isAuthenticated: true,
        isCodeVerified: true,
        isLoading: false,
        isCompleted: true,
      };
    }
    case Consts.SET_IS_CHILD: {
      return {
        ...state,
        isChild: action.payload,
      };
    }
    case Consts.SIGN_IN_ANONYMOUS_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        session: action.payload.session,
        error: initialState.error,
        isAnonymous: true,
        isAuthenticated: false,
        isLoading: false,
      };
    }
    case Consts.SET_AUTHENTICATED: {
      return {
        ...state,
      };
    }
    case Consts.SIGN_IN_FAILURE:
    case Consts.SIGN_IN_VIA_TOKEN_FAILURE:
    case Consts.SIGN_IN_ANONYMOUS_FAILURE:
    case Consts.GET_SESSION_DETAILS_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isCompleted: true,
      };
    }
    case Consts.VALIDATE_ACTIVATION_CODE_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isAuthenticated: false,
        isCodeVerified: false,
        isCompleted: true,
      };
    }
    case Consts.SIGN_OUT_SUCCESS:
    case Consts.REFRESH_TOKEN_FAILURE: {
      return {
        ...state,
        isAuthenticated: false,
        session: undefined,
        user: undefined,
      };
    }
    case Consts.GET_SESSION_DETAILS_SUCCESS: {
      return {
        ...state,
        session: {
          ...state.session,
          IsOverTheDeviceLimit: action.payload.session.overTheDeviceLimit,
        },
        error: undefined,
        isLoading: false,
      };
    }
    case Consts.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        session: { ...state.session, ...action.payload.session },
        user: action.payload.user ?? state.user,
      };
    }
    case Consts.CLEAR_CHANGE_PASSWORD_RESULT: {
      return {
        ...state,
        changePasswordSuccess: undefined,
      };
    }
    case Consts.CHANGE_PASSWORD: {
      return {
        ...state,
        isProcessing: true,
        isCompleted: false,
        isLoading: true,
        changePasswordSuccess: undefined,
      };
    }
    case Consts.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        session: action.payload,
        isCompleted: true,
        isLoading: false,
        changePasswordSuccess: true,
      };
    }
    case Consts.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.error,
        isProcessing: false,
        isCompleted: true,
        isLoading: false,
        changePasswordSuccess: false,
      };
    }
    case Consts.SYNC_USER: {
      return {
        ...state,
        isLoading: true,
        isProcessing: true,
      };
    }
    case Consts.SYNC_WEB_USER_PENDING: {
      return {
        ...state,
        isLoading: true,
        isInitialized: true,
        isCompleted: false,
        isChild: action.payload.isChild,
        session: action.payload.session,
        user: action.payload.userData,
        isAuthenticated: true,
        isAnonymous: false,
        isTokenValid: true,
        isProcessing: true,
      };
    }
    case Consts.SYNC_WEB_USER_SUCCESS: {
      return {
        ...state,
        user: { ...state.user, ...action.payload.customer },
        isLoading: false,
        isInitialized: true,
        isCompleted: true,
        isProcessing: false,
      };
    }
    case Consts.SYNC_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload.userInfo,
        session: action.payload.session,
        isLoading: false,
        isInitialized: true,
        isCompleted: true,
      };
    }
    case Consts.SYNC_USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        isCompleted: true,
        error: undefined,
        isProcessing: false,
        isAuthenticated: false,
        isAnonymous: true,
      };
    }
    case Consts.REFRESH_USER: {
      const user: IUserInfoModel = {
        Id: -1,
        ...state.user,
        FullName: action.payload.FullName,
        AvatarUrl: action.payload.AvatarUrl,
        PhoneNumber: action.payload.PhoneNumber,
        Email: action.payload.Email ?? state.user?.Email,
      };
      return {
        ...state,
        user,
      };
    }
    case Consts.UPDATE_TOKENS: {
      return {
        ...state,
        session: action.payload,
      };
    }
    default:
      return state;
  }
};
