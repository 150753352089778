import { BehaviorSubject } from "rxjs";
import { ITokenModel, IUserModel } from "../../models";
import axios from "axios";
import { ROUTES } from "@nf/constants";
import { BrowserHelper } from "../../helpers";

type TSessionBehavior = {
  session?: ITokenModel;
  userId?: string;
  users?: IUserModel;
};
class AuthMemoryStorage {
  static _instance?: AuthMemoryStorage = undefined;
  private static authStorage = new BehaviorSubject<TSessionBehavior>({
    session: undefined,
    userId: undefined,
    users: undefined,
  });

  static getInstance() {
    if (!AuthMemoryStorage._instance) {
      AuthMemoryStorage._instance = new AuthMemoryStorage();
    }
    return AuthMemoryStorage._instance;
  }

  getSessionBehavior() {
    return AuthMemoryStorage.authStorage.getValue().session;
  }

  setSessionBehavior(session: ITokenModel) {
    const prevSessionValues = AuthMemoryStorage.authStorage.getValue().session;

    const isTokenChanged = prevSessionValues?.Token !== session?.Token;
    const isRefreshTokenChanged =
      prevSessionValues?.RefreshToken !== session?.RefreshToken;
    const isDataTokenChanged =
      prevSessionValues?.DataToken !== session?.DataToken;

    const isInitialToken = prevSessionValues?.Token === undefined;
    const isInitialRefreshToken = prevSessionValues?.RefreshToken === undefined;
    const isInitialDataToken = prevSessionValues?.DataToken === undefined;

    const dataChanged =
      isTokenChanged || isRefreshTokenChanged || isDataTokenChanged;
    const initialData =
      isInitialToken || isInitialRefreshToken || isInitialDataToken;

    if (dataChanged) {
      const isWindow = typeof window !== "undefined";
      if (!BrowserHelper.isTV() && !initialData && isWindow) {
        axios.post(ROUTES.API_UPDATE_TOKENS, session);
      }
      AuthMemoryStorage.authStorage.next({
        ...AuthMemoryStorage.authStorage.getValue(),
        session,
      });
    }
  }

  deleteSession() {
    AuthMemoryStorage.authStorage.next({
      session: undefined,
      userId: undefined,
      users: undefined,
    });
  }

  getUsers() {
    return [AuthMemoryStorage.authStorage.getValue().users];
  }
  getUserId() {
    return AuthMemoryStorage.authStorage.getValue().userId;
  }

  setSessionValues(props: TSessionBehavior) {
    AuthMemoryStorage.authStorage.next(props);
  }

  updateSessionValues(sessionProps: TSessionBehavior) {
    AuthMemoryStorage.authStorage.next({
      ...AuthMemoryStorage.authStorage.getValue(),
      ...sessionProps,
    });
  }
}

export const authStorage = {
  deleteSession: AuthMemoryStorage.getInstance().deleteSession,
  setSessionValues: AuthMemoryStorage.getInstance().setSessionValues,
  setSessionBehavior: AuthMemoryStorage.getInstance().setSessionBehavior,
  getSessionBehavior: AuthMemoryStorage.getInstance().getSessionBehavior,
  getUsers: AuthMemoryStorage.getInstance().getUsers,
  getUserId: AuthMemoryStorage.getInstance().getUserId,
  updateSessionValues: AuthMemoryStorage.getInstance().updateSessionValues,
};
