/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaProductModel } from "../models/Media";

export const checkProductAvailabilityKey = (
  mediaKeys?: IMediaProductModel[],
  userKeys?: string[]
) => mediaKeys?.some((mediaKey) => userKeys?.includes(mediaKey.Id));
