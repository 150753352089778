/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaType {
  Collection = "COLLECTION",
  Custom = "CUSTOM",
  Series = "SERIES",
  Video = "VOD",
  Episode = "EPISODE",
  Season = "TV_SHOW",
  Live = "LIVE",
  Intro = "INTRO",
  Channel = "CHANNEL",
  Program = "PROGRAM",
  Package = "PACKAGE",
  Premiere = "VIDEO_PREMIERE",
  Category = "CATEGORY",
}
