/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export const listNamePrefixHelper = (name: string | undefined, prefix: string) => {
    if (name && prefix) {
        return name.toLowerCase().includes(prefix.toLowerCase());
    }
    return false;
};
