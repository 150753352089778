/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AxiosSubscriber } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import {
  IUserSubprofilePinCodeRequestModel,
  IUserSubprofilePinCodeResponseModel,
} from "../models";
import { Observable, Observer } from "rxjs";
import { AppConfig } from "@nf/constants";

export class UserSubprofilesPinCodeService {
  get url(): string {
    return `/v3/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/user/pincode`;
  }

  public getPinCode = (): Observable<IUserSubprofilePinCodeResponseModel[]> =>
    new Observable(
      (observer: Observer<IUserSubprofilePinCodeResponseModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}`,
        })
    );

  public setPinCode = (
    request: IUserSubprofilePinCodeRequestModel,
    pinCodeId?: string
  ): Observable<IUserSubprofilePinCodeResponseModel[]> =>
    new Observable(
      (observer: Observer<IUserSubprofilePinCodeResponseModel[]>) =>
        new AxiosSubscriber(observer, {
          method: pinCodeId ? HTTP_METHOD.PUT : HTTP_METHOD.POST,
          url: pinCodeId ? `${this.url}/pin/${pinCodeId}` : `${this.url}`,
          data: request,
        })
    );

  public deletePinCode = (pinCodeId?: string): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/pin/${pinCodeId}`,
        })
    );

  public validatePinCode = (
    pinCodeId: string,
    data: { inClear: string }
  ): Observable<string[]> =>
    new Observable(
      (observer: Observer<string[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          data,
          url: `${this.url}/pin/${pinCodeId}/validate`,
        })
    );
}
