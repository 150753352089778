/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { StringHelper } from "@nf/helpers";
import { IAppConfig } from "./IAppConfig";

export class AppConfigDefault implements IAppConfig {

  get ApiUrl(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_API_URL || process.env.REACT_APP_API_URL);
  }

  get ApiAuthUrl(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_API_AUTH_URL || process.env.REACT_APP_API_AUTH_URL);
  }

  get Customer(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_API_CUSTOMER || process.env.REACT_APP_API_CUSTOMER);
  }

  get BusinessUnit(): string {

    return StringHelper.toString(process.env.NEXT_PUBLIC_API_BUSINESS_UNIT || process.env.REACT_APP_API_BUSINESS_UNIT);
  }

  get ConfigId(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_API_CONFIG_ID || process.env.REACT_APP_API_CONFIG_ID);
  }

  get TranslationsUrl(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_TRANSLATIONS_URL || process.env.REACT_APP_TRANSLATIONS_URL);
  }

  get TranslationsCacheLifetime(): number {
    const fallbackValue = "1" // in milliseconds
    return parseInt(process.env.NEXT_PUBLIC_TRANSLATIONS_CACHE_LIFETIME || process.env.REACT_APP_TRANSLATIONS_CACHE_LIFETIME || fallbackValue);
  }

  get AppName(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_NAME || process.env.REACT_APP_NAME);
  }

  get ChannelHome(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_CHANNEL_SCREENID_HOME || process.env.REACT_APP_CHANNEL_SCREENID_HOME);
  }

  get ChannelDansk(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_CHANNEL_SCREENID_DANSK || process.env.REACT_APP_CHANNEL_SCREENID_DANSK);
  }

  get ChannelKids(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_CHANNEL_SCREENID_KIDS || process.env.REACT_APP_CHANNEL_SCREENID_KIDS);
  }

  get DeviceOrientation(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_FORCE_ORIENTATION || process.env.REACT_APP_FORCE_ORIENTATION);
  }

  get Environment(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_ENV || process.env.REACT_APP_ENV);
  }

  get PlatformCode(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_PLATFORM_CODE || process.env.REACT_APP_PLATFORM_CODE);
  }

  get PlayerType(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_PLAYER_TYPE || process.env.REACT_APP_PLAYER_TYPE);
  }

  get PlatformVersion(): string {
    return `${process.env.NEXT_PUBLIC_VERSION || process.env.REACT_APP_VERSION}` ;
  }
               
  get TenantOrigin(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_TENANT_ORIGIN_URL || process.env.REACT_APP_API_AUTH_URL);
  }

  get SentryDsn(): string {
    return StringHelper.toString(process.env.NEXT_PUBLIC_SENTRY_DSN || process.env.REACT_APP_API_AUTH_URL);
  }

  get SentryReporting(): boolean {
    return process.env.NEXT_PUBLIC_SENTRY_REPORTING === "true" || process.env.REACT_APP_API_AUTH_URL === "true";
  }
  
  get AppLocalTimezone(): string {
    return "";
  }

  networkHandler = (callback: CallableFunction) => {}
}
