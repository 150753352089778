import { useEffect } from "react";
import { dispatch, EmptyObject, IMediaListModel, MediaStore } from "@nf/common";

export const useUpdateMediaListStore = (
  mediaListId: string,
  mediaList: IMediaListModel | EmptyObject
) => {
  useEffect(
    function updateMediaListStore() {
      mediaList.Filter = {
        PageNumber: mediaList.PageNumber,
        PageSize: mediaList.PageSize,
      };
      mediaList &&
        dispatch(
          MediaStore.Actions.getMediaListSuccess(
            mediaListId,
            mediaList as IMediaListModel
          )
        );
    },
    [mediaListId, mediaList]
  );
};
