/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IErrorModel,
  IPeopleSearchFilterModel,
  IPeopleSearchStateModel,
} from "../../models";
import * as Consts from "./consts";
import {
  ISearchPeopleAction,
  ISearchPeopleFailureAction,
  ISearchPeopleSuccessAction,
} from "./types";

export const searchPeople = (
  filter: IPeopleSearchFilterModel
): ISearchPeopleAction => {
  return {
    filter,
    type: Consts.SEARCH_PEOPLE,
  };
};

export const searchPeopleSuccess = (
  data: IPeopleSearchStateModel
): ISearchPeopleSuccessAction => {
  return {
    payload: data,
    type: Consts.SEARCH_PEOPLE_SUCCESS,
  };
};

export const searchPeopleFailure = (
  error?: IErrorModel
): ISearchPeopleFailureAction => {
  return {
    error,
    type: Consts.SEARCH_PEOPLE_FAILURE,
  };
};

export const Actions = {
  searchPeople,
};
