import { UserSubscriptionStatus } from "../../../../enums";

export enum OrderStatus {
  DRAFT = "draft",
  PENDING = "pending",
  OTHER_ERROR = "other_error",
  PAYMENT_CANCEL = "payment_cancel",
  PAYMENT_ERROR = "payment_error",
  COMPLETED = "completed",
}

export enum RedbeeStatus {
  DRAFT = "draft",
  PENDING = "pending",
  ERROR = "error",
  COMPLETED = "completed",
}

export enum AgillicStatus {
  DRAFT = "draft",
  PENDING = "pending",
  ERROR = "error",
  COMPLETED = "completed",
}

export interface IOrderStatusResponseModel {
  error?: {
    message: string;
    stack: Record<string, string> | null;
  };
  order: {
    status: OrderStatus;
    redbee_status: RedbeeStatus;
    agillic_status: AgillicStatus;
  };
  subscription: {
    id: number;
    status: UserSubscriptionStatus;
  } | null;
  reepay_payment_method?: {
    id: string;
    payment_type: "card"; // For now it is the only available payment method
    card: {
      card_type: string;
      masked_card: string;
      exp_date: string;
    };
  };
}
