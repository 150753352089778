/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaCategoryType {
  Channel = "CHANNEL",
  Main = "MAIN",
  Label = "LABEL",
  Other = "OTHER",
  Format = "FORMAT",
}
