/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IUserConsentModel } from "../models/User";

interface WindowWithCookieConsent extends Window {
  CookieInformation?: {
    loadConsent: () => void;
  };
}

export const getConsentsToAccept = (consents?: IUserConsentModel[]) =>
  consents?.filter((consent) => consent.ConsentRequired && consent.NeedUpdate);

export const reloadCookieConsent = () => {
  if (window && (window as WindowWithCookieConsent)?.CookieInformation) {
    (window as WindowWithCookieConsent)?.CookieInformation?.loadConsent();
  }
};
