export class BrowserHelper {
  static getUserAgent(): string | undefined {
    if (typeof window === "undefined") {
      return undefined;
    }

    return navigator.userAgent;
  }

  static isDesktopBrowser(): boolean {
    return this.getUserAgent()?.match(/(Windows NT|Macintosh|Linux)/i) !== null;
  }

  static isMobileBrowser(): boolean {
    return (
      this.getUserAgent()?.match(
        /(Android|iPhone|iPad|iPod|BlackBerry|Windows Phone|Opera Mini|Opera Mobile)/i
      ) !== null
    );
  }

  static isTizenBrowser(): boolean {
    return this.getUserAgent()?.includes("Tizen") ?? false;
  }

  static isTitanOsBrowser(): boolean {
    return this.getUserAgent()?.includes("TitanOS") ?? false;
  }

  static isWebOsBrowser(): boolean {
    return this.getUserAgent()?.includes("Web0S") ?? false;
  }

  static isBrowserAgentAvailable(): boolean {
    return this.getUserAgent() !== undefined;
  }

  static isBrowser(): boolean {
    if (this.isBrowserAgentAvailable()) {
      return (
        (this.isDesktopBrowser() || this.isMobileBrowser()) && !this.isTV()
      );
    } else {
      return false;
    }
  }

  static isTV(): boolean {
    if (this.isBrowserAgentAvailable()) {
      return (
        this.isTizenBrowser() ||
        this.isTitanOsBrowser() ||
        this.isWebOsBrowser()
      );
    } else {
      return false;
    }
  }
}
