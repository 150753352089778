/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";

export const SET_IS_CHILD = "SET_IS_CHILD";

export const SIGN_IN_WEB_SUCCESS = "SIGN_IN_WEB_SUCCESS";
export const SIGN_IN_SUCCESS_EVENT = "SIGN_IN_SUCCESS_EVENT";
export const SIGN_OUT_SUCCESS_EVENT = "SIGN_OUT_SUCCESS_EVENT";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_IN_VIA_TOKEN = "SIGN_IN_VIA_TOKEN";
export const SIGN_IN_VIA_TOKEN_TV = "SMART_TV_SIGN_IN_VIA_TOKEN";
export const SIGN_IN_VIA_TOKEN_SUCCESS = "SIGN_IN_VIA_TOKEN_SUCCESS";
export const SIGN_IN_VIA_TOKEN_FAILURE = "SIGN_IN_VIA_TOKEN_FAILURE";
export const SIGN_IN_ANONYMOUS = "SIGN_IN_ANONYMOUS";
export const SIGN_IN_ANONYMOUS_SUCCESS = "SIGN_IN_ANONYMOUS_SUCCESS";
export const SIGN_IN_ANONYMOUS_FAILURE = "SIGN_IN_ANONYMOUS_FAILURE";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";
export const GET_SESSION_DETAILS = "GET_SESSION_DETAILS";
export const GET_SESSION_DETAILS_SUCCESS = "GET_SESSION_DETAILS_SUCCESS";
export const GET_SESSION_DETAILS_FAILURE = "GET_SESSION_DETAILS_FAILURE";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const CLEAR_CHANGE_PASSWORD_RESULT = "CLEAR_CHANGE_PASSWORD_RESULT";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const REFRESH_USER = "REFRESH_USER";
export const SYNC_USER = "SYNC_USER";
export const SYNC_WEB_USER = "SYNC_WEB_USER";
export const SYNC_WEB_USER_PENDING = "SYNC_WEB_USER_PENDING";
export const SYNC_USER_SUCCESS = "SYNC_USER_SUCCESS";
export const SYNC_WEB_USER_SUCCESS = "SYNC_WEB_USER_SUCCESS";
export const SYNC_USER_FAILURE = "SYNC_USER_FAILURE";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const GET_ACTIVATION_CODE = "GET_ACTIVATION_CODE";
export const GET_ACTIVATION_CODE_SUCCESS = "GET_ACTIVATION_CODE_SUCCESS";
export const GET_ACTIVATION_CODE_FAILURE = "GET_ACTIVATION_CODE_FAILURE";
export const VALIDATE_ACTIVATION_CODE = "VALIDATE_ACTIVATION_CODE";
export const VALIDATE_ACTIVATION_CODE_SUCCESS =
  "VALIDATE_ACTIVATION_CODE_SUCCESS";
export const VALIDATE_ACTIVATION_CODE_FAILURE =
  "VALIDATE_ACTIVATION_CODE_FAILURE";
export const GET_LOGIN_TOKEN_FROM_CODE = "VGET_LOGIN_TOKEN_FROM_CODE";
export const GET_LOGIN_TOKEN_FROM_CODE_SUCCESS =
  "GET_LOGIN_TOKEN_FROM_CODE_SUCCESS";
export const GET_LOGIN_TOKEN_FROM_CODE_FAILURE =
  "GET_LOGIN_TOKEN_FROM_CODE_FAILURE";
export const UPDATE_TOKENS = "UPDATE_TOKENS";
