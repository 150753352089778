/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
const logo = "/assets/app/logo.png";
const logoTransparent = "/assets/app/logo-transparent.svg";

export default {
  logo,
  logoTransparent,
};
