import { useMemo } from "react";
import { useUserCurrentSubprofileSelector } from "../selectors";
import { userProfileHelper } from "../helpers";

export const useInitialProfileHomePageUrl = (): string | undefined => {
  const currentSubProfile = useUserCurrentSubprofileSelector();
  return useMemo(
    () => userProfileHelper.getInitialHomePage(currentSubProfile),
    [currentSubProfile?.InitialHomePage]
  );
};
