/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useMemo } from "react";
import {
  checkProductAvailabilityKey,
  IMediaModel,
  useAvailabilityKeysSelector,
} from "..";
import { useIsLoggedIn } from "./useIsLoggedIn";

export const useCanWatchAuthContent = (media?: IMediaModel) => {
  const { availabilityKeys, isLoading: isAvabilityKeyLoading } =
    useAvailabilityKeysSelector();
  const isLoggedIn = useIsLoggedIn();

  const hasValidProductsKeys = useMemo(
    () =>
      (media &&
        checkProductAvailabilityKey(media?.Products, availabilityKeys)) ??
      false,
    [media, availabilityKeys]
  );

  return {
    isValidatingContent: isAvabilityKeyLoading,
    isLoggedIn,
    canWatch: isLoggedIn && hasValidProductsKeys,
  };
};
