/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  ActionType,
  CellType,
  ComponentType,
  MediaType,
  Orientation,
  PlatformType,
  ScreenFrameType,
  ScreenRenderMode,
  ScreenType,
  SourceType,
} from "../../../enums";
import {
  ACTION,
  APP_IMAGES_TYPES,
  BLACKLISTED_URL,
  COMPONENT_TYPE,
  CONFIGURATION,
  IMAGE_ORIENTATION,
} from "./constants";
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_COUNTRY_CODE_LOWERCASE,
  DEFAULT_LANGUAGE_CODE,
  LanguageCode,
  LOCALE_LIST,
  SEARCH_MAX_RESULTS,
} from "@nf/constants";
import {
  AuthorizationHelper,
  LocaleHelper,
  sortByKey,
  StorageHelper,
  uniqueBy,
  UrlHelper,
  userProfileHelper,
} from "../../../helpers";
import {
  IChannelModel,
  IConfigurationBrandingModel,
  IConfigurationModel,
  IErrorModel,
  IMediaCategoryListModel,
  IMediaCategoryModel,
  IMediaListModel,
  IMediaListOptionsModel,
  IMediaMapperOptions,
  IMediaModel,
  IMediaOptionsModel,
  IMediaSearchFilterModel,
  IMediaSearchStateModel,
  IMultiSearchStateModel,
  IPeopleListModel,
  IPeopleSearchFilterModel,
  IPeopleSearchStateModel,
  IScreenModel,
  ITokenModel,
  IUserAvailabilityKeysModel,
  IUserDeviceModel,
  IUserFavoritesModel,
  IUserParentalRatingModel,
  IUserSubprofileModel,
  LanguageStorage,
} from "../../../models";
import {
  IAssetListModel,
  IAssetModel,
  IComponentConfigModel,
  IListModel,
  IPurchaseResponseModel,
  ISessionModel,
  ITagListModel,
  ITransactionProductOfferingResponseModel,
  IUserLocationModel,
  IUserPlayHistoryModel,
  IUserSubprofilePinCodeRequestModel,
  IUserSubprofileRequestModel,
} from "./models";
import { HttpFactory, StorageManager } from "../../../services";
import {
  AssetService,
  ChannelService,
  ClientConfigService,
  EntitlementService,
  LocationService,
  ProductOfferingService,
  TagService,
  UserDevicesServices,
  UserMediaPropertiesService,
  UserParentalRatingService,
  UserSubprofilesPinCodeService,
  UserSubprofilesService,
} from "./services";
import {
  catchError,
  forkJoin,
  lastValueFrom,
  Observable,
  of,
  shareReplay,
} from "rxjs";
import { ModelsMapperHelper } from "./helpers";
import { IConfigOptionModel } from "./models/Config/IConfigOptionModel";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { loadDayjsLocale } from "./helpers/translationsHelper";
import { SessionService } from "./services/SessionService";
import { AppConfig } from "@nf/constants";
import dayjs from "dayjs";
import { map, switchMap } from "rxjs/operators";
import { PeopleService } from "./services/PeopleService";

const DEFAULT_BRANDING_KEY = "MAIN";
const DANSK_BRANDING_KEY = "DANSK";
const KIDS_BRANDING_KEY = "KIDS";

export class RedBeeMediaDataProvider {
  clientConfigService = new ClientConfigService();
  assetService = new AssetService();
  tagService = new TagService();
  httpFactory = HttpFactory.getInstance();
  locationService = new LocationService();
  userMediaPropertiesService = new UserMediaPropertiesService();
  userDevicesService = new UserDevicesServices();
  sessionService = new SessionService();
  entitlementService = new EntitlementService();
  userSubprofilesService = new UserSubprofilesService();
  userParentalRatingService = new UserParentalRatingService();
  userSubprofilesPinCodeService = new UserSubprofilesPinCodeService();
  productOfferingService = new ProductOfferingService();
  peopleService = new PeopleService();
  channelService = new ChannelService();
  options: IConfigOptionModel = {
    lang: "en_GB",
    defaultLocale: DEFAULT_LANGUAGE_CODE,
    country: DEFAULT_COUNTRY_CODE,
    defaultCountry: DEFAULT_COUNTRY_CODE_LOWERCASE,
    categoriesCache: {
      categories: new Map<string, IMediaCategoryModel>(),
      refresh: true,
    },
  };

  private _defaultBranding: IConfigurationBrandingModel = {
    Id: DEFAULT_BRANDING_KEY,
    AppFontFamily: "Raleway",
    AppFontSize: 16,
    AppMenuPaddingTop: 47,
    AppPaddingRight: 78,
    AppPaddingLeft: 78,
    AppListPaddingTop: 55,
    AppListPaddingBottom: 0,
    AppListItemPaddingHorizontal: 10,
    AppMenuBackgroundColor: "#18161F",
    AppBackgroundColor: "#18161F",
    AppSecondaryBackroundColor: "#000",
    AppCellsBackgroundColor: "#383346",
    AppButtonBackgroundColor: "#5b5865",
    AppPrimaryColor: "#EAFF00",
    AppPrimaryTextColor: "#FFFFFF",
    AppSecondaryTextColor: "#38324C",
    AppAccentColor: "#D50909",
    AppButtonHoverColor: "#335AFF",
    AppButtonActiveColor: "#6683FF",
    WebHeaderLogoResourceKey: "APP_LOGO",
    IsDefault: true,
  };

  private _kidsChannel: IConfigurationBrandingModel = Object.assign(
    {},
    this._defaultBranding,
    {
      Id: KIDS_BRANDING_KEY,
      AppPrimaryColor: "#7846FF",
      AppBackgroundColor: "#281757",
      AppButtonHoverColor: "#9973FF",
      AppButtonActiveColor: "#A07DFF",
      IsDefault: false,
    }
  );

  private _danskChannel: IConfigurationBrandingModel = Object.assign(
    {},
    this._defaultBranding,
    {
      Id: DANSK_BRANDING_KEY,
      AppPrimaryColor: "#660224",
      AppBackgroundColor: "#000000",
      AppButtonHoverColor: "#9B093A",
      AppButtonActiveColor: "#AE0740",
      IsDefault: false,
    }
  );

  initHttpFactory(
    httpRequestFulfilledInterceptor?: (
      config: AxiosRequestConfig
    ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
    httpRequestRejectedInterceptor?: (error: IErrorModel) => any,
    httpResponseFulfilledInterceptor?: (
      config: AxiosResponse
    ) => AxiosResponse | Promise<AxiosResponse>,
    httpResponseRejectedInterceptor?: (error: IErrorModel) => any
  ) {
    this.httpFactory.addRequestInterceptor(
      "HttpRequestInterceptor",
      httpRequestFulfilledInterceptor,
      httpRequestRejectedInterceptor
    );
    this.httpFactory.addResponseInterceptor(
      "HttpResponseInterceptor",
      httpResponseFulfilledInterceptor,
      httpResponseRejectedInterceptor
    );
  }

  setLanguage(lang: string) {
    const language = LOCALE_LIST.some((el) => el === lang)
      ? LocaleHelper.getLanguageFromLocale(lang)
      : DEFAULT_LANGUAGE_CODE;
    const dayjsLocale = language || LanguageCode.English;

    this.options.lang = language || LanguageCode.English;
    loadDayjsLocale(dayjsLocale);
    dayjs.locale(dayjsLocale);
  }

  setCountry(country: string) {
    this.options.country = LocaleHelper.getSupportedCountry(country);
  }

  async getConfiguration(): Promise<IConfigurationModel> {
    const config = await lastValueFrom(
      this.clientConfigService.getConfiguration()
    );
    const configurationFromCache: IConfigurationModel | undefined =
      await StorageHelper.getConfiguration();

    const userLocation: IUserLocationModel = await lastValueFrom(
      this.locationService.get()
    );
    const defaultLanguageKey = StorageManager.getLanguageKey(
      LanguageStorage.defaultKey
    );
    const defaultLang =
      LocaleHelper.getLocaleFromLanguage(this.options.country.toLowerCase()) ||
      DEFAULT_LANGUAGE_CODE;
    if (defaultLanguageKey !== LanguageStorage.defaultValue) {
      StorageManager.setLanguageKey(LanguageStorage.defaultKey, defaultLang);
    }
    StorageManager.setLanguageKey(LanguageStorage.i18nextKey, defaultLang);

    if (userLocation?.countryCode && userLocation?.locationKnown) {
      this.options.country = userLocation.countryCode;
    }

    if (config && config?.systemConfig?.defaultLocale) {
      this.options.defaultLocale = config.systemConfig.defaultLocale;
    }

    if (configurationFromCache) {
      if (
        configurationFromCache.ModificationDate === config.changed &&
        configurationFromCache?.AppVersion ===
          process.env.NEXT_PUBLIC_VERSION &&
        configurationFromCache.Language?.split("_")[0] === this.options.lang
      ) {
        if (configurationFromCache?.DefaultLocale) {
          this.options.defaultLocale = configurationFromCache?.DefaultLocale;
        }
        return configurationFromCache;
      }
    }

    const configuration: IConfigurationModel = {
      Id: config.id,
      Guid: "3d176677-f4d8-4399-8c5f-d96ae5d12e33",
      VersionNumber: 1,
      ModificationDate: config.changed,
      Description: "NORDISK FILM PLUS",
      System: {
        ChromecastAppId: config.parameters?.chromecastAppId,
        CustomScript: config.parameters?.customScript,
        PlayerUrl: config.systemConfig?.playerUrl,
      },
      Language: LocaleHelper.getLocaleFromLanguage(this.options.lang),
      DefaultLocale: this.options.defaultLocale,
      DefaultCountry: this.options.defaultCountry,
      Country: this.options.country,
      AppVersion: process.env.NEXT_PUBLIC_VERSION ?? "",
    };

    configuration.Branding = this._defaultBranding;

    if (config.systemConfig) {
      // Init translations
      if (config.systemConfig.displayLocales) {
        configuration.Languages = config.systemConfig.displayLocales;
      }
    }

    if (config.theme) {
      configuration.Branding = {
        ...configuration.Branding,
        AppBackgroundColor: config.theme.primaryBackgroundColor,
        AppSecondaryBackroundColor: config.theme.secondaryBackgroundColor,
        AppMenuBackgroundColor: config.theme.primaryBackgroundColor,
        HeaderBackgroundColor: config.theme.primaryBackgroundColor,
        FooterBackgroundColor: config.theme.primaryBackgroundColor,
        AppPrimaryColor: config.theme.primaryBrandColor,
        AppPrimaryTextColor: config.theme.primaryTextColor,
        AppSecondaryTextColor: config.theme.secondaryTextColor,
      };

      configuration.Branding.AppFontFaces = [];

      // if (config.theme.fontLight) {
      //   configuration.Branding.AppFontFaces.push({
      //     Family: "Font",
      //     Style: "lighter",
      //     Weight: 300,
      //     Dispaly: "swap",
      //     Url: config.theme.fontLight,
      //   });
      // }

      // if (config.theme.fontRegular) {
      //   configuration.Branding.AppFontFaces.push({
      //     Family: "Font",
      //     Style: "normal",
      //     Weight: 400,
      //     Dispaly: "swap",
      //     Url: config.theme.fontRegular,
      //   });
      // }

      // if (config.theme.fontBold) {
      //   configuration.Branding.AppFontFaces.push({
      //     Family: "Font",
      //     Style: "bold",
      //     Weight: 700,
      //     Dispaly: "swap",
      //     Url: config.theme.fontBold,
      //   });
      // }

      // if (configuration.Branding.AppFontFaces.length > 0) {
      //   configuration.Branding.AppFontFamily = "Font, Raleway";
      // }
    }

    configuration.Brandings = {
      [DANSK_BRANDING_KEY]: this._danskChannel,
      [KIDS_BRANDING_KEY]: this._kidsChannel,
      [DEFAULT_BRANDING_KEY]: configuration.Branding,
    };

    if (config.presentation) {
      const presentationFallback = config.presentation.fallback;
      const presentationLocalized =
        config.presentation.localized[this.options.lang];

      const presentationLocalizedImages = presentationLocalized?.images || [];
      const presentationFallbackImages = presentationFallback?.images || [];
      // App logo
      let appLogoImg = presentationLocalizedImages.find((img) =>
        img.tags?.includes(APP_IMAGES_TYPES.LOGO)
      );

      if (!appLogoImg) {
        appLogoImg = presentationFallbackImages.find((img) =>
          img.tags?.includes(APP_IMAGES_TYPES.LOGO)
        );
      }

      if (appLogoImg) {
        configuration.Branding.WebHeaderLogoUrl = appLogoImg.url;
      }
      // App background
      let appBackgroundImg = presentationLocalizedImages.find((img) =>
        img.tags?.includes(APP_IMAGES_TYPES.BACKGROUND)
      );

      if (!appBackgroundImg) {
        appBackgroundImg = presentationFallbackImages.find((img) =>
          img.tags?.includes(APP_IMAGES_TYPES.BACKGROUND)
        );
      }

      if (appBackgroundImg) {
        configuration.Branding.AppBackgroundUrl = appBackgroundImg.url;
      }
      // App fav icon
      let appFavIcon = presentationLocalizedImages.find((img) =>
        img.tags?.includes(APP_IMAGES_TYPES.FAV_ICON)
      );

      if (!appFavIcon) {
        appFavIcon = presentationFallbackImages.find((img) =>
          img.tags?.includes(APP_IMAGES_TYPES.FAV_ICON)
        );
      }

      if (appFavIcon) {
        configuration.Branding.AppFavIconUrl = appFavIcon.url;
      }
    }

    configuration.Screens = {
      CUSTOM: {},
      SEARCH: {
        Id: CONFIGURATION.SEARCH_SCREEN_ID,
        Name: "Search",
        ScreenTypeCode: ScreenType.Search,
        Components: [
          {
            ComponentTypeCode: ComponentType.List,
            Orientation: Orientation.Horizontal,
            CellType: CellType.Frame,
            VisibleItemsCount: 4,
            IsVisible: true,
          },
        ],
      },
    };

    if (config.components) {
      // Get home screen ID
      this.options.homeScreenId =
        config.components[CONFIGURATION.HOME_SCREEN_ID] &&
        config.components[CONFIGURATION.HOME_SCREEN_ID][0]
          ? config.components[CONFIGURATION.HOME_SCREEN_ID][0].referenceId
          : undefined;

      const requests = Object.keys(config.components).map((componentKey) => {
        const componentConfig =
          config.components[componentKey].length > 0
            ? config.components[componentKey][0]
            : null;

        if (!componentConfig) {
          return;
        }

        if (componentConfig.referenceUrl) {
          return this.clientConfigService.getComponentByUrlAsPromise(
            componentConfig.referenceUrl
          );
        }

        switch (componentKey) {
          case CONFIGURATION.MENU_SCREEN_ID:
          case CONFIGURATION.FOOTER_SCREEN_ID:
          case CONFIGURATION.HOME_SCREEN_ID:
            return this.clientConfigService.getComponentByUrlAsPromise(
              componentConfig.referenceUrl
            );
        }
      });

      const data = await Promise.all(requests);

      for (const el of data) {
        if (!el) {
          continue;
        }

        const componentKey = (el as IComponentConfigModel).appType;

        let screen;

        switch (componentKey) {
          case CONFIGURATION.MENU_SCREEN_ID:
            screen = ModelsMapperHelper.toApplicationMenuScreenModel(
              el as IComponentConfigModel,
              this.options
            );

            if (
              AppConfig.PlatformCode === PlatformType.Tizen ||
              AppConfig.PlatformCode === PlatformType.TitanOS ||
              AppConfig.PlatformCode === PlatformType.WebOS
            ) {
              screen.Components?.push({
                Id: 1,
                ComponentTypeCode: ComponentType.ApplicationMenuItem,
                Name: "Application Menu Item",
                IsVisible: true,
                IsAgeRestricted: false,
                IconResourceKey: "MENU_SEARCH_ICON",
                Title: "Search",
                TitleTranslationKey: "web_menu_search",
                Action: {
                  ActionType: ActionType.OpenScreen,
                  ScreenTypeCode: ScreenType.Search,
                },
              });
            }

            // Get others screens definitions assigned to menu
            if ((el as IComponentConfigModel)?.components?.menuItems) {
              for (const menuItem of (el as IComponentConfigModel)?.components
                ?.menuItems) {
                if (menuItem?.actions) {
                  const defaultAction = menuItem?.actions[ACTION.DEFAULT];

                  if (
                    defaultAction &&
                    defaultAction.verb === ACTION.NAVIGATE_TO_PAGE &&
                    defaultAction.url
                  ) {
                    if (
                      defaultAction.componentId === this.options.homeScreenId
                    ) {
                      // Home screen is already defined in configuration
                      continue;
                    } else {
                      const navigateComponentConfig: IComponentConfigModel =
                        await this.clientConfigService.getComponentByUrlAsPromise(
                          defaultAction.url
                        );

                      if (navigateComponentConfig) {
                        const navigateScreen =
                          ModelsMapperHelper.toApplicationScreenModel(
                            navigateComponentConfig
                          );
                        navigateScreen.ScreenFrameTypeCode =
                          ScreenFrameType.Main;
                        navigateScreen.ScreenRenderModeCode =
                          ScreenRenderMode.Lazy;
                        configuration.Screens.CUSTOM[`${navigateScreen.Id}`] =
                          navigateScreen;
                      }
                    }
                  }
                }
              }
            }
            break;
          case CONFIGURATION.FOOTER_SCREEN_ID:
            screen = ModelsMapperHelper.toApplicationFooterScreenModel(
              el as IComponentConfigModel,
              this.options
            );
            break;
          case CONFIGURATION.HOME_SCREEN_ID:
            screen = ModelsMapperHelper.toApplicationScreenModel(
              el as IComponentConfigModel,
              this.options
            );

            screen.ScreenRenderModeCode = ScreenRenderMode.Lazy;

            break;
          default:
            screen = el;
            break;
        }

        if (screen) {
          // @ts-ignore
          if (screen.ScreenTypeCode === ScreenType.Custom) {
            // @ts-ignore
            configuration.Screens.CUSTOM[`${screen.Id}`] = screen;
          } else {
            // @ts-ignore
            configuration.Screens[`${screen.ScreenTypeCode}`] = screen;
          }
        }
      }
    }

    return configuration;
  }

  getScreenComponentBody(screenName: string) {
    return this.clientConfigService.getComponent(`${screenName}`).pipe(
      map((data) => ({
        screen: ModelsMapperHelper.toApplicationScreenModel(data, this.options),
        data,
      })),
      map(({ screen, data }) => ({
        screen,
        components: data.components?.pageBody,
      })),
      map(({ screen, components }) => {
        const components$ = components?.map((item) =>
          this.clientConfigService.getComponent(`${item.referenceId}`)
        );

        return {
          screen,
          components$,
        };
      }),
      switchMap(({ screen, components$ }) =>
        forkJoin([of(screen), ...components$]).pipe(
          map(([screen, ...components]) => ({
            ...screen,
            Components: components.map((component) => {
              return ModelsMapperHelper.mapToComponentModelLandingPage(
                component,
                this.options
              );
            }),
          }))
        )
      )
    );
  }

  async getComponentConfiguration(
    componentId?: string | number,
    parentalRatings?: string
  ): Promise<IScreenModel | undefined> {
    if (!componentId) {
      return Promise.resolve(undefined);
    }

    if (this.options.categoriesCache.refresh) {
      await this.refreshCategoriesCachce();
    }

    const componentConfig = await lastValueFrom(
      this.clientConfigService.getComponent(`${componentId}`)
    );

    if (!componentConfig) {
      return Promise.resolve(undefined);
    }

    const isTV =
      AppConfig.PlatformCode === PlatformType.Tizen ||
      AppConfig.PlatformCode === PlatformType.TitanOS ||
      AppConfig.PlatformCode === PlatformType.WebOS;

    const screen: IScreenModel = ModelsMapperHelper.toApplicationScreenModel(
      componentConfig,
      this.options
    );

    try {
      if (componentConfig.components) {
        screen.Components = [];

        for (const screenComponentKey in componentConfig.components) {
          const screenComponents =
            componentConfig.components[screenComponentKey];
          let screenComponentsData: IComponentConfigModel[] = [];

          // TODO if possible do it like on TVs and fetch lists data
          // when they are about to be shown on screen
          if (!isTV) {
            const screenData = screenComponents.map((screenComponent) => {
              return lastValueFrom(
                this.clientConfigService.getComponentByUrl(
                  screenComponent.referenceUrl
                )
              );
            });

            screenComponentsData = await Promise.all(screenData);
          }

          for (let i = 0; i < screenComponents.length; i++) {
            const screenComponentData = screenComponentsData?.[i];
            let screenComponentConfig = screenComponents[i];

            try {
              if (!screenComponentConfig.parameters) {
                screenComponentConfig.parameters = {
                  carouselLayout: COMPONENT_TYPE.CAROUSEL,
                  imageOrientation: IMAGE_ORIENTATION.LANDSCAPE,
                };
              }

              screenComponentConfig = {
                ...screenComponentConfig,
                ...screenComponentData,
                parameters: {
                  carouselLayout:
                    screenComponentConfig.parameters.carouselLayout,
                  imageOrientation:
                    screenComponentConfig.parameters.imageOrientation,
                },
              };

              // By default hero items have less data than what we display on a page (no year nor categories).
              // We have to make separate request for assets to get that data
              if (
                !isTV &&
                screenComponentConfig.appType === COMPONENT_TYPE.HERO_BANNER
              ) {
                try {
                  const heroItems =
                    screenComponentConfig.components?.heroBannerItems || [];
                  const heroItemsIds = heroItems
                    .map((item) => item.actions?.[ACTION.DEFAULT]?.assetId)
                    .filter((id): id is string => !!id); // Ensures only non-null/undefined IDs are included

                  if (heroItemsIds.length > 0) {
                    const { items: heroAssets } = await lastValueFrom(
                      this.assetService.assetsList(heroItemsIds)
                    );

                    // Match assets to hero items
                    for (const heroItem of heroItems) {
                      const assetId =
                        heroItem.actions?.[ACTION.DEFAULT]?.assetId;
                      heroItem.asset = heroAssets.find(
                        (asset) => asset.assetId === assetId
                      );
                    }
                  }
                } catch (error) {
                  console.error(error);
                }
              }

              if (
                screenComponentConfig.appType === COMPONENT_TYPE.IMAGE_COMPONENT
              ) {
                const defaultAction =
                  screenComponentConfig.actions?.[ACTION.DEFAULT];

                if (defaultAction && defaultAction.assetId) {
                  screenComponentConfig.asset = await lastValueFrom(
                    await this.assetService.get({
                      assetId: defaultAction.assetId,
                      includeSeasons: false,
                      includeEpisodes: false,
                      includeCollections: false,
                      parentalRatings: parentalRatings,
                    })
                  );
                }
              }

              const screenComponent = ModelsMapperHelper.toComponentModel(
                screenComponentConfig,
                this.options
              );

              if (screenComponent) {
                screen.Components.push(screenComponent);
              } else {
                ModelsMapperHelper.logMissingDefinition(screenComponentConfig);
              }
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
      return Promise.resolve(screen);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getMediaExtraMaterials(
    assetsIds: string[],
    mediaMapperOption?: IMediaMapperOptions
  ): Promise<IMediaModel[]> {
    return await lastValueFrom(
      this.assetService
        .assetsList(assetsIds)
        .pipe(
          map((results) =>
            results.items.map((item) =>
              ModelsMapperHelper.toMediaModel(
                item,
                this.options,
                mediaMapperOption
              )
            )
          )
        )
    );
  }

  async getMedia(options: IMediaOptionsModel): Promise<IMediaModel> {
    try {
      const asset = await lastValueFrom(
        await this.assetService.get({
          assetId: options.MediaId,
          includeSeasons: options.IncludeSeasons,
          /* This param is bugged and returns only 100 episodes
             so we have to do separate requests for episodes */
          // includeEpisodes: options.IncludeEpisodes,
          includeCollections: options.IncludeCollections,
          onlyPublished: options.OnlyPublished,
        })
      );

      if (options.IncludeSeasons && options.IncludeEpisodes) {
        /* "episodes" endpoint is bugged so we have to fetch seasons once again
            but this time including episodes */
        const seasonsWithEpisodes = await lastValueFrom(
          this.assetService.seasons(
            asset.seasons.map((season) => season.seasonId),
            options.IncludeEpisodes
          )
        );

        asset.seasons = seasonsWithEpisodes.items;
      }

      const isAnonymous = await AuthorizationHelper.isAnonymous();

      if (this.options.categoriesCache.refresh) {
        await this.refreshCategoriesCachce();
      }

      const media = ModelsMapperHelper.toMediaModel(
        asset,
        this.options,
        options?.MediaMapperOptions
      );

      if (options.IncludeProgress && !isAnonymous) {
        try {
          await this.getMediaProgress(media);
        } catch (error) {
          console.error(error);
        }
      }

      if (options.IncludeCollections) {
        const collections = await lastValueFrom(
          this.assetService.getCollectionEntries(media.Id)
        );

        media.Media = collections.items.map((item) =>
          ModelsMapperHelper.toMediaModel(
            item,
            this.options,
            options?.MediaMapperOptions
          )
        );
      }

      if (options.IncludeRecommendedMedia) {
        const recommendedMediaList = await lastValueFrom(
          this.assetService.recommended(options.MediaId)
        );
        if (
          recommendedMediaList.items &&
          recommendedMediaList.items.length > 0
        ) {
          media.RecommendedMedia = recommendedMediaList.items.map((item) =>
            ModelsMapperHelper.toMediaModel(
              item,
              this.options,
              options?.MediaMapperOptions
            )
          );
        }
      }
      return Promise.resolve(media);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getMediaProgress(media: IMediaModel): Promise<IMediaModel> {
    try {
      let response: IListModel<IUserPlayHistoryModel[]>;

      if (media.MediaTypeCode !== MediaType.Series)
        response = await lastValueFrom(
          this.userMediaPropertiesService.getUserPlayHistory(media.Id)
        );
      else {
        let episodesIds: (string | number)[] = [];
        media.Media?.forEach((season) =>
          season.Media?.forEach((episode) => episodesIds.push(episode.Id))
        );

        response = await lastValueFrom(
          this.userMediaPropertiesService.getUserPlayHistory(episodesIds)
        );
      }

      return ModelsMapperHelper.toMediaProgressModel(media, response);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getNextMedia(
    mediaId: string | number,
    collectionId?: string
  ): Promise<IMediaModel> {
    try {
      let asset = await lastValueFrom(
        collectionId
          ? this.assetService.getNextCollectionEntry(mediaId, collectionId)
          : this.assetService.getNext(mediaId)
      );

      const media = ModelsMapperHelper.toMediaModel(asset, this.options);

      return Promise.resolve(media);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getPrevMedia(
    mediaId: IMediaModel,
    collectionId?: string
  ): Promise<IMediaModel> {
    try {
      let prevMedia;
      if (mediaId?.OrderInParent !== 1) {
        let asset = await lastValueFrom(
          collectionId
            ? this.assetService.getPrevCollectionEntry(mediaId.Id, collectionId)
            : this.assetService.getPrev(mediaId.Id)
        );
        prevMedia = ModelsMapperHelper.toMediaModel(asset, this.options);
      }

      if (!prevMedia) {
        return Promise.reject();
      }
      return Promise.resolve(prevMedia);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async searchMedia({
    Locales,
    FullTextSearch,
    ParentalRatings,
    PageNumber,
    PageSize,
    People,
    Sort,
  }: IMediaSearchFilterModel): Promise<IMediaSearchStateModel> {
    const result: IMediaListModel = {
      SourceType: SourceType.MediaList,
      Entities: [],
      TotalCount: 0,
    };

    try {
      const assetsList = await lastValueFrom(
        await this.assetService.search(
          PageSize || SEARCH_MAX_RESULTS,
          PageNumber || 1,
          `${Sort}`,
          `${Locales}`,
          `${FullTextSearch}`,
          ParentalRatings,
          People?.join(",")
        )
      );

      result.Entities = assetsList.items.map((item) => {
        return ModelsMapperHelper.toMediaModel(item.asset, this.options);
      });
      result.PageNumber = assetsList.pageNumber;
      result.PageSize = assetsList.pageSize;
      result.TotalCount = assetsList.totalCount || 0;
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }

    return Promise.resolve(result);
  }

  async searchPeople({
    PageSize,
    PageNumber,
    FullTextSearch,
    Sort,
  }: IPeopleSearchFilterModel): Promise<IPeopleSearchStateModel> {
    const result: IPeopleListModel = {
      SourceType: SourceType.MediaList,
      Entities: [],
      TotalCount: 0,
    };

    try {
      const peopleList = await lastValueFrom(
        await this.peopleService.search(
          PageSize,
          PageNumber,
          `${FullTextSearch}`,
          Sort
        )
      );

      result.Entities = peopleList.items.map((item) => {
        return ModelsMapperHelper.toPeopleModel(item.participant);
      });
      result.PageNumber = peopleList.pageNumber;
      result.PageSize = peopleList.pageSize;
      result.TotalCount = peopleList.totalCount || 0;
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }

    return Promise.resolve(result);
  }

  async searchMulti({
    PageSize,
    PageNumber,
    Locales,
    FullTextSearch,
    ParentalRatings,
  }: IMediaSearchFilterModel): Promise<IMultiSearchStateModel> {
    try {
      const results = await lastValueFrom(
        await this.assetService.searchMulti(
          PageSize,
          PageNumber,
          `${Locales}`,
          `${FullTextSearch}`,
          ParentalRatings
        )
      );

      const result: IMultiSearchStateModel = {
        Media: {
          Entities: results.assetHits.items.map((item) =>
            ModelsMapperHelper.toMediaModel(item.asset, this.options)
          ),
          PageNumber: results.assetHits.pageNumber,
          PageSize: results.assetHits.pageSize,
          TotalCount: results.assetHits.totalCount || 0,
        },
        People: {
          Entities: results.participantHits.items.map((item) =>
            ModelsMapperHelper.toPeopleModel(item.participant)
          ),
          PageNumber: results.participantHits.pageNumber,
          PageSize: results.participantHits.pageSize,
          TotalCount: results.participantHits.totalCount || 0,
        },
      };

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getMediaList(
    options: IMediaListOptionsModel
  ): Promise<IMediaListModel> {
    const result: IMediaListModel = {
      SourceType: options.SourceType ?? SourceType.MediaList,
      Entities: [],
      TotalCount: 0,
      MediaListId: options.MediaListId,
      Title: "",
    };

    try {
      let sourceUrl = options.SourceUrl;
      let assetsList: IAssetListModel | null = null;
      let tagsList: ITagListModel | null = null;

      // Workaround for case where empty carousel returns url to all assets
      if (sourceUrl && sourceUrl.endsWith(BLACKLISTED_URL.EMPTY_CAROUSEL)) {
        return Promise.resolve(result);
      }

      if (
        options.QueryParams?.category === options.MediaListId ||
        options.QueryParams?.participant === options.MediaListId
      ) {
        const query = options.QueryParams?.category
          ? `tagIds:${options.QueryParams.category}`
          : `participants.personId:${options.QueryParams?.participant}`;

        assetsList = await lastValueFrom(
          await this.assetService.searchV1(
            options.PageSize,
            options.PageNumber,
            query,
            options.QueryParams.sort,
            options.QueryParams.assetTypes,
            options.ParentalRatings
          )
        );

        result.Entities = assetsList.items.map((item) =>
          ModelsMapperHelper.toMediaModel(
            item,
            this.options,
            options?.MediaMapperOptions
          )
        );
        result.PageNumber = assetsList?.pageNumber ?? options.PageNumber;
        result.PageSize = assetsList?.pageSize ?? options.PageSize;
        result.TotalCount = assetsList?.totalCount ?? assetsList.items.length;

        return Promise.resolve(result);
      } else if (
        options.QueryParams?.category ||
        options.QueryParams?.participant
      ) {
        const query = options.QueryParams?.category
          ? `tagIds:${options.QueryParams.category}`
          : `participants.personId:${options.QueryParams?.participant}`;

        assetsList = await lastValueFrom(
          await this.assetService.searchV1(
            options.PageSize,
            options.PageNumber,
            query,
            options.QueryParams?.sort,
            options.QueryParams?.assetTypes,
            options.ParentalRatings
          )
        );
      }

      if (this.options.categoriesCache.refresh) {
        await this.refreshCategoriesCachce();
      }

      if (!sourceUrl) {
        if (options.MediaListId) {
          const mediaListComponent = await lastValueFrom(
            this.clientConfigService.getComponent(`${options.MediaListId}`)
          );
          const presentation = mediaListComponent?.presentation;
          const localized = presentation?.localized?.[this.options.lang];
          const fallback = presentation?.fallback;

          result.Title = localized?.title || fallback?.title || "";

          if (options.IncludeSeoData) {
            result.Seo = {
              Title: localized?.seoTitle || fallback?.seoTitle || "",
              Description:
                localized?.seoDescription || fallback?.seoDescription || "",
            };
          }

          if (mediaListComponent) {
            sourceUrl = mediaListComponent.contentUrl?.url;

            // By default hero items have less data than what we display on a page (no year nor categories).
            // We have to make separate request for assets to get that data
            if (mediaListComponent.appType === COMPONENT_TYPE.HERO_BANNER) {
              const heroItems =
                mediaListComponent.components?.heroBannerItems || [];
              const heroItemsIds = heroItems
                .map((item) => item.actions?.[ACTION.DEFAULT]?.assetId)
                .filter((id): id is string => !!id); // Ensures only non-null/undefined IDs are included

              if (heroItemsIds.length > 0) {
                const ids = heroItemsIds.join(",");
                sourceUrl = `${this.assetService.url}/content/asset?assetIds=${ids}&fieldSet=ALL`;
              }
            }

            switch (mediaListComponent.appSubType) {
              case SourceType.Favourites:
                result.SourceType = SourceType.Favourites;
                break;
              case SourceType.ContinueWatching:
                result.SourceType = SourceType.ContinueWatching;
                break;
              default:
                result.SourceType = SourceType.MediaList;
                break;
            }

            if (sourceUrl) {
              const url = sourceUrl.split("?")[0];
              const query = sourceUrl.split("?")[1];
              const queryParams = new URLSearchParams(query);

              if (options.PageSize) {
                queryParams.set("pageSize", `${options.PageSize}`);
              }

              if (options.PageNumber) {
                queryParams.set("pageNumber", `${options.PageNumber}`);
              }

              if (options.QueryParams && options.QueryParams.sort) {
                queryParams.delete("sort");
              }

              sourceUrl = url + UrlHelper.joinQueries(queryParams.toString());
            }
          }
        }
      }

      if (!sourceUrl) {
        return Promise.resolve(result);
      }

      if (options.QueryParams && options.QueryParams.sort) {
        sourceUrl += `&sort=${options.QueryParams.sort}`;
      }

      switch (result.SourceType) {
        case SourceType.Favourites:
          const response = await lastValueFrom(
            sourceUrl
              ? this.userMediaPropertiesService.getUserFavouriteListByComponentUrl(
                  sourceUrl
                )
              : this.userMediaPropertiesService.getUserFavouriteList(
                  options.QueryParams?.channelRoute
                )
          );
          assetsList = {
            items: response.map((item) => item.asset!),
          };
          break;
        case SourceType.GenreList:
          tagsList = await lastValueFrom(
            await this.tagService.searchByUrl(sourceUrl)
          );
          break;
        case SourceType.ContinueWatching:
        default:
          assetsList = await lastValueFrom(
            await this.assetService.searchByUrl(
              sourceUrl,
              options.ParentalRatings
            )
          );

          break;
      }

      if (tagsList) {
        const items = tagsList.items;
        const entities = items.map((item) => {
          return ModelsMapperHelper.toGenreModel(
            item,
            this.options,
            options?.MediaMapperOptions
          );
        });

        result.Entities = entities.sort((a, b) => {
          return a.Title && b.Title && a.Title < b.Title ? -1 : 1;
        });
        result.PageNumber = tagsList?.pageNumber ?? options.PageNumber;
        result.PageSize = tagsList?.pageSize ?? options.PageSize;
        result.TotalCount = tagsList?.totalCount ?? items.length;
        result.SourceUrl = sourceUrl;
      }

      if (assetsList) {
        const items =
          result.SourceType === SourceType.ContinueWatching
            ? uniqueBy(assetsList.items, "tvShowId")
            : assetsList.items;

        result.Entities = items.map((item) => {
          return ModelsMapperHelper.toMediaModel(
            item,
            this.options,
            options?.MediaMapperOptions
          );
        });
        result.PageNumber = assetsList?.pageNumber ?? options.PageNumber;
        result.PageSize = assetsList?.pageSize ?? options.PageSize;
        result.TotalCount = assetsList?.totalCount ?? items.length;
        result.SourceUrl = sourceUrl;
      }
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
    return Promise.resolve(result);
  }

  async getMediaCategories(
    tagType?: boolean
  ): Promise<IMediaCategoryListModel> {
    const result: IMediaCategoryListModel = {
      Entities: [],
      TotalCount: 0,
    };
    try {
      let tags;
      if (tagType) {
        const genreTags = await lastValueFrom(
          await this.tagService.searchByTagType("genre", 100, 1)
        );

        tags = genreTags.items;

        result.Entities = sortByKey(
          tags.map((item) =>
            ModelsMapperHelper.toMediaCategoryModel(item, this.options)
          ),
          "CategoryName"
        );
        result.TotalCount = result.Entities.length;
      } else {
        tags = await lastValueFrom(this.tagService.search(100, 1, "tagId"));

        result.Entities = sortByKey(
          tags.items.map((item) =>
            ModelsMapperHelper.toMediaCategoryModel(item, this.options)
          ),
          "CategoryName"
        );
        result.TotalCount = tags.totalCount;
      }
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }

    return Promise.resolve(result);
  }

  async getUserSessionDetails(): Promise<ISessionModel> {
    try {
      return await lastValueFrom(
        this.sessionService.get().pipe(shareReplay(2))
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getUserFavorites(): Promise<IUserFavoritesModel[]> {
    try {
      const result = await lastValueFrom(
        this.userMediaPropertiesService
          .getUserFavouriteList()
          .pipe(
            map((result) => ModelsMapperHelper.toUserFavoritesModel(result))
          )
      );

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getUserWatchHistory(): Promise<IMediaModel[]> {
    try {
      const response = await lastValueFrom(
        this.userMediaPropertiesService.getUserPlayHistoryList()
      );

      const result: IMediaModel[] | undefined = !!response.items?.length
        ? response.items?.map((item) =>
            ModelsMapperHelper.toMediaModel(item, this.options)
          )
        : undefined;

      return Promise.resolve(result ?? []);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async deleteUserWatchHistory(id: string | number): Promise<void> {
    try {
      await lastValueFrom(
        this.userMediaPropertiesService.deleteUserPlayHistory(id)
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async addToMyList(mediaId: string | number): Promise<IMediaModel> {
    try {
      await lastValueFrom(
        this.userMediaPropertiesService.addToFavourites(mediaId)
      );
      const media = await Promise.all([
        this.getMedia({ MediaId: mediaId }),
        lastValueFrom(this.userMediaPropertiesService.addToFavourites(mediaId)),
      ]);

      return media[0];
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getUserDevices(): Promise<IUserDeviceModel[]> {
    try {
      const response = await lastValueFrom(this.userDevicesService.get());
      return response.devices.map((item) =>
        ModelsMapperHelper.toUserDeviceModel(item)
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getUserPlayDevices(): Promise<IUserDeviceModel[]> {
    try {
      const response = await lastValueFrom(
        this.userDevicesService.getPlayDevice()
      );
      return response.map((item) =>
        ModelsMapperHelper.toUserPlayDeviceModel(item)
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async deleteUserDevice(id: string): Promise<void> {
    try {
      await lastValueFrom(this.userDevicesService.delete(id));
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async deleteUserPlayDevice(id: string): Promise<void> {
    try {
      await lastValueFrom(this.userDevicesService.deletePlayDevice(id));
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async removeFromMyList(mediaId: string | number): Promise<void> {
    try {
      await lastValueFrom(
        this.userMediaPropertiesService.removeFromFavourites(mediaId)
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getLastViewEpisode(mediaId: string | number): Promise<IMediaModel> {
    try {
      return await lastValueFrom(
        this.userMediaPropertiesService
          .getUserLastViewedEpisode(mediaId)
          .pipe(
            map((response) =>
              ModelsMapperHelper.toMediaModel(response.asset, this.options)
            )
          )
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async refreshCategoriesCachce() {
    this.options.categoriesCache.categories.clear();
    let pageNumber = 1;
    let pageSize = 100;
    let totalCount = 100;

    try {
      while (this.options.categoriesCache.categories.size < totalCount) {
        const tags = await lastValueFrom(
          this.tagService.search(pageSize, pageNumber)
        );

        for (const tag of tags.items) {
          const category = ModelsMapperHelper.toMediaCategoryModel(
            tag,
            this.options
          );
          this.options.categoriesCache.categories.set(tag.tagId, category);
        }

        pageNumber += 1;
        totalCount = tags.totalCount;
      }
      this.options.categoriesCache.refresh = false;
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getUserAvailabilityKeys(): Promise<IUserAvailabilityKeysModel> {
    try {
      return await lastValueFrom(
        this.entitlementService
          .getAvailabilityKeys()
          .pipe(
            map((response) =>
              ModelsMapperHelper.toUserAvailabilityKeysModel(response)
            )
          )
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  getUserSubprofiles(
    currentSubprofileId?: string,
    defaultMainSubprofileName?: string,
    countryCode?: string
  ): Observable<IUserSubprofileModel[]> {
    return this.userSubprofilesService
      .getUserSubprofiles()
      .pipe(
        map((userSubprofiles) =>
          userSubprofiles.profiles.map((subprofile) =>
            ModelsMapperHelper.toUserSubprofileModel(
              subprofile,
              currentSubprofileId,
              defaultMainSubprofileName,
              countryCode
            )
          )
        )
      );
  }

  async createUserSubprofile(
    data: IUserSubprofileModel,
    currentSubprofileId?: string,
    defaultMainSubprofileName?: string
  ): Promise<IUserSubprofileModel[]> {
    try {
      const payload: IUserSubprofileRequestModel = {
        displayName: data.DisplayName,
        child: data.Child,
        profileType: data.ProfileType,
        language: data.Language,
        metadata: {
          color: data.Color,
          avatarUrl: data.AvatarUrl,
          initialHomePage: userProfileHelper.getInitialHomePage(data),
        },
      };

      const response = await lastValueFrom(
        this.userSubprofilesService.createUserSubprofile(payload)
      );

      return response.profiles.map((subprofile) =>
        ModelsMapperHelper.toUserSubprofileModel(
          subprofile,
          currentSubprofileId,
          defaultMainSubprofileName,
          DEFAULT_COUNTRY_CODE
        )
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async updateUserSubprofile(
    data: IUserSubprofileModel,
    id: string | number
  ): Promise<IUserSubprofileModel> {
    try {
      const payload = {
        displayName: data.DisplayName,
        child: data.Child,
        profileType: data.ProfileType,
        language: data.Language,
        metadata: {
          color: data.Color,
          avatarUrl: data.AvatarUrl,
          initialHomePage: userProfileHelper.getInitialHomePage(data),
        },
      };

      await lastValueFrom(
        this.userSubprofilesService.updateUserSubprofile(payload, id)
      );

      return { ...data, FirstLetter: data.DisplayName?.charAt(0) };
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async deleteUserSubprofile(id: string | number): Promise<void> {
    try {
      await lastValueFrom(this.userSubprofilesService.deleteUseSubprofile(id));
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async selectUserSubprofile(
    subprofileId: string,
    remember: boolean
  ): Promise<ITokenModel | undefined> {
    try {
      const response = await lastValueFrom(
        this.userSubprofilesService.selectUseSubprofile(subprofileId)
      );
      const prevTokens = StorageHelper.getSession();
      const newTokens: ITokenModel = {
        ...prevTokens,
        DataToken: response.sessionToken,
      };

      StorageHelper.setSession(newTokens);

      remember
        ? await StorageHelper.setCurrentSubprofileId(response.userId)
        : await StorageHelper.deleteCurrentSubprofileId();

      return newTokens;
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  getUserParentalRatings(
    countryCode: string
  ): Observable<IUserParentalRatingModel[]> {
    return this.userParentalRatingService
      .getParentalRating(
        "suggestedProfileTypeMapping2" // TO DO - CHANGE TO suggestedProfileTypeMapping when will be ready
      )
      .pipe(map((response) => Object.values(response[countryCode])));
  }

  getUserSubprofilePinCode(): Observable<string | undefined> {
    return this.userSubprofilesPinCodeService.getPinCode().pipe(
      map((response) => response?.[0]?.pinId ?? undefined),
      catchError(() => of(undefined))
    );
  }

  async setUserSubprofilePinCode(
    pinCode: string,
    pinCodeId?: string
  ): Promise<string> {
    try {
      const request: IUserSubprofilePinCodeRequestModel = {
        inClear: pinCode,
        grants: ["ok"],
      };

      const response = await lastValueFrom(
        this.userSubprofilesPinCodeService.setPinCode(request, pinCodeId)
      );

      return response[0].pinId;
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async deleteUserSubprofilePinCode(pinCodeId: string): Promise<void> {
    try {
      await lastValueFrom(
        this.userSubprofilesPinCodeService.deletePinCode(pinCodeId)
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async validateUserSubprofilePinCode(
    pinCodeId: string,
    pinCode: string
  ): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.userSubprofilesPinCodeService.validatePinCode(pinCodeId, {
          inClear: pinCode,
        })
      );

      return response[0] === "ok";
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  getUserFavouriteListByComponentUrl(url: string): Observable<IMediaModel[]> {
    return this.userMediaPropertiesService
      .getUserFavouriteListByComponentUrl(url)
      .pipe(
        map((response) =>
          response
            .map((item) => item.asset as IAssetModel)
            .map((item) => ModelsMapperHelper.toMediaModel(item, this.options))
        )
      );
  }

  getProductOffers(): Observable<ITransactionProductOfferingResponseModel> {
    return this.productOfferingService.getProductOfferings();
  }

  getProductOfferingIds(): Observable<string[]> {
    return this.getAccountPurchase().pipe(
      map((response) =>
        response.filter(
          (item) =>
            item.until > new Date().toISOString() &&
            item.from < new Date().toISOString() &&
            item.status === "fulfilled"
        )
      ),
      map((response) => response.map(({ productIds }) => productIds)),
      map((data) => {
        const reduceDuplicates = (arr: string[]) =>
          arr.filter((item, index) => arr.indexOf(item) === index);

        return reduceDuplicates(data.flat());
      })
    );
  }

  getAccountPurchase(): Observable<IPurchaseResponseModel> {
    return this.productOfferingService.getAccountPurchase();
  }

  async getChannelOnNow(channelId: string): Promise<IChannelModel> {
    const channelData = await lastValueFrom(
      this.channelService.onNow(channelId)
    );

    return {
      ...ModelsMapperHelper.toMediaModel(channelData.channel, this.options),
      Active: channelData.active,
      Media: channelData.assets?.map((asset) => ({
        ...ModelsMapperHelper.toMediaModel(asset.asset, this.options),
        StartDateTime: new Date(asset.startTime),
        EndDateTime: new Date(asset.endTime),
      })),
    };
  }
}
