import { useEffect } from "react";
import { isServerSideRender } from "@nf/helpers";

export const useScrollToTopOnMount = () => {
  useEffect(() => {
    if (!isServerSideRender) {
      document.body.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);
};
