/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { MediaCategoryType } from "../enums/Media/MediaCategoryType";
import { IMediaCategoryModel } from "../models/Media";
import { MediaChannelType } from "../enums/Media/MediaChannelType";
import { IMediaModel } from "../models/Media/IMediaModel";
import { CHANNELS } from "@nf/constants";

export const mediaDetailsTagHelper = (
  tags?: IMediaCategoryModel[],
  tagType = MediaCategoryType.Main
) =>
  tags?.filter(
    (tag) => tag.CategoryName !== "" && tag.CategoryTypeCode === tagType
  ) ?? [];

export const getCurrentChannelType = (type: string | string[]) => {
  if (type.includes(MediaChannelType.Dansk_Filmskat)) {
    return CHANNELS.DANSK_FILMSKAT;
  }

  if (type.includes(MediaChannelType.Kids)) {
    return CHANNELS.KIDS;
  }

  return CHANNELS.NF_PLUS;
};

export const channelTagsFromMedia = (media: IMediaModel) => {
  const channelTags = media.Categories?.filter(
    (tag) => tag.CategoryTypeCode === MediaCategoryType.Channel
  ).map((tag) => tag.CategoryId.toString());
  if (channelTags) {
    return getCurrentChannelType(channelTags);
  }
  return CHANNELS.NF_PLUS;
};
