/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import { IDeviceListModel, IPlayDeviceModel } from "../models";
import { AppConfig } from "@nf/constants";

export class UserDevicesServices {
  get url(): string {
    return `/v2/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/device`;
  }

  public get = (): Observable<IDeviceListModel> => {
    return new Observable(
      (observer: Observer<IDeviceListModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}`,
        })
    );
  };

  public delete = (id: string): Observable<void> => {
    return new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/${id}`,
        })
    );
  };

  public getPlayDevice = (): Observable<IPlayDeviceModel[]> => {
    return new Observable(
      (observer: Observer<IPlayDeviceModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `/v1/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/play/device`,
        })
    );
  };

  public deletePlayDevice = (id: string): Observable<void> => {
    return new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `/v1/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/play/device/${id}`,
        })
    );
  };
}
