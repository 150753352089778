/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AxiosSubscriber } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import { Observable, Observer } from "rxjs";
import { ISessionModel } from "../models";
import { AppConfig } from "@nf/constants";

export class SessionService {
  get url(): string {
    return `/v2/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/auth/session`;
  }

  public get = (): Observable<ISessionModel> =>
    new Observable(
      (observer: Observer<ISessionModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: this.url,
        })
    );
}
