import { REDIRECT_URL_PARAM } from "@nf/constants";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useRedirectUrlParam = () => {
  const router = useRouter();
  const redirectUrl = Array.isArray(router.query[REDIRECT_URL_PARAM])
    ? router.query[REDIRECT_URL_PARAM]?.[0]
    : router.query[REDIRECT_URL_PARAM];

  return useMemo(() => redirectUrl, [router.query]);
};
