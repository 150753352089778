/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const LIST_NAME_PREFIX = {
    LOGO: "$logo",
    LARGE: "$large",

};
