import { getCookie, setCookie, CookieValueTypes } from "cookies-next";

export class CookieManager {
  static delete(name: string): void {
    const expiresYesterday: Date = new Date(
      new Date().setDate(new Date().getDate() - 1)
    );
    setCookie(name, "", { maxAge: -1, expires: expiresYesterday });
  }

  static add(
    name: string,
    value: unknown,
    expires?: Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    httpOnly?: boolean
  ) {
    return setCookie(name, value, {
      expires,
      path: path ? path : "/",
      domain,
      secure,
      httpOnly,
    });
  }

  static hasCookie(name: string): boolean {
    return !!getCookie(name);
  }

  static getCookie(name: string): CookieValueTypes {
    return getCookie(name);
  }
}
