import { useEffect } from "react";
import { ROUTES } from "@nf/constants";
import { AuthStore, dispatch, useAuthSelector } from "@nf/common";
import { useRouter } from "next/router";
import { useIsAuthenticated } from "./useIsAuthenticated";

export const useSyncWebUser = () => {
  const { isLoggedIn, isProcessing } = useIsAuthenticated();
  const router = useRouter();
  useEffect(() => {
    const excludedRoutes = [
      ROUTES.LOGOUT,
      ROUTES.LOGIN,
      ROUTES.SIGN_UP_PAYMENT,
    ].find((x) => router.asPath.includes(x));

    if (!isLoggedIn && !excludedRoutes && !isProcessing) {
      dispatch(AuthStore.Actions.syncWebUser());
    }
  }, [router.asPath, isLoggedIn, isProcessing]);
};

export const useSyncCompleted = (): boolean => {
  const { isCompleted: isAuthProcessCompleted } = useAuthSelector();
  return isAuthProcessCompleted ?? false;
};
