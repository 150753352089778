/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum SourceType {
  MediaList = "MediaList",
  Favourites = "Favourites",
  ContinueWatching = "ContinueWatching",
  GenreList = "GenreList",
}
