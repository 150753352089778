/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum CellType {
  Default = "DEFAULT",
  Highlights = "HIGHLIGHTS",
  Circle = "CIRCLE",
  Cover = "COVER",
  CoverLarge = "COVER_LARGE",
  Frame = "FRAME",
  FrameLarge = "FRAME_LARGE",
  Promo = "PROMO",
  Button = "BUTTON",
  Square = "SQUARE",
  Event = "EVENT",
  Custom = "CUSTOM",
  Poster = "POSTER",
  Genres = "GENRES",
}
