/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppConfigDefault } from "./AppConfig.default";

declare let tizen: any;

export class AppConfigTizen extends AppConfigDefault {

  static get AppLocalTimezone() {
    if (typeof tizen != "undefined") {
      return tizen.time.getLocalTimezone();
    }
  }

  static getDeviceLocale = (callback: CallableFunction) => {
    if (typeof tizen != "undefined") {
      const onSuccess = (data: any) => {
        callback(`${data.language}`, `${data.country}`);
      };
      const onError = (error: any) => {
        console.error("Get Device Locale Error: ", JSON.stringify(error));
        callback("", "");
      };
      tizen.systeminfo.getPropertyValue("LOCALE", onSuccess, onError);
    } else {
      callback("", "");
    }
  };

  static get AppDevicePlatformVersion(): string {
    if (typeof tizen != "undefined") {
      try {
        const platform = tizen.systeminfo.getCapability(
          "http://tizen.org/feature/platform.version"
        );
        if (platform) {
          return `${platform}`;
        }
      } catch {}
    }
    return "";
  }
}
