export const EXTERNAL_LINKS = {
  HELPCENTER: "https://help.nordiskfilmplus.com/",
  PRIVACY_POLICY: "https://help.nordiskfilmplus.com/da/articles/36",
  MARKETING_POLICY: "https://help.nordiskfilmplus.com/da/articles/33",
  SIGNUP: process.env.NEXT_PUBLIC_AUTH_URL + "/{country}/{language}/signup",
  FORGOT_PASSWORD:
    process.env.NEXT_PUBLIC_AUTH_URL + "/{country}/{language}/forgot-password",
  TV_LOGIN: "nordiskfilmplus.com/tv",
  WISH_LIST: "https://feedback.userreport.com/89e7f0d6-969c-4969-8dc9-8ca751be4f47/#ideas/popular",
};
