import { useEffect } from "react";
import { useProfiles } from "./useProfiles";
import {
  IUserSubprofileModel,
  StorageHelper,
  useUserSubprofilesSelector,
} from "@nf/common";
import { ROUTES } from "@nf/constants";
import { useRouter } from "next/router";
import { useSetCurrentSubProfile } from "./useSetCurrentSubProfile";
import { useRedirectToProfileScreen } from "./useRedirectToProfileScreen";
export const useSetCurrentProfileOnMount = () => {
  const router = useRouter();
  const { Data: subprofilesState } = useUserSubprofilesSelector();
  const selectCurrentProfile = useSetCurrentSubProfile();
  const { getCurrentProfile } = useProfiles();

  const redirectToProfilePageSelection = useRedirectToProfileScreen();

  useEffect(() => {
    const checkRedirection = async () => {
      const hasProfiles = await StorageHelper.hasProfiles();

      if (
        hasProfiles &&
        !subprofilesState?.currentSubprofile &&
        !router.asPath.includes(ROUTES.SELECT_PROFILE_SCREEN) &&
        !router.asPath.includes(ROUTES.LOGIN)
      ) {
        try {
          getCurrentProfile().then((currentProfile) => {
            if (currentProfile) {
              const selectedProfile = subprofilesState?.subprofiles?.find(
                (subProfile: IUserSubprofileModel) =>
                  subProfile.UserId === currentProfile.UserId
              );

              selectedProfile &&
                selectCurrentProfile({ ...selectedProfile, ...currentProfile });
            } else {
              redirectToProfilePageSelection();
            }
          });
        } catch (e) {
          console.warn(e);
        }
      }

      if (
        !hasProfiles &&
        !subprofilesState?.currentSubprofile &&
        subprofilesState
      ) {
        redirectToProfilePageSelection();
      }
    };

    checkRedirection();
  }, [subprofilesState, router.asPath]);
};
