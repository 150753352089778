import { useReplacePage } from "./useReplacePage";
import { useRedirectUrlParam } from "./useRedirectUrlParam";
import { useCallback } from "react";

export const useRedirectToUrlParam = () => {
  const redirectUrl = useRedirectUrlParam();
  const redirectTo = useReplacePage();

  const redirectToUrlParam = useCallback(() => {
    redirectTo(`/${redirectUrl}`);
  }, []);

  return {
    hasRedirectUrlParam: !!redirectUrl,
    redirectToUrlParam,
  };
};
