const COOKIE_PREFIX = "nf";
export const LOGGED_IN_COOKIE = `${COOKIE_PREFIX}_loggedIn` as const;
export const USERS_COOKIE = `${COOKIE_PREFIX}_users` as const;
export const SESSION_COOKIE = `${COOKIE_PREFIX}_session` as const;
export const IS_CHILD_COOKIE = "isChild";
export const USER_ID_COOKIE = `${COOKIE_PREFIX}_userId` as const;
export const DEVICE_COOKIE = "device";

export const MOBILE_COOKIE = "isMobile";

export const CSRF_TOKEN = `${COOKIE_PREFIX}_csrfToken` as const;

export const PRODUCTS_COOKIE = `${COOKIE_PREFIX}_products` as const;

export const COUNTRY_COOKIE = "country";
export const LANGUAGE_COOKIE = "lang";
