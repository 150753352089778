import React from "react";
import { useIsLoading } from "../../helpers/setIsLoadingContext";
import { Splash } from "../../screens/Splash";

export const PageOverlay: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isLoading: isPageTransitioning } = useIsLoading();
  return (
    <>
      {isPageTransitioning && <Splash />}
      {children}
    </>
  );
};
