/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppConfig, CHANNELS } from "@nf/constants";
import { IThemeContext } from "../context/ThemeContext";
import { MediaChannelType } from "../enums";

export const setBrandingFromUrl = (
  screenKey: string,
  channelContext: IThemeContext
) => {
  if (channelContext.onBrandingChange && !screenKey) {
    channelContext.onBrandingChange(CHANNELS.NF_PLUS);
  }
  if (channelContext.onBrandingChange && screenKey) {
    switch (screenKey) {
      case AppConfig?.ChannelDansk:
        channelContext.onBrandingChange(CHANNELS.DANSK_FILMSKAT);
        break;
      case AppConfig?.ChannelKids:
        channelContext.onBrandingChange(CHANNELS.KIDS);
        break;
      default:
        channelContext.onBrandingChange(CHANNELS.NF_PLUS);
        break;
    }
  }
};

export const channelRouteToChannelTypeMapping = {
  [AppConfig.ChannelHome]: MediaChannelType.NFplus,
  [AppConfig.ChannelDansk]: MediaChannelType.Dansk_Filmskat,
  [AppConfig.ChannelKids]: MediaChannelType.Kids,
};

export const getChannelTypeFromChannelRoute = (channel: string) => {
  return channelRouteToChannelTypeMapping[channel];
};
