/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { AppConfigDefault } from "./AppConfig.default";
import { AppConfigTizen } from "./AppConfig.tizen";
import { AppConfigWebOS } from "./AppConfig.webos";
import { IAppConfig } from "./IAppConfig";

let AppConfig: IAppConfig;

switch (process.env.NEXT_PUBLIC_PLATFORM_CODE || process.env.REACT_APP_PLATFORM_CODE) {
  case "TIZEN":
    AppConfig = new AppConfigTizen();
    break;
  case "WEB_OS":
    AppConfig = new AppConfigWebOS();
    break;
  default:
    AppConfig = new AppConfigDefault();
    break;
}

export { AppConfig };
