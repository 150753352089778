/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable } from "rxjs";
import { IErrorModel } from "../../models";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { DataProvider } from "../../providers";
import * as Actions from "./actions";
import * as Consts from "./consts";
import { IGetConfigurationAction } from "./types";
import { StorageHelper } from "../../helpers";

const getConfigurationEpic = (action$: Observable<IGetConfigurationAction>) =>
  action$.pipe(
    ofType(Consts.GET_CONFIGURATION),
    switchMap(async () => {
      try {
        const config = await DataProvider.getConfiguration();
        await StorageHelper.setConfiguration(config as Record<string, unknown>);
        return Actions.getConfigurationSuccess(config);
      } catch (error) {
        console.log("configuration error: ", error);
        return Actions.getConfigurationFailure(error as IErrorModel);
      }
    })
  );

export const configurationEpics = [getConfigurationEpic];
