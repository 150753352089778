/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  browserName,
  osName,
  mobileModel,
  isMobile,
  isMobileOnly,
  isTablet,
  isSafari,
  isFirefox,
} from "react-device-detect";
import { IUserDeviceModel } from "../models";
import { GuidHelper } from "./guid.helper";
import { PlatformHelper } from "./platformHelper";
import { PlatformTypeHelper } from "./platformTypeHelper";
import { StorageHelper } from "./storageHelper";
export class DeviceHelper {
  static getDeviceInfo = async (): Promise<IUserDeviceModel> => {
    let device = await StorageHelper.getDevice();

    if (!device || !device.Id) {
      device = {
        Id: GuidHelper.newGuid(),
        PlatformCode: PlatformTypeHelper.getValue(
          `${
            process.env.NEXT_PUBLIC_PLATFORM_CODE ||
            process.env.REACT_APP_PLATFORM_CODE
          }`,
          PlatformHelper.Platform
        ),
        Name: mobileModel !== "none" ? mobileModel : `${osName} ${browserName}`,
      };

      await StorageHelper.setDevice(device);
    }

    return device;
  };

  static isPhone(): boolean {
    return isMobileOnly;
  }

  static isTablet(): boolean {
    return isTablet;
  }

  // tablets & phones
  static isMobile(): boolean {
    return isMobile;
  }

  static isSafari(): boolean {
    return isSafari;
  }

  static isFirefox(): boolean {
    return isFirefox;
  }
}
