/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { StorageHelper } from "../../";
import { AppConfig } from "@nf/constants";
import { ScreenType } from "../../enums";
import { IConfigurationModel } from "../../models";
import * as Consts from "./consts";
import { ConfigurationActionsTypes, IConfigurationState } from "./types";

export const initialState: IConfigurationState = {
  isLoading: false,
};

export const configurationReducer = (
  state = initialState,
  action: ConfigurationActionsTypes
): IConfigurationState => {
  state.action = action;

  switch (action.type) {
    case Consts.GET_CONFIGURATION: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case Consts.GET_CONFIGURATION_SUCCESS: {
      let configuration: IConfigurationModel = {};
      if (action.payload) {
        configuration = { ...action.payload };
      }

      if (configuration.Sources) {
        delete configuration.Sources;
      }

      return {
        ...state,
        configuration,
        isLoading: false,
      };
    }
    case Consts.GET_CONFIGURATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Consts.UPDATE_APPLICATION_MENU: {
      let configuration = { ...state.configuration };

      if (configuration.Screens?.[ScreenType.ApplicationMenu]?.Components) {
        configuration.Screens[ScreenType.ApplicationMenu].Components =
          configuration.Screens?.[ScreenType.ApplicationMenu].Components?.map(
            (item) => {
              if (action.isKidSubprofile)
                return {
                  ...item,
                  IsVisible:
                    item.Id === AppConfig.ChannelKids ||
                    item.Action?.ScreenTypeCode === ScreenType.Search,
                };
              else return { ...item, IsVisible: true };
            }
          );
        if (configuration?.Screens?.CUSTOM) {
          Object.keys(configuration?.Screens?.CUSTOM).forEach((channel) => {
            if (configuration?.Screens?.CUSTOM[channel])
              configuration.Screens.CUSTOM[channel].Components = undefined;
          });
        }
        if (configuration?.Screens?.CUSTOM[AppConfig.ChannelHome]) {
          configuration.Screens.CUSTOM[AppConfig.ChannelHome].Components =
            undefined;
        }
      }

      StorageHelper.setConfiguration(configuration);

      return {
        ...state,
        configuration,
      };
    }
    default:
      return state;
  }
};
