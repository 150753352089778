/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { TitanOSDeviceInfo } from "../../@types";
import { KeyboardKeyCode, PlatformType } from "../enums";
import { ManufacturerName } from "../enums/Common/ManufacturerName";

declare global {
  interface Window {
    PalmSystem: any;

    tizen: any;

    webOS: any;
    webOSSystem: any;

    // TitanOS
    DeviceInfo: TitanOSDeviceInfo;
  }
}

export class PlatformHelper {
  static isTV = () => {
    return (
      PlatformHelper.isTizen() ||
      PlatformHelper.isTitanOs() ||
      PlatformHelper.isWebos()
    );
  };

  static isTizen = () => {
    return !!(window && window.tizen);
  };

  static isTitanOs = () => {
    return !!(window && window.DeviceInfo);
  };

  static isWebos = () => {
    return (
      window.hasOwnProperty("webOSSystem") ||
      window.hasOwnProperty("webos") ||
      window.hasOwnProperty("webOS")
    );
  };

  static closeApp = () => {
    if (PlatformHelper.isTizen()) {
      window.tizen.application.getCurrentApplication().exit();
    } else {
      window.close();
    }
  };

  static getProducer(): string | undefined {
    if (this.isTizen()) {
      return ManufacturerName.SAMSUMG;
    } else if (this.isTitanOs()) {
      return window.DeviceInfo.Channel.brand || ManufacturerName.PHILIPS;
    } else if (this.isWebos()) {
      return ManufacturerName.LG;
    } else {
      return undefined;
    }
  }

  static registerKeyboardKeys(): void {
    const platform = this.Platform;

    if (platform === PlatformType.Tizen) {
      const keysToRegister = [
        "MediaRewind",
        "MediaFastForward",
        "MediaPlay",
        "MediaPause",
        "MediaStop",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ];

      const inputDevice =
        window.tizen.tvinputdevice || window.tizen.inputdevice;

      if (inputDevice) {
        for (const key of keysToRegister) {
          inputDevice.registerKey(key);
        }
      }
    }
  }

  static getKeyboardKeyCode(key: number): KeyboardKeyCode {
    const platform = this.Platform;

    switch (platform) {
      case PlatformType.Tizen:
        switch (key) {
          case 13:
            return KeyboardKeyCode.Enter;
          case 19:
            return KeyboardKeyCode.Pause;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          case 412:
            return KeyboardKeyCode.Rewind;
          case 413:
            return KeyboardKeyCode.Stop;
          case 415:
            return KeyboardKeyCode.Play;
          case 417:
            return KeyboardKeyCode.FastForward;
          case 10009:
            return KeyboardKeyCode.Back;
          case 10252:
            return KeyboardKeyCode.PlayPause;
          case 48:
            return KeyboardKeyCode.Zero;
          case 49:
            return KeyboardKeyCode.One;
          case 50:
            return KeyboardKeyCode.Two;
          case 51:
            return KeyboardKeyCode.Three;
          case 52:
            return KeyboardKeyCode.Four;
          case 53:
            return KeyboardKeyCode.Five;
          case 54:
            return KeyboardKeyCode.Six;
          case 55:
            return KeyboardKeyCode.Seven;
          case 56:
            return KeyboardKeyCode.Eight;
          case 57:
            return KeyboardKeyCode.Nine;
          default:
            return KeyboardKeyCode.Undefined;
        }
      case PlatformType.TitanOS:
        switch (key) {
          case 13:
            return KeyboardKeyCode.Enter;
          case 19:
            return KeyboardKeyCode.Pause;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          case 412:
            return KeyboardKeyCode.Rewind;
          case 413:
            return KeyboardKeyCode.Stop;
          case 415:
            return KeyboardKeyCode.Play;
          case 417:
            return KeyboardKeyCode.FastForward;
          case 8:
            return KeyboardKeyCode.Back;
          case 179:
            return KeyboardKeyCode.PlayPause;
          default:
            return KeyboardKeyCode.Undefined;
        }
      case PlatformType.WebOS:
        switch (key) {
          case 13:
            return KeyboardKeyCode.Enter;
          case 19:
            return KeyboardKeyCode.Pause;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          case 412:
            return KeyboardKeyCode.Rewind;
          case 413:
            return KeyboardKeyCode.Stop;
          case 415:
            return KeyboardKeyCode.Play;
          case 417:
            return KeyboardKeyCode.FastForward;
          case 461:
            return KeyboardKeyCode.Back;
          case 10252:
            return KeyboardKeyCode.PlayPause;
          default:
            return KeyboardKeyCode.Undefined;
        }
      default:
        switch (key) {
          case 8:
            return KeyboardKeyCode.Back;
          case 13:
            return KeyboardKeyCode.Enter;
          case 27:
            return KeyboardKeyCode.ESC;
          case 37:
            return KeyboardKeyCode.Left;
          case 38:
            return KeyboardKeyCode.Up;
          case 39:
            return KeyboardKeyCode.Right;
          case 40:
            return KeyboardKeyCode.Down;
          default:
            return KeyboardKeyCode.Undefined;
        }
    }
  }

  static get Platform(): PlatformType {
    if (PlatformHelper.isTizen()) {
      return PlatformType.Tizen;
    } else if (PlatformHelper.isTitanOs()) {
      return PlatformType.TitanOS;
    } else if (PlatformHelper.isWebos()) {
      return PlatformType.WebOS;
    }

    return PlatformType.Web;
  }

  static getPlatformByCode = (code: string): PlatformType | undefined => {
    switch (code) {
      case "ANY":
        return PlatformType.Any;
      case "ANDROID_PHONE":
        return PlatformType.AndroidPhone;
      case "ANDROID_TABLET":
        return PlatformType.AndroidTablet;
      case "ANDROID_TV":
        return PlatformType.AndroidTV;
      case "APPLE_TV":
        return PlatformType.AppleTV;
      case "IOS_PHONE":
        return PlatformType.iOSPhone;
      case "IPAD":
        return PlatformType.iPad;
      case "TIZEN":
        return PlatformType.Tizen;
      case "TITAN_OS":
        return PlatformType.TitanOS;
      case "WEB":
        return PlatformType.Web;
      case "WEB_OS":
        return PlatformType.WebOS;
      case "ROKU":
        return PlatformType.Roku;
      default:
        return undefined;
    }
  };

  static getPlatformVersion = (): string => {
    let version = "1.0";

    switch (PlatformHelper.Platform) {
      case PlatformType.Tizen:
        if (typeof window.tizen != "undefined") {
          try {
            const platform = window.tizen.systeminfo.getCapability(
              "http://tizen.org/feature/platform.version"
            );
            version = `${platform}`;
          } catch (error) {
            console.error(
              "Get Platform Version Error: ",
              JSON.stringify(error)
            );
          }
        }
        break;
      case PlatformType.TitanOS:
        version = window.DeviceInfo.Product.firmwareVersion;
        break;
      case PlatformType.WebOS:
        window.webOS.deviceInfo((deviceInfo: { sdkVersion: string }) => {
          version = deviceInfo?.sdkVersion || "unknown";
        });
    }

    return version;
  };

  static getPlatformVersionNumber = (): number => {
    const version = PlatformHelper.getPlatformVersion();

    return parseFloat(version);
  };

  static getDeviceModel = (): string => {
    return `${this.getProducer()}-tv-${PlatformHelper.getPlatformVersion()}`;
  };
}
