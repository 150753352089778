import { useAuthSelector, useIsLoggedIn } from "@nf/common";

export const useIsAuthenticated = () => {
  const { isChild, isProcessing } = useAuthSelector();
  const isLoggedIn = useIsLoggedIn();
  return {
    isLoggedIn,
    isChild,
    isProcessing,
  };
};
