/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export enum LoginCodeStatusTypes {
  verified = "verified",
  expired = "expired",
  unverified = "unverified",
}
