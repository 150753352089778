/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const subprofilesColors = [
  "#0031FF",
  "#ae70ca",
  "#00EBFF",
  "#660224",
  "#281757",
];
