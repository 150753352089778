/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import dayjs, { UnitType } from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export type DateInputType = Date | number | string;

export class TimeHelper {
  static getCurrentDateTime(): Date {
    return dayjs().toDate();
  }

  static getDateKey(value: DateInputType, format = "YYYY-MM-DD") {
    return dayjs(value).format(format);
  }

  static getDateTime(value: DateInputType): Date {
    const date = dayjs(value);

    return date.toDate();
  }

  static format(value: DateInputType, stringWithTokens: string): string {
    return dayjs(value).format(stringWithTokens);
  }

  static diff(
    value1: DateInputType,
    value2: DateInputType,
    unit: dayjs.QUnitType = "day"
  ): number {
    const date1 = dayjs(value1);
    const date2 = dayjs(value2);

    return date1.diff(date2, unit);
  }

  public static isAfter(date: string, unit?: UnitType) {
    return dayjs().isAfter(date, unit);
  }

  static isBefore(
    date: DateInputType,
    compareTo: DateInputType,
    unit: dayjs.OpUnitType = "millisecond"
  ): boolean {
    const date1 = dayjs(date);
    const date2 = dayjs(compareTo);

    return date1.isBefore(date2, unit);
  }

  static isBeforeCurrent(value: DateInputType | null | undefined): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    const date1 = dayjs(value);
    const date2 = dayjs();

    return date1.isBefore(date2);
  }

  static isAfterCurrent(value: DateInputType | null | undefined): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    const date1 = dayjs(value);
    const date2 = dayjs();

    return date1.isAfter(date2);
  }

  static toISOString(value: DateInputType) {
    return dayjs(value).toJSON();
  }

  static isBetween(
    compareDate: DateInputType,
    startDate?: DateInputType,
    endDate?: DateInputType
  ): boolean {
    const compare = dayjs(compareDate);
    const start = startDate ? dayjs(startDate) : undefined;
    const end = endDate ? dayjs(endDate) : undefined;

    if (start && end) {
      return start.isBefore(compare) && end.isAfter(compare);
    } else {
      return (start?.isBefore(compare) || end?.isAfter(compare)) ?? false;
    }
  }

  static isCurrentBetween(
    value1: DateInputType | undefined,
    value2: DateInputType | undefined
  ): boolean {
    const currentDateTime = dayjs();

    if (!value1) {
      return false;
    }

    const date1 = dayjs(value1);

    if (!value2) {
      return date1.isBefore(currentDateTime);
    }

    const date2 = dayjs(value2);

    return date1.isBefore(currentDateTime) && date2.isAfter(currentDateTime);
  }

  static pad(value: number, size: number) {
    return ("000" + value).slice(size * -1);
  }

  static formatDuration(miliseconds: number | undefined) {
    let durationText = "";

    if (miliseconds) {
      const hours = miliseconds / (60 * 60 * 1000);
      const rhours = Math.floor(miliseconds / (60 * 60 * 1000));
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);

      if (rhours) {
        durationText += `${rhours}h `;
      }

      if (rminutes) {
        durationText += `${rminutes} min.`;
      }
    }

    return durationText;
  }

  static formatDurationMilliseconds(
    duration: number | undefined,
    format: "HH:mm" | "HH:mm:ss" | "(HH):mm:ss" | "HH:mm:ss,ms" = "HH:mm"
  ): string | undefined {
    let result: string | undefined = undefined;

    if (typeof duration !== "undefined") {
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const milliseconds = +parseFloat(`${duration}`).toFixed(3).slice(-3);

      switch (format) {
        case "HH:mm":
          result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}`;
          break;
        case "HH:mm:ss":
          result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
            seconds,
            2
          )}`;
          break;
        case "(HH):mm:ss":
          if (hours === 0) {
            result = `${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`;
          } else {
            result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
              seconds,
              2
            )}`;
          }
          break;
        case "HH:mm:ss,ms":
          result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
            seconds,
            2
          )},${this.pad(milliseconds, 3)}`;
          break;
      }
    }

    return result;
  }
}

export const milisecondsToSeconds = (ms?: number) => (ms ? ms / 1000 : 0);
