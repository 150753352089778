/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { getRandomString } from "./getRandomString";

export class StringHelper {
  /*
   * Function used to generate an uniq random string
   */
  static generateRandomString = () =>
    getRandomString().substring(2, 15) + getRandomString().substring(2, 15);

  static toString(value: any) {
    if (value === null || value === undefined) {
      return "";
    }

    return `${value}`;
  }

  static shortenString = (text: string, size: number) =>
    text.length > size ? text.slice(0, size - 1) + "…" : text;
}

export const compareStrings = (
  string1: string,
  string2: string,
  ignoreCase = true
) => {
  if (!string1 || !string2) return false;

  if (ignoreCase) {
    return string1.toUpperCase() === string2.toUpperCase();
  }

  return string1 === string2;
};
