/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AxiosSubscriber } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import { IUserParentalRatingResponseModel } from "../models";
import { Observable, Observer } from "rxjs";
import { AppConfig } from "@nf/constants";

export class UserParentalRatingService {
  get url(): string {
    return "/v2";
  }

  public getParentalRating = (
    referenceId: string
  ): Observable<IUserParentalRatingResponseModel> =>
    new Observable(
      (observer: Observer<IUserParentalRatingResponseModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            headers: {
              Authorization: "",
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/config/${referenceId}`,
        })
    );
}
