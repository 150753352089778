/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useSelector } from "react-redux";
import { IAppState } from "../store";

export const useConfigurationSelector = () => {
  return useSelector((state: IAppState) => state.configuration.configuration);
};

export const useConfigurationLoadingSelector = () => {
  return useSelector((state: IAppState) => state.configuration.isLoading);
};

export const useConfigurationErrorSelector = () => {
  return useSelector((state: IAppState) => state.configuration.error);
};