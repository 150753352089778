/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { goBack, push, replace } from "connected-react-router";
import { match as RouterMatch } from "react-router";
import type { UrlObject } from "url";
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LANGUAGE_CODE,
  ROUTES,
} from "@nf/constants";
import { ActionType, MediaStreamType, MediaType, ScreenType } from "../enums";
import { IConfigurationModel, IMediaModel } from "../models";
import { dispatch } from "../store";
import { UrlHelper } from "./url.helper";
import { PlatformHelper } from "./platformHelper";
import { slugify } from "./slugify";
import NextRouter from "next/router";

export interface UrlQuery {
  [key: string]: string | number | boolean | undefined;
}

export class RouteHelper {
  static getIdPathValue(media: IMediaModel) {
    switch (media.MediaTypeCode) {
      case MediaType.Series:
        return `${media.Id}`;
      case MediaType.Episode:
        return `${media.ParentMediaId}`;
      case MediaType.Package:
        return `${media.Id}`;
      case MediaType.Collection:
        return `${media.Id}`;
      default:
        return `${media.Id}`;
    }
  }

  static getDetailsPath(media: IMediaModel) {
    const formattedTitle = slugify(media.Title);
    let path: string;

    switch (media.MediaTypeCode) {
      case MediaType.Series:
        path = `${ROUTES.SERIES_DETAILS_SCREEN}/${formattedTitle}/${media.Id}`;
        break;
      case MediaType.Episode:
        path = `${ROUTES.SERIES_DETAILS_SCREEN}/${formattedTitle}/${media.ParentMediaId}`;
        break;
      case MediaType.Package:
        path = `${ROUTES.PACKAGE_DETAILS_SCREEN}/${media.Id}`;
        break;
      case MediaType.Collection:
        path = `${ROUTES.COLLECTION_DETAILS_SCREEN}/${formattedTitle}/${media.Id}`;
        break;
      default:
        path = `${ROUTES.MOVIE_DETAILS_SCREEN}/${formattedTitle}/${media.Id}`;
        break;
    }

    return path;
  }

  static getCanonicalUrl(
    pathname: string,
    configuration?: IConfigurationModel
  ) {
    const path = pathname
      .replace(
        "[country]",
        configuration?.DefaultCountry || DEFAULT_COUNTRY_CODE
      )
      .replace(
        "[language]",
        configuration?.DefaultLocale || DEFAULT_LANGUAGE_CODE
      )
      .toLowerCase();

    return `${process.env.NEXT_PUBLIC_MAIN_CANONICAL}${path}`;
  }

  static getMainCanonicalUrl() {
    return `${process.env.NEXT_PUBLIC_MAIN_CANONICAL}`;
  }

  static getMediaCanonicalUrl(
    pathname: string,
    media: IMediaModel,
    configuration?: IConfigurationModel
  ) {
    let url = this.getCanonicalUrl(pathname, configuration);

    const id = RouteHelper.getIdPathValue(media);
    const title = slugify(media.Title);

    if (id) {
      url = url.replace("[id]", id);
    }

    if (title) {
      url = url.replace("[title]", title);
    }

    return url;
  }

  /**
   * Replace dynamic segments in the pathname with corresponding query values.
   * @param {string} path - The pathname with dynamic segments.
   * @param {object} query - The query object containing dynamic segment values.
   * @returns {string} - The updated pathname with replaced values.
   */
  static replacePathSegments = (
    path: string,
    query: Record<string, string | string[] | undefined>
  ): string => {
    return path.replace(/\[([^\]]+)\]/g, (_, segment) => {
      const value = query[segment];
      return Array.isArray(value) ? value.join(",") : value || `[${segment}]`;
    });
  };

  static goToDetails(media?: IMediaModel, query: UrlQuery = {}) {
    if (!media) {
      return {};
    }

    let path = "";
    let queryParams: UrlQuery = {
      ...query,
    };

    if (media?.Action?.ActionType === ActionType.OpenScreen) {
      if (
        media.Action.ScreenTypeCode &&
        media.Action.ScreenTypeCode !== ScreenType.Custom
      ) {
        path = `/${media.Action.ScreenTypeCode.toLowerCase().replace(
          "_",
          "-"
        )}`;

        if (media.Action.ScreenId) {
          path += `/${media.Action.ScreenId}`;
        }
      } else {
        path = `/${media.Action.ScreenId}`;
      }

      if (media.Action.QueryParams) {
        queryParams = {
          ...queryParams,
          ...media.Action.QueryParams,
        };
      }
    } else {
      path = RouteHelper.getDetailsPath(media);
    }

    const location: UrlObject = {
      pathname: path,
      query: UrlHelper.stringify(queryParams),
    };
    return location;
  }

  static goToPlayer(media: IMediaModel, query: UrlObject = {}) {
    let pathname;
    let queryParams: UrlObject = {
      ...query,
    };

    if (media.Action?.QueryParams) {
      queryParams = {
        ...queryParams,
        ...media.Action.QueryParams,
      };
    }

    switch (media.MediaTypeCode) {
      case MediaType.Channel:
        pathname = ROUTES.PLAYER_CHANNEL_SCREEN;
        break;
      default:
        pathname = ROUTES.PLAYER_SCREEN;
        break;
    }

    const location: UrlObject = {
      pathname: `${pathname}/${media.Id}`,
      query: UrlHelper.stringify(queryParams),
    };

    return location;
  }

  static goToTrial(mediaId: string | number) {
    const location: UrlObject = {
      pathname: `${ROUTES.PLAYER_SCREEN}/${mediaId}`,
      query: MediaStreamType.Trial,
    };

    return location;
  }

  static goToReadMore(
    media: IMediaModel | undefined,
    replaceRoute?: boolean
  ): void {
    if (!media) {
      return;
    }
    const path = `${ROUTES.READ_MORE_SCREEN}/${media?.Id}`;
    dispatch(replaceRoute ? replace(path) : push(path));
  }

  static goToLogin(replaceRoute?: boolean) {
    const path = `${ROUTES.LOGIN}`;

    if (PlatformHelper.isTV()) {
      return dispatch(replaceRoute ? replace(path) : push(path));
    }

    const pathname = `/${NextRouter.query.country}/${NextRouter.query.language}${ROUTES.LOGIN}`;

    return replaceRoute
      ? NextRouter.replace({ pathname }, undefined, { shallow: true })
      : NextRouter.push({ pathname }, undefined, { shallow: true });
  }

  static goToBase(replaceRoute?: boolean) {
    const pathname = `${ROUTES.BASE}`;

    if (PlatformHelper.isTV()) {
      return dispatch(replaceRoute ? replace(pathname) : push(pathname));
    }

    return replaceRoute
      ? NextRouter.replace({ pathname }, undefined, { shallow: true })
      : NextRouter.push({ pathname }, undefined, { shallow: true });
  }

  static goToLoginViaCode(replaceRoute?: boolean) {
    const path = `${ROUTES.LOGIN_VIA_CODE}`;
    return replaceRoute ? replace(path) : push(path);
  }

  static goToPerson(name: string, id: string) {
    const path = `${ROUTES.PERSON_DETAILS_SCREEN}/${slugify(name)}/${id}`;
    return path;
  }

  static goToSettings(submenuKey?: string) {
    if (!submenuKey) {
      return `${ROUTES.SETTINGS_SCREEN}`;
    }

    return `${ROUTES.SETTINGS_SCREEN}/${submenuKey}`;
  }

  static goToHome(submenuKey?: string) {
    const path = `${ROUTES.HOME}`;
    return path;
  }

  static goToKids(submenuKey?: string) {
    const path = `${ROUTES.KIDS}`;
    return path;
  }

  static goToSearch(submenuKey?: string) {
    const path = `${ROUTES.SEARCH_SCREEN}/${submenuKey}`;
    return path;
  }

  static getScreenRouteKey(match: RouterMatch): string | undefined {
    if (match.url.length > 0) {
      const pathSegments = match.url.slice(1).split("/");

      if (pathSegments.length > 0) {
        return pathSegments[0];
      }
    }

    return undefined;
  }

  static goBack() {
    dispatch(goBack());
  }
}
