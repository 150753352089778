/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppConfig } from "@nf/constants";
import { AxiosSubscriber } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import { IConfigModel, IComponentConfigModel } from "../models";
import { lastValueFrom, Observable, Observer, tap } from "rxjs";
import { BehaviorSubject } from "rxjs";

export class ClientConfigService {
  private componentSubject = new BehaviorSubject<
    Record<string, IComponentConfigModel>
  >({});
  get url(): string {
    return "/v2/whitelabel";
  }

  private findComponent = (referenceId: string): IComponentConfigModel => {
    const components = this.componentSubject.getValue();
    return components[referenceId];
  };

  private isComponentExistInCache = (referenceId: string): boolean => {
    const components = this.componentSubject.getValue();
    return !!components[referenceId];
  };

  private setComponent = (referenceId: string, componentData: any) => {
    const components = this.componentSubject.getValue();
    components[referenceId] = componentData;
    this.componentSubject.next(components);
  };

  public getConfiguration = (): Observable<IConfigModel> =>
    new Observable(
      (observer: Observer<IConfigModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            timeout: 60000,
            headers: {
              Authorization: "",
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/config/${AppConfig.ConfigId}`,
        })
    );

  public getComponent = (
    referenceId: string
  ): Observable<IComponentConfigModel> => {
    if (this.isComponentExistInCache(referenceId)) {
      return new Observable((observer: Observer<IComponentConfigModel>) => {
        observer.next(this.findComponent(referenceId));
        observer.complete();
      });
    }

    return new Observable(
      (observer: Observer<IComponentConfigModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            timeout: 10000,
            headers: {
              Authorization: "",
            },
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/config/${AppConfig.ConfigId}/component/${referenceId}`,
        })
    ).pipe(
      tap((component) => {
        if (component.appType === "page" || component.appType === "carousel") {
          this.setComponent(referenceId, component);
        }
      })
    );
  };

  public getComponentByUrl = (
    referenceUrl: string
  ): Observable<IComponentConfigModel> =>
    new Observable(
      (observer: Observer<IComponentConfigModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            headers: {
              Authorization: "",
            },
          },
          method: HTTP_METHOD.GET,
          url: `${referenceUrl}`,
        })
    );

  public getComponentByUrlAsPromise = (
    referenceUrl: string
  ): Promise<IComponentConfigModel> =>
    lastValueFrom(this.getComponentByUrl(referenceUrl));
}
