/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useSelector } from "../store";

export const useAvailabilityKeysSelector = () => {
  const {
    Data,
    Error: error,
    IsProcessing: isLoading,
  } = useSelector((state) => state.user.availabilityKeys);

  return {
    availabilityKeys: Data?.AvailabilityKeys,
    error,
    isLoading,
  };
};
