import dayjs from "dayjs";

export const registerSupportedTranslations = (
  countries: any,
  languages: any
) => {
  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
  countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));
  countries.registerLocale(require("i18n-iso-countries/langs/da.json"));
  countries.registerLocale(require("i18n-iso-countries/langs/no.json"));
  languages.registerLocale(
    require("@cospired/i18n-iso-languages/langs/en.json")
  );
  languages.registerLocale(
    require("@cospired/i18n-iso-languages/langs/sv.json")
  );
  languages.registerLocale(
    require("@cospired/i18n-iso-languages/langs/da.json")
  );
  languages.registerLocale(
    require("@cospired/i18n-iso-languages/langs/no.json")
  );
};

const dayjsLocales: { [key: string]: any } = {
  en: () => import("dayjs/locale/en"),
  da: () => import("dayjs/locale/da"),
  fi: () => import("dayjs/locale/fi"),
  nb: () => import("dayjs/locale/nb"),
  sv: () => import("dayjs/locale/sv"),
};

export const loadDayjsLocale = (language: string) =>
  dayjsLocales[language]().then(() => dayjs.locale(language));
