import { PersonInAssetType } from "../enums";
import { IMediaPersonModel } from "../models";

export const filterPeopleByRole = (
  people: IMediaPersonModel[] | undefined,
  role: PersonInAssetType
) => {
  return people?.filter(
    (person) => person.PersonRoleCode && person.PersonRoleCode === role
  );
};
