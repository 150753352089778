import { useEffect } from "react";

export const useHideCookieConsentButton = () => {
  useEffect(() => {
    const cookieElement = document.getElementById(
      "Coi-Renew"
    ) as HTMLInputElement | null;
    if (cookieElement != null) {
      cookieElement.style.zIndex = "0";

      return () => {
        cookieElement.style.zIndex = "99999999999";
      };
    }
  }, []);
};
