/*

 * @author BSG <dev@bsgroup.eu>

 * @copyright Better Software Group S.A.

 * @version: 1.0

 */

import { useSelector } from "react-redux";

import { IAppState } from "../store";

export const useUserSubprofilesSelector = () =>

  useSelector((state: IAppState) => state.user.subprofiles);