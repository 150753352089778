import { useCallback } from "react";
import { useRoutePathBuild } from "./useRoutePathBuild";
import { userProfileHelper } from "@nf/common";

export const useGeneratePath = (withHost = false, withProtocol = false) => {
  const routePrefix = useRoutePathBuild();
  return useCallback(
    (routeUrl: string) =>
      `${withProtocol ? window.location.protocol + "//" : ""}${
        withHost ? window.location.host : ""
      }${routePrefix}${userProfileHelper.cleanUp(routeUrl)}`.trim(),
    [routePrefix]
  );
};
