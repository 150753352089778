/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { Observable, from } from "rxjs";
import { HTTP_METHOD } from "@nf/constants";
import { ajax } from "rxjs/internal/ajax/ajax";
import { AjaxResponse } from "rxjs/internal/ajax/AjaxResponse";
import { EmptyObject } from "../../../../models";

export class PauseSubscriptionService {
  get url(): string {
    return "https://prod-243.westeurope.logic.azure.com/workflows/2b18f9585d5b49baa86a0f48dac61d09/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=xKkp_05O_V_NXtAQQsND-FAX4WOVx157twWQW9Sh6-w";
  }

  public pauseSubscription = (
    id: string,
    pausePeriod: string
  ): Observable<AjaxResponse<EmptyObject>> => {
    const apiCall = ajax<EmptyObject>({
      url: this.url,
      method: HTTP_METHOD.POST,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PausePeriod: pausePeriod,
        CustomerID: id,
      }),
    });
    return from(apiCall);
  };
}
