/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

enum CancellationOtherSubreason {
  CONTENT_OTHER = "content_other",
  ACCESS_ALREADY_OTHER = "access_already_other",
  TECHNICAL_OTHER = "technical_other",
  USAGE_OTHER = "usage_other",
  PRICE_OTHER = "price_other",
  OTHER_OTHER = "other_other",
}

export const CANCELLATION_OTHER_SUBREASON_ARRAY = Object.values(
  CancellationOtherSubreason
);

export const CANCELLATION_REASONS = [
  {
    Id: "content",
    translationKey: "web_subscription_cancel_reason_content",
    subReasons: [
      {
        Id: "content_unavailable",
        translationKey: "web_subscription_cancel_reason_content_unavailable",
      },
      {
        Id: "content_old_movies",
        translationKey: "web_subscription_cancel_reason_content_old_movies",
      },
      {
        Id: "content_new_movies",
        translationKey: "web_subscription_cancel_reason_content_new_movies",
      },
      {
        Id: "content_american_movies",
        translationKey:
          "web_subscription_cancel_reason_content_american_movies",
      },
      {
        Id: "content_series",
        translationKey: "web_subscription_cancel_reason_content_series",
      },
      {
        Id: "content_kids",
        translationKey: "web_subscription_cancel_reason_content_kids",
      },
      {
        Id: "content_subtitles",
        translationKey: "web_subscription_cancel_reason_content_subtitles",
      },
      {
        Id: CancellationOtherSubreason.CONTENT_OTHER,
        translationKey: "web_subscription_cancel_reason_content_other",
      },
    ],
  },
  {
    Id: "access_already",
    translationKey: "web_subscription_cancel_reason_access_already",
    subReasons: [
      {
        Id: "access_already_partner",
        translationKey: "web_subscription_cancel_reason_access_already_partner",
      },
      {
        Id: "access_already_share_household",
        translationKey:
          "web_subscription_cancel_reason_access_already_share_household",
      },
      {
        Id: "access_already_share_outside_household",
        translationKey:
          "web_subscription_cancel_reason_access_already_share_outside_household",
      },
      {
        Id: CancellationOtherSubreason.ACCESS_ALREADY_OTHER,
        translationKey: "web_subscription_cancel_reason_access_already_other",
      },
    ],
  },
  {
    Id: "technical",
    translationKey: "web_subscription_cancel_reason_technical",
    subReasons: [
      {
        Id: "technical_device",
        translationKey: "web_subscription_cancel_reason_technical_device",
      },
      {
        Id: "technical_interruptions",
        translationKey:
          "web_subscription_cancel_reason_technical_interruptions",
      },
      {
        Id: "technical_sound",
        translationKey: "web_subscription_cancel_reason_technical_sound",
      },
      {
        Id: "technical_usability",
        translationKey: "web_subscription_cancel_reason_technical_usability",
      },
      {
        Id: CancellationOtherSubreason.TECHNICAL_OTHER,
        translationKey: "web_subscription_cancel_reason_technical_other",
      },
    ],
  },
  {
    Id: "usage",
    translationKey: "web_subscription_cancel_reason_usage",
    subReasons: [
      {
        Id: "usage_seen",
        translationKey: "web_subscription_cancel_reason_usage_seen",
      },
      {
        Id: "usage_other_services",
        translationKey: "web_subscription_cancel_reason_usage_other_services",
      },
      {
        Id: "usage_no_services",
        translationKey: "web_subscription_cancel_reason_usage_no_services",
      },
      {
        Id: CancellationOtherSubreason.USAGE_OTHER,
        translationKey: "web_subscription_cancel_reason_usage_other",
      },
    ],
  },
  {
    Id: "price",
    translationKey: "web_subscription_cancel_reason_price",
    subReasons: [
      {
        Id: "price_value",
        translationKey: "web_subscription_cancel_reason_price_value",
      },
      {
        Id: "price_afford",
        translationKey: "web_subscription_cancel_reason_price_afford",
      },
      {
        Id: "price_other_services",
        translationKey: "web_subscription_cancel_reason_price_other_services",
      },
      {
        Id: CancellationOtherSubreason.PRICE_OTHER,
        translationKey: "web_subscription_cancel_reason_price_other",
      },
    ],
  },
  {
    Id: "other",
    translationKey: "web_subscription_cancel_reason_other",
    subReasons: [
      {
        Id: "other_pause",
        translationKey: "web_subscription_cancel_reason_other_pause",
      },
      {
        Id: CancellationOtherSubreason.OTHER_OTHER,
        translationKey: "web_subscription_cancel_reason_other_text",
      },
    ],
  },
];

export const PRIMARY_REASONS = CANCELLATION_REASONS.map((reason) => reason.Id);
