/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { PlatformType, ScreenType } from "../../enums";
import { IConfigurationModel, IErrorModel, IScreenModel } from "../../models";
import * as Consts from "./consts";
import {
  IGetConfigurationAction,
  IGetConfigurationFailureAction,
  IGetConfigurationSuccessAction,
  IUpdateApplicationMenuAction,
} from "./types";

export const getConfiguration = (
  platformCode?: PlatformType
): IGetConfigurationAction => {
  return {
    platformCode,
    type: Consts.GET_CONFIGURATION,
  };
};

export const getConfigurationSuccess = (
  configuration: IConfigurationModel
): IGetConfigurationSuccessAction => {
  return {
    payload: configuration,
    type: Consts.GET_CONFIGURATION_SUCCESS,
  };
};

export const getConfigurationFailure = (
  error?: IErrorModel
): IGetConfigurationFailureAction => {
  return {
    error,
    type: Consts.GET_CONFIGURATION_FAILURE,
  };
};

export const updateApplicationMenu = (
  isKidSubprofile: boolean
): IUpdateApplicationMenuAction => {
  return {
    type: Consts.UPDATE_APPLICATION_MENU,
    isKidSubprofile,
  };
};

export const Actions = {
  getConfiguration,
  getConfigurationSuccess,
  updateApplicationMenu,
};
