export const applyPreloadStylesheetLinks = () => {
  setTimeout(() => {
    const preloadStylesheetLinks = document.querySelectorAll<HTMLLinkElement>(
      "link[rel='preload'][as='style']"
    );

    preloadStylesheetLinks.forEach((preloadLink) => {
      const existingStylesheetLink = document.querySelector<HTMLLinkElement>(
        `link[rel='stylesheet'][href='${preloadLink.href}']`
      );

      if (!existingStylesheetLink) {
        const stylesheetLink = document.createElement("link");
        stylesheetLink.rel = "stylesheet";
        stylesheetLink.href = preloadLink.href;
        document.head.appendChild(stylesheetLink);
      }
    });
  }, 500);
};
