export const SubmenuKeys = {
  PERSONAL_DETAILS: "personal-details",
  CHANGE_PASSWORD: "change-password",
  SUBSCRIPTION: "subscription",
  PROFILES: "profiles",
  PAYMENT_DETAILS: "payment-details",
  WATCH_HISTORY: "watch-history",
  DEVICES: "devices",
  USER_DATA: "user-data",
  PAYMENT_HISTORY: "payment-history",
};
