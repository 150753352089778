import { SEARCH_QUERY_MIN_LENGTH } from "@nf/constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDeepMemo } from "../";
import { IPeopleSearchFilterModel } from "../../models";
import { usePeopleSearchSelector } from "../../selectors";
import { PeopleStore } from "../../store";

export const useSearchPeopleDispatch = () => {
  const dispatch = useDispatch();
  return (filter: IPeopleSearchFilterModel) =>
    dispatch(PeopleStore.Actions.searchPeople(filter));
};

export const useSearchPeople = (filters: IPeopleSearchFilterModel) => {
  const memoizedFilters = useDeepMemo(filters);
  const data = usePeopleSearchSelector();
  const searchPeople = useSearchPeopleDispatch();

  useEffect(() => {
    const query = memoizedFilters.FullTextSearch;
    if (query && query?.length >= SEARCH_QUERY_MIN_LENGTH) {
      searchPeople(memoizedFilters);
    }
  }, [memoizedFilters]);

  return { data: data.Entities, loading: data.IsLoading };
};
