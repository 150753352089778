import { useContext, useEffect } from "react";
import { setBrandingFromUrl, ThemeContext } from "@nf/common";

export const useBrandingFromUrlParameter = (channel: string) => {
  const channelContext = useContext(ThemeContext);

  useEffect(() => {
    if (channel) {
      setBrandingFromUrl(channel, channelContext);
    }
  }, [channel, channelContext]);
};
