/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum PersonInAssetType {
  Actor = "ACTOR",
  Director = "DIRECTOR",
  Writer = "WRITER",
  Cast = "CAST",
  Commentator = "COMMENTATOR",
}
