/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum GridComponentType {
  GenresScreen = "GENRES_SCREEN",
  MovieDetails = "MOVIE_DETAILS",
  PlaylistScreen = "PLAYLIST_SCREEN",
}
