import slug from "slugify";

export const slugify = (string?: string) => {
  if (!string) {
    return "";
  }

  return slug(string, {
    lower: true,
    remove: /[^\w\s]+/g,
  });
};
