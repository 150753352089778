import { Observable, Subject, Subscription, tap } from "rxjs";
import { filter, map } from "rxjs/operators";
import * as Consts from "../../store/user/consts";
import {
  SIGN_IN_SUCCESS_EVENT,
  SIGN_OUT_SUCCESS_EVENT,
} from "../../store/auth/consts";
import { Types } from "../../store/auth";
import {
  SWITCH_SUBSCRIPTION_FAILURE_EVENT,
  SWITCH_SUBSCRIPTION_SUCCESS_EVENT,
} from "../../store/payments/consts";

type TEvent = {
  eventName: string;
  eventData: any;
};

class EventService {
  private _subject = new Subject<TEvent>();

  publish<T>(eventName: string, eventData: T) {
    this._subject.next({
      eventName,
      eventData,
    });
  }

  private getEventEmitter<T>(name: string): Observable<T> {
    return this._subject.pipe(
      filter(({ eventName }: TEvent) => eventName === name),
      map(({ eventData }) => eventData)
    );
  }

  unsubscribe(subscription: Subscription) {
    subscription.unsubscribe();
  }

  onUserSubProfileChangeEvent(
    handler: (initialHomePage: string) => void
  ): Subscription {
    return this.getEventEmitter<string>(
      Consts.SWITCH_USER_INITIAL_HOME_PAGE_EVENT
    ).subscribe(handler);
  }

  onSignInSuccessEvent(handler: () => void): Subscription {
    return this.getEventEmitter<void>(SIGN_IN_SUCCESS_EVENT).subscribe(handler);
  }

  onSignOutSuccessEvent(
    handler: (action: Types.ISignOutAction) => void
  ): Subscription {
    return this.getEventEmitter<any>(SIGN_OUT_SUCCESS_EVENT).subscribe(handler);
  }

  onSwitchSubscriptionSuccessEvent(handler: () => void): Subscription {
    return this.getEventEmitter<void>(
      SWITCH_SUBSCRIPTION_SUCCESS_EVENT
    ).subscribe(handler);
  }

  onSwitchSubscriptionFailureEvent(handler: () => void): Subscription {
    return this.getEventEmitter<void>(
      SWITCH_SUBSCRIPTION_FAILURE_EVENT
    ).subscribe(handler);
  }

  clear() {
    this._subject.next({ eventName: "", eventData: {} });
  }
}

export const eventService = new EventService();
