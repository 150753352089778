/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IErrorModel,
  IMediaModel,
  IUserFavoritesModel,
  IUserConsentModel,
  IUserDeviceModel,
  IUserModel,
  IUserSubscriptionModel,
  IUserAvailabilityKeysModel,
  IUserSubprofileModel,
  IUserParentalRatingModel,
  ITokenModel,
  IUserInfoModel,
} from "../../models";
import * as Consts from "./consts";
import * as AuthConsts from "../auth/consts";
import {
  ICancelSubscriptionAction,
  ICancelSubscriptionFailureAction,
  ICancelSubscriptionSuccessAction,
  IGetProfileAction,
  IGetProfileFailureAction,
  IGetProfileSuccessAction,
  IGetUserFavoritesAction,
  IGetUserFavoritesSuccessAction,
  IGetUserConsentsAction,
  IGetUserConsentsFailureAction,
  IGetUserConsentsSuccessAction,
  IGetUserDevicesAction,
  IGetUserDevicesSuccessAction,
  IGetUserDevicesFailureAction,
  IUpdateSubscriptionAction,
  IUpdateSubscriptionFailureAction,
  IUpdateSubscriptionSuccessAction,
  IUpdateProfileAction,
  IUpdateProfileFailureAction,
  IUpdateProfileSuccessAction,
  IUpdateUserConsentsAction,
  IUpdateUserConsentsFailureAction,
  IUpdateUserConsentsSuccessAction,
  IDeleteUserDeviceAction,
  IDeleteUserDeviceSuccessAction,
  IDeleteUserDeviceFailureAction,
  IGetUserFavoritesFailureAction,
  IGetWatchHistoryAction,
  IGetWatchHistorySuccessAction,
  IGetWatchHistoryFailureAction,
  IDeleteWatchHistoryAction,
  IDeleteWatchHistorySuccessAction,
  IDeleteWatchHistoryFailureAction,
  IAddToUserFavoritesAction,
  IRemoveFromUserFavoritesAction,
  IGetUserSubscriptionsAction,
  IGetUserSubscriptionsSuccessAction,
  IGetUserSubscriptionsFailureAction,
  IGetUserAvailabilityKeysAction,
  IGetUserAvailabilityKeysSuccessAction,
  IGetUserAvailabilityKeysFailureAction,
  IGetUserSubprofilesAction,
  IGetUserSubprofilesSuccessAction,
  IGetUserSubprofilesFailureAction,
  ICreateUserSubprofilesAction,
  ICreateUserSubprofilesSuccessAction,
  ICreateUserSubprofilesFailureAction,
  IDeleteUserSubprofilesAction,
  IDeleteUserSubprofilesSuccessAction,
  IDeleteUserSubprofilesFailureAction,
  IEditUserSubprofileAction,
  IEditUserSubprofileSuccessAction,
  IEditUserSubprofileFailureAction,
  ISelectUserSubprofileAction,
  ISelectUserSubprofileSuccessAction,
  ISelectUserSubprofileFailureAction,
  IGetUserParentalRatingsAction,
  IGetUserParentalRatingsSuccessAction,
  IGetUserParentalRatingsFailureAction,
  ISetUserSubprofilePinCodeAction,
  ISetUserSubprofilePinCodeSuccessAction,
  ISetUserSubprofilePinCodeFailureAction,
  IDeleteUserSubprofilePinCodeAction,
  IDeleteUserSubprofilePinCodeSuccessAction,
  IDeleteUserSubprofilePinCodeFailureAction,
  ISelectUserSubprofileCompletedAction,
  IUpdateSubscriptionPendingAction,
  IPauseSubscriptionAction,
  IPauseSubscriptionSuccessAction,
  IPauseSubscriptionFailureAction,
  IGetProductOfferingIdsAction,
  IGetProductOfferingIdsSuccessAction,
  IGetProductOfferingIdsFailureAction,
  ICheckOrderAndFetchSubscriptionsAction,
  ICheckOrderAndFetchSubscriptionsSuccessAction,
  ICheckOrderAndFetchSubscriptionsFailureAction,
} from "./types";
import { ISessionModel } from "../../providers/DataProvider/RedBeeMedia/models";
import { ISyncWebUserSuccessAction } from "../auth/types";
import { ICancellationSubscriptionReasons } from "../../providers/DataProvider/Skymill/models";

export const getProfile = (): IGetProfileAction => {
  return {
    type: Consts.GET_PROFILE,
  };
};

export const getProfileSuccess = (
  data: IUserModel
): IGetProfileSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_PROFILE_SUCCESS,
  };
};

export const getProfileFailure = (
  error?: IErrorModel
): IGetProfileFailureAction => {
  return {
    error,
    type: Consts.GET_PROFILE_FAILURE,
  };
};

export const updateProfile = (
  data: Partial<IUserModel>
): IUpdateProfileAction => {
  return {
    payload: data,
    type: Consts.UPDATE_PROFILE,
  };
};

export const updateProfileSuccess = (
  data: IUserModel
): IUpdateProfileSuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_PROFILE_SUCCESS,
  };
};

export const updateProfileFailure = (
  error?: IErrorModel
): IUpdateProfileFailureAction => {
  return {
    error,
    type: Consts.UPDATE_PROFILE_FAILURE,
  };
};

export const getUserFavorites = (): IGetUserFavoritesAction => {
  return {
    type: Consts.GET_USER_FAVORITES,
  };
};

export const getUserFavoritesSuccess = (
  userAssetsProperties: IUserFavoritesModel[]
): IGetUserFavoritesSuccessAction => {
  return {
    payload: userAssetsProperties,
    type: Consts.GET_USER_FAVORITES_SUCCESS,
  };
};

export const getUserFavoritesFailure = (
  error?: IErrorModel
): IGetUserFavoritesFailureAction => {
  return {
    error,
    type: Consts.GET_USER_FAVORITES_FAILURE,
  };
};

export const addToUserFavorites = (
  mediaId: string | number
): IAddToUserFavoritesAction => {
  return {
    type: Consts.ADD_TO_USER_FAVORITES,
    payload: mediaId,
  };
};

export const removeFromUserFavorites = (
  mediaId: string | number
): IRemoveFromUserFavoritesAction => {
  return {
    type: Consts.REMOVE_FROM_USER_FAVORITES,
    payload: mediaId,
  };
};

export const getUserConsents = (): IGetUserConsentsAction => {
  return {
    type: Consts.GET_USER_CONSENTS,
  };
};

export const getUserConsentsSuccess = (
  data: IUserConsentModel[]
): IGetUserConsentsSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_USER_CONSENTS_SUCCESS,
  };
};

export const getUserConsentsFailure = (
  error?: IErrorModel
): IGetUserConsentsFailureAction => {
  return {
    error,
    type: Consts.GET_USER_CONSENTS_FAILURE,
  };
};

export const updateUserConsents = (
  data: IUserConsentModel[]
): IUpdateUserConsentsAction => {
  return {
    payload: data,
    type: Consts.UPDATE_USER_CONSENTS,
  };
};

export const updateUserConsentsSuccess = (
  data: IUserConsentModel[]
): IUpdateUserConsentsSuccessAction => {
  return {
    payload: data,
    type: Consts.UPDATE_USER_CONSENTS_SUCCESS,
  };
};

export const updateUserConsentsFailure = (
  error?: IErrorModel
): IUpdateUserConsentsFailureAction => {
  return {
    error,
    type: Consts.UPDATE_USER_CONSENTS_FAILURE,
  };
};

export const getUserDevices = (): IGetUserDevicesAction => {
  return {
    type: Consts.GET_USER_DEVICES,
  };
};

export const getUserDevicesSuccess = (
  data: IUserDeviceModel[]
): IGetUserDevicesSuccessAction => {
  return {
    payload: data,
    type: Consts.GET_USER_DEVICES_SUCCESS,
  };
};

export const getUserDevicesFailure = (
  error?: IErrorModel
): IGetUserDevicesFailureAction => {
  return {
    error,
    type: Consts.GET_USER_DEVICES_FAILURE,
  };
};

export const deleteUserDevice = (id: string): IDeleteUserDeviceAction => {
  return {
    type: Consts.DELETE_USER_DEVICE,
    payload: id,
  };
};

export const deleteUserDeviceSuccess = (
  id: string
): IDeleteUserDeviceSuccessAction => {
  return {
    type: Consts.DELETE_USER_DEVICE_SUCCESS,
    payload: id,
  };
};

export const deleteUserDeviceFailure = (
  error?: IErrorModel
): IDeleteUserDeviceFailureAction => {
  return {
    error,
    type: Consts.DELETE_USER_DEVICE_FAILURE,
  };
};

export const getWatchHistory = (): IGetWatchHistoryAction => {
  return {
    type: Consts.GET_WATCH_HISTORY,
  };
};

export const getWatchHistorySuccess = (
  data: IMediaModel[]
): IGetWatchHistorySuccessAction => {
  return {
    payload: data,
    type: Consts.GET_WATCH_HISTORY_SUCCESS,
  };
};

export const getWatchHistoryFailure = (
  error?: IErrorModel
): IGetWatchHistoryFailureAction => {
  return {
    error,
    type: Consts.GET_WATCH_HISTORY_FAILURE,
  };
};

export const deleteWatchHistory = (
  id: string | number
): IDeleteWatchHistoryAction => {
  return {
    payload: id,
    type: Consts.DELETE_WATCH_HISTORY,
  };
};

export const deleteWatchHistorySuccess = (
  id: string | number
): IDeleteWatchHistorySuccessAction => {
  return {
    payload: id,
    type: Consts.DELETE_WATCH_HISTORY_SUCCESS,
  };
};

export const deleteWatchHistoryFailure = (
  error?: IErrorModel
): IDeleteWatchHistoryFailureAction => {
  return {
    error,
    type: Consts.DELETE_WATCH_HISTORY_FAILURE,
  };
};

export const getUserSubscriptions = (): IGetUserSubscriptionsAction => {
  return {
    type: Consts.GET_USER_SUBSCRIPTIONS,
  };
};

export const getUserSubscriptionsSuccess = (
  data: IUserSubscriptionModel[]
): IGetUserSubscriptionsSuccessAction => {
  return {
    type: Consts.GET_USER_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};

export const getUserSubscriptionsFailure = (
  error?: IErrorModel
): IGetUserSubscriptionsFailureAction => {
  return {
    type: Consts.GET_USER_SUBSCRIPTIONS_FAILURE,
    error,
  };
};

export const cancelSubscription = (
  id: number,
  reasons?: {
    cancellation_answers_v2: string[];
    cancellation_reasons_v2: ICancellationSubscriptionReasons;
  }
): ICancelSubscriptionAction => {
  return {
    type: Consts.CANCEL_SUBSCRIPTION,
    payload: {
      id,
      reasons,
    },
  };
};

export const cancelSubscriptionSuccess = (
  subscription: IUserSubscriptionModel
): ICancelSubscriptionSuccessAction => {
  return {
    type: Consts.CANCEL_SUBSCRIPTION_SUCCESS,
    payload: subscription,
  };
};

export const cancelSubscriptionFailure = (
  error?: IErrorModel
): ICancelSubscriptionFailureAction => {
  return {
    error,
    type: Consts.CANCEL_SUBSCRIPTION_FAILURE,
  };
};

export const pauseSubscription = (
  id: string,
  pausePeriod: string
): IPauseSubscriptionAction => {
  return {
    type: Consts.PAUSE_SUBSCRIPTION,
    payload: {
      id,
      pausePeriod,
    },
  };
};

export const pauseSubscriptionSuccess = (): IPauseSubscriptionSuccessAction => {
  return {
    type: Consts.PAUSE_SUBSCRIPTION_SUCCESS,
  };
};

export const pauseSubscriptionFailure = (
  error?: IErrorModel
): IPauseSubscriptionFailureAction => {
  return {
    error,
    type: Consts.PAUSE_SUBSCRIPTION_FAILURE,
  };
};

export const updateSubscription = (
  id: number,
  couponCode?: string
): IUpdateSubscriptionAction => {
  return {
    type: Consts.UPDATE_SUBSCRIPTION,
    id,
    couponCode,
  };
};

export const updateSubscriptionSuccess =
  (): IUpdateSubscriptionSuccessAction => {
    return {
      type: Consts.UPDATE_SUBSCRIPTION_SUCCESS,
    };
  };

export const updateSubscriptionsPending =
  (): IUpdateSubscriptionPendingAction => {
    return {
      type: Consts.UPDATE_SUBSCRIPTION_PENDING,
    };
  };

export const updateSubscriptionFailure = (
  error?: IErrorModel
): IUpdateSubscriptionFailureAction => {
  return {
    error,
    type: Consts.UPDATE_SUBSCRIPTION_FAILURE,
  };
};

export const getUserAvailabilityKeys = (): IGetUserAvailabilityKeysAction => {
  return {
    type: Consts.GET_USER_AVAILABILITY_KEYS,
  };
};

export const getUserAvailabilityKeysSuccess = (
  data: IUserAvailabilityKeysModel
): IGetUserAvailabilityKeysSuccessAction => {
  return {
    type: Consts.GET_USER_AVAILABILITY_KEYS_SUCCESS,
    payload: data,
  };
};

export const getUserAvailabilityKeysFailure = (
  error?: IErrorModel
): IGetUserAvailabilityKeysFailureAction => {
  return {
    type: Consts.GET_USER_AVAILABILITY_KEYS_FAILURE,
    error,
  };
};

export const getProductOfferingIds = (): IGetProductOfferingIdsAction => {
  return {
    type: Consts.GET_PRODUCT_OFFERING_IDS,
  };
};

export const getProductOfferingIdsSuccess = (
  data: string[]
): IGetProductOfferingIdsSuccessAction => {
  return {
    type: Consts.GET_PRODUCT_OFFERING_IDS_SUCCESS,
    payload: data,
  };
};

export const getProductOfferingIdsFailure = (
  error?: IErrorModel
): IGetProductOfferingIdsFailureAction => {
  return {
    type: Consts.GET_PRODUCT_OFFERING_IDS_FAILURE,
    error,
  };
};

export const getUserSubprofiles = (): IGetUserSubprofilesAction => {
  return {
    type: Consts.GET_USER_SUBPROFILES,
  };
};

export const getUserSubprofilesSuccess = (
  subprofiles: IUserSubprofileModel[],
  currentSubprofile?: IUserSubprofileModel,
  pinCodeId?: string
): IGetUserSubprofilesSuccessAction => {
  return {
    payload: { subprofiles, currentSubprofile, pinCodeId },
    type: Consts.GET_USER_SUBPROFILES_SUCCESS,
  };
};

export const getUserSubprofilesFailure = (
  error?: IErrorModel
): IGetUserSubprofilesFailureAction => {
  return {
    error,
    type: Consts.GET_USER_SUBPROFILES_FAILURE,
  };
};

export const createUserSubprofiles = (
  data: IUserSubprofileModel
): ICreateUserSubprofilesAction => {
  return {
    type: Consts.CREATE_USER_SUBPROFILES,
    payload: data,
  };
};

export const createUserSubprofilesSuccess = (
  data: IUserSubprofileModel[]
): ICreateUserSubprofilesSuccessAction => {
  return {
    type: Consts.CREATE_USER_SUBPROFILES_SUCCESS,
    payload: data,
  };
};

export const createUserSubprofilesFailure = (
  error?: IErrorModel
): ICreateUserSubprofilesFailureAction => {
  return {
    error,
    type: Consts.CREATE_USER_SUBPROFILES_FAILURE,
  };
};

export const updateUserSubprofile = (
  data: IUserSubprofileModel,
  id: string | number
): IEditUserSubprofileAction => {
  return {
    type: Consts.EDIT_USER_SUBPROFILES,
    payload: data,
    id,
  };
};

export const updateUserSubprofileSuccess = (
  data: IUserSubprofileModel
): IEditUserSubprofileSuccessAction => {
  return {
    type: Consts.EDIT_USER_SUBPROFILES_SUCCESS,
    payload: data,
  };
};

export const updateUserSubprofileFailure = (
  error?: IErrorModel
): IEditUserSubprofileFailureAction => {
  return {
    error,
    type: Consts.EDIT_USER_SUBPROFILES_FAILURE,
  };
};

export const deleteUserSubprofile = (
  id: string | number
): IDeleteUserSubprofilesAction => {
  return {
    payload: id,
    type: Consts.DELETE_USER_SUBPROFILES,
  };
};

export const deleteUserSubprofileSuccess = (
  id: string | number
): IDeleteUserSubprofilesSuccessAction => {
  return {
    payload: id,
    type: Consts.DELETE_USER_SUBPROFILES_SUCCESS,
  };
};

export const deleteUserSubprofileFailure = (
  error?: IErrorModel
): IDeleteUserSubprofilesFailureAction => {
  return {
    error,
    type: Consts.DELETE_USER_SUBPROFILES_FAILURE,
  };
};

export const selectUserSubprofile = (
  profile: IUserSubprofileModel,
  remember: boolean,
  isAfterResetPinCode?: boolean
): ISelectUserSubprofileAction => {
  return {
    type: Consts.SELECT_USER_SUBPROFILE,
    payload: { profile, remember, isAfterResetPinCode },
  };
};

export const selectUserSubprofileSuccess = (
  profile: IUserSubprofileModel,
  tokens?: ITokenModel,
  isAfterResetPinCode?: boolean
): ISelectUserSubprofileSuccessAction => {
  return {
    type: Consts.SELECT_USER_SUBPROFILE_SUCCESS,
    payload: { profile, tokens, isAfterResetPinCode },
  };
};

export const selectUserSubprofileFailure = (
  error?: IErrorModel
): ISelectUserSubprofileFailureAction => {
  return {
    error,
    type: Consts.SELECT_USER_SUBPROFILE_FAILURE,
  };
};

export const selectUserSubprofileCompleted =
  (): ISelectUserSubprofileCompletedAction => {
    return {
      type: Consts.SELECT_USER_SUBPROFILE_COMPLETED,
    };
  };

export const getUserParentalRatings = (
  countryCode: string
): IGetUserParentalRatingsAction => {
  return {
    type: Consts.GET_USER_PARENTAL_RATINGS,
    payload: countryCode,
  };
};

export const getUserParentalRatingsSuccess = (
  data: IUserParentalRatingModel[]
): IGetUserParentalRatingsSuccessAction => {
  return {
    type: Consts.GET_USER_PARENTAL_RATINGS_SUCCESS,
    payload: data,
  };
};

export const getUserParentalRatingsFailure = (
  error?: IErrorModel
): IGetUserParentalRatingsFailureAction => {
  return {
    error,
    type: Consts.GET_USER_PARENTAL_RATINGS_FAILURE,
  };
};

export const setUserSubprofilePinCode = (
  pinCode: string
): ISetUserSubprofilePinCodeAction => {
  return { type: Consts.SET_USER_SUBPROFILE_PIN_CODE, payload: pinCode };
};

export const setUserSubprofilePinCodeSuccess = (
  pinCodeId: string
): ISetUserSubprofilePinCodeSuccessAction => {
  return {
    type: Consts.SET_USER_SUBPROFILE_PIN_CODE_SUCCESS,
    payload: pinCodeId,
  };
};

export const setUserSubprofilePinCodeFailure = (
  error?: IErrorModel
): ISetUserSubprofilePinCodeFailureAction => {
  return { error, type: Consts.SET_USER_SUBPROFILE_PIN_CODE_FAILURE };
};

export const deleteUserSubprofilePinCode =
  (): IDeleteUserSubprofilePinCodeAction => {
    return { type: Consts.DELETE_USER_SUBPROFILE_PIN_CODE };
  };

export const deleteUserSubprofilePinCodeSuccess =
  (): IDeleteUserSubprofilePinCodeSuccessAction => {
    return {
      type: Consts.DELETE_USER_SUBPROFILE_PIN_CODE_SUCCESS,
    };
  };

export const deleteUserSubprofilePinCodeFailure = (
  error?: IErrorModel
): IDeleteUserSubprofilePinCodeFailureAction => {
  return { error, type: Consts.DELETE_USER_SUBPROFILE_PIN_CODE_FAILURE };
};

export const syncWebUserSuccess = (
  subscriptions: IUserSubscriptionModel[],
  availabilityKeys: IUserAvailabilityKeysModel,
  currentSubProfile: IUserSubprofileModel,
  subProfiles: IUserSubprofileModel[],
  session: ISessionModel,
  pinCode: string,
  favorites: IUserFavoritesModel[],
  customer: IUserInfoModel
): ISyncWebUserSuccessAction => {
  return {
    type: AuthConsts.SYNC_WEB_USER_SUCCESS,
    payload: {
      subscriptions,
      availabilityKeys,
      currentSubProfile,
      subProfiles,
      session,
      pinCode,
      favorites,
      customer,
    },
  };
};

export const checkOrderAndFetchSubscriptions = (
  orderId: string
): ICheckOrderAndFetchSubscriptionsAction => {
  return {
    type: Consts.CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS,
    orderId,
  };
};

export const checkOrderAndFetchSubscriptionsSuccess =
  (): ICheckOrderAndFetchSubscriptionsSuccessAction => {
    return {
      type: Consts.CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS_SUCCESS,
    };
  };

export const checkOrderAndFetchSubscriptionsFailure = (
  error?: IErrorModel
): ICheckOrderAndFetchSubscriptionsFailureAction => {
  return {
    error,
    type: Consts.CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS_FAILURE,
  };
};

export const Actions = {
  syncWebUserSuccess,
  getProfile,
  updateProfile,
  getUserDevices,
  deleteUserDevice,
  getUserFavorites,
  getUserFavoritesSuccess,
  getUserFavoritesFailure,
  addToUserFavorites,
  removeFromUserFavorites,
  getUserConsents,
  updateUserConsents,
  getWatchHistory,
  getWatchHistorySuccess,
  getWatchHistoryFailure,
  deleteWatchHistory,
  deleteWatchHistorySuccess,
  deleteWatchHistoryFailure,
  getUserSubscriptions,
  getUserSubscriptionsSuccess,
  getUserSubscriptionsFailure,
  cancelSubscription,
  pauseSubscription,
  updateSubscription,
  getUserAvailabilityKeys,
  getUserAvailabilityKeysSuccess,
  getUserAvailabilityKeysFailure,
  getProductOfferingIds,
  getProductOfferingIdsSuccess,
  getProductOfferingIdsFailure,
  getUserSubprofiles,
  getUserSubprofilesSuccess,
  getUserSubprofilesFailure,
  createUserSubprofiles,
  createUserSubprofilesSuccess,
  createUserSubprofilesFailure,
  updateUserSubprofile,
  updateUserSubprofileSuccess,
  updateUserSubprofileFailure,
  deleteUserSubprofile,
  deleteUserSubprofileSuccess,
  deleteUserSubprofileFailure,
  selectUserSubprofile,
  selectUserSubprofileSuccess,
  selectUserSubprofileFailure,
  selectUserSubprofileCompleted,
  getUserParentalRatings,
  getUserParentalRatingsSuccess,
  getUserParentalRatingsFailure,
  setUserSubprofilePinCode,
  setUserSubprofilePinCodeSuccess,
  setUserSubprofilePinCodeFailure,
  deleteUserSubprofilePinCode,
  deleteUserSubprofilePinCodeSuccess,
  deleteUserSubprofilePinCodeFailure,
  checkOrderAndFetchSubscriptions,
  checkOrderAndFetchSubscriptionsSuccess,
  checkOrderAndFetchSubscriptionsFailure,
};
