const cleanUp = (text?: string): string => {
  if (!text) {
    return "";
  }

  return text
    .replace(/\\r\\n|\\n|\\r/g, "\n") // Normalize escaped newlines (\r\n, \n, \r) to \n
    .replace(/\\/g, "") // Remove backslashes
    .replace(/\r?\n\s*(?=[^"]*("[^"]*"[^"]*)*$)/g, "") // Remove structural newlines (those outside of string values)
    .replace(/\r?\n|\r/g, "\\n"); // Convert remaining newlines to escaped \n for JSON parsing
};

export const parseTextComponentData = (
  componentText?: string,
  componentTitle?: string
): Record<string, unknown> | undefined => {
  try {
    if (componentText) {
      // nosemgrep
      return JSON.parse(cleanUp(componentText));
    }
  } catch (error) {
    console.log("Parse error:", componentTitle, error);
    return undefined;
  }
};
