import { ScreenType } from "../../../enums";
import {
  IConfigurationBrandingModel,
  IConfigurationTranslationsModel,
  IConfigurationModel,
  IScreenModel,
  IMediaOptionsModel,
  IMediaModel,
  IMediaSearchFilterModel,
  IMediaSearchStateModel,
  IMediaListModel,
  IMediaListOptionsModel,
  IMediaCategoryListModel,
  IUserFavoritesModel,
  IAuthResponseModel,
  IAuthRequestModel,
  IUserDeviceModel,
  IUserModel,
  IUserConsentModel,
  ITokenModel,
  ILocalTranslationsModel,
  ITranslationListItem,
  IUserSubscriptionModel,
  IChangePasswordModel,
  IAuthViaTokenRequestModel,
  IPaymentOfferModel,
  ICreatePaymentOrderRequestModel,
  IUserAvailabilityKeysModel,
  IUserSubprofileModel,
  IUserParentalRatingModel,
  IErrorModel,
  IUserInfoModel,
  IChangePasswordResponseModel,
  EmptyObject,
  IMultiSearchStateModel,
  IPeopleSearchFilterModel,
  IPeopleSearchStateModel,
  IAuthSignUpRequestModel,
  IAuthSignUpResponseModel,
  IValidCouponModel,
  IOrderStatusModel,
  IPaymentOrderModel,
  ICreatePaymentDetailsModel,
} from "../../../models";
import { IDataProvider } from "../IDataProvider";
import { RedBeeMediaDataProvider } from "../RedBeeMedia";
import { SkymillDataProvider } from "../Skymill";
import {
  HttpRequestFulfilledInterceptor,
  HttpRequestRejectedInterceptor,
  HttpResponseFulfilledInterceptor,
  HttpResponseRejectedInterceptor,
  TranslationService,
} from "./services";
import { lastValueFrom, Observable } from "rxjs";
import {
  ILoginCodeResponseModel,
  ILoginCodeModel,
  IVerifyLoginCodeResponseModel,
  IGetLoginTokenFromCodeModel,
  IUpdatedSubscriptionModel,
  IOfferSwitchModel,
  ICancellationSubscriptionReasons,
  ICancelSubscriptionSwitchModelResponse,
} from "../Skymill/models";
import { ISessionModel } from "../RedBeeMedia/models";
import { StorageHelper } from "../../../helpers";
import { AjaxResponse } from "rxjs/internal/ajax/AjaxResponse";

export class NordiskFilmDataProvider implements IDataProvider {
  private _RBMProvider = new RedBeeMediaDataProvider();
  private _SkymillProvider = new SkymillDataProvider();
  private tanslationService = new TranslationService();

  private _defaultTranslations: ILocalTranslationsModel = {
    en_GB: {
      web_signup_login: "Do you already have an account? <1>Log in</1>",
      web_subscription_cancel_feedback:
        "We would like to get your feedback. Why do you cancel your subscription?",
      EPISODES_NOT_FOUND: "Episodes not found",
      web_series_details_episode: "Episode {{number}}",
      app_contacts_form_unsubscribe_info:
        'You can always unsubscribe again via links at the bottom of emails or change your preferences under "My Account".',
      web_subscription_monthly_shortcut: "mth.",
      web_subscription_status: "Subscription status:",
      web_subscription_status_active: "Active",
      web_subscription_status_canceled: "Canceled",
      web_subscription_restart_subscription: "Restart subscription",
      web_subscription_subscription_ends: "Subscription ends",
      web_subscription_subscription_terminated:
        "Your subscription will be terminated at the end of this period: ",
      web_subscription_subscription_restart_into:
        "Changed your mind? You can restart your subscription here.",
      web_subscription_subscription_updated:
        "Your subscription status has been updated",
      web_subscription_no_subscription:
        "You currently have no active subscriptions.",
      web_subscription_no_subscription_button: "Sign up for subscription",
      web_subscription_start_subscription_button: "Start subscription",
      web_subscription_partner_subscription:
        "You have a subscription via Partner A. <1></1><1></1> Contact Partner A if you want to make changes to your subcription. ",
      web_asset_page_avaliable_from: "Avaliable from {{date}}",
      web_payment_success_header: "Welcome!",
      web_payment_success_text:
        "<p>Thank you for subscribing to Nordisk Film+. We can’t wait to entertain you.</p> <p>You have received a receipt on <bold>{{email}}</bold>.</p>",
      web_watch_now: "Watch now",
      web_payment_success_help_header: "Need help?",
      web_payment_success_help_text:
        "<p>Read <a>frequently asked questions</a> or contact our customer service at: <bold>kontakt@nordiskfilmplus.dk</bold> <br/> Tel. 70 70 77 88</p>",
      web_select_sort: "Sort: ",
      web_select_categories: "Genres: ",
      web_seo_cta_title_tags_landing_page: "Watch films on the go",
      web_all_genres: "All Genres & Collections",
      web_all_categories: " All categories",
      app_asset_page_avaliable_from: "Avaliable from {{date}}",
      web_sort_option_year_newest: "Year newest",
      web_sort_option_year_oldest: "Year oldest",
      web_payment_header: "Payment details",
      web_payment_description:
        "<paragraph>As a special introductory offer, you get both Nordisk Film+ and Dansk Filmskat for only <bold>{{price}} {{currency}}/mth</bold>.</paragraph> <paragraph>The subscription is renewed automatically. You can cancel your subscription any time.</paragraph>",
      web_offer_subscription_period_month: "Monthly subscription",
      web_offer_voucher_header: "Do you have a <bold>voucher?</bold>",
      web_offer_voucher_input_label: "Add voucher",
      web_offer_voucher_redeem: "Redeem",
      web_offer_voucher_success: "Voucher successfully added",
      web_offer_voucher_success_giftcard: "Gift card",
      web_offer_voucher_success_discount: "{{amount}}% off:",
      web_payment_methods_header: "Choose payment type",
      web_payment_method_card: "Credit card",
      web_payment_history_description:
        "See your payment history for the last 12 months here.",
      web_payment_failed:
        "! An error has occured. Your payment details were not registered. Try again or <a>contact us</a> if the problem persist.",
      web_payment_history_header_date: "Date",
      web_payment_history_header_period: "Period",
      web_payment_history_header_payment_method: "Payment method",
      web_payment_history_header_amount: "Amount",
      web_payment_details_edit_payment_details: "Change payment",
      web_payment_details_current_payment_type: "Current payment type:",
      web_create_account_link: "Create an account.",
      web_login_create_account: "Not registered yet?",
      web_error_wrong_email_or_password: "Wrong email or password",
      smart_login_action: "Login",
      smart_settings: "Settings",
      web_minimum_password_validation_message:
        "Password requires minimum 10 characters",
      web_maximum_password_validation_message:
        "Password requires maximum 60 characters",
      web_new_password_information:
        "When you update your password, you will be logged out from all devices and be asked to log in again.",
      web_login_password_updated:
        "Your password was successfully updated. Please log in with your new password.",
      web_phone_length_byCountry_validation_message:
        "Phone lenght is {{maxLenght}}",
      web_payment_update_success: "Payment details updated successfully",
      web_offer_voucher_error_message:
        "Voucher cannot be redeemed. Check voucher and try again.",
      web_offer_voucher_input_placeholder: "Add voucher",
      web_error_request_throttled:
        "An e-mail is on the way already. Please check your spam folder.",
      web_offer_voucher_period_info:
        "After the introductory offer of {{quantity}} months at {{amount}} discount, the price increases to {{offerPrice}}",
      web_offer_voucher_success_discount_over_one_period:
        "{{amount}} off for {{quantity}} months: ",

      web_subprofiles_description:
        "You can add up to 5 profiles to your account. Remember to add a pin code for added security for kids profiles.",
      web_subprofiles_button_add_profile: "Add profile",
      web_subprofiles_parental_control_header: "Parental control",
      web_subprofiles_edit_profile: "Edit profile",
      web_subprofiles_add_profile: "Add profile",
      web_subprofiles_name: "Name",
      web_subprofiles_placeholder: "Enter profile name",
      web_subprofiles_age_restriction: "Age restriction on content",
      web_subprofiles_button_save: "Save profile",
      web_subprofiles_button_save_changes: "Save changes",
      web_subprofiles_button_delete_profile: "Delete profile",
      web_subprofiles_kids_profile: "Kids profile",
      web_subprofiles_kids_years: " years",
      web_subprofiles_kids_profile_reminder:
        "<p>This profile will only have access to the Kids channel. <br/><br/> For extra security, remember to set a pin code.</p>",
      web_subprofiles_color: "Choose color",
      web_subprofiles_parental_control_description:
        "Add a pin code to protect kids profiles from adult content. You will need to enter the pin code to navigate from a kids profile to a parent profile.",
      web_subprofiles_parental_control_button_save: "Save",
      web_subprofiles_select_profile_remember_device:
        "Remember me on this device",
      web_subprofiles_nav_item_manage_profiles: "Manage profiles",
      web_subprofiles_pin_error: "Incorrect PIN. Please try again.",
      web_subprofiles_enter_pin: "Enter pin",
      web_subprofiles_forgot_pin: "Forgot pin?",
      web_subprofiles_forgot_pin_enter_password: "Enter password",
      web_subprofiles_forgot_pin_description:
        "To retrieve your profile PIN, your account password in required.",
      web_subprofiles_forgot_pin_button_continue: "Continue",
      web_subprofiles_forgot_pin_input_placeholder: "Password",
      ctv_subprofiles_select_profile_header: "Whos watching?",
      ctv_subprofiles_parental_control_enter_pin: "Enter pin",
      ctv_subprofiles_parental_control_forgot_pin:
        "<strong>Forgot pin?</strong> Please go to nordiskfilmplus.com to reset your pin code",
      web_error_web_subprofiles_wrong_password: "Wrong password",
    },
  };

  initHttpFactory(): void {
    this._SkymillProvider.initHttpFactory();
    this._RBMProvider.initHttpFactory(
      HttpRequestFulfilledInterceptor,
      HttpRequestRejectedInterceptor,
      HttpResponseFulfilledInterceptor,
      HttpResponseRejectedInterceptor
    );
  }

  setLanguage(lang: string): void {
    this._RBMProvider.setLanguage(lang);
  }

  setCountry(country: string): void {
    this._RBMProvider.setCountry(country);
  }

  getDefaultBranding(): IConfigurationBrandingModel | undefined {
    return undefined;
  }

  getDefaultTranslations(): ILocalTranslationsModel | undefined {
    return this._defaultTranslations;
  }

  getResource(resourceKey: string): any {
    return null;
  }

  getTranslation(language: string) {
    return this.tanslationService.getTranslation(language);
  }

  async getConfiguration(): Promise<IConfigurationModel> {
    try {
      const translations = await lastValueFrom(
        this.tanslationService.getTranslationList()
      );
      let Translations: IConfigurationTranslationsModel = [];
      const configuration = await this._RBMProvider.getConfiguration();

      Translations = translations.map(
        (trans: ITranslationListItem) => Object.keys(trans)[0]
      );

      configuration.Translations = Translations.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      return Promise.resolve(configuration);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getScreenConfiguration(
    screenId?: string | number,
    parentalRatings?: string
  ): Promise<IScreenModel | undefined> {
    return this._RBMProvider.getComponentConfiguration(
      screenId,
      parentalRatings
    );
  }

  getScreenComponentBody(screenName: string) {
    return this._RBMProvider.getScreenComponentBody(screenName);
  }

  getMedia(options: IMediaOptionsModel): Promise<IMediaModel> {
    return this._RBMProvider.getMedia(options);
  }

  getMediaExtraMaterials(extras: string[]): Promise<IMediaModel[]> {
    return this._RBMProvider.getMediaExtraMaterials(extras);
  }

  getMediaProgress(media: IMediaModel): Promise<IMediaModel> {
    return this._RBMProvider.getMediaProgress(media);
  }

  getNextMedia(mediaId: string | number, collectionId?: string) {
    return this._RBMProvider.getNextMedia(mediaId, collectionId);
  }

  getPrevMedia(mediaId: IMediaModel, collectionId?: string) {
    return this._RBMProvider.getPrevMedia(mediaId, collectionId);
  }

  searchMedia(
    filter: IMediaSearchFilterModel
  ): Promise<IMediaSearchStateModel> {
    return this._RBMProvider.searchMedia(filter);
  }

  searchPeople(
    filter: IPeopleSearchFilterModel
  ): Promise<IPeopleSearchStateModel> {
    return this._RBMProvider.searchPeople(filter);
  }

  searchMulti(
    filter: IMediaSearchFilterModel
  ): Promise<IMultiSearchStateModel> {
    return this._RBMProvider.searchMulti(filter);
  }

  getMediaList(options: IMediaListOptionsModel): Promise<IMediaListModel> {
    return this._RBMProvider.getMediaList(options);
  }

  getMediaCategories(tagType?: boolean): Promise<IMediaCategoryListModel> {
    return this._RBMProvider.getMediaCategories(tagType);
  }

  getUserDevices(): Promise<IUserDeviceModel[]> {
    return this._RBMProvider.getUserDevices();
  }

  getUserPlayDevices(): Promise<IUserDeviceModel[]> {
    return this._RBMProvider.getUserPlayDevices();
  }

  deleteUserDevice(id: string): Promise<void> {
    return this._RBMProvider.deleteUserDevice(id);
  }

  deleteUserPlayDevice(id: string): Promise<void> {
    return this._RBMProvider.deleteUserPlayDevice(id);
  }

  getUserFavorites(): Promise<IUserFavoritesModel[]> {
    return this._RBMProvider.getUserFavorites();
  }
  addToMyList(mediaId: string | number): Promise<IMediaModel> {
    return this._RBMProvider.addToMyList(mediaId);
  }
  removeFromMyList(mediaId: string | number): Promise<void> {
    return this._RBMProvider.removeFromMyList(mediaId);
  }
  getLastViewEpisode(mediaId: string | number): Promise<IMediaModel> {
    return this._RBMProvider.getLastViewEpisode(mediaId);
  }
  getUserSessionDetails(): Promise<ISessionModel> {
    return this._RBMProvider.getUserSessionDetails();
  }
  getUserAvailabilityKeys(): Promise<IUserAvailabilityKeysModel> {
    return this._RBMProvider.getUserAvailabilityKeys();
  }
  getUserWatchHistory(): Promise<IMediaModel[]> {
    return this._RBMProvider.getUserWatchHistory();
  }
  deleteUserWatchHistory(id: string | number): Promise<void> {
    return this._RBMProvider.deleteUserWatchHistory(id);
  }
  signIn(data: IAuthRequestModel): Observable<IAuthResponseModel> {
    return this._SkymillProvider.signIn(data);
  }

  signUp(data: IAuthSignUpRequestModel): Promise<IAuthSignUpResponseModel> {
    return this._SkymillProvider.signUp(data);
  }

  validatePassword(password: string, email?: string): Promise<boolean> {
    return this._SkymillProvider.validatePassword(password, email);
  }

  signInViaToken(
    data: IAuthViaTokenRequestModel
  ): Observable<IAuthResponseModel> {
    return this._SkymillProvider.signInViaToken(data);
  }

  loginViaToken(
    data: IAuthViaTokenRequestModel
  ): Observable<IAuthResponseModel> {
    return this._SkymillProvider.loginViaToken(data);
  }

  async signOut(device: IUserDeviceModel): Promise<void> {
    try {
      const session = StorageHelper.getSession();
      if (session?.RefreshToken) {
        await this._SkymillProvider.signOut(session?.RefreshToken);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }
  refreshToken(
    refreshToken: string,
    device: IUserDeviceModel
  ): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }
  changePassword(
    data: IChangePasswordModel
  ): Promise<IChangePasswordResponseModel> {
    return this._SkymillProvider.changePassword(data);
  }
  getProfile(): Promise<IUserModel> {
    return this._SkymillProvider.getProfile();
  }
  updateProfile(data: Partial<IUserModel>): Promise<IUserModel> {
    return this._SkymillProvider.updateProfile(data);
  }
  getUserConsents(locale?: string): Promise<IUserConsentModel[]> {
    return this._SkymillProvider.getUserConsents(locale);
  }
  updateUserConsents(
    data: IUserConsentModel[],
    locale?: string
  ): Observable<IUserConsentModel[]> {
    return this._SkymillProvider.updateUserConsents(data, locale);
  }
  getOffers(): Observable<IPaymentOfferModel[]> {
    return this._SkymillProvider.getOffers();
  }
  createPaymentOrder(data: ICreatePaymentOrderRequestModel) {
    return this._SkymillProvider.createPaymentOrder(data);
  }
  getPaymentInvoices() {
    return this._SkymillProvider.getPaymentInvoices();
  }
  getInvoicePDF(invoiceId: string) {
    return this._SkymillProvider.getInvoicePDF(invoiceId);
  }
  getPaymentDetails() {
    return this._SkymillProvider.getPaymentDetails();
  }
  createPaymentDetails(
    data: ICreatePaymentDetailsModel
  ): Promise<IPaymentOrderModel> {
    return this._SkymillProvider.createPaymentDetails(data);
  }
  validateCoupon(coupon: string): Promise<IValidCouponModel> {
    return this._SkymillProvider.validateCoupon(coupon);
  }
  getOrderStatus(orderId: string): Promise<IOrderStatusModel> {
    return this._SkymillProvider.getOrderStatus(orderId);
  }
  getUserSubscriptions(): Observable<IUserSubscriptionModel[] | IErrorModel> {
    return this._SkymillProvider.getUserSubscriptions();
  }
  cancelSubscription(
    id: number,
    reason?: {
      cancellation_answers_v2: string[];
      cancellation_reasons_v2: ICancellationSubscriptionReasons;
    }
  ): Observable<IUserSubscriptionModel | IErrorModel> {
    return this._SkymillProvider.cancelUserSubscription(id, reason);
  }
  pauseSubscription(
    id: string,
    pausePeriod: string
  ): Observable<AjaxResponse<EmptyObject>> {
    return this._SkymillProvider.pauseUserSubscription(id, pausePeriod);
  }
  updateSubscription(
    id: number,
    couponCode?: string
  ): Observable<IUpdatedSubscriptionModel> {
    return this._SkymillProvider.updateUserSubscription(id, couponCode);
  }

  getUserSubprofiles(
    currentSubprofileId?: string,
    defaultMainSubprofileName?: string,
    countryCode?: string
  ): Observable<IUserSubprofileModel[]> {
    return this._RBMProvider.getUserSubprofiles(
      currentSubprofileId,
      defaultMainSubprofileName,
      countryCode
    );
  }

  createUserSubprofile(
    data: IUserSubprofileModel,
    currentSubprofileId?: string,
    defaultMainSubprofileName?: string
  ): Promise<IUserSubprofileModel[]> {
    return this._RBMProvider.createUserSubprofile(
      data,
      currentSubprofileId,
      defaultMainSubprofileName
    );
  }

  updateUserSubprofile(
    data: IUserSubprofileModel,
    id: string | number
  ): Promise<IUserSubprofileModel> {
    return this._RBMProvider.updateUserSubprofile(data, id);
  }

  deleteUserSubprofile(id: string | number): Promise<any> {
    return this._RBMProvider.deleteUserSubprofile(id);
  }

  selectUserSubprofile(
    userId: string,
    remember: boolean
  ): Promise<ITokenModel | undefined> {
    return this._RBMProvider.selectUserSubprofile(userId, remember);
  }

  getUserParentalRatings(
    countryCode: string
  ): Observable<IUserParentalRatingModel[]> {
    return this._RBMProvider.getUserParentalRatings(countryCode);
  }

  getUserSubprofilePinCode(): Observable<string | undefined> {
    return this._RBMProvider.getUserSubprofilePinCode();
  }

  setUserSubprofilePinCode(
    pinCode: string,
    pinCodeId?: string
  ): Promise<string> {
    return this._RBMProvider.setUserSubprofilePinCode(pinCode, pinCodeId);
  }

  validateUserSubprofilePinCode(
    pinCodeId: string,
    pinCode: string
  ): Promise<boolean> {
    return this._RBMProvider.validateUserSubprofilePinCode(pinCodeId, pinCode);
  }

  deleteUserSubprofilePinCode(pinCodeId: string): Promise<void> {
    return this._RBMProvider.deleteUserSubprofilePinCode(pinCodeId);
  }

  getLoginCode(): Promise<ILoginCodeResponseModel> {
    return this._SkymillProvider.getLoginCode();
  }

  verifyLogin(data: ILoginCodeModel): Promise<IVerifyLoginCodeResponseModel> {
    return this._SkymillProvider.verifyLoginCode(data);
  }

  getLoginTokenFromCode(
    data: ILoginCodeModel
  ): Promise<IGetLoginTokenFromCodeModel> {
    return this._SkymillProvider.getLoginTokenFromCode(data);
  }

  getOfferSwitch(subscription_id: number): Observable<IOfferSwitchModel[]> {
    return this._SkymillProvider.getOfferSwitches(subscription_id);
  }
  switchSubscription(data: {
    subscription_id: number;
    to_offer_id: string | null;
  }): Observable<IOfferSwitchModel> {
    return this._SkymillProvider.switchUserSubscription(data);
  }

  cancelSubscriptionSwitch(
    subscription_id: number
  ): Observable<ICancelSubscriptionSwitchModelResponse> {
    return this._SkymillProvider.cancelUserSubscriptionSwitch(subscription_id);
  }

  getUserFavouriteListByComponentUrl(url: string): Observable<IMediaModel[]> {
    return this._RBMProvider.getUserFavouriteListByComponentUrl(url);
  }

  getCustomer(): Observable<IUserInfoModel> {
    return this._SkymillProvider.getCustomer();
  }

  getProductOffers(): Observable<any> {
    return this._RBMProvider.getProductOffers();
  }

  getProductOfferingIds(): Observable<string[]> {
    return this._RBMProvider.getProductOfferingIds();
  }

  getAccountPurchase(): Observable<any> {
    return this._RBMProvider.getAccountPurchase();
  }

  getChannelOnNow(channelId: string) {
    return this._RBMProvider.getChannelOnNow(channelId);
  }
}
