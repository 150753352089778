/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel } from "../models";

export const calcProgress = (media?: IMediaModel) => {
  if (media?.Id && media?.Duration) {
    if (media?.LastViewedOffset) {
      const progress = (media.LastViewedOffset / media.Duration) * 100;
      return progress < 98 ? progress : 100;
    } else return 0;
  }
};
