import { useCallback } from "react";
import {
  dispatch,
  IUserSubprofileModel,
  StorageHelper,
  UserStore,
  AuthStore,
} from "@nf/common";
import { ROUTES } from "@nf/constants";
import { useProfiles } from "./useProfiles";
import axios from "axios";

export const useSetCurrentSubProfile = () => {
  const { setAsCurrentProfile } = useProfiles();

  return useCallback(
    async (
      subProfile?: IUserSubprofileModel,
      isAfterResetPinCode?: boolean
    ) => {
      const remember = await StorageHelper.getCurrentSubprofileId();
      if (subProfile) {
        await axios.post(ROUTES.API_SET_CHILD_COOKIE, {
          isChild: subProfile.Child,
        });
        dispatch(AuthStore.Actions.setIsChild(subProfile.Child));
        setAsCurrentProfile(subProfile);
        dispatch(
          UserStore.Actions.selectUserSubprofile(
            subProfile,
            !!remember,
            isAfterResetPinCode
          )
        );
      }
    },
    []
  );
};
