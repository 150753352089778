/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const CHANNELS = {
    KIDS: "KIDS",
    DANSK_FILMSKAT: "DANSK",
    NF_PLUS: "MAIN"
};
