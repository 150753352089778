import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { PRODUCTS_COOKIE } from "@nf/constants";
import { BrowserHelper, StorageHelper } from "../../helpers";

const getOfferingIds = async (): Promise<string> => {
  try {
    const products = BrowserHelper.isTV() ? await StorageHelper.getProducts() : getCookie(PRODUCTS_COOKIE)?.toString();
    const productsParsed = products && JSON.parse(products);
    if (productsParsed && productsParsed?.length > 0) {
      return productsParsed.join(",");
    }
  } catch (e) {
    console.error(e);
  }
  return "";
};

export const includeProductsIdsParam = async (
  params: Record<string, string | number | null | undefined>
): Promise<Record<string, string | number | null | undefined>> => {
  const ids = await getOfferingIds();
  if (ids && ids?.length > 0) {
    params.products = ids;
  }
  return params;
};

const setOfferingIds = (ids: string[]): void => {
  if (ids.length) {
    BrowserHelper.isTV() ? StorageHelper.setProducts(JSON.stringify(ids)) : setCookie(PRODUCTS_COOKIE, JSON.stringify(ids));
    return;
  }
  BrowserHelper.isTV() ? StorageHelper.deleteProducts() : deleteCookie(PRODUCTS_COOKIE);
};

const deleteOfferingIds = (): void => {
  BrowserHelper.isTV() ? StorageHelper.deleteProducts() : deleteCookie(PRODUCTS_COOKIE);
};

export const offeringIdsService = {
  includeProductsIdsParam,
  getOfferingIds,
  setOfferingIds,
  deleteOfferingIds,
};
