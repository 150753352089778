import { useContext, useEffect } from "react";
import { getCurrentChannelType, ThemeContext } from "@nf/common";

export const useBrandingChangeEvent = (mediaListId: string) => {
  const channelContext = useContext(ThemeContext);
  useEffect(() => {
    if (mediaListId && channelContext.onBrandingChange) {
      channelContext.onBrandingChange(getCurrentChannelType(mediaListId));
    }
  }, [mediaListId, channelContext]);
};
