/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  ILoginCodeResponseModel,
  ILoginCodeModel,
  IVerifyLoginCodeResponseModel,
  IGetLoginTokenFromCodeModel,
} from "../../providers/DataProvider/Skymill/models";
import {
  IAuthRequestModel,
  IAuthViaTokenRequestModel,
  IChangePasswordModel,
  IErrorModel,
  ITokenModel,
  IUserFavoritesModel,
  IUserInfoModel,
  IUserModel,
} from "../../models";
import * as Consts from "./consts";
import {
  IChangePasswordAction,
  IChangePasswordFailureAction,
  IChangePasswordSuccessAction,
  IClearChangePasswordResultAction,
  IGetLoginCode,
  IGetLoginCodeFailure,
  IGetLoginCodeSuccess,
  IGetLoginTokenFromCode,
  IGetLoginTokenFromCodeFailure,
  IGetLoginTokenFromCodeSuccess,
  IGetSessionDetailsAction,
  IGetSessionDetailsFailureAction,
  IGetSessionDetailsSuccessAction,
  IRefreshTokenFailureAction,
  IRefreshTokenSuccessAction,
  IRefreshUserAction,
  ISetAuthenticatedAction,
  ISetIsChildAction,
  ISignInAction,
  ISignInAnonymousAction,
  ISignInAnonymousFailureAction,
  ISignInAnonymousSuccessAction,
  ISignInFailureAction,
  ISignInSuccessAction,
  ISignInViaTokenAction,
  ISignInViaTokenFailureAction,
  ISignInViaTokenSuccessAction,
  ISignInViaTokenTVAction,
  ISignInWebSuccessAction,
  ISignOutAction,
  ISignOutFailureAction,
  ISignOutSuccessAction,
  ISyncUserAction,
  ISyncUserFailureAction,
  ISyncUserSuccessAction,
  ISyncWebUserAction,
  ISyncWebUserPendingAction,
  IUpdateTokens,
  IVerifyLoginCode,
  IVerifyLoginCodeFailure,
  IVerifyLoginCodeSuccess,
} from "./types";
import { ISessionModel } from "../../providers/DataProvider/RedBeeMedia/models";

export const signIn = (data: IAuthRequestModel): ISignInAction => {
  return {
    data,
    type: Consts.SIGN_IN,
  };
};

export const signInSuccess = (
  user?: IUserInfoModel,
  session?: ITokenModel
): ISignInSuccessAction => {
  return {
    payload: {
      session,
      user,
    },
    type: Consts.SIGN_IN_SUCCESS,
  };
};

export const signInWebSuccess = (
  user?: IUserInfoModel,
  session?: ITokenModel
): ISignInWebSuccessAction => {
  return {
    payload: {
      session,
      user,
    },
    type: Consts.SIGN_IN_WEB_SUCCESS,
  };
};

export const signInFailure = (error?: IErrorModel): ISignInFailureAction => {
  return {
    error,
    type: Consts.SIGN_IN_FAILURE,
  };
};

export const signInViaToken = (
  data: IAuthViaTokenRequestModel
): ISignInViaTokenAction => {
  return {
    data,
    type: Consts.SIGN_IN_VIA_TOKEN,
  };
};

export const signInViaTokenTV = (
  data: IAuthViaTokenRequestModel
): ISignInViaTokenTVAction => {
  return {
    data,
    type: Consts.SIGN_IN_VIA_TOKEN_TV,
  };
};

export const setIsChild = (isChild: boolean): ISetIsChildAction => {
  return {
    payload: isChild,
    type: Consts.SET_IS_CHILD,
  };
};

export const signInViaTokenSuccess = (
  user?: IUserInfoModel,
  session?: ITokenModel
): ISignInViaTokenSuccessAction => {
  return {
    payload: {
      session,
      user,
    },
    type: Consts.SIGN_IN_VIA_TOKEN_SUCCESS,
  };
};

export const signInViaTokenFailure = (
  error?: IErrorModel
): ISignInViaTokenFailureAction => {
  return {
    error,
    type: Consts.SIGN_IN_VIA_TOKEN_FAILURE,
  };
};

export const signInAnonymous = (
  data: IAuthRequestModel = {}
): ISignInAnonymousAction => {
  return {
    data,
    type: Consts.SIGN_IN_ANONYMOUS,
  };
};

export const signInAnonymousSuccess = (
  user?: IUserInfoModel,
  session?: ITokenModel
): ISignInAnonymousSuccessAction => {
  return {
    payload: {
      session,
      user,
    },
    type: Consts.SIGN_IN_ANONYMOUS_SUCCESS,
  };
};

export const signInAnonymousFailure = (
  error?: IErrorModel
): ISignInAnonymousFailureAction => {
  return {
    error,
    type: Consts.SIGN_IN_ANONYMOUS_FAILURE,
  };
};

export const signOut = (): ISignOutAction => {
  return {
    type: Consts.SIGN_OUT,
  };
};

export const signOutSuccess = (): ISignOutSuccessAction => {
  return {
    type: Consts.SIGN_OUT_SUCCESS,
  };
};

export const signOutFailure = (error?: IErrorModel): ISignOutFailureAction => {
  return {
    error,
    type: Consts.SIGN_OUT_FAILURE,
  };
};

export const getSessionDetails = (): IGetSessionDetailsAction => {
  return {
    type: Consts.GET_SESSION_DETAILS,
  };
};

export const getSessionDetailsSuccess = (
  payload: ISessionModel
): IGetSessionDetailsSuccessAction => {
  return {
    type: Consts.GET_SESSION_DETAILS_SUCCESS,
    payload: {
      session: payload,
    },
  };
};

export const getSessionDetailsFailure = (
  error?: IErrorModel
): IGetSessionDetailsFailureAction => {
  return {
    error,
    type: Consts.GET_SESSION_DETAILS_FAILURE,
  };
};

export const setAuthenticated = (): ISetAuthenticatedAction => {
  return {
    type: Consts.SET_AUTHENTICATED,
  };
};

export const refreshUser = (data: IUserModel): IRefreshUserAction => {
  return {
    payload: data,
    type: Consts.REFRESH_USER,
  };
};

export const syncUser = (): ISyncUserAction => {
  return {
    type: Consts.SYNC_USER,
  };
};

export const syncWebUser = (): ISyncWebUserAction => {
  return {
    type: Consts.SYNC_WEB_USER,
  };
};

export const syncWebUserPending = (
  session: ITokenModel,
  isChild: boolean,
  userId: string,
  userData: IUserInfoModel,
  favorites: IUserFavoritesModel[]
): ISyncWebUserPendingAction => {
  return {
    type: Consts.SYNC_WEB_USER_PENDING,
    payload: {
      session,
      isChild,
      userId,
      userData,
      favorites,
    },
  };
};

export const syncUserSuccess = (
  userInfo: IUserInfoModel,
  session?: ITokenModel
): ISyncUserSuccessAction => {
  return {
    type: Consts.SYNC_USER_SUCCESS,
    payload: {
      userInfo,
      session,
    },
  };
};

export const syncUserFailure = (): ISyncUserFailureAction => {
  return {
    type: Consts.SYNC_USER_FAILURE,
  };
};

export const refreshTokenFailure = (): IRefreshTokenFailureAction => {
  return {
    type: Consts.REFRESH_TOKEN_FAILURE,
  };
};

export const refreshTokenSuccess = (
  session: ITokenModel,
  user?: IUserInfoModel
): IRefreshTokenSuccessAction => {
  return {
    payload: {
      session,
      user,
    },
    type: Consts.REFRESH_TOKEN_SUCCESS,
  };
};

export const changePassword = (
  data: IChangePasswordModel
): IChangePasswordAction => {
  return {
    payload: data,
    type: Consts.CHANGE_PASSWORD,
  };
};

export const changePasswordSuccess = (
  data: ITokenModel
): IChangePasswordSuccessAction => {
  return {
    payload: data,
    type: Consts.CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordFailure = (
  error?: IErrorModel
): IChangePasswordFailureAction => {
  return {
    error,
    type: Consts.CHANGE_PASSWORD_FAILURE,
  };
};

export const clearChangePasswordSuccess = (
  payload?: boolean
): IClearChangePasswordResultAction => {
  return {
    payload,
    type: Consts.CLEAR_CHANGE_PASSWORD_RESULT,
  };
};

export const getLoginCode = (): IGetLoginCode => {
  return {
    type: Consts.GET_ACTIVATION_CODE,
  };
};

export const getLoginCodeSuccess = (
  data: ILoginCodeResponseModel
): IGetLoginCodeSuccess => {
  return {
    type: Consts.GET_ACTIVATION_CODE_SUCCESS,
    payload: data,
  };
};

export const getLoginCodeFailure = (
  error: IErrorModel
): IGetLoginCodeFailure => {
  return {
    type: Consts.GET_ACTIVATION_CODE_FAILURE,
    error,
  };
};

export const verifyLoginCode = (code: ILoginCodeModel): IVerifyLoginCode => {
  return {
    type: Consts.VALIDATE_ACTIVATION_CODE,
    payload: {
      code,
    },
  };
};

export const verifyLoginCodeSuccess = (
  data: IVerifyLoginCodeResponseModel
): IVerifyLoginCodeSuccess => {
  return {
    type: Consts.VALIDATE_ACTIVATION_CODE_SUCCESS,
    payload: data,
  };
};

export const verifyLoginCodeFailure = (
  error: IErrorModel
): IVerifyLoginCodeFailure => {
  return {
    type: Consts.VALIDATE_ACTIVATION_CODE_FAILURE,
    error,
  };
};

export const getLoginTokenFromCode = (
  code: ILoginCodeModel
): IGetLoginTokenFromCode => {
  return {
    type: Consts.GET_LOGIN_TOKEN_FROM_CODE,
    payload: {
      code,
    },
  };
};

export const getLoginTokenFromCodeSuccess = (
  data: IGetLoginTokenFromCodeModel
): IGetLoginTokenFromCodeSuccess => {
  return {
    type: Consts.GET_LOGIN_TOKEN_FROM_CODE_SUCCESS,
    payload: data,
  };
};

export const getLoginTokenFromCodeFailure = (
  error: IErrorModel
): IGetLoginTokenFromCodeFailure => {
  return {
    type: Consts.GET_LOGIN_TOKEN_FROM_CODE_FAILURE,
    error,
  };
};

export const updateTokens = (tokens?: ITokenModel): IUpdateTokens => {
  return {
    type: Consts.UPDATE_TOKENS,
    payload: tokens,
  };
};

export const Actions = {
  signIn,
  signInSuccess,
  signInWebSuccess,
  signInAnonymous,
  signOut,
  signInViaToken,
  signInViaTokenSuccess,
  signInViaTokenFailure,
  getSessionDetails,
  getSessionDetailsSuccess,
  getSessionDetailsFailure,
  setAuthenticated,
  changePassword,
  clearChangePasswordSuccess,
  refreshUser,
  syncUser,
  syncWebUser,
  syncUserSuccess,
  syncUserFailure,
  refreshTokenSuccess,
  refreshTokenFailure,
  getLoginCode,
  getLoginCodeSuccess,
  getLoginCodeFailure,
  verifyLoginCode,
  verifyLoginCodeSuccess,
  verifyLoginCodeFailure,
  getLoginTokenFromCode,
  getLoginTokenFromCodeSuccess,
  getLoginTokenFromCodeFailure,
  updateTokens,
  setIsChild,
  signInViaTokenTV,
};
