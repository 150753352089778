/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IScreenSkeletonModel } from "../models/Configuration/IScreenSkeletonModel";
import { ActionType, ScreenType } from "../enums";
import {
  IApplicationMenuItemComponentModel,
  IConfigurationModel,
  IScreenModel,
} from "../models";

export class ConfigurationHelper {
  static getScreenByType(
    configuration: IConfigurationModel | undefined,
    screenType: ScreenType
  ) {
    let screen: IScreenModel = {};

    if (configuration && configuration.Screens) {
      screen = configuration.Screens[screenType];
    }

    return screen;
  }

  static getScreenRouteKeyByScreen(screen: IScreenModel) {
    return this.getScreenRouteKey(screen.Id, screen.ScreenTypeCode);
  }

  static getScreenSkeleton(): IScreenSkeletonModel {
    return {
      Id: "Skeleton",
      Name: "Skeleton",
      ScreenTypeCode: "CUSTOM",
      Components: [
        {
          Id: "Skeleton",
          ComponentTypeCode: "LIST",
          Orientation: "HORIZONTAL",
          CellType: "FRAME",
          Name: "",
          VisibleItemsCount: 1,
          Title: "",
          IsVisible: true,
          HasAuthorizedContent: false,
        },
      ],
    };
  }

  static getScreenRouteKey(
    screenId?: string | number,
    _screenType: ScreenType = ScreenType.Custom
  ) {
    return `${screenId}`;
  }

  static getScreenByRouteKey(
    configuration: IConfigurationModel | undefined,
    routeKey: string
  ): IScreenModel | undefined {
    let screen: IScreenModel | undefined = undefined;

    if (configuration && configuration.Screens) {
      screen = configuration.Screens[routeKey.toUpperCase()];

      if (
        ScreenType.Home in configuration.Screens &&
        configuration.Screens[ScreenType.Home].Id === routeKey
      )
        screen = configuration.Screens[ScreenType.Home];

      if (!screen && configuration.Screens.CUSTOM) {
        screen = configuration.Screens.CUSTOM[routeKey];
      }
    }

    return screen;
  }

  static getScreenById(
    configuration: IConfigurationModel | undefined,
    screenId: number
  ): IScreenModel | undefined {
    const scrrenKey = `${screenId}`;

    return this.getScreenByRouteKey(configuration, scrrenKey);
  }

  static getApplicationMenuItemScreenKey(
    component: IApplicationMenuItemComponentModel
  ) {
    if (component?.Action?.ActionType === ActionType.OpenScreen) {
      if (
        component?.Action?.ScreenTypeCode &&
        component?.Action?.ScreenTypeCode !== ScreenType.Custom
      ) {
        return `${component?.Action?.ScreenTypeCode?.toLowerCase().replace(
          "_",
          "-"
        )}`;
      }

      return `${component?.Action?.ScreenId}`;
    }

    return "";
  }
}
