/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AxiosSubscriber, offeringIdsService } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import { Observable, Observer } from "rxjs";
import { ITagModel, ITagListModel } from "../models";
import { AppConfig } from "@nf/constants";

export class TagService {
  get url(): string {
    return `/v1/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/tag`;
  }

  public get = (assetId: string | number): Observable<ITagModel> =>
    new Observable(
      (observer: Observer<ITagModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/asset/${assetId}`,
        })
    );

  public search = (
    pageSize: number = 50,
    pageNumber: number = 1,
    sort?: string,
    scheme?: "other" | "genre" | undefined
  ): Observable<ITagListModel> => {
    const params = {
      pageSize,
      pageNumber,
      scheme,
      sort,
    };
    return new Observable(
      (observer: Observer<ITagListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: this.url,
        })
    );
  };

  public searchByTagType = async (
    tagType: string,
    pageSize: number = 50,
    pageNumber: number = 1
  ): Promise<Observable<ITagListModel>> => {
    const params = {
      pageSize,
      pageNumber,
      tagType,
      products: await offeringIdsService.getOfferingIds(),
    };
    return new Observable(
      (observer: Observer<ITagListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/asset`,
        })
    );
  };

  public searchByUrl = async (
    url: string,
  ): Promise<Observable<ITagListModel>> => {
    let params: { products?: string } = {};

    params = await offeringIdsService.includeProductsIdsParam(params);

    return new Observable(
      (observer: Observer<ITagListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: { params },
          method: HTTP_METHOD.GET,
          url: url,
        })
    );
  };
}
