/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Locale, LOCALE_LIST } from "@nf/constants";
import HttpBackend from "i18next-http-backend";

const config = {
  load: "languageOnly" as const,
  i18n: {
    locales: LOCALE_LIST,
    defaultLocale: Locale.Danish,

    backend: {
      loadPath: `https://translation.qa.nordiskfilmplus.com/{{lng}}.json`,
    },
  },
  ns: ["translations"],
  use: [HttpBackend],
};

export default config;
