/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  HTTP_RESPONSE_TYPE,
  UNDEFINED_MEDIA_ID,
  LIST_NAME_PREFIX,
  subprofilesColors,
  ROUTES,
  DEFAULT_COUNTRY_CODE,
} from "@nf/constants";
import {
  ActionType,
  AssetType,
  CellType,
  ComponentType,
  MediaCategoryType,
  MediaImageType,
  MediaType,
  Orientation,
  PlatformType,
  ScreenType,
  SourceType,
} from "../../../../enums";
import {
  TimeHelper,
  listNamePrefixHelper,
  userProfileHelper,
} from "../../../../helpers";
import {
  IActionModel,
  IApplicationFooterItemComponentModel,
  IApplicationFooterScreenModel,
  IApplicationMenuItemComponentModel,
  IApplicationMenuScreenModel,
  IErrorModel as ICommonErrorModel,
  IListComponentModel,
  IMediaCategoryModel,
  IMediaImageModel,
  IMediaModel,
  IScreenModel,
  IUserFavoritesModel,
  IUserDeviceModel,
  IUserAvailabilityKeysModel,
  IUserSubprofileModel,
  IMediaMapperOptions,
  IPersonModel,
  IFrameComponentModel,
  ITextComponentModel,
} from "../../../../models";
import {
  IComponentConfigModel,
  IActionConfigModel,
  IAssetModel,
  IAssetImageModel,
  ITagModel,
  IAssetTagModel,
  IAssetSeasonModel,
  IFavoritesAssetModel,
  IDeviceModel,
  IListModel,
  IUserPlayHistoryModel,
  IUserAvailabilityKeyModel,
  IUserSubprofileResponseModel,
  IChannelAssetModel,
  IPlayDeviceModel,
} from "../models";
import {
  COMPONENT_TYPE,
  COMPONENT_COLECTIONS,
  ACTION,
  ASSET_IMAGE_ORIENTATION,
  IMAGE_ORIENTATION,
  COMPONENT_SUB_TYPE,
  ASSET_IMAGE_TAG,
  CAROUSEL_LAYOUT,
  ASSET_TYPE,
} from "../constants";
import { sortByKey } from "../../../../helpers";
import { IConfigOptionModel } from "../models/Config/IConfigOptionModel";
import { registerSupportedTranslations } from "./translationsHelper";
import countries from "i18n-iso-countries";
import languages from "@cospired/i18n-iso-languages";
import { AppConfig } from "@nf/constants";
import { IPresentationItemModel } from "../models/Config/IPresentationItemModel";
import { toHeroComponentModel } from "./toHeroComponentModel";
import { parseTextComponentData } from "./parseTextComponentData";
import { IParticipantModel } from "../models/Participant/IParticipantModel";
import { compareStrings } from "@nf/helpers";

export class ModelsMapperHelper {
  static toGenreModel(
    entity: ITagModel,
    options: IConfigOptionModel,
    mediaMapperOptions?: IMediaMapperOptions
  ): IMediaModel {
    let genre: IMediaModel = { Id: entity.tagId };
    if (entity.localized && entity.localized.length > 0) {
      const fallback = entity.localized.find(
        (row) => row.locale === options.defaultLocale
      );
      const localized = entity.localized.find(
        (row) => row.locale === options.lang
      );
      const images = (
        localized?.images && localized?.images.length > 0
          ? localized?.images
          : fallback?.images || []
      )
        .map((image) => this.toMediaImageModel(image, mediaMapperOptions))
        .filter((image) => image !== undefined) as IMediaImageModel[];

      genre.Title = localized?.title || fallback?.title;
      genre.Description = localized?.description || fallback?.description;
      genre.Article = localized?.article || fallback?.article;
      genre.Images = images;

      if (typeof window === "undefined" && mediaMapperOptions?.IsList) {
        return {
          Id: entity.tagId,
          Title: localized?.title || fallback?.title,
          Description: localized?.description || fallback?.description,
          Images: images,
        };
      }
    }

    return genre;
  }

  static fillCommonData(
    media: IMediaModel,
    entity: IAssetModel | IAssetSeasonModel,
    options: IConfigOptionModel,
    mediaMapperOptions?: IMediaMapperOptions
  ): IMediaModel {
    if (entity.localized && entity.localized.length > 0) {
      const fallback = entity.localized.find(
        (row) => row.locale === options.defaultLocale
      );
      const localized = entity.localized.find(
        (row) => row.locale === options.lang
      );

      media.Title = localized?.title || fallback?.title;
      if (!mediaMapperOptions?.ExcludeDescription) {
        media.Description = localized?.description || fallback?.description;
      }

      media.Article = localized?.article || fallback?.article;

      if (!mediaMapperOptions?.ExcludeLongDescription) {
        media.LongDescription =
          localized?.longDescription || fallback?.longDescription;
      }
      if (!mediaMapperOptions?.ExcludeShortDescription) {
        media.ShortDescription =
          localized?.shortDescription || fallback?.shortDescription;
      }
      if (!mediaMapperOptions?.ExcludeExtendedDescription) {
        media.ExtendedDescription =
          localized?.extendedDescription || fallback?.extendedDescription;
      }
      if (!mediaMapperOptions?.ExcludeContentAdvisory) {
        media.ContentAdvisory =
          localized?.tinyDescription || fallback?.tinyDescription;
      }

      const images = (
        localized?.images && localized?.images.length > 0
          ? localized?.images
          : fallback?.images || []
      )
        .map((image) => this.toMediaImageModel(image, mediaMapperOptions))
        .filter((image) => image !== undefined) as IMediaImageModel[];

      media.Images = images;

      if (typeof window === "undefined" && mediaMapperOptions?.IsList) {
        return {
          Id: media.Id,
          Title: localized?.title || fallback?.title,
          Description: localized?.description || fallback?.description,
          Images: images,
          Year: media.Year,
        };
      }
    }

    // // Categories
    if (
      entity.tags &&
      entity.tags.length > 0 &&
      !mediaMapperOptions?.ExcludeCategories
    ) {
      const [firstCategory, ...restCategories] = this.toMediaCategoriesModel(
        entity.tags,
        options
      );
      if (mediaMapperOptions?.FirstCategoryOnly) {
        media.Categories = [firstCategory];
      } else {
        media.Categories = [firstCategory, ...restCategories];
      }
    }

    if (mediaMapperOptions?.ExcludeImagesFromCategories) {
      media.Categories = media.Categories?.map((category) => {
        delete category.Images;
        return category;
      });
    }

    if (entity.seoData && mediaMapperOptions?.IncludeSeoData) {
      const fallback = entity.seoData.find(
        (row) => row.locale === options.defaultLocale
      );
      const localized = entity.seoData.find(
        (row) => row.locale === options.lang
      );

      media.Seo = {
        Title: localized?.seoTitle || fallback?.seoTitle,
        Description: localized?.seoDescription || fallback?.seoDescription,
      };
    }

    return media;
  }

  static getLocalizedFallback(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ): IPresentationItemModel {
    if (config.appSubType === COMPONENT_SUB_TYPE.ASSET) {
      return (
        <IPresentationItemModel>(
          (config?.content?.presentation?.fallback ||
            config?.presentation?.localized?.[options.defaultLocale])
        ) || {}
      );
    }
    return (
      config?.presentation?.fallback ||
      config?.presentation?.localized?.[options.defaultLocale] ||
      {}
    );
  }

  static toMediaModel(
    entity: IAssetModel,
    options: IConfigOptionModel,
    mediaMapperOptions?: IMediaMapperOptions
  ): IMediaModel {
    let media: IMediaModel = {
      Id: entity.assetId,
      MediaTypeCode: MediaType.Video,
      Year: entity.productionYear,
      Duration: entity.duration,
    };
    switch (entity.type) {
      case ASSET_TYPE.COLLECTION:
        media.MediaTypeCode = MediaType.Collection;
        break;
      case ASSET_TYPE.TV_SHOW:
        media.MediaTypeCode = MediaType.Series;
        break;
      case ASSET_TYPE.TV_CHANNEL:
        media.MediaTypeCode = MediaType.Channel;
        break;
      case ASSET_TYPE.EPISODE:
        media.MediaTypeCode = MediaType.Episode;
        media.ParentMediaId = entity.tvShowId;
        media.ParentOrderInParent = entity.season ? +entity.season : undefined;

        if (entity.episode) {
          media.OrderInParent = +entity.episode;
        }
        break;
      default:
        media.MediaTypeCode = MediaType.Video;
        break;
    }

    this.fillCommonData(media, entity, options, mediaMapperOptions);

    // Trailer
    if (entity.linkedEntities && !!entity.linkedEntities.length) {
      media.TrailerMediaId = entity.linkedEntities.find(
        (item) => item.linkType === ASSET_TYPE.TRAILER
      )?.entityId;

      media.ExtraMaterialsIds = entity.linkedEntities
        .filter((item) => item.linkType === ASSET_TYPE.EXTRA)
        .map((item) => item.entityId);

      media.RelatedMaterialsIds = entity.linkedEntities
        .filter((item) => item.linkType === ASSET_TYPE.RELATED)
        .map((item) => item.entityId);
    }

    // Publications
    if (entity.publications && entity.publications.length > 0) {
      const publication =
        entity.publications.find((el) =>
          TimeHelper.isBetween(
            TimeHelper.getCurrentDateTime(),
            el.fromDate,
            el.toDate
          )
        ) || entity.publications[0];
      media.AvailableFrom = TimeHelper.getDateTime(publication.fromDate);
      media.AvailableTo = TimeHelper.getDateTime(publication.toDate);
      media.IsPlayable = TimeHelper.isBeforeCurrent(publication.fromDate);

      if (
        publication.products &&
        publication.products.length > 0 &&
        !mediaMapperOptions?.ExcludeProducts
      ) {
        media.Products = publication.products.map((productId) => {
          return { Id: productId };
        });
      }
    }

    // Collections
    if (entity.collections && entity.collections.length > 0) {
      media.Media = entity.collections.map((collection) =>
        this.fillCommonData(
          {
            Id: collection.collectionId,
          },
          entity,
          options,
          mediaMapperOptions
        )
      );
    }

    // Seasons
    if (
      entity.seasons &&
      entity.seasons.length > 0 &&
      !mediaMapperOptions?.ExcludeSeasons
    ) {
      media.Media = [];
      for (const season of entity.seasons) {
        const mediaSeason: IMediaModel = {
          Id: season.seasonId,
          ParentMediaId: media.Id,
          MediaTypeCode: MediaType.Season,
          OrderInParent: season.season ? +season.season : undefined,
        };

        this.fillCommonData(mediaSeason, entity, options, mediaMapperOptions);

        if (season.episodes && season.episodes.length > 0) {
          mediaSeason.Media = [];

          for (const episode of season.episodes) {
            const mediaEpisode = this.toMediaModel(episode, options);

            mediaSeason.Media.push(mediaEpisode);
          }

          mediaSeason.Media = sortByKey(mediaSeason.Media, "OrderInParent");
        }
        media.Media.push(mediaSeason);
      }
      media.Media = sortByKey(media.Media, "OrderInParent");
    }

    // Media People
    if (
      entity.participants &&
      !!entity.participants.length &&
      !mediaMapperOptions?.ExcludePeople
    ) {
      media.People = [
        ...entity.participants.map((people) => ({
          PersonId: people.personId,
          PersonFullName: people.name,
          PersonRoleDisplayName: people.function,
          PersonRoleCode: people?.function?.toUpperCase(),
        })),
      ];
    }

    //Countries
    if (entity.productionCountries && !!entity.productionCountries.length) {
      media.Countries = [
        ...entity.productionCountries.map((country) => ({
          Code: country,
          Name: countries.getName(country, options.lang) ?? country,
        })),
      ];
    }

    //Subtitles
    if (
      entity.subtitles &&
      !!entity.subtitles.length &&
      !mediaMapperOptions?.ExcludeSubtitles
    ) {
      media.Subtitles = entity.subtitles.map(
        (subtitle) => languages.getName(subtitle, options.lang) ?? subtitle
      );
    }

    //Languages
    if (entity.spokenLanguages && !!entity.spokenLanguages.length) {
      media.Languages = [
        ...entity.spokenLanguages.map((language) => ({
          Code: language,
          Name: languages.getName(language, options.lang) ?? language,
        })),
      ];
    }

    //MediaAgeRestriction
    if (entity.parentalRatings && !!entity.parentalRatings.length) {
      media.MediaAgeRestrictionValueMin = entity.parentalRatings.find(
        (ageRestriction) =>
          compareStrings(ageRestriction.country, DEFAULT_COUNTRY_CODE)
      )?.rating;
    }

    //LastViewedOffset
    if (entity?.userData?.playHistory?.lastViewedOffset)
      media.LastViewedOffset = entity.userData.playHistory.lastViewedOffset;

    //LastViewedTime
    if (entity?.userData?.playHistory?.lastViewedTime)
      media.LastViewedTime = entity.userData.playHistory.lastViewedTime;

    return media;
  }

  static toMediaProgressModel(
    media: IMediaModel,
    progress: IListModel<IUserPlayHistoryModel[]>
  ) {
    if (media.MediaTypeCode !== MediaType.Series) {
      media.LastViewedOffset = progress.items?.length
        ? progress.items[0].lastViewedOffset
        : 0;
    } else {
      media.Media?.forEach((season) =>
        season.Media?.forEach((episode) => {
          episode.LastViewedOffset =
            progress.items?.find((progress) => progress.assetId === episode.Id)
              ?.lastViewedOffset ?? 0;
        })
      );
    }
    return media;
  }

  static toMediaImageModel(
    entity: IAssetImageModel,
    mediaMapperOptions?: IMediaMapperOptions
  ): IMediaImageModel | undefined {
    const mediaImage: IMediaImageModel = {
      ImageTypeCode: MediaImageType.Any,
      PlatformCode: PlatformType.Any,
      Url: entity.url,
      Width: entity.width,
      Height: entity.height,
    };
    switch (entity.orientation) {
      case ASSET_IMAGE_ORIENTATION.LANDSCAPE:
        switch (entity.type) {
          case ASSET_IMAGE_TAG.BANNER:
            mediaImage.ImageTypeCode = MediaImageType.Banner;
            break;
          case ASSET_IMAGE_TAG.FRAME:
            mediaImage.ImageTypeCode = MediaImageType.Frame;
            break;
          case ASSET_IMAGE_TAG.HIGHLIGHT:
            mediaImage.ImageTypeCode = MediaImageType.Highlights;
            break;
          case ASSET_IMAGE_TAG.THUMBNAIL:
            mediaImage.ImageTypeCode = MediaImageType.Thumbnail;
            break;
          case ASSET_IMAGE_TAG.HIGHLIGHT_ASSET_DETAILS:
            mediaImage.ImageTypeCode = MediaImageType.Highlight_Asset_Details;
            break;
          case ASSET_IMAGE_TAG.HOVER_ASSET:
            mediaImage.ImageTypeCode = MediaImageType.Hover_Asset;
            break;
          case ASSET_IMAGE_TAG.PROMO:
            mediaImage.ImageTypeCode = MediaImageType.Promo;
            break;
          case ASSET_IMAGE_TAG.HERO_ASSET_DETAILS:
          case ASSET_IMAGE_TAG.HERO_ASSET_DETAIL:
            mediaImage.ImageTypeCode = MediaImageType.Hero_Asset_Details;
            break;
          case ASSET_IMAGE_TAG.HERO_FRONTPAGE:
            mediaImage.ImageTypeCode = MediaImageType.Hero_Frontpage;
            break;
          case ASSET_IMAGE_TAG.LOGO:
            mediaImage.ImageTypeCode = MediaImageType.Logo;
            break;
          case ASSET_IMAGE_TAG.COVER:
            mediaImage.ImageTypeCode = MediaImageType.Cover;
            break;
          default:
            mediaImage.ImageTypeCode = MediaImageType.Thumbnail;
        }
        break;
      case ASSET_IMAGE_ORIENTATION.PORTRAIT:
        switch (entity.type) {
          case ASSET_IMAGE_TAG.POSTER:
            mediaImage.ImageTypeCode = MediaImageType.Poster;
            break;
          case ASSET_IMAGE_TAG.LOGO:
            mediaImage.ImageTypeCode = MediaImageType.Logo;
            break;
          default:
            mediaImage.ImageTypeCode = MediaImageType.Cover;
        }
        break;
      case ASSET_IMAGE_ORIENTATION.SQUARE:
        mediaImage.ImageTypeCode = MediaImageType.Logo;
        break;
    }

    const shouldExclude =
      mediaMapperOptions?.ExcludeImageType && mediaImage?.ImageTypeCode
        ? mediaMapperOptions?.ExcludeImageType.includes(
            mediaImage.ImageTypeCode
          )
        : false;

    return shouldExclude ? undefined : mediaImage;
  }

  static toMediaCategoriesModel(
    entities: IAssetTagModel[],
    options: IConfigOptionModel
  ): IMediaCategoryModel[] {
    const result: IMediaCategoryModel[] = [];

    for (const entity of entities) {
      if (entity.tagValues && entity.tagValues.length > 0) {
        for (const tag of entity.tagValues) {
          let mediaCategory = options.categoriesCache.categories.get(tag.tagId);

          if (!mediaCategory) {
            options.categoriesCache.refresh = true;
            mediaCategory = {
              CategoryId: tag.tagId,
              CategoryCode: tag.tagId,
              CategoryName: tag.tagId,
            };

            mediaCategory.CategoryTypeCode =
              entity.type === "genre"
                ? MediaCategoryType.Main
                : entity.type === "format"
                ? MediaCategoryType.Format
                : entity.type === "channel"
                ? MediaCategoryType.Channel
                : MediaCategoryType.Other;
          }

          result.push(mediaCategory);
        }
      }
    }

    return result;
  }

  static toMediaCategoryModel(
    entity: ITagModel,
    options: IConfigOptionModel,
    mediaMapperOptions?: IMediaMapperOptions
  ): IMediaCategoryModel {
    const mediaCategory: IMediaCategoryModel = {
      CategoryId: entity.tagId,
      CategoryCode: entity.tagId,
      CategoryName: "",
      Article: "",
    };

    mediaCategory.CategoryTypeCode =
      entity.scheme === "genre"
        ? MediaCategoryType.Main
        : entity.scheme === "format"
        ? MediaCategoryType.Format
        : entity.scheme === "channel"
        ? MediaCategoryType.Channel
        : MediaCategoryType.Other;

    if (entity.localized && entity.localized.length > 0) {
      const fallback = entity.localized.find(
        (row) => row.locale === options.defaultLocale
      );
      const localized = entity.localized.find(
        (row) => row.locale === options.lang
      );

      mediaCategory.CategoryName = localized?.title || fallback?.title || "";
      mediaCategory.Article = localized?.article || fallback?.article || "";

      const images =
        localized?.images && localized?.images.length > 0
          ? localized?.images
          : fallback?.images || [];

      mediaCategory.Images =
        (images
          .map((image) => this.toMediaImageModel(image, mediaMapperOptions))
          .filter((image) => image !== undefined) as IMediaImageModel[]) || [];
    }

    return mediaCategory;
  }

  static logMissingDefinition(config: IComponentConfigModel) {
    console.warn(`Missing definition for component type ${config.appType}`);
  }

  static toComponentModel(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ) {
    switch (config.appType) {
      case COMPONENT_TYPE.MENU_ITEM:
        return this.toApplicationMenuItemComponentModel(config, options);
      case COMPONENT_TYPE.CAROUSEL:
      case COMPONENT_TYPE.HERO_BANNER:
        return this.toListComponentModel(config, options);
      case COMPONENT_TYPE.TAG_TYPE:
        return this.toListComponentGenreModel(config, options);
      case COMPONENT_TYPE.IMAGE_COMPONENT:
        if (config.asset) {
          return this.toListComponentModel(config, options);
        }
        break;
      case COMPONENT_TYPE.TEXT:
        return this.toTextComponentModel(config, options);
    }
  }

  static mapToComponentModelLandingPage(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ) {
    switch (config.appType) {
      case COMPONENT_TYPE.CAROUSEL:
        return this.toListComponentModel(config, options);
      case COMPONENT_TYPE.HERO_BANNER:
        return toHeroComponentModel(config, options);
      case COMPONENT_TYPE.TEXT:
        return this.toTextComponentModel(config, options);
      case COMPONENT_TYPE.IFRAME:
        return this.toIFrameComponentModel(config, options);
    }
  }

  static toIFrameComponentModel(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ) {
    const fallback = this.getLocalizedFallback(config, options);

    const component: IFrameComponentModel = {
      Id: config.id,
      Title: config.name,
      ComponentTypeCode: ComponentType.Iframe,
      Fallback: fallback.iframe,
    };

    return component;
  }

  static toTextComponentModel(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ) {
    const fallback = this.getLocalizedFallback(config, options);
    const localized = config?.presentation?.localized?.[options.lang] || {};

    const component: ITextComponentModel = {
      Id: config.id,
      ComponentTypeCode: ComponentType.TextWidget,
      Title: config.name,
      Text: localized.body,
      TextParsed: parseTextComponentData(localized.body, config.name),
      Fallback: fallback.body,
      IsVisible: true,
    };

    return component;
  }

  static toActionModel(
    config: IActionConfigModel,
    options?: IConfigOptionModel
  ) {
    const action: IActionModel = {};

    switch (config?.verb) {
      case ACTION.NAVIGATE_TO_PAGE:
        action.ActionType = ActionType.OpenScreen;

        if (config.componentId === options?.homeScreenId) {
          action.ScreenTypeCode = ScreenType.Home;
          action.ScreenId = config.componentId;
        } else {
          action.ScreenTypeCode = ScreenType.Custom;
          action.ScreenId = config.componentId;
        }
        break;
      case ACTION.NAVIGATE_TO_DETAILS:
        action.ActionType = ActionType.OpenScreen;
        action.ScreenTypeCode = ScreenType.MovieDetails;
        action.ScreenId = config.assetId;
        break;
      case ACTION.EXTERNAL_LINK:
        action.ActionType = ActionType.OpenUrl;
        action.Url = config.url;
        break;
    }

    return action;
  }

  static toApplicationScreenModel(
    config: IComponentConfigModel,
    options?: IConfigOptionModel
  ): IScreenModel {
    const screen: IScreenModel = {
      Id: config.id,
      Name: config.name || `Screen ${config.id}`,
      ScreenTypeCode: ScreenType.Custom,
    };

    if (screen.Id === options?.homeScreenId) {
      screen.ScreenTypeCode = ScreenType.Home;
    }

    return screen;
  }

  static toApplicationMenuScreenModel(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ): IApplicationMenuScreenModel {
    const screen: IApplicationMenuScreenModel = {
      Id: config.referenceId,
      Name: "Application Menu",
      ScreenTypeCode: ScreenType.ApplicationMenu,
    };

    if (config.components) {
      screen.Components = [];

      for (const componentKey in config.components) {
        const componentConfigs = config.components[componentKey];
        for (const componentConfig of componentConfigs) {
          const component = this.toApplicationMenuItemComponentModel(
            componentConfig,
            options
          );

          if (component) {
            screen.Components.push(component);
          } else {
            this.logMissingDefinition(componentConfig);
          }
        }
      }
    }

    return screen;
  }

  static toApplicationMenuItemComponentModel(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ): IApplicationMenuItemComponentModel {
    const fallback = this.getLocalizedFallback(config, options);
    const localized = config?.presentation?.localized?.[options.lang] || {};
    const componentId = config.actions?.default?.componentId;

    const component: IApplicationMenuItemComponentModel = {
      Id: componentId || config.id || fallback.title,
      ComponentTypeCode: ComponentType.ApplicationMenuItem,
      IsVisible: true,
      Title: localized.title || fallback.title,
    };

    if (config.actions?.[ACTION.DEFAULT]) {
      component.Action = this.toActionModel(
        config.actions?.[ACTION.DEFAULT],
        options
      );
    }

    component.IsAgeRestricted = component.Id !== AppConfig.ChannelKids;

    return component;
  }

  static toApplicationFooterScreenModel(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ): IApplicationFooterScreenModel {
    const screen: IApplicationFooterScreenModel = {
      Id: config.referenceId,
      Name: "Application Footer",
      ScreenTypeCode: ScreenType.ApplicationFooter,
    };

    if (config.components) {
      screen.Components = [];

      for (const componentKey in config.components) {
        const componentConfigs = config.components[componentKey];
        for (const componentConfig of componentConfigs) {
          const component = this.toApplicationFooterItemComponentModel(
            componentConfig,
            options
          );

          if (component) {
            screen.Components.push(component);
          } else {
            this.logMissingDefinition(componentConfig);
          }
        }
      }
    }

    return screen;
  }

  static toApplicationFooterItemComponentModel(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ): IApplicationFooterItemComponentModel {
    const fallback = this.getLocalizedFallback(config, options);
    const localized = config?.presentation?.localized?.[options.lang] || {};

    const component: IApplicationFooterItemComponentModel = {
      Id: config.id,
      ComponentTypeCode: ComponentType.ApplicationFooterItem,
      IsVisible: true,
      Title: localized.title || fallback.title,
    };

    if (config.actions?.[ACTION.DEFAULT]) {
      component.Action = this.toActionModel(
        config.actions?.[ACTION.DEFAULT],
        options
      );
    }

    return component;
  }

  static toListComponentGenreModel(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ) {
    const fallback = this.getLocalizedFallback(config, options);
    const localized = config?.presentation?.localized?.[options.lang] || {};

    const result: IListComponentModel = {
      Id: config.id || config.referenceId,
      ComponentTypeCode: ComponentType.List,
      Orientation: Orientation.Horizontal,
      CellType: CellType.Frame,
      Name: config.name,
      VisibleItemsCount: 4,
      Title: fallback.title || config.name,
      SubTitle: localized?.subTitle,
      IsVisible: true,
      HasAuthorizedContent: config.hasAuthorizedContent,
      SourceType: SourceType.GenreList,
    };

    if (config.contentUrl) {
      result.SourceId = result.Id;
      result.SourceUrl = config.contentUrl.url;
    }

    return result;
  }

  static toListComponentModel(
    config: IComponentConfigModel,
    options: IConfigOptionModel
  ) {
    const fallback = this.getLocalizedFallback(config, options);
    const localized = config?.presentation?.localized?.[options.lang] || {};
    const isLargeFrameList =
      config.name && listNamePrefixHelper(config.name, LIST_NAME_PREFIX.LARGE);
    const isLogoList =
      config.name && listNamePrefixHelper(config.name, LIST_NAME_PREFIX.LOGO);

    const result: IListComponentModel = {
      Id: config.id || config.referenceId,
      ComponentTypeCode: ComponentType.List,
      Orientation: Orientation.Horizontal,
      CellType: isLargeFrameList ? CellType.FrameLarge : CellType.Frame,
      Name: config.name,
      VisibleItemsCount: isLargeFrameList ? 3 : 4,
      Title:
        localized.title ||
        (isLargeFrameList || isLogoList
          ? fallback.title
          : fallback.title || config.name),
      SubTitle: localized?.subTitle,
      IsVisible: true,
      HasAuthorizedContent: config.hasAuthorizedContent,
    };
    if (config.appType === COMPONENT_TYPE.HERO_BANNER) {
      result.CellType = CellType.Highlights;
      result.VisibleItemsCount = 1;
    }
    if (config.appType === COMPONENT_TYPE.IMAGE_COMPONENT) {
      result.CellType = CellType.Promo;
      result.VisibleItemsCount = 1;
    }

    if (config.contentUrl) {
      result.SourceId = result.Id;
      result.SourceUrl = config.contentUrl.url;
      switch (config.appSubType) {
        case SourceType.Favourites:
          result.SourceType = SourceType.Favourites;
          break;
        case SourceType.ContinueWatching:
          result.SourceType = SourceType.ContinueWatching;
          break;
        default:
          result.SourceType = SourceType.MediaList;
          break;
      }
    }
    if (config.parameters) {
      switch (config.parameters.imageOrientation) {
        case IMAGE_ORIENTATION.PORTRAIT:
          result.CellType = isLargeFrameList
            ? CellType.CoverLarge
            : CellType.Cover;
          result.VisibleItemsCount = isLargeFrameList ? 3 : 5;
          break;
        case IMAGE_ORIENTATION.LANDSCAPE:
        default:
          if (
            result.CellType === CellType.Highlights ||
            result.CellType === CellType.Promo
          ) {
            break;
          }
          if (isLogoList) {
            result.CellType = CellType.Circle;
            result.VisibleItemsCount = 6;
            break;
          }
          result.CellType = isLargeFrameList
            ? CellType.FrameLarge
            : CellType.Frame;
          break;
      }

      switch (config.parameters.carouselLayout) {
        case CAROUSEL_LAYOUT.GRID:
          result.Orientation = Orientation.Grid;
          result.VisibleItemsCount = 3;
          break;
        case CAROUSEL_LAYOUT.CAROUSEL:
          result.Orientation = Orientation.Horizontal;
          break;
        case CAROUSEL_LAYOUT.LIST:
          result.Orientation = Orientation.Vertical;
          result.VisibleItemsCount = 3;
          break;
      }
    }
    if (config.appType === COMPONENT_TYPE.IMAGE_COMPONENT) {
      const defaultAction = config?.actions?.[ACTION.DEFAULT];

      const media: IMediaModel = config.asset
        ? this.toMediaModel(config.asset, options)
        : {
            Id: defaultAction.assetId || -1,
            MediaTypeCode: MediaType.Custom,
          };
      const title = localized.title || fallback.title;
      const description = localized.body || fallback.body;
      media.Title = media.Title || title;
      media.Description = description || media.Description;

      const imageItemFallback = this.getLocalizedFallback(config, options);
      const iamgeItemLocalized =
        config?.presentation?.localized?.[options.lang] || {};
      const imageItemImages =
        iamgeItemLocalized.images || imageItemFallback.images;
      if (imageItemImages) {
        media.Images = imageItemImages.map((image) => {
          return {
            ImageTypeCode: MediaImageType.Highlight_Asset_Frontpage,
            PlatformCode: PlatformType.Any,
            Url: image.url,
            Width: image.width,
            Height: image.height,
          };
        });
      }
      result.MediaList = [media];
      return result;
    }
    if (
      config.components &&
      config.components[COMPONENT_COLECTIONS.HERO_BANNER_ITEMS]
    ) {
      result.MediaList = config.components[
        COMPONENT_COLECTIONS.HERO_BANNER_ITEMS
      ].map((herobannerItem) => {
        const herobannerItemFallback = this.getLocalizedFallback(
          herobannerItem,
          options
        );
        const herobannerItemLocalized =
          herobannerItem?.presentation?.localized?.[options.lang] || {};

        const herobannerItemAssetType =
          herobannerItem.asset?.type || MediaType.Custom;

        const herobannerParentalRatings = herobannerItem.asset?.parentalRatings;

        const media: IMediaModel = {
          Id: herobannerItem.actions?.default?.assetId || UNDEFINED_MEDIA_ID,
          Title: herobannerItemFallback.title || herobannerItemLocalized.title,
          Description:
            herobannerItemFallback.body || herobannerItemLocalized.body,
          MediaTypeCode:
            herobannerItemAssetType === AssetType.Series
              ? MediaType.Series
              : herobannerItemAssetType,
          TrailerMediaId:
            herobannerItemLocalized.trailerAssetId ||
            herobannerItemFallback.trailerAssetId,
          ParentalRatings: herobannerParentalRatings,
        };

        const herobannerItemImages =
          herobannerItemLocalized.images || herobannerItemFallback.images;

        if (herobannerItemImages) {
          media.Images = herobannerItemImages.map((image) => {
            return {
              ImageTypeCode: MediaImageType.Highlights,
              PlatformCode: PlatformType.Any,
              Url: image.url,
              Width: image.width,
              Height: image.height,
            };
          });
        }

        if (
          herobannerItem?.actions &&
          herobannerItem?.actions[ACTION.DEFAULT]
        ) {
          result.Action = this.toActionModel(
            herobannerItem?.actions[ACTION.DEFAULT]
          );
        }

        if (
          herobannerItem.appSubType === COMPONENT_SUB_TYPE.ASSET &&
          herobannerItem.content
        ) {
          const herobannerItemContentFallback =
            herobannerItem?.content?.presentation?.fallback ||
            herobannerItem?.content?.presentation?.localized?.[
              options.defaultLocale
            ] ||
            {};
          const herobannerItemContentLocalized =
            herobannerItem?.content?.presentation?.localized?.[options.lang] ||
            {};

          const herobannerAsset = herobannerItem.asset?.tags;

          if (herobannerAsset && herobannerAsset.length > 0) {
            media.Categories = this.toMediaCategoriesModel(
              herobannerAsset,
              options
            );
          }

          const herobannerYear = herobannerItem.asset?.productionYear;
          if (herobannerYear) {
            media.Year = herobannerYear;
          }

          if (
            herobannerItem.asset?.publications &&
            herobannerItem.asset.publications.length > 0
          ) {
            const herobannerPublications =
              herobannerItem.asset?.publications[0];
            media.IsPlayable = TimeHelper.isBeforeCurrent(
              herobannerPublications.fromDate
            );
            media.Products = herobannerPublications.products.map(
              (productId) => {
                return { Id: productId };
              }
            );
          }

          if (!media.Title) {
            media.Title =
              herobannerItemContentLocalized.title ||
              herobannerItemContentFallback.title;
          }

          if (!media.Description) {
            media.Description =
              herobannerItemContentLocalized.body ||
              herobannerItemContentFallback.body;
          }

          if (!media.TrailerMediaId) {
            media.TrailerMediaId =
              herobannerItemContentLocalized.trailerAssetId ||
              herobannerItemContentFallback.trailerAssetId;
          }

          const herobannerItemAssetContentLocalized =
            herobannerItem.asset?.localized.find(
              (row) => row.locale === options.defaultLocale
            );

          if (!media.ExtendedDescription) {
            media.ExtendedDescription =
              herobannerItemAssetContentLocalized?.extendedDescription;
          }

          const herobannerItemContentImages =
            herobannerItemContentLocalized.images ||
            herobannerItemContentFallback.images;

          if (herobannerItemContentImages) {
            if (!media.Images) {
              media.Images = [];
            }

            for (const herobannerItemContentImage of herobannerItemContentImages) {
              const image: IMediaImageModel = {
                ImageTypeCode:
                  herobannerItemContentImage.orientation ===
                  ASSET_IMAGE_ORIENTATION.PORTRAIT
                    ? MediaImageType.Cover
                    : MediaImageType.Thumbnail,
                PlatformCode: PlatformType.Any,
                Url: herobannerItemContentImage.url,
                Width: herobannerItemContentImage.width,
                Height: herobannerItemContentImage.height,
              };

              if (
                herobannerItemContentImage.tags &&
                herobannerItemContentImage.tags.includes(ASSET_IMAGE_TAG.BANNER)
              ) {
                image.ImageTypeCode = MediaImageType.Banner;
              }

              if (
                herobannerItemContentImage.tags &&
                herobannerItemContentImage.tags.includes(
                  ASSET_IMAGE_TAG.HERO_FRONTPAGE
                )
              ) {
                image.ImageTypeCode = MediaImageType.Hero_Frontpage;
              }
              if (
                herobannerItemContentImage.tags &&
                herobannerItemContentImage.tags.includes(
                  ASSET_IMAGE_TAG.HERO_ASSET_DETAILS
                )
              ) {
                image.ImageTypeCode = MediaImageType.Hero_Asset_Details;
              }

              media.Images.push(image);
            }
          }
        }

        return media;
      });
    }

    return result;
  }

  static toErrorModel(error: any): ICommonErrorModel {
    return {
      Message: error?.message,
      ResultType: HTTP_RESPONSE_TYPE.ERROR,
    };
  }

  static toUserDeviceModel(response: IDeviceModel): IUserDeviceModel {
    return {
      Id: response.deviceId,
      Name: response.deviceName,
      CurrentDevice: response.currentDevice,
    };
  }

  static toUserPlayDeviceModel(response: IPlayDeviceModel): IUserDeviceModel {
    return {
      Id: response.id,
    };
  }

  static toUserFavoritesModel(
    response: IFavoritesAssetModel[]
  ): IUserFavoritesModel[] {
    return response.map((item) => ({
      AssetId: item.assetId,
    }));
  }

  static toUserAvailabilityKeysModel(
    response: IUserAvailabilityKeyModel
  ): IUserAvailabilityKeysModel {
    return { AvailabilityKeys: response.currentAvailabilityKeys };
  }

  static toUserSubprofileModel(
    data: IUserSubprofileResponseModel,
    currentSubprofileId?: string,
    defaultName?: string,
    countryCode?: string
  ): IUserSubprofileModel {
    const subprofile: IUserSubprofileModel = {
      UserId: data.userId,
      DisplayName: data.displayName,
      Child: data.child,
      ProfileType: data.profileType?.replace(
        /SE|FI|NO|DK/g,
        countryCode as string
      ),
      Language: data.language,
      Owner: data.owner,
      CurrentProfile: data.userId === currentSubprofileId || data.active,
      FirstLetter: data.displayName?.charAt(0),
      Color: data.metadata?.color ?? subprofilesColors[0],
      AvatarUrl: data.metadata?.avatarUrl,
      InitialHomePage: data.child
        ? userProfileHelper.cleanUp(ROUTES.KIDS)
        : userProfileHelper.cleanUp(data?.metadata?.initialHomePage) ??
          userProfileHelper.cleanUp(ROUTES.HOME),
    };

    if (subprofile.Owner && !subprofile.DisplayName?.length) {
      return {
        ...subprofile,
        DisplayName: defaultName,
        FirstLetter: defaultName?.charAt(0),
      };
    }

    return subprofile;
  }

  static toPeopleModel(participant: IParticipantModel): IPersonModel {
    return {
      PersonId: participant.participantId,
      PersonFullName: participant.name,
      // TODO
      PersonRoleDisplayName: participant.function,
      PersonRoleCode: participant?.function?.toUpperCase(),
    };
  }

  static toAssetChannelModel(
    entity: IChannelAssetModel,
    options: IConfigOptionModel
  ): IMediaModel {
    return {
      ...ModelsMapperHelper.toMediaModel(entity.asset, options),
      StartDateTime: new Date(entity.startTime),
      EndDateTime: new Date(entity.endTime),
    };
  }
}

registerSupportedTranslations(countries, languages);
