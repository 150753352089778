/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AnyAction } from "redux";
import { ofType, StateObservable } from "redux-observable";
import {
  of,
  Observable,
  tap,
  catchError,
  forkJoin,
  retry,
  takeUntil,
  repeat,
  from,
  defer,
} from "rxjs";
import {
  filter,
  last,
  map,
  mergeMap,
  switchMap,
  takeWhile,
} from "rxjs/operators";
import { IAppState } from "../types";
import { MAX_DEVICES_LIMIT, ROUTES } from "@nf/constants";
import {
  AuthorizationHelper,
  StorageHelper,
  userProfileHelper,
} from "../../helpers";
import {
  IErrorModel,
  IUserAvailabilityKeysModel,
  IUserConsentModel,
  IUserFavoritesModel,
  IUserInfoModel,
  IUserSubprofileModel,
  IUserSubscriptionModel,
  toErrorModel,
} from "../../models";
import { DataProvider } from "../../providers";
import { eventService, offeringIdsService } from "../../services";
import {
  refreshUser,
  getSessionDetails,
  updateTokens,
  Actions,
} from "../auth/actions";
import {
  cancelSubscriptionFailure,
  deleteUserDeviceFailure,
  deleteUserDeviceSuccess,
  getProfileFailure,
  getProfileSuccess,
  getUserFavoritesSuccess,
  getUserFavoritesFailure,
  getUserConsentsFailure,
  getUserConsentsSuccess,
  getUserDevicesFailure,
  getUserDevicesSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  updateUserConsentsFailure,
  updateUserConsentsSuccess,
  getWatchHistoryFailure,
  getWatchHistorySuccess,
  deleteWatchHistorySuccess,
  deleteWatchHistoryFailure,
  getUserSubscriptionsSuccess,
  getUserSubscriptionsFailure,
  updateSubscriptionFailure,
  getUserAvailabilityKeysFailure,
  getUserAvailabilityKeysSuccess,
  createUserSubprofilesSuccess,
  createUserSubprofilesFailure,
  getUserSubprofilesSuccess,
  getUserSubprofilesFailure,
  deleteUserSubprofileSuccess,
  deleteUserSubprofileFailure,
  updateUserSubprofileSuccess,
  updateUserSubprofileFailure,
  selectUserSubprofileSuccess,
  selectUserSubprofileFailure,
  getUserFavorites,
  getUserParentalRatingsSuccess,
  getUserParentalRatingsFailure,
  setUserSubprofilePinCodeSuccess,
  setUserSubprofilePinCodeFailure,
  deleteUserSubprofilePinCodeSuccess,
  deleteUserSubprofilePinCodeFailure,
  updateSubscriptionsPending,
  syncWebUserSuccess,
  pauseSubscriptionFailure,
  pauseSubscriptionSuccess,
  getProductOfferingIdsSuccess,
  getProductOfferingIdsFailure,
  checkOrderAndFetchSubscriptionsFailure,
  checkOrderAndFetchSubscriptionsSuccess,
  getUserSubscriptions,
} from "./actions";
import * as Consts from "./consts";
import * as AuthConsts from "../auth/consts";
import {
  ICancelSubscriptionAction,
  IGetProfileAction,
  IDeleteUserDeviceAction,
  IGetUserFavoritesAction,
  IGetUserConsentsAction,
  IGetUserDevicesAction,
  IUpdateProfileAction,
  IUpdateProfileSuccessAction,
  IUpdateUserConsentsAction,
  IDeleteUserDeviceSuccessAction,
  IGetWatchHistoryAction,
  IDeleteWatchHistoryAction,
  IGetUserSubscriptionsAction,
  IUpdateSubscriptionAction,
  IGetUserAvailabilityKeysAction,
  IUpdateSubscriptionSuccessAction,
  ICreateUserSubprofilesAction,
  IGetUserSubprofilesAction,
  IDeleteUserSubprofilesAction,
  IEditUserSubprofileAction,
  ISelectUserSubprofileAction,
  ISelectUserSubprofileSuccessAction,
  IGetUserParentalRatingsAction,
  ISetUserSubprofilePinCodeAction,
  IDeleteUserSubprofilePinCodeAction,
  IGetProfileSuccessAction,
  IGetUserSubscriptionsSuccessAction,
  IPauseSubscriptionAction,
  IPauseSubscriptionSuccessAction,
  IGetProductOfferingIdsAction,
  ICheckOrderAndFetchSubscriptionsAction,
} from "./types";
import { deleteAllMediaAction } from "../media/actions";
import { updateApplicationMenu } from "../configuration/actions";
import { toError, toSuccess } from "../operators";
import * as Types from "../auth/types";
import { ajax } from "rxjs/internal/ajax/ajax";
import { AjaxResponse } from "rxjs/internal/ajax/AjaxResponse";
import { ISessionModel } from "../../providers/DataProvider/RedBeeMedia/models";
import { OrderStatus } from "../../providers/DataProvider/Skymill/models";

const getProfileEpic = (action$: Observable<IGetProfileAction>) =>
  action$.pipe(
    ofType(Consts.GET_PROFILE),
    switchMap(async () => {
      try {
        const user = await DataProvider.getProfile();

        return getProfileSuccess(user);
      } catch (error) {
        return getProfileFailure(error as IErrorModel);
      }
    })
  );

const updateProfileEpic = (action$: Observable<IUpdateProfileAction>) =>
  action$.pipe(
    ofType(Consts.UPDATE_PROFILE),
    switchMap(async (action: IUpdateProfileAction) => {
      try {
        const user = await DataProvider.updateProfile(action.payload);

        return updateProfileSuccess(user);
      } catch (error) {
        return updateProfileFailure(error as IErrorModel);
      }
    })
  );

const getUserFavoritesEpic = (action$: Observable<IGetUserFavoritesAction>) =>
  action$.pipe(
    ofType(Consts.GET_USER_FAVORITES),
    switchMap(async () => {
      try {
        const userAssetsProperties = await DataProvider.getUserFavorites();
        return getUserFavoritesSuccess(userAssetsProperties);
      } catch (error) {
        return getUserFavoritesFailure(error as IErrorModel);
      }
    })
  );

const updateProfileSuccessEpic = (
  action$: Observable<IUpdateProfileSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_PROFILE_SUCCESS),
    switchMap((action: IUpdateProfileSuccessAction) => {
      return of(refreshUser(action.payload));
    })
  );

const getUserDevicesEpic = (action$: Observable<IGetUserDevicesAction>) =>
  action$.pipe(
    ofType(Consts.GET_USER_DEVICES),
    switchMap(async () => {
      try {
        const [devices, playDevices] = await Promise.all([
          DataProvider.getUserDevices(),
          DataProvider.getUserPlayDevices(),
        ]);

        return getUserDevicesSuccess(
          devices.map((device) => ({
            ...device,
            PlayDevice: playDevices.some(
              (playDevice) => playDevice.Id === device.Id
            ),
          }))
        );
      } catch (error) {
        return getUserDevicesFailure(error as IErrorModel);
      }
    })
  );

const deleteUserDeviceEpic = (action$: Observable<IDeleteUserDeviceAction>) =>
  action$.pipe(
    ofType(Consts.DELETE_USER_DEVICE),
    switchMap(async (action: IDeleteUserDeviceAction) => {
      try {
        await DataProvider.deleteUserDevice(action.payload);
        await DataProvider.deleteUserPlayDevice(action.payload);

        return deleteUserDeviceSuccess(action.payload);
      } catch (error) {
        return deleteUserDeviceFailure(error as IErrorModel);
      }
    })
  );

const deleteUserDeviceSuccessEpic = (
  action$: Observable<IDeleteUserDeviceSuccessAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_USER_DEVICE_SUCCESS),
    switchMap(async () =>
      state$.value.user.devices.Data?.length === MAX_DEVICES_LIMIT
        ? getSessionDetails()
        : { type: "NEVER" }
    )
  );

const getUserConsentsEpic = (
  action$: Observable<IGetUserConsentsAction | Types.ISyncWebUserSuccessAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_USER_CONSENTS),
    map(() => state$.value.auth.user?.Locale),
    filter((locale) => !!locale),
    switchMap(async (locale) => {
      try {
        const consents = await DataProvider.getUserConsents(locale);
        return getUserConsentsSuccess(consents);
      } catch (error) {
        return getUserConsentsFailure(error as IErrorModel);
      }
    })
  );

const updateUserConsentsEpic = (
  action$: Observable<IUpdateUserConsentsAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_USER_CONSENTS),
    map((action: IUpdateUserConsentsAction) => action.payload),
    map((payload) => ({ locale: state$.value?.auth?.user?.Locale, payload })),
    switchMap(({ locale, payload }) => {
      return DataProvider.updateUserConsents(payload, locale).pipe(
        map((data: IUserConsentModel[]) => updateUserConsentsSuccess(data)),
        catchError((error: IErrorModel) =>
          of(updateUserConsentsFailure(toErrorModel(error)))
        )
      );
    })
  );

const getWatchHistoryEpic = (action$: Observable<IGetWatchHistoryAction>) =>
  action$.pipe(
    ofType(Consts.GET_WATCH_HISTORY),
    switchMap(async () => {
      try {
        const response = await DataProvider.getUserWatchHistory();

        return getWatchHistorySuccess(response);
      } catch (error) {
        return getWatchHistoryFailure(error as IErrorModel);
      }
    })
  );

const deleteWatchHistoryEpic = (
  action$: Observable<IDeleteWatchHistoryAction>
) =>
  action$.pipe(
    ofType(Consts.DELETE_WATCH_HISTORY),
    switchMap(async (action: IDeleteWatchHistoryAction) => {
      try {
        await DataProvider.deleteUserWatchHistory(action.payload);

        return deleteWatchHistorySuccess(action.payload);
      } catch (error) {
        return deleteWatchHistoryFailure(error as IErrorModel);
      }
    })
  );

const getUserSubscriptionsEpic = (
  action$: Observable<
    | IGetUserSubscriptionsAction
    | IGetProfileSuccessAction
    | IUpdateSubscriptionSuccessAction
    | IPauseSubscriptionSuccessAction
  >
) =>
  action$.pipe(
    ofType(
      Consts.GET_USER_SUBSCRIPTIONS,
      Consts.GET_PROFILE_SUCCESS,
      Consts.PAUSE_SUBSCRIPTION_SUCCESS
    ),
    switchMap(
      async (): Promise<boolean> => await AuthorizationHelper.isAuthenticated()
    ),
    filter((authenticated) => authenticated),
    switchMap(() =>
      DataProvider.getUserSubscriptions().pipe(
        toSuccess(getUserSubscriptionsSuccess),
        toError(getUserSubscriptionsFailure)
      )
    )
  );

const cancelSubscriptionEpic = (
  action$: Observable<ICancelSubscriptionAction>
) =>
  action$.pipe(
    ofType(Consts.CANCEL_SUBSCRIPTION),
    map(({ payload }: ICancelSubscriptionAction) => payload),
    switchMap(({ id, reasons }) =>
      DataProvider.cancelSubscription(id, reasons).pipe(
        toSuccess(updateSubscriptionsPending),
        toError(cancelSubscriptionFailure)
      )
    )
  );

const pauseSubscriptionEpic = (action$: Observable<IPauseSubscriptionAction>) =>
  action$.pipe(
    ofType(Consts.PAUSE_SUBSCRIPTION),
    map(({ payload }: IPauseSubscriptionAction) => payload),
    switchMap(({ id, pausePeriod }) =>
      DataProvider.pauseSubscription(id, pausePeriod).pipe(
        toSuccess(() => pauseSubscriptionSuccess()),
        toError(pauseSubscriptionFailure)
      )
    )
  );

const updateSubscriptionEpic = (
  action$: Observable<IUpdateSubscriptionAction>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_SUBSCRIPTION),
    switchMap((action: IUpdateSubscriptionAction) => {
      return DataProvider.updateSubscription(
        action.id,
        action?.couponCode
      ).pipe(
        map(() => updateSubscriptionsPending()),
        retry({ count: 2, delay: 3000 }),
        catchError((error) =>
          of(updateSubscriptionFailure(error as IErrorModel))
        )
      );
    })
  );

const updateSubscriptionPendingEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_SUBSCRIPTION_PENDING),
    map(() => state$.value.user.subscriptions?.Data?.[0]?.IsActive),
    switchMap((isActive) => {
      return DataProvider.getUserSubscriptions()
        .pipe(
          map((data) => data),
          catchError((err) => of(err))
        )
        .pipe(
          repeat({ count: 5, delay: 3000 }),
          takeUntil(
            action$.pipe(
              ofType(
                Consts.GET_USER_SUBSCRIPTIONS_SUCCESS,
                Consts.GET_USER_SUBSCRIPTIONS_FAILURE
              )
            )
          ),
          filter((data) => data?.[0].IsActive !== isActive),
          toSuccess(getUserSubscriptionsSuccess),
          toError(getUserSubscriptionsFailure)
        );
    })
  );

const updateSubscriptionSuccessEpic = (
  action$: Observable<IUpdateSubscriptionSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_SUBSCRIPTION_SUCCESS),
    switchMap(async (action: IUpdateSubscriptionSuccessAction) => {
      //if (action.payload.IsDiscount) return getUserSubscriptions();
      return { type: "NEVER" };
    })
  );

const getUserAvailabilityKeysEpic = (
  action$: Observable<
    IGetUserAvailabilityKeysAction | IGetUserSubscriptionsSuccessAction
  >
) =>
  action$.pipe(
    ofType(
      Consts.GET_USER_AVAILABILITY_KEYS,
      Consts.GET_USER_SUBSCRIPTIONS_SUCCESS
    ),
    switchMap(async () => {
      try {
        const availabilityKeys = await DataProvider.getUserAvailabilityKeys();
        return getUserAvailabilityKeysSuccess(availabilityKeys);
      } catch (error) {
        return getUserAvailabilityKeysFailure(error as IErrorModel);
      }
    })
  );

const getProductOfferingIdsEpic = (
  action$: Observable<IGetProductOfferingIdsAction>
) =>
  action$.pipe(
    ofType(Consts.GET_PRODUCT_OFFERING_IDS),
    switchMap(() =>
      DataProvider.getProductOfferingIds().pipe(
        map((payload) => {
          offeringIdsService.setOfferingIds(payload);
          return getProductOfferingIdsSuccess(payload);
        }),
        toError(getProductOfferingIdsFailure)
      )
    )
  );

const getUserSubprofilesEpic = (
  action$: Observable<IGetUserSubprofilesAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_USER_SUBPROFILES),
    switchMap(async () => await StorageHelper.getCurrentSubprofileId()),
    switchMap(async (currentSubProfileId) => {
      const currentSubProfileFromRedux =
        state$.value.user.subprofiles.Data?.currentSubprofile;
      const defaultMainSubProfileName =
        state$.value.auth.user?.FullName?.split(" ")[0];
      const countryCode =
        state$.value.configuration.configuration?.DefaultCountry?.toUpperCase();

      return {
        currentSubProfileId,
        currentSubProfileFromRedux,
        defaultMainSubProfileName,
        countryCode,
      };
    }),
    switchMap(
      ({
        currentSubProfileId,
        currentSubProfileFromRedux,
        defaultMainSubProfileName,
        countryCode,
      }) =>
        forkJoin({
          pinCodeId: DataProvider.getUserSubprofilePinCode(),
          subProfiles: DataProvider.getUserSubprofiles(
            currentSubProfileId ?? currentSubProfileFromRedux?.UserId,
            defaultMainSubProfileName,
            countryCode
          ),
        }).pipe(
          map(({ pinCodeId, subProfiles }) => {
            const currentSubProfile =
              subProfiles.length === 1
                ? subProfiles[0]
                : currentSubProfileId && !currentSubProfileFromRedux
                ? subProfiles.find(
                    (subProfile) => subProfile.UserId === currentSubProfileId
                  )
                : currentSubProfileFromRedux;

            if (subProfiles.length > 1) {
              userProfileHelper.sortSubProfiles(subProfiles);
            }

            return { currentSubProfile, pinCodeId, subProfiles };
          }),
          switchMap(async ({ currentSubProfile, pinCodeId, subProfiles }) => {
            if (!currentSubProfile && subProfiles.length > 0) {
              currentSubProfile = subProfiles.filter(
                (subProfile) => subProfile.CurrentProfile === true
              )[0];
            }

            return { currentSubProfile, pinCodeId, subProfiles };
          }),
          map(({ currentSubProfile, pinCodeId, subProfiles }) => {
            return getUserSubprofilesSuccess(
              subProfiles,
              currentSubProfile,
              pinCodeId
            );
          }),
          toError(getUserSubprofilesFailure)
        )
    )
  );

const createUserSubprofileEpic = (
  action$: Observable<ICreateUserSubprofilesAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.CREATE_USER_SUBPROFILES),
    switchMap(async (action: ICreateUserSubprofilesAction) => {
      try {
        const currentSubprofileId =
          state$.value.user.subprofiles.Data?.currentSubprofile?.UserId;
        const defaultMainSubprofileName =
          state$.value.auth.user?.FullName?.split(" ")[0];

        const subprofiles = await DataProvider.createUserSubprofile(
          action.payload,
          currentSubprofileId,
          defaultMainSubprofileName
        );

        return createUserSubprofilesSuccess(subprofiles);
      } catch (error) {
        return createUserSubprofilesFailure(error as IErrorModel);
      }
    })
  );

const updateUserSubprofileEpic = (
  action$: Observable<IEditUserSubprofileAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.EDIT_USER_SUBPROFILES),
    switchMap(async (action: IEditUserSubprofileAction) => {
      try {
        const prevSubprofile =
          state$.value.user.subprofiles.Data?.subprofiles?.find(
            (subprofile) => subprofile.UserId === action.id
          );

        const user = await DataProvider.updateUserSubprofile(
          { ...prevSubprofile, ...action.payload },
          action.id
        );

        return updateUserSubprofileSuccess(user);
      } catch (error) {
        return updateUserSubprofileFailure(error as IErrorModel);
      }
    })
  );

const deleteUserSubprofileEpic = (
  action$: Observable<IDeleteUserSubprofilesAction>
) =>
  action$.pipe(
    ofType(Consts.DELETE_USER_SUBPROFILES),
    switchMap(async (action: IDeleteUserSubprofilesAction) => {
      try {
        await DataProvider.deleteUserSubprofile(action.payload);

        return deleteUserSubprofileSuccess(action.payload);
      } catch (error) {
        return deleteUserSubprofileFailure(error as IErrorModel);
      }
    })
  );

const selectUserSubprofileEpic = (
  action$: Observable<ISelectUserSubprofileAction>
) =>
  action$.pipe(
    ofType(Consts.SELECT_USER_SUBPROFILE),
    switchMap(async (action: ISelectUserSubprofileAction) => {
      try {
        const newTokens = await DataProvider.selectUserSubprofile(
          action.payload.profile.UserId ?? "",
          action.payload.remember
        );

        await StorageHelper.deleteSource();

        return selectUserSubprofileSuccess(
          action.payload.profile,
          newTokens,
          action.payload.isAfterResetPinCode
        );
      } catch (error) {
        return selectUserSubprofileFailure(error as IErrorModel);
      }
    })
  );

const selectUserSubprofileSuccessEpic = (
  action$: Observable<ISelectUserSubprofileSuccessAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_USER_SUBPROFILE_SUCCESS),
    mergeMap((action) => {
      const isKid = action.payload.profile.Child;
      const newTokens = action.payload.tokens;
      const result: AnyAction[] = [
        updateTokens(newTokens),
        deleteAllMediaAction(),
        updateApplicationMenu(isKid),
      ];

      if (!state$.value.auth.session?.IsOverTheDeviceLimit) {
        result.push(getUserFavorites());
      }

      return result;
    })
  );

const selectUserHomePageEpic = (
  action$: Observable<ISelectUserSubprofileSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.SELECT_USER_SUBPROFILE_SUCCESS),
    map(({ payload }) => {
      return {
        InitialHomePage: payload.profile.InitialHomePage,
        isAfterResetPinCode: payload.isAfterResetPinCode,
      };
    }),
    tap(({ InitialHomePage, isAfterResetPinCode }) => {
      eventService.publish(
        Consts.SWITCH_USER_INITIAL_HOME_PAGE_EVENT,
        isAfterResetPinCode
          ? `${ROUTES.SETTINGS_SUBPROFILE_SCREEN}`
          : InitialHomePage
      );
    }),
    map(() => ({ type: Consts.SWITCH_USER_INITIAL_HOME_PAGE_EVENT }))
  );

const getUserParentalRatingsEpic = (
  action$: Observable<IGetUserParentalRatingsAction>
) =>
  action$.pipe(
    ofType(Consts.GET_USER_PARENTAL_RATINGS),
    map((action) => action.payload),
    switchMap((countryCode) =>
      DataProvider.getUserParentalRatings(countryCode).pipe(
        toSuccess(getUserParentalRatingsSuccess),
        toError(getUserParentalRatingsFailure)
      )
    ),
    toError(getUserParentalRatingsFailure)
  );

const setUserSubprofilePinCodeEpic = (
  action$: Observable<ISetUserSubprofilePinCodeAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.SET_USER_SUBPROFILE_PIN_CODE),
    switchMap(async (action: ISetUserSubprofilePinCodeAction) => {
      try {
        const pinCodeId = state$.value.user.subprofiles.Data?.auth?.PinCodeId;

        const updatedPinCodeId = await DataProvider.setUserSubprofilePinCode(
          action.payload,
          pinCodeId
        );

        return setUserSubprofilePinCodeSuccess(updatedPinCodeId);
      } catch (error) {
        return setUserSubprofilePinCodeFailure(error as IErrorModel);
      }
    })
  );

const deleteUserSubprofilePinCodeEpic = (
  action$: Observable<IDeleteUserSubprofilePinCodeAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_USER_SUBPROFILE_PIN_CODE),
    switchMap(async () => {
      try {
        const pinCodeId = state$.value.user.subprofiles.Data?.auth?.PinCodeId;

        if (!pinCodeId) throw new Error("Pin code does not exist!");

        await DataProvider.deleteUserSubprofilePinCode(pinCodeId);

        return deleteUserSubprofilePinCodeSuccess();
      } catch (error) {
        return deleteUserSubprofilePinCodeFailure(error as IErrorModel);
      }
    })
  );

const synWebUserPendingEpic = (
  action$: Observable<Types.ISyncWebUserPendingAction>
) =>
  action$.pipe(
    ofType(AuthConsts.SYNC_WEB_USER_PENDING),
    switchMap(() =>
      from(ajax({ url: ROUTES.API_SYNC_USER, method: "POST" })).pipe(
        map(
          ({ response }: AjaxResponse<any>) =>
            response as {
              session: ISessionModel;
              availabilityKeys: IUserAvailabilityKeysModel;
              currentSubProfile: IUserSubprofileModel;
              subProfiles: IUserSubprofileModel[];
              subscriptions: IUserSubscriptionModel[];
              pinCode: string;
              favorites: IUserFavoritesModel[];
              customer: IUserInfoModel;
              products: string[];
            }
        )
      )
    ),
    tap(({ products }) => {
      offeringIdsService.setOfferingIds(products);
    }),
    map(
      ({
        availabilityKeys,
        subProfiles,
        subscriptions,
        currentSubProfile,
        session,
        pinCode,
        favorites,
        customer,
      }) => {
        return syncWebUserSuccess(
          subscriptions,
          availabilityKeys,
          currentSubProfile,
          subProfiles,
          session,
          pinCode,
          favorites,
          customer
        );
      }
    ),
    catchError(() => {
      return of(Actions.syncUserFailure());
    })
  );

const checkOrderAndFetchSubscriptionsEpic = (
  action$: Observable<ICheckOrderAndFetchSubscriptionsAction>
) =>
  action$.pipe(
    ofType(Consts.CHECK_ORDER_AND_FETCH_SUBSCRIPTIONS),
    switchMap((action) =>
      defer(() => DataProvider.getOrderStatus(action.orderId)).pipe(
        repeat({ delay: 2000, count: 15 }),
        takeWhile(
          (response) =>
            response.Order.Status === OrderStatus.DRAFT ||
            response.Order.Status === OrderStatus.PENDING,
          true
        ),
        last(),
        mergeMap((response) => {
          if (
            response.Order.Status === OrderStatus.DRAFT ||
            response.Order.Status === OrderStatus.PENDING
          ) {
            return of(
              checkOrderAndFetchSubscriptionsFailure({
                Message: "Failed to get order status",
              })
            );
          }
          return of(
            checkOrderAndFetchSubscriptionsSuccess(),
            getUserSubscriptions()
          );
        }),
        catchError(() =>
          of(
            checkOrderAndFetchSubscriptionsFailure({
              Message: "Failed to get order status",
            })
          )
        )
      )
    )
  );

export const userEpics = [
  synWebUserPendingEpic,
  getUserFavoritesEpic,
  getProfileEpic,
  updateProfileEpic,
  updateProfileSuccessEpic,
  getUserDevicesEpic,
  deleteUserDeviceEpic,
  deleteUserDeviceSuccessEpic,
  getUserConsentsEpic,
  updateUserConsentsEpic,
  getWatchHistoryEpic,
  deleteWatchHistoryEpic,
  getUserSubscriptionsEpic,
  updateSubscriptionSuccessEpic,
  cancelSubscriptionEpic,
  updateSubscriptionEpic,
  getUserAvailabilityKeysEpic,
  getUserSubprofilesEpic,
  createUserSubprofileEpic,
  updateUserSubprofileEpic,
  deleteUserSubprofileEpic,
  selectUserSubprofileEpic,
  selectUserSubprofileSuccessEpic,
  selectUserHomePageEpic,
  getUserParentalRatingsEpic,
  setUserSubprofilePinCodeEpic,
  deleteUserSubprofilePinCodeEpic,
  updateSubscriptionPendingEpic,
  pauseSubscriptionEpic,
  getProductOfferingIdsEpic,
  checkOrderAndFetchSubscriptionsEpic,
];
