/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IErrorModel,
  IPaymentInvoiceModel,
  IPaymentOfferModel,
} from "../../models";
import * as Consts from "./consts";
import * as Types from "./types";
import {
  IGetUserSwitchOffersAction,
  IGetUserSwitchOffersFailureAction,
  IGetUserSwitchOffersSuccessAction,
  ISwitchSubscriptionAction,
  ISwitchSubscriptionFailureAction,
  ISwitchSubscriptionSuccessAction,
  ICancelSubscriptionSwitchAction,
  ICancelSubscriptionSwitchSuccessAction,
  ICancelSubscriptionSwitchFailureAction,
} from "./types";

export const getOffers = (): Types.IGetOffersAction => {
  return {
    type: Consts.GET_OFFERS,
  };
};

export const getOffersSuccess = (
  offers: IPaymentOfferModel[]
): Types.IGetOffersSuccessAction => {
  return {
    type: Consts.GET_OFFERS_SUCCESS,
    payload: { offers },
  };
};

export const getOffersFailure = (
  error?: IErrorModel
): Types.IGetOffersErrorAction => {
  return {
    error,
    type: Consts.GET_OFFERS_FAILURE,
  };
};

export const getPaymentInvoices = (): Types.IGetPaymentInvoicesAction => {
  return {
    type: Consts.GET_PAYMENT_INVOICES,
  };
};

export const getPaymentInvoicesSuccess = (
  payload: IPaymentInvoiceModel[]
): Types.IGetPaymentInvoicesSuccessAction => {
  return {
    type: Consts.GET_PAYMENT_INVOICES_SUCCESS,
    payload,
  };
};

export const getPaymentInvoicesFailure = (
  error?: IErrorModel
): Types.IGetPaymentInvoicesErrorAction => {
  return {
    error,
    type: Consts.GET_PAYMENT_INVOICES_FAILURE,
  };
};

export const getSwitchOffer = (
  subscription_id: number
): IGetUserSwitchOffersAction => {
  return {
    type: Consts.GET_SWITCH_OFFER,
    payload: { subscription_id },
  };
};

export const getUserSwitchOffersSuccess = (
  payload: IPaymentOfferModel[]
): IGetUserSwitchOffersSuccessAction => {
  return {
    type: Consts.GET_SWITCH_OFFER_SUCCESS,
    payload,
  };
};

export const getUserSwitchOffersFailure = (
  error?: IErrorModel
): IGetUserSwitchOffersFailureAction => {
  return {
    type: Consts.GET_SWITCH_OFFER_FAILURE,
    error,
  };
};

export const switchSubscription = (data: {
  subscription_id: number;
  to_offer_id: string | null;
}): ISwitchSubscriptionAction => {
  return {
    type: Consts.SWITCH_SUBSCRIPTION,
    data,
  };
};
export const switchSubscriptionSuccess = (
  payload: IPaymentOfferModel[]
): ISwitchSubscriptionSuccessAction => {
  return {
    type: Consts.SWITCH_SUBSCRIPTION_SUCCESS,
    payload,
  };
};
export const switchSubscriptionFailure = (
  error?: IErrorModel
): ISwitchSubscriptionFailureAction => {
  return {
    error,
    type: Consts.SWITCH_SUBSCRIPTION_FAILURE,
  };
};

export const cancelSubscriptionSwitch = (
  subscription_id: number
): ICancelSubscriptionSwitchAction => {
  return {
    type: Consts.CANCEL_SUBSCRIPTION_SWITCH,
    subscription_id,
  };
};

export const cancelSubscriptionSwitchSuccess = (
  payload: IPaymentOfferModel[]
): ICancelSubscriptionSwitchSuccessAction => {
  return {
    type: Consts.CANCEL_SUBSCRIPTION_SWITCH_SUCCESS,
    payload,
  };
};

export const cancelSubscriptionSwitchFailure = (
  error?: IErrorModel
): ICancelSubscriptionSwitchFailureAction => {
  return {
    error,
    type: Consts.CANCEL_SUBSCRIPTION_SWITCH_FAILURE,
  };
};

export const PaymentActions = {
  getOffers,
  getOffersSuccess,
  getOffersFailure,
  getPaymentInvoices,
  getPaymentInvoicesSuccess,
  getPaymentInvoicesFailure,
  switchSubscription,
  switchSubscriptionSuccess,
  switchSubscriptionFailure,
  getSwitchOffer,
  getUserSwitchOffersSuccess,
  getUserSwitchOffersFailure,
  cancelSubscriptionSwitch,
  cancelSubscriptionSwitchSuccess,
  cancelSubscriptionSwitchFailure,
};
