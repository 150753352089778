/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { IErrorModel } from "../../models";
import { DataProvider } from "../../providers";
import * as Actions from "./actions";
import * as Consts from "./consts";
import { ISearchPeopleAction } from "./types";

const searchPeopleEpic = (action$: Observable<ISearchPeopleAction>) =>
  action$.pipe(
    ofType(Consts.SEARCH_PEOPLE),
    switchMap(async (action: ISearchPeopleAction) => {
      try {
        const response = await DataProvider.searchPeople(action.filter);
        response.Filter = action.filter;

        return Actions.searchPeopleSuccess(response);
      } catch (error) {
        return Actions.searchPeopleFailure(error as IErrorModel);
      }
    })
  );

export const peopleEpics = [searchPeopleEpic];
