/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppConfig, HTTP_METHOD } from "@nf/constants";
import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "../../../../services";
import { IParticipantSearchListModel } from "../models/Participant";

export class PeopleService {
  get url(): string {
    return `/v3/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}`;
  }

  public search = async (
    pageSize: number = 30,
    pageNumber: number = 1,
    query?: string,
    sort?: string
  ): Promise<Observable<IParticipantSearchListModel>> => {
    const params = {
      pageSize,
      pageNumber,
      query,
      sort,
      fieldSet: "ALL",
    };

    return new Observable(
      (observer: Observer<IParticipantSearchListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/content/search/participant/query/${query}`,
        })
    );
  };
}
