import { useInitialProfileHomePageUrl } from "@nf/common";
import { useRouter } from "next/router";
import { useRoutePathBuild } from "./useRoutePathBuild";

export const useGoBackOrHome = () => {
  const router = useRouter();
  const routePrefix = useRoutePathBuild();
  const initialHomePage = useInitialProfileHomePageUrl();

  const goBackOrHome = () => {
    if (window.history.length > 2) {
      router.back();
    } else {
      router.push(`${routePrefix}${initialHomePage}`);
    }
  };

  return goBackOrHome;
};
