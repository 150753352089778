/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useSelector } from "../store";

export const useSubscriptionsSelector = () => {
  const {
    Data: subscriptions,
    Error: error,
    IsProcessing: isLoading,
    Completed,
  } = useSelector((state) => state.user.subscriptions);

  return { subscriptions, error, isLoading, Completed };
};
