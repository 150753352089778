import { IMediaCategoryListModel } from "@nf/common";
import { useMemo } from "react";

export const useGetCategoryById = (
  mediaCategories: IMediaCategoryListModel,
  categoryId?: string | string[]
) => {
  const activeCategory = useMemo(() => {
    return (
      mediaCategories?.Entities.find(
        (entity) => entity.CategoryId === categoryId
      ) || mediaCategories.Entities[0]
    );
  }, [mediaCategories?.Entities, categoryId]);

  return activeCategory;
};
