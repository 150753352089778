/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { getMediaListId } from "../../helpers";
import { SourceType } from "../../enums";
import { IMediaModel } from "../../models";
import * as Consts from "./consts";
import { IMediaState, MediaActionsTypes } from "./types";

export const initialState: IMediaState = {
  action: undefined,
  channel: {},
  media: {},
  mediaList: {},
  mediaCategories: {
    Entities: [],
    TotalCount: 0,
  },
  mediaSearch: {
    Entities: [],
    TotalCount: 0,
  },
  multiSearch: {
    Media: {
      Entities: [],
      TotalCount: 0,
    },
    People: {
      Entities: [],
      TotalCount: 0,
    },
  },
};

export const mediaReducer = (
  state = initialState,
  action: MediaActionsTypes
): IMediaState => {
  state.action = action;

  switch (action.type) {
    case Consts.GET_MEDIA: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.options.MediaId]: {
            Media: state.media[action.options.MediaId]?.Media,
            IsLoading: true,
          },
        },
      };
    }
    case Consts.GET_MEDIA_SUCCESS:
    case Consts.GET_MEDIA_PROGRESS_SUCCESS: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.payload.Id]: {
            ...state.media[action.payload.Id],
            Media: action.payload,
            Error: undefined,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_FAILURE: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.mediaId]: {
            ...state.media[action.mediaId],
            Media: undefined,
            Error: action.error,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_PROGRESS: {
      return {
        ...state,
        media: {
          ...state.media,
          [action.payload.Id]: {
            Media: {
              ...state.media[action.payload.Id]?.Media,
              Id: action.payload.Id,
            },
            IsLoading: true,
          },
        },
      };
    }
    case Consts.SEARCH_MEDIA: {
      let mediaList = state.mediaList[Consts.MEDIA_LIST_SEARCH_KEY];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [Consts.MEDIA_LIST_SEARCH_KEY]: {
            ...mediaList,
            Filter: action.filter,
            IsLoading: true,
          },
        },
        mediaSearch: {
          ...state.mediaSearch,
          Filter: action.filter,
          Error: undefined,
          IsLoading: true,
        },
      };
    }
    case Consts.SEARCH_MEDIA_SUCCESS: {
      const pageNumber = action.payload?.Filter?.PageNumber;
      let entities: IMediaModel[] = [];

      if (pageNumber === 1) {
        entities = action.payload.Entities;
      } else if (pageNumber && pageNumber > 1) {
        // entities = [
        //     ...(state.mediaList[Consts.MEDIA_LIST_SEARCH_KEY]?.Entities || []),
        //     ...action.payload.Entities,
        // ];
        entities = [...state.mediaSearch.Entities, ...action.payload.Entities];
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [Consts.MEDIA_LIST_SEARCH_KEY]: {
            ...state.media[Consts.MEDIA_LIST_SEARCH_KEY],
            SourceType: SourceType.MediaList,
            Filter: action.payload.Filter,
            TotalCount: action.payload.TotalCount,
            Entities: entities,
            Error: undefined,
            IsLoading: false,
          },
        },
        mediaSearch: {
          ...state.mediaSearch,
          ...action.payload,
          Suggestions:
            action.payload.Suggestions ?? state.mediaSearch.Suggestions,
          Entities: entities,
          Error: undefined,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_MEDIA_FAILURE: {
      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [Consts.MEDIA_LIST_SEARCH_KEY]: {
            ...state.media[Consts.MEDIA_LIST_SEARCH_KEY],
            SourceType: SourceType.MediaList,
            Entities: [],
            TotalCount: 0,
            Error: action.error,
            IsLoading: false,
          },
        },
        mediaSearch: {
          ...state.mediaSearch,
          Entities: [],
          TotalCount: 0,
          Error: action.error,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_MULTI: {
      return {
        ...state,
        multiSearch: {
          ...state.multiSearch,
          Error: undefined,
          IsLoading: true,
        },
      };
    }
    case Consts.SEARCH_MULTI_SUCCESS: {
      return {
        ...state,
        multiSearch: {
          ...state.multiSearch,
          ...action.payload,
          Error: undefined,
          IsLoading: false,
        },
      };
    }
    case Consts.SEARCH_MULTI_FAILURE: {
      return {
        ...state,
        multiSearch: {
          ...state.mediaSearch,
          Media: {
            Entities: [],
            TotalCount: 0,
          },
          People: {
            Entities: [],
            TotalCount: 0,
          },
          Error: action.error,
          IsLoading: false,
        },
      };
    }
    case Consts.GET_MEDIA_LIST:
    case Consts.GET_MEDIA_LIST_FROM_CACHE: {
      let mediaList = state.mediaList[action.options.MediaListId];

      if (!mediaList) {
        mediaList = {
          Entities: [],
          TotalCount: 0,
          SourceType: SourceType.MediaList,
        };
      }

      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.options.MediaListId]: {
            ...mediaList,
            Filter: action.options,
            IsLoading: true,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST_SUCCESS:
    case Consts.GET_MEDIA_LIST_FROM_CACHE_SUCCESS: {
      const pageNumber =
        action.payload?.Filter?.PageNumber || action?.payload?.PageNumber;
      let entities: IMediaModel[] = [];

      if (pageNumber === 1) {
        entities = action.payload.Entities;
      } else if (pageNumber && pageNumber > 1) {
        entities = [
          ...(state.mediaList[action.mediaListId]?.Entities || []),
          ...action.payload.Entities,
        ];
      }
      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.mediaListId]: {
            ...state.media[action.mediaListId],
            ...action.payload,
            Entities: entities,
            Error: undefined,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_LIST_FAILURE:
    case Consts.GET_MEDIA_LIST_FROM_CACHE_FAILURE: {
      return {
        ...state,
        mediaList: {
          ...state.mediaList,
          [action.mediaListId]: {
            ...state.media[action.mediaListId],
            SourceType: SourceType.MediaList,
            Entities: [],
            TotalCount: 0,
            Error: action.error,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_MEDIA_CATEGORIES: {
      return {
        ...state,
        mediaCategories: {
          ...state.mediaCategories,
          IsLoading: true,
          Error: undefined,
        },
      };
    }
    case Consts.GET_MEDIA_CATEGORIES_SUCCESS: {
      return {
        ...state,
        mediaCategories: {
          ...action.payload,
          IsLoading: false,
        },
      };
    }
    case Consts.GET_MEDIA_CATEGORIES_FAILURE: {
      return {
        ...state,
        mediaCategories: {
          ...state.mediaCategories,
          IsLoading: false,
          Error: action.error,
        },
      };
    }
    case Consts.GET_CHANNEL_ON_NOW: {
      return {
        ...state,
        channel: {
          ...state.channel,
          [action.channelId]: {
            Media: state.channel[action.channelId]?.Media,
            IsLoading: true,
          },
        },
      };
    }
    case Consts.GET_CHANNEL_ON_NOW_SUCCESS: {
      const channelId = action.payload.Id;
      return {
        ...state,
        channel: {
          ...state.channel,
          [channelId]: {
            Media: action.payload,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.GET_CHANNEL_ON_NOW_FAILURE: {
      return {
        ...state,
        channel: {
          ...state.channel,
          [action.channelId]: {
            ...state.channel[action.channelId],
            Media: undefined,
            Error: action.error,
            IsLoading: false,
          },
        },
      };
    }
    case Consts.DELETE_ALL_MEDIA: {
      return {
        ...state,
        media: {},
        mediaList: {},
      };
    }
    case Consts.REMOVE_FROM_FAVORITES_LIST: {
      const mediaListId = getMediaListId(
        state.mediaList,
        SourceType.Favourites
      );

      if (mediaListId) {
        const favoritesList = state.mediaList[mediaListId];
        favoritesList.Entities = favoritesList.Entities.filter(
          (asset) => asset.Id !== action.mediaId
        );

        return {
          ...state,
          mediaList: {
            ...state.mediaList,
            [mediaListId]: {
              ...favoritesList,
              TotalCount: favoritesList.Entities.length,
            },
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case Consts.ADD_TO_FAVORITES_LIST: {
      const mediaListId = getMediaListId(
        state.mediaList,
        SourceType.Favourites
      );

      if (mediaListId) {
        const favoritesList = state.mediaList[mediaListId];
        favoritesList.Entities.unshift(action.media);

        return {
          ...state,
          mediaList: {
            ...state.mediaList,
            [mediaListId]: {
              ...favoritesList,
              TotalCount: favoritesList.Entities.length,
            },
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
};
