import { IMediaModel, useUserSelector } from "@nf/common";
import { useEffect } from "react";
import { useReplacePage } from "./useReplacePage";
import { ROUTES } from "@nf/constants";

export const useMediaParentalRatingGuard = (media?: IMediaModel) => {
  const user = useUserSelector();
  const currentSubProfile = user?.subprofiles?.Data?.currentSubprofile;
  const replaceTo = useReplacePage();

  useEffect(() => {
    if (
      media?.MediaAgeRestrictionValueMin &&
      currentSubProfile?.Child &&
      currentSubProfile?.ProfileType
    ) {
      const maxRating = currentSubProfile.ProfileType?.split(",")
        .map((parentalRating) => parseInt(parentalRating.split(":")[1]))
        .reduce((acc, cur) => Math.max(acc, cur), 0);

      if (parseInt(media.MediaAgeRestrictionValueMin) > maxRating) {
        console.warn(
          "User is not allowed to play this media",
          "ageRestriction",
          media.MediaAgeRestrictionValueMin,
          "currentRating",
          maxRating
        );
        replaceTo(ROUTES.SELECT_PROFILE_SCREEN);
      }
    }
  }, [
    currentSubProfile?.Child,
    currentSubProfile?.ProfileType,
    media?.MediaAgeRestrictionValueMin,
    replaceTo,
  ]);
};
