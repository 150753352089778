export const getRandomString = (count = 30): string => {
  const randomString = (length: number, chars: string) => {
    let result = "";
    // nosemgrep
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)]; // nosemgrep
    return result;
  };

  return randomString(count, "0123456789abcdefghijklmnopqrstuvwxyz");
};
