/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  COUNTRY_LIST,
  CountryCode,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LOCALE,
  LOCALE_LIST,
} from "@nf/constants";
import {
  IConfigurationModel,
  IConfigurationTranslationsModel,
} from "../models";

export class LocaleHelper {
  static getLanguageFromLocale(locale: string): string {
    return locale.split("_")[0];
  }

  static getLocaleFromLanguage(language: string): string | undefined {
    return LOCALE_LIST.find((locale) => locale.startsWith(language));
  }

  static getCountryFromLocale(locale: string): string {
    if (locale.includes("_")) {
      return locale.split("_")[1];
    }
    return locale.split("-")[1];
  }

  static filterTranslations(
    translations: IConfigurationTranslationsModel,
    languages: string[]
  ): string[] | [] {
    return translations.filter((translation) =>
      languages.includes(this.getLanguageFromLocale(translation))
    );
  }

  static getSupportedCountry(countryCode: string) {
    if (COUNTRY_LIST.includes(countryCode.toUpperCase() as CountryCode)) {
      return countryCode.toUpperCase();
    }
    return DEFAULT_COUNTRY_CODE;
  }

  static getLocalizedAuthUrl(url: string, config?: IConfigurationModel) {
    const country = this.getSupportedCountry(
      config?.Country || DEFAULT_COUNTRY_CODE
    )?.toLowerCase();
    const language = this.getLanguageFromLocale(
      config?.Language || DEFAULT_LOCALE
    );
    const path = url
      .replace("{country}", country)
      .replace("{language}", language);

    return path;
  }
}
