/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { AxiosSubscriber } from "../../../../services";
import { HTTP_METHOD } from "@nf/constants";
import { Observable, Observer } from "rxjs";
import { AppConfig } from "@nf/constants";
import { ITranslationListModel, ITranslationModel } from "../../../../models";

export class TranslationService {
  public getTranslationList = (): Observable<ITranslationListModel> =>
    new Observable(
      (observer: Observer<ITranslationListModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            timeout: 60000,
            headers: {
              Authorization: "",
            },
          },
          method: HTTP_METHOD.GET,
          url: `${AppConfig.TranslationsUrl}/api/translations`,
        })
    );

    public getTranslation = (language: string): Observable<ITranslationModel> =>
      new Observable(
        (observer: Observer<ITranslationModel>) =>
          new AxiosSubscriber(observer, {
            axiosConfig: {
              timeout: 60000,
              headers: {
                Authorization: "",
              },
            },
            method: HTTP_METHOD.GET,
            url: `${AppConfig.TranslationsUrl}/${language}.json`,
          })
      );
}
