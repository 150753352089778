/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { Observable, Observer } from "rxjs";
import { AxiosSubscriber } from "./AxiosSubscriber";

import { HTTP_METHOD } from "@nf/constants";
import {
  ICanceledSubscriptionModel,
  IConsentListModel,
  ICustomerModel,
  ISubscriptionListModel,
  IUpdatedSubscriptionModel,
  IUserConsentModel,
  IOfferSwitchListModelResponse,
  ISubscriptionSwitchModelResponse,
  IConsentModel,
  ICancellationSubscriptionReasons,
  ICancelSubscriptionSwitchModelResponse,
} from "../models";

export class UserService {
  get url(): string {
    return "/v1";
  }

  private getMethod<T>(url: string) {
    return new Observable(
      (observer: Observer<T>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/${url}`,
        })
    );
  }

  private putMethod<T, P>(url: string, data: P) {
    return new Observable(
      (observer: Observer<T>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/${url}`,
        })
    );
  }

  private deleteMethod<T, P>(url: string, data: P) {
    return new Observable(
      (observer: Observer<T>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/${url}`,
          data,
        })
    );
  }

  public availableConsents = (): Observable<
    IConsentListModel<IConsentModel[]>
  > => this.getMethod<IConsentListModel<IConsentModel[]>>("available-consents");

  public consents = (): Observable<IConsentListModel<IUserConsentModel[]>> =>
    this.getMethod<IConsentListModel<IUserConsentModel[]>>("consents");

  public updateUserConsents = (
    data: IConsentListModel<Partial<IConsentModel>[]>
  ): Observable<void> =>
    this.putMethod<void, IConsentListModel<Partial<IConsentModel>[]>>(
      "consents",
      data
    );

  public profile = (): Observable<ICustomerModel> =>
    this.getMethod<ICustomerModel>("customer");

  public updateProfile = (
    data: Partial<ICustomerModel>
  ): Observable<ICustomerModel> =>
    this.putMethod<ICustomerModel, Partial<ICustomerModel>>("customer", data);

  public updateEmail = (email: string): Observable<ICustomerModel> =>
    new Observable(
      (observer: Observer<ICustomerModel>) =>
        new AxiosSubscriber(observer, {
          data: { email },
          method: HTTP_METHOD.PATCH,
          url: `${this.url}/email`,
        })
    );

  public subscriptions = (): Observable<ISubscriptionListModel> =>
    this.getMethod<ISubscriptionListModel>("subscriptions");

  public cancelSubscription = (
    id: number,
    reason?: { cancellation_answers_v2: string[]; cancellation_reasons_v2: ICancellationSubscriptionReasons }
  ): Observable<ICanceledSubscriptionModel> =>
    this.deleteMethod<
      ICanceledSubscriptionModel,
      | { cancellation_answers_v2: string[]; cancellation_reasons_v2: ICancellationSubscriptionReasons }
      | undefined
    >(`subscriptions/${id}`, reason);

  public updateSubscription = (
    id: number,
    data: { status?: string; coupon_code?: string }
  ): Observable<IUpdatedSubscriptionModel> =>
    new Observable(
      (observer: Observer<IUpdatedSubscriptionModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.PATCH,
          url: `${this.url}/subscriptions/${id}`,
          data,
        })
    );

  public offerSwitches = (
    subscription_id: number
  ): Observable<IOfferSwitchListModelResponse> =>
    new Observable(
      (observer: Observer<IOfferSwitchListModelResponse>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/offer-switches?subscription_id=${subscription_id}`,
        })
    );

  public switchSubscription = (data: {
    subscription_id: number;
    to_offer_id: string | null;
  }): Observable<ISubscriptionSwitchModelResponse> =>
    new Observable(
      (observer: Observer<ISubscriptionSwitchModelResponse>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          url: `${this.url}/subscription-switches`,
          data,
        })
    );

    public cancelSubscriptionSwitch = (
      subscription_id: number
     ): Observable<ICancelSubscriptionSwitchModelResponse> =>
      new Observable(
        (observer: Observer<ICancelSubscriptionSwitchModelResponse>) => {
          const data = {
            subscription_id, 
            to_offer_id: null
          };

            return new AxiosSubscriber(observer, {
            method: HTTP_METHOD.POST,
            url: `${this.url}/subscription-switches`,
            data,
          })}
      );
}
