import { useMemo } from "react";
import { DeviceHelper } from "@nf/common";
import { ROUTES } from "@nf/constants";
import { useGeneratePath } from "./useGeneratePath";
import { PAYMENT_PARAM, SUCCESS_VALUE } from "constants/param";

export const useAssignSubscriptionSuccessUrl = () => {
  const generatePath = useGeneratePath();
  const isMobile = DeviceHelper.isMobile();

  return useMemo(() => {
    const targetRoute = isMobile ? ROUTES.WELCOME : ROUTES.HOME;
    const queryParam = `${PAYMENT_PARAM}=${SUCCESS_VALUE}`;

    return `${generatePath(targetRoute)}?${queryParam}`;
  }, [isMobile, generatePath]);
};
