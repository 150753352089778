/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import dayjs from "dayjs";
import { ANONYMOUS_ID } from "@nf/constants";
import { ITokenModel, IUserInfoModel } from "../models";
import { StorageHelper } from "./storageHelper";

export class AuthorizationHelper {
  static async isLoggedIn(): Promise<boolean> {
    const isAnonymous = await this.isAnonymous();
    const session = await this.session();
    return !(isAnonymous || !session);
  }

  static async isAuthenticated(): Promise<boolean> {
    const session = StorageHelper.getSession();

    if (!session) return false;
    return session && session.Token !== undefined;
  }

  static async isAnonymous(): Promise<boolean> {
    const user = await StorageHelper.getUser();

    return !user || user.Id === ANONYMOUS_ID;
  }

  static async session(): Promise<ITokenModel | undefined> {
    return await StorageHelper.getSession();
  }

  static async user(): Promise<IUserInfoModel> {
    return await StorageHelper.getUser();
  }
}
