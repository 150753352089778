import { CookieManager } from "../services/CookieManager/CookieManager";
import { DEVICE_KEY, SESSION_KEY } from "@nf/constants";
import { ITokenModel, IUserInfoModel, UsersStorage } from "../models";
import { authStorage } from "../services";

const expireInNextWeek: Date = new Date(
  new Date().setDate(new Date().getDate() + 7)
);

export const CookieHelper = {
  getSession: (): Promise<ITokenModel | undefined> => {
    try {
      const session = CookieManager.getCookie(SESSION_KEY) as string;
      const sessionObject = JSON.parse(session);
      return Promise.resolve(sessionObject);
    } catch (e) {
      return Promise.resolve(undefined);
    }
  },

  setSession: (value?: ITokenModel): Promise<void> => {
    CookieManager.add(SESSION_KEY, value, expireInNextWeek);
    return Promise.resolve();
  },

  deleteSession: () => {
    return Promise.resolve();
  },

  getDevice: () => {
    try {
      const device = CookieManager.getCookie(DEVICE_KEY) as string;
      const parsedDevice = JSON.parse(device);
      return Promise.resolve(parsedDevice);
    } catch {
      return Promise.resolve(undefined);
    }
  },

  setDevice: (value: string) => {
    const expiresInYear: Date = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    CookieManager.add(DEVICE_KEY, value, expiresInYear);
    return Promise.resolve();
  },

  setUser: ({ Id }: IUserInfoModel) => {
    return Promise.resolve();
  },

  getUser: (): Promise<string | undefined> => {
    return Promise.resolve(authStorage.getUserId());
  },

  deleteUser: () => {
    return Promise.resolve();
  },
  getUsers: () => {
    return Promise.resolve(authStorage.getUsers());
  },
  setUsers: (value: UsersStorage) => {
    return Promise.resolve();
  },
};
