/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AuthResult,
  LoginCodeStatusTypes,
  PlatformType,
  UserConsentsState,
  UserSubscriptionStatus,
} from "../../../enums";
import {
  EmptyObject,
  IAuthRequestModel,
  IAuthResponseModel,
  IAuthSignUpRequestModel,
  IAuthSignUpResponseModel,
  IAuthViaTokenRequestModel,
  ICreatePaymentOrderRequestModel,
  IErrorModel,
  IOrderStatusModel,
  IPaymentDetailsModel,
  IPaymentInvoiceModel,
  IPaymentOfferModel,
  IPaymentOrderModel,
  ITokenModel,
  IUserConsentModel,
  IUserInfoModel,
  IUserModel,
  IUserSubscriptionModel,
  IValidCouponModel,
  ICreatePaymentDetailsModel,
} from "../../../models";
import { authStorage, HttpFactory } from "../../../services";
import {
  AuthService,
  HttpRequestFulfilledInterceptor,
  HttpRequestRejectedInterceptor,
  HttpResponseFulfilledInterceptor,
  HttpResponseRejectedInterceptor,
  PauseSubscriptionService,
  PaymentsService,
  UserService,
} from "./services";
import { lastValueFrom, mergeMap, Observable, shareReplay } from "rxjs";
import { ModelsMapperHelper } from "./helpers";
import { AppConfig, DEFAULT_LOCALE } from "@nf/constants";
import {
  ICancellationSubscriptionReasons,
  ICancelSubscriptionSwitchModelResponse,
  IChangePasswordModel,
  IChangePasswordResponseModel,
  ICreateOrderRequestModel,
  ICreatePaymentDetailsRequestModel,
  ICustomerModel,
  IGetLoginTokenFromCodeModel,
  ILoginCodeModel,
  ILoginCodeResponseModel,
  ILoginViaTokenRequestModel,
  IOfferSwitchModel,
  IUpdatedSubscriptionModel,
  IVerifyLoginCodeResponseModel,
} from "./models";
import { LocaleHelper, StorageHelper } from "../../../helpers";
import { map, switchMap } from "rxjs/operators";
import { toError, toSuccess } from "../../../store/operators";
import { AjaxResponse } from "rxjs/internal/ajax/AjaxResponse";

export class SkymillDataProvider {
  authService = new AuthService();
  userService = new UserService();
  httpFactory = HttpFactory.getInstance("skymill");
  paymentsService = new PaymentsService();
  pauseSubscriptionService = new PauseSubscriptionService();

  initHttpFactory() {
    this.httpFactory.baseUrl = AppConfig.ApiAuthUrl;
    this.httpFactory.addRequestInterceptor(
      "HttpRequestInterceptor",
      HttpRequestFulfilledInterceptor,
      HttpRequestRejectedInterceptor
    );
    this.httpFactory.addResponseInterceptor(
      "HttpResponseInterceptor",
      HttpResponseFulfilledInterceptor,
      HttpResponseRejectedInterceptor
    );
  }

  signIn(data: IAuthRequestModel): Observable<IAuthResponseModel> {
    const deviceType =
      data.Device?.PlatformCode === PlatformType.Tizen ||
      data.Device?.PlatformCode === PlatformType.TitanOS ||
      data.Device?.PlatformCode === PlatformType.WebOS
        ? PlatformType.SmartTV
        : data.Device?.PlatformCode;

    const payload = {
      email: data.Username || "",
      password: data.Password || "",
      device_id: data.Device?.Id || "",
      device_name: data.Device?.Name,
      device_type: deviceType,
    };

    return this.authService.login(payload).pipe(
      shareReplay(2),
      map((response) => ModelsMapperHelper.toAuthResponseModel(response)),
      mergeMap(async (results) => {
        StorageHelper.setSession(results.AuthorizationToken);
        return results;
      }),
      switchMap((authResponse) =>
        this.authService.customer().pipe(
          map((customer) => ModelsMapperHelper.toUserInfoModel(customer)),
          map((user) => ({ ...authResponse, User: user }))
        )
      )
    );
  }

  async signUp(
    data: IAuthSignUpRequestModel
  ): Promise<IAuthSignUpResponseModel> {
    try {
      const response = await lastValueFrom(
        this.authService.signup({
          email: data.Email,
          utm_source: data.UtmSource,
          utm_medium: data.UtmMedium,
          utm_campaign: data.UtmCampaign,
        })
      );

      return Promise.resolve(
        ModelsMapperHelper.toAuthSignUpResponseModel(response)
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async validatePassword(password: string, email?: string): Promise<boolean> {
    try {
      const device = await StorageHelper.getDevice();
      const payload = {
        email: email || "",
        password: password,
        device_id: device.Id,
      };
      const response = await lastValueFrom(this.authService.login(payload));
      const result = ModelsMapperHelper.toAuthResponseModel(response);

      StorageHelper.setSession(result.AuthorizationToken);

      return true;
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  loginViaToken(
    data: IAuthViaTokenRequestModel
  ): Observable<IAuthResponseModel> {
    const deviceType =
      data.Device?.PlatformCode === PlatformType.Tizen ||
      data.Device?.PlatformCode === PlatformType.TitanOS ||
      data.Device?.PlatformCode === PlatformType.WebOS
        ? PlatformType.SmartTV
        : data.Device?.PlatformCode;

    const payload: ILoginViaTokenRequestModel = {
      loginToken: data.LoginToken,
      device_id: data.Device?.Id || "",
      device_name: data.Device?.Name,
      device_type: deviceType || "WEB",
    };

    return this.authService
      .loginViaToken(payload)
      .pipe(
        map((response) => ModelsMapperHelper.toAuthResponseModel(response))
      );
  }

  signInViaToken(
    data: IAuthViaTokenRequestModel
  ): Observable<IAuthResponseModel> {
    return this.loginViaToken(data).pipe(
      mergeMap(async (result) => {
        StorageHelper.setSession(result.AuthorizationToken);
        return result;
      }),
      switchMap((authResponse) =>
        this.authService.customer().pipe(
          map((customer) => ModelsMapperHelper.toUserInfoModel(customer)),
          map((user) => ({ ...authResponse, User: user }))
        )
      )
    );
  }

  getCustomer(): Observable<IUserInfoModel> {
    return this.authService
      .customer()
      .pipe(map((response) => ModelsMapperHelper.toUserInfoModel(response)));
  }

  async signOut(refreshToken: string): Promise<void> {
    try {
      const payload = {
        refresh_token: refreshToken,
      };
      await lastValueFrom(this.authService.logout(payload));
      authStorage.deleteSession();
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  public async refreshToken(refreshToken: string): Promise<ITokenModel> {
    try {
      const payload = {
        refresh_token: refreshToken,
      };
      const response = await lastValueFrom(
        this.authService.refreshToken(payload)
      );
      const token: ITokenModel = {
        AuthResult: AuthResult.OK,
        Token: response.idToken,
      };
      return Promise.resolve(token);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getProfile(): Promise<IUserModel> {
    try {
      const response = await lastValueFrom(this.userService.profile());
      const user = ModelsMapperHelper.toUserModel(response);
      return Promise.resolve(user);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async updateProfile(data: Partial<IUserModel>): Promise<IUserModel> {
    try {
      const payload: Partial<ICustomerModel> = {
        first_name: data.FirstName,
        last_name: data.LastName,
        phone: data.PhoneNumber,
      };

      const response = await Promise.all([
        lastValueFrom(this.userService.updateProfile(payload)),
        data.Email && lastValueFrom(this.userService.updateEmail(data.Email)),
      ]);

      const user = ModelsMapperHelper.toUserModel({
        ...response[1],
        ...response[0],
      });

      return Promise.resolve(user);
    } catch (error: any) {
      if (error?.message && error.message.includes("Request throttled"))
        error.message = "request_throttled";

      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getUserConsents(locale?: string): Promise<IUserConsentModel[]> {
    try {
      const response = await Promise.all([
        lastValueFrom(this.userService.availableConsents()),
        lastValueFrom(this.userService.consents()),
      ]);

      return ModelsMapperHelper.toConsentModel(
        response,
        locale || DEFAULT_LOCALE
      );
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  updateUserConsents(
    data: IUserConsentModel[],
    locale?: string
  ): Observable<IUserConsentModel[]> {
    const userCountry = LocaleHelper.getCountryFromLocale(
      locale || DEFAULT_LOCALE
    ).toLowerCase();

    const payload = {
      consents: data.map((consent) => ({
        name: `${userCountry}_${consent.ConsentName}`,
        state: consent.Accepted
          ? UserConsentsState.Accepted
          : UserConsentsState.Declined,
        version: consent.ConsentVersion,
      })),
    };

    return this.userService.updateUserConsents(payload).pipe(map(() => data));
  }

  async changePassword(
    data: IChangePasswordModel
  ): Promise<IChangePasswordResponseModel> {
    try {
      return await lastValueFrom(this.authService.changePassword(data));
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  getUserSubscriptions(): Observable<IUserSubscriptionModel[] | IErrorModel> {
    return this.userService.subscriptions().pipe(
      map(({ subscriptions }) => subscriptions),
      map((subscriptions) =>
        subscriptions.map((subscription) =>
          ModelsMapperHelper.toSubscriptionModel(subscription)
        )
      ),
      toError(ModelsMapperHelper.toErrorModel)
    );
  }

  cancelUserSubscription(
    id: number,
    reason?: {
      cancellation_answers_v2: string[];
      cancellation_reasons_v2: ICancellationSubscriptionReasons;
    }
  ): Observable<IUserSubscriptionModel | IErrorModel> {
    return this.userService
      .cancelSubscription(id, reason)
      .pipe(
        toSuccess(ModelsMapperHelper.toSubscriptionModel),
        toError(ModelsMapperHelper.toErrorModel)
      );
  }

  pauseUserSubscription(
    id: string,
    pausePeriod: string
  ): Observable<AjaxResponse<EmptyObject>> {
    return this.pauseSubscriptionService.pauseSubscription(id, pausePeriod);
  }

  updateUserSubscription(
    id: number,
    couponCode?: string
  ): Observable<IUpdatedSubscriptionModel> {
    const payload = {
      coupon_code: couponCode ?? undefined,
      status: couponCode ? undefined : UserSubscriptionStatus.ACTIVE,
    };
    return this.userService
      .updateSubscription(id, payload)
      .pipe(map((subscription) => subscription));
  }

  getOffers(): Observable<IPaymentOfferModel[]> {
    return this.paymentsService.getOffers().pipe(
      map((response) =>
        response.offers.reduce((arr: IPaymentOfferModel[], offer) => {
          arr.push(ModelsMapperHelper.toPaymentOfferModel(offer));
          return arr;
        }, [])
      ),
      map((offers) => {
        return offers.map((offer) => {
          offer.DefaultOffer = offer.Period === "year";
          return offer;
        });
      }),
      map((offers) => {
        const getOffersByPeriod = (period: "month" | "year") =>
          offers.filter((offer) => offer.Period === period);
        return [...getOffersByPeriod("month"), ...getOffersByPeriod("year")];
      })
    );
  }

  async createPaymentDetails(
    data: ICreatePaymentDetailsModel
  ): Promise<IPaymentOrderModel> {
    const payload: ICreatePaymentDetailsRequestModel = {
      accept_url: data.AcceptUrl,
      cancel_url: data.CancelUrl,
    };

    try {
      const response = await lastValueFrom(
        this.paymentsService.createPaymentDetails(payload)
      );

      return Promise.resolve(ModelsMapperHelper.toPaymentOrderModel(response));
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async createPaymentOrder(
    data: ICreatePaymentOrderRequestModel
  ): Promise<IPaymentOrderModel> {
    const payload: ICreateOrderRequestModel = {
      offer_id: data.OfferId,
      coupon_code: data.CouponCode,
      offer_handle: data.OfferHandle,
      accept_url: data.AcceptUrl,
      cancel_url: data.CancelUrl,
    };

    try {
      const response = await lastValueFrom(
        this.paymentsService.createOrder(payload)
      );
      return Promise.resolve(ModelsMapperHelper.toPaymentOrderModel(response));
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  getPaymentInvoices(): Observable<IPaymentInvoiceModel[]> {
    return this.paymentsService
      .paymentInvoices()
      .pipe(
        map((response) =>
          response.invoices.map((invoice) =>
            ModelsMapperHelper.toInvoiceModel(invoice)
          )
        )
      );
  }

  async getInvoicePDF(invoiceId: string): Promise<string> {
    try {
      return await lastValueFrom(this.paymentsService.getInvoicePDF(invoiceId));
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getPaymentDetails(): Promise<IPaymentDetailsModel | undefined> {
    try {
      const response = await lastValueFrom(
        this.paymentsService.getPaymentDetails()
      );
      const payment = response?.[0];

      if (payment) {
        return Promise.resolve(
          ModelsMapperHelper.toPaymentDetailsModel(payment)
        );
      }

      return;
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async validateCoupon(coupon: string): Promise<IValidCouponModel> {
    try {
      const response = await lastValueFrom(
        this.paymentsService.validateCoupon(coupon)
      );
      return Promise.resolve(ModelsMapperHelper.toValidCouponModel(response));
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getOrderStatus(orderId: string): Promise<IOrderStatusModel> {
    try {
      const response = await lastValueFrom(
        this.paymentsService.getOrderStatus(orderId)
      );
      return Promise.resolve(ModelsMapperHelper.toOrderStatusModel(response));
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getLoginCode(): Promise<ILoginCodeResponseModel> {
    try {
      const response = await lastValueFrom(this.authService.getLoginCode());
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async verifyLoginCode(
    data: ILoginCodeModel
  ): Promise<IVerifyLoginCodeResponseModel> {
    try {
      const payload = {
        code: data.code,
        refresh_token: data.refresh_token,
      };
      const response = await lastValueFrom(
        this.authService.verifyCode(payload)
      );

      if (response.status === LoginCodeStatusTypes.expired) {
        return Promise.reject(ModelsMapperHelper.toErrorModel(response.status));
      }

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  async getLoginTokenFromCode(
    data: ILoginCodeModel
  ): Promise<IGetLoginTokenFromCodeModel> {
    try {
      const payload = {
        code: data.code,
      };
      const response = await lastValueFrom(
        this.authService.getLoginTokenFromCode(payload)
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(ModelsMapperHelper.toErrorModel(error));
    }
  }

  getOfferSwitches(subscription_id: number): Observable<IOfferSwitchModel[]> {
    return this.userService
      .offerSwitches(subscription_id)
      .pipe(
        map(({ offer_switches }) =>
          offer_switches.map((offer_switch) =>
            ModelsMapperHelper.toOfferSwitchModel(offer_switch)
          )
        )
      );
  }

  switchUserSubscription(data: {
    subscription_id: number;
    to_offer_id: string | null;
  }): Observable<IOfferSwitchModel> {
    return this.userService
      .switchSubscription(data)
      .pipe(map((response) => ModelsMapperHelper.toOfferSwitchModel(response)));
  }

  cancelUserSubscriptionSwitch(
    subscription_id: number
  ): Observable<ICancelSubscriptionSwitchModelResponse> {
    return this.userService.cancelSubscriptionSwitch(subscription_id);
  }
}
