/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaListModel } from "../models";
import { SourceType } from "../enums";

export const getMediaListId = (
  mediaList: { [key: string]: IMediaListModel },
  type: SourceType
) => {
  for (const key in mediaList) {
    if (mediaList[key].SourceType === type) return key;
  }
};
