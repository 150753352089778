import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
} from "react";

interface IIsLoadingContext {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<any>>;
}
const IsLoadingContext = createContext({} as IIsLoadingContext);

export const IsLoadingContextProvider = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <IsLoadingContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
    >
      {props?.children}
    </IsLoadingContext.Provider>
  );
};

export const useIsLoading = () => {
  const context = useContext(IsLoadingContext);

  if (!context) {
    throw new Error("Component beyond EpgTimeMarkerContext");
  }

  return context;
};
