/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useDispatch } from "react-redux";
import { MediaStore } from "../../store";
import { IMediaSearchFilterModel, QueryProps } from "../../models";
import { useCallback } from "react";

export const useGetMediaListFromQuery = (
  id: string,
  params: QueryProps,
  filter?: IMediaSearchFilterModel,
  itemsPerPage?: number
) => {
  const dispatch = useDispatch();
  const getList = useCallback(() => {
    dispatch(
      MediaStore.Actions.getMediaList({
        MediaListId: id,
        QueryParams: {
          sort: "-popularity.month",
          ...params,
        },
        PageSize: itemsPerPage,
        PageNumber: 1,
      })
    );
  }, [params]);

  const getMore = useCallback(() => {
    if (filter?.PageNumber) {
      dispatch(
        MediaStore.Actions.getMediaList({
          ...filter,
          MediaListId: id,
          PageSize: itemsPerPage,
          PageNumber: filter.PageNumber + 1,
          QueryParams: {
            ...params,
          },
        })
      );
    }
  }, [filter?.PageNumber, dispatch, params]);

  return { getList, getMore };
};
