import { useEffect } from "react";
import { eventService } from "@nf/common";
import { ROUTES } from "@nf/constants";
import { useReplacePage } from "./useReplacePage";

export const useSubProfileChangeEvent = () => {
  const redirectToPage = useReplacePage();

  useEffect(() => {
    const subscription = eventService.onUserSubProfileChangeEvent(
      (initialHomePage?: string) => {
        redirectToPage(initialHomePage ?? ROUTES.HOME, true);
      }
    );
    return () => {
      eventService.unsubscribe(subscription);
    };
  }, [redirectToPage]);
};
