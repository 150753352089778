/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { HTTP_METHOD } from "@nf/constants";
import { Observable, Observer } from "rxjs";
import { getChannelTypeFromChannelRoute } from "../../../../helpers";
import { AxiosSubscriber } from "../../../../services";
import {
  IAssetLastViewedEpisode,
  IFavoritesAssetModel,
  IUserPlayHistoryModel,
  IListModel,
  IAssetModel,
} from "../models";
import { AppConfig } from "@nf/constants";

export class UserMediaPropertiesService {
  get url(): string {
    return `/v1/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}`;
  }

  public getUserPlayHistory = (
    mediaId?: string | number | (string | number)[]
  ): Observable<IListModel<IUserPlayHistoryModel[]>> =>
    new Observable(
      (observer: Observer<IListModel<IUserPlayHistoryModel[]>>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/userplayhistory/lastviewedoffset?assetIds=${
            mediaId ?? ""
          }`,
        })
    );

  public deleteUserPlayHistory = (id: string | number): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/userplayhistory/lastviewed/asset/${id}`,
        })
    );

  public getUserPlayHistoryList = (): Observable<IListModel<IAssetModel[]>> =>
    new Observable(
      (observer: Observer<IListModel<IAssetModel[]>>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/recommend/continue`,
        })
    );

  public getUserLastViewedEpisode = (
    assetId: string | number
  ): Observable<IAssetLastViewedEpisode> =>
    new Observable(
      (observer: Observer<IAssetLastViewedEpisode>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/userplayhistory/continue/tvshow/${assetId}`,
        })
    );

  public getUserFavouriteList = (
    channel?: string
  ): Observable<IFavoritesAssetModel[]> => {
    const params = channel && {
      tagIds: getChannelTypeFromChannelRoute(channel),
    };

    return new Observable(
      (observer: Observer<IFavoritesAssetModel[]>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `${this.url}/preferences/list/favorites/asset`,
        })
    );
  };

  public getUserFavouriteListByComponentUrl = (
    url: string
  ): Observable<IFavoritesAssetModel[]> => {
    return new Observable(
      (observer: Observer<IFavoritesAssetModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url,
        })
    );
  };

  public addToFavourites = (assetId: string | number): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          url: `${this.url}/preferences/list/favorites/asset/${assetId}`,
          data: {},
        })
    );

  public removeFromFavourites = (assetId: string | number): Observable<void> =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.DELETE,
          url: `${this.url}/preferences/list/favorites/asset/${assetId}`,
        })
    );
}
