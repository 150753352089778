import {
  IUserSubprofileModel,
  StorageHelper,
  userProfileHelper,
} from "@nf/common";

export type IUserSavedProfileModel = Partial<
  Pick<
    IUserSubprofileModel,
    "UserId" | "InitialHomePage" | "DisplayName" | "CurrentProfile"
  >
>;

type TProfilesHook = {
  handleProfile: (profile: IUserSavedProfileModel) => void;
  getCurrentProfile: () => Promise<IUserSavedProfileModel | null>;
  setAsCurrentProfile: (activeSubProfile: IUserSubprofileModel) => void;
};

export const useProfiles = (): TProfilesHook => {
  const handleProfile = (profile: IUserSavedProfileModel): void => {
    getProfiles().then((profiles) => {
      const currentProfileId = findIndex(profiles, profile.UserId);

      profiles.forEach((item) => (item.CurrentProfile = false));
      if (currentProfileId !== -1) {
        profiles[currentProfileId].InitialHomePage =
          userProfileHelper.getInitialHomePage(profile);
        profiles[currentProfileId].CurrentProfile = profile.CurrentProfile;
      } else {
        profiles.push(profile);
      }

      StorageHelper.setProfiles(profiles);
    });
  };

  const getProfiles = async (): Promise<IUserSavedProfileModel[]> => {
    const cookiesProfiles = await StorageHelper.getProfiles();
    if (cookiesProfiles) {
      return cookiesProfiles;
    }
    return [];
  };

  const findIndex = (
    users: IUserSavedProfileModel[],
    userId?: string
  ): number => {
    return users.findIndex(
      (user: IUserSavedProfileModel) => user.UserId === userId
    );
  };

  const getCurrentProfile =
    async (): Promise<IUserSavedProfileModel | null> => {
      return userProfileHelper.findCurrentProfile(await getProfiles()) || null;
    };

  const setAsCurrentProfile = (activeSubProfile: IUserSubprofileModel) => {
    handleProfile({
      UserId: activeSubProfile.UserId,
      DisplayName: activeSubProfile.DisplayName,
      InitialHomePage: activeSubProfile.InitialHomePage,
      CurrentProfile: true,
    });
  };

  return {
    handleProfile,
    getCurrentProfile,
    setAsCurrentProfile,
  };
};
