/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  FormInstance,
  Rule,
  RuleObject,
  StoreValue,
  RuleType,
} from "rc-field-form/es/interface";
import { ApiErrors, IFormValues } from "./types";
import { Trans } from "react-i18next";
import React from "react";

const REGEX = {
  PASSWORD: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^&*-]).{8,}$/,
  PHONE:
    /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){7,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
  COUNTRYCODE: /^\+/,
};

interface IValidatorAssetValue {
  uid: string;
  lastModified: number;
  lastModifiedDate: string;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export function updateApiErrors(
  apiErrors: ApiErrors,
  changedValues: IFormValues
): [boolean, ApiErrors] {
  const newApiErrors = { ...apiErrors };
  let isUpdated = false;

  for (const field in changedValues) {
    if (field in apiErrors) {
      delete newApiErrors[field];
      isUpdated = true;
    }
  }

  return [isUpdated, newApiErrors];
}

export function combineApiErrors(apiErrors: Record<string, any>) {
  const combinedErrors: string[] = [];
  for (const [_, errors] of Object.entries(apiErrors)) {
    combinedErrors.push(...errors);
  }
  return combinedErrors;
}

export function compareValues(
  value: StoreValue,
  otherValue: StoreValue,
  message?: string
) {
  const errorMessage = message || "Provided values do not match.";

  if (otherValue !== value) {
    return Promise.reject(errorMessage);
  }

  return Promise.resolve();
}

export function buildPasswordPatternRule(t?: any) {
  return {
    pattern: REGEX.PASSWORD,
    message: (
      <Trans
        i18nKey={
          t
            ? t("PASSWORD_PATTERN_VALIDATION_MESSAGE")
            : "PASSWORD_PATTERN_VALIDATION_MESSAGE"
        }
      />
    ),
  };
}

export function buildPasswordMatchRule(
  context: FormInstance,
  fieldName: string,
  t?: any
) {
  return {
    validator(rule: RuleObject, value: StoreValue) {
      const valueToCompare = context.getFieldValue(fieldName);
      return compareValues(value, valueToCompare);
    },
    message: (
      <Trans
        i18nKey={
          t
            ? t("web_password_match_validation_message")
            : "web_password_match_validation_message"
        }
      />
    ),
  };
}

export function buildPhonePatternRule(t?: any) {
  return {
    pattern: REGEX.PHONE,
    message: (
      <Trans
        i18nKey={
          t ? t("web_phone_pattern_message") : "web_phone_pattern_message"
        }
      />
    ),
  };
}

export function buildPhoneCountryCodeRule(t?: any) {
  return {
    pattern: REGEX.COUNTRYCODE,
    message: (
      <Trans
        i18nKey={
          t
            ? t("web_phone_country_code_message")
            : "web_phone_country_code_message"
        }
      />
    ),
  };
}

export function buildPhoneMaxLengthRule(t?: any): Rule {
  return {
    max: 15,
    message: (
      <Trans
        i18nKey={
          t
            ? t("web_phone_length_validation_message")
            : "web_phone_length_validation_message"
        }
      />
    ),
  };
}

export function buildPhoneCountryMaxLengthRule(t?: any): Rule {
  return {
    validator: (_, value) => {
      const prefix = value.slice(0, 3);
      const number = value.slice(3);
      let maxLength = 0;

      switch (prefix) {
        case "+45":
          maxLength = 8;
          break;
        case "+46":
        case "+47":
          maxLength = 7;
          break;
      }

      if (number.length > maxLength) {
        return Promise.reject(
          <Trans
            i18nKey={
              t
                ? t("web_phone_length_byCountry_validation_message")
                : "web_phone_length_byCountry_validation_message"
            }
            values={{ maxLenght: `${maxLength}` }}
          />
        );
      }
      if (number.length < maxLength) {
        return Promise.reject(
          <Trans
            i18nKey={
              t
                ? t("web_phone_length_byCountry_validation_message")
                : "web_phone_length_byCountry_validation_message"
            }
            values={{ maxLenght: `${maxLength}` }}
          />
        );
      }
      return Promise.resolve();
    },
  };
}

export function buildRequiredRule(valueType?: RuleType, t?: any): Rule {
  return {
    required: true,
    type: valueType,
    message: (
      <Trans
        i18nKey={
          t
            ? t("web_required_validation_message")
            : "web_required_validation_message"
        }
      />
    ),
  };
}

export function buildRequiredObjectPropertiedRule(t?: any): Rule {
  return {
    required: true,
    type: "object",
    validator: (_, value: any) => {
      if (typeof value === "object") {
        for (const param in value) {
          if (!value[param]) {
            return Promise.reject(
              `${param} field is required in object ${value}`
            );
          }
        }
      }
      return Promise.resolve();
    },
    message: (
      <Trans
        i18nKey={
          t
            ? t("web_required_validation_message")
            : "web_required_validation_message"
        }
      />
    ),
  };
}

export const buildRequiredObjectSinglePropertyRule = (
  errorParam: string,
  message: string
): Rule => {
  return {
    required: true,
    type: "object",
    validator: (_: Rule, value: any) => {
      if (typeof value === "object") {
        for (const param in value) {
          if (param === errorParam && !value[param]) {
            return Promise.reject(
              `${param} field is required in object ${value}`
            );
          }
        }
      }
      return Promise.resolve();
    },
    message,
  };
};

export function buildEmailRule(t?: any): Rule {
  return {
    type: "email",
    message: (
      <Trans
        i18nKey={
          t ? t("web_email_validation_message") : "web_email_validation_message"
        }
      />
    ),
  };
}

export function buildPasswordMinimumRule(t?: any): Rule {
  return {
    min: 10,
    message: (
      <Trans
        i18nKey={
          t
            ? t("web_minimum_password_validation_message")
            : "web_minimum_password_validation_message"
        }
        defaults="Password requires minimum 10 characters"
      />
    ),
  };
}

export function buildPasswordMaximumRule(t?: any): Rule {
  return {
    max: 60,
    message: (
      <Trans
        i18nKey={
          t
            ? t("web_maximum_password_validation_message")
            : "web_maximum_password_validation_message"
        }
        defaults="Password requires maximum 60 characters"
      />
    ),
  };
}

export function buildNameMaxLengthRule(t?: any): Rule {
  return {
    max: 20,
    message: (
      <Trans
        i18nKey={
          t
            ? t("NAME_LENGTH_VALIDATION_MESSAGE")
            : "NAME_LENGTH_VALIDATION_MESSAGE"
        }
      />
    ),
  };
}

export const buildAssetUploadRule = (wrongAsset: boolean) => {
  return {
    validator: (_: Rule, value: IValidatorAssetValue) => {
      if (value && wrongAsset) {
        return Promise.reject(`${value.type} is not proper type`);
      }
      return Promise.resolve();
    },
    message: <Trans i18nKey="INCORRECT_FILE_TYPE" />,
  };
};

export * from "./types";
