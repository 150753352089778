/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppConfigDefault } from "./AppConfig.default";

declare let webOS: any;

export type NetworkState = {
  tetheringEnabled?: boolean;
  state: string;
};
export type NetworkWiredInfo = {
  [key: string]: string | boolean;
};
export interface INetwork {
  wifi: NetworkState;
  wifiDirect: NetworkState;
  wired: NetworkWiredInfo;
  isInternetConnectionAvailable: boolean;
  subscribed: boolean;
  offlineMode: string;
  returnValue: boolean;
}

export interface IWebOSErrorModel {
  errorCode: number;
  errorText: string;
}

export class AppConfigWebOS extends AppConfigDefault {

  static CheckWebOS(): boolean {
    const systemInfo = webOS.systemInfo();
    return (
      Object.keys(systemInfo).length !== 0 && systemInfo.constructor === Object
    );
  }

  static get AppLocalTimezone(): string {
    webOS.service.request("luna://com.webos.settingsservice", {
      method: "getSystemSettings",
      parameters: {
        keys: ["localeInfo"],
      },
      onSuccess: (inResponse: any) => {
        const plainResult = JSON.stringify(inResponse);
        const results = JSON.parse(plainResult);
        return results.settings.localeInfo.timezone;
      },
      onFailure: () => {
        return;
      },
    });
    return "";
  }

  static getDeviceLocale = (callback: CallableFunction) => {
    // @ts-ignore
    if (this.CheckWebOS()) {
      const systemInfo = webOS.systemInfo();
      webOS.service.request("luna://com.webos.settingsservice", {
        method: "getSystemSettings",
        parameters: {
          keys: ["localeInfo"],
        },
        onSuccess: function (inResponse: any) {
          const plainResult = JSON.stringify(inResponse);
          const results = JSON.parse(plainResult);
          const language = results.settings.localeInfo.locales.UI.replace(
            "-",
            "_"
          );

          callback(`${language}`, `${systemInfo.country}`);
        },
        onFailure: function (inError: IWebOSErrorModel) {
          callback("[" + inError.errorCode + "]: " + inError.errorText);
        },
      });
    }
  };

  static get AppDevicePlatformVersion(): string {
    let osVersion;
    if (this.CheckWebOS()) {
      webOS.deviceInfo((device: any) => {
        osVersion = device.sdkVersion;
      });
    }

    return osVersion || "";
  }

  static networkHandler = (callback: CallableFunction) => {
    if (this.CheckWebOS()) {
      webOS.service.request("luna://com.palm.connectionmanager", {
        method: "getStatus",
        parameters: { subscribe: true },
        onSuccess: (inResponse: INetwork) => {
          if (typeof inResponse.subscribed != "undefined") {
            if (!inResponse.subscribed) {
              console.log("Failed to subscribe network state");
              return;
            }
          }
          callback(inResponse.isInternetConnectionAvailable);
        },
        onFailure: (inError: IWebOSErrorModel) => {
          console.log("Failed to get network state");
          console.log("[" + inError.errorCode + "]: " + inError.errorText);
        },
      });
    }
  };
}
