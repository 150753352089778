import {
  IAppState,
  QueryProps,
  useGetMediaListFromQuery,
  useSelector,
} from "@nf/common";

export const useInfiniteScrollData = (
  queryParams: QueryProps,
  mediaListId: string
) => {
  const mediaListSelector = (state: IAppState) =>
    state.media.mediaList[mediaListId] || {};

  const mediaList = useSelector(mediaListSelector);

  const { getList, getMore } = useGetMediaListFromQuery(
    mediaListId,
    queryParams,
    mediaList.Filter,
    mediaList.PageSize
  );

  return { getList, getMore, mediaList };
};
