import { useCallback } from "react";
import { userProfileHelper } from "@nf/common";
import { REDIRECT_URL_PARAM, ROUTES } from "@nf/constants";
import { useReplacePage } from "./useReplacePage";
import { useRedirectUrlParam } from "./useRedirectUrlParam";
import { useRoutePathBuild } from "./useRoutePathBuild";
import { UrlObject } from "url";

export const useRedirectToProfileScreen = () => {
  const redirectTo = useReplacePage();
  const routePrefix = useRoutePathBuild();
  const redirectUrlParam = useRedirectUrlParam();

  const getRedirectUrl = useCallback((): string | UrlObject => {
    if (redirectUrlParam) {
      return {
        pathname: `${routePrefix}${
          userProfileHelper.cleanUp(ROUTES.SELECT_PROFILE_SCREEN) as string
        }`,
        query: {
          [REDIRECT_URL_PARAM]: redirectUrlParam,
        },
      };
    }

    return `${userProfileHelper.cleanUp(ROUTES.SELECT_PROFILE_SCREEN)}`;
  }, [redirectUrlParam, routePrefix]);

  return useCallback(() => {
    redirectTo(getRedirectUrl());
  }, [redirectTo]);
};
