import { AppConfig, HTTP_METHOD } from "@nf/constants";
import { Observable, Observer } from "rxjs";
import {
  IPurchaseResponseModel,
  ITransactionProductOfferingResponseModel,
} from "../models";
import { AxiosSubscriber } from "../../../../services";

export class ProductOfferingService {
  get url(): string {
    return `/v2/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}/store`;
  }

  public getProductOfferings =
    (): Observable<ITransactionProductOfferingResponseModel> => {
      const url = `${this.url}/account/transactions/productoffering`;
      return new Observable(
        (observer: Observer<ITransactionProductOfferingResponseModel>) =>
          new AxiosSubscriber(observer, {
            method: HTTP_METHOD.GET,
            url,
          })
      );
    };

  public getAccountPurchase = (): Observable<IPurchaseResponseModel> => {
    const url = `${this.url}/account/purchases`;
    return new Observable(
      (observer: Observer<IPurchaseResponseModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url,
        })
    );
  };
}
