/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IPaymentsState, PaymentActionsTypes } from "./types";
import { Consts } from ".";

export const initialState: IPaymentsState = {
  offers: {},
  switchOffers: {},
  paymentInvoices: {},
};

export const paymentsReducer = (
  state = initialState,
  action: PaymentActionsTypes
): IPaymentsState => {
  switch (action.type) {
    case Consts.GET_OFFERS: {
      return {
        ...state,
        offers: {
          Initialized: true,
          ...state.offers,
          IsProcessing: true,
          Error: undefined,
          Completed: false,
        },
      };
    }
    case Consts.GET_OFFERS_SUCCESS: {
      return {
        ...state,
        offers: {
          Initialized: true,
          Data: action.payload,
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };
    }
    case Consts.GET_OFFERS_FAILURE: {
      return {
        ...state,
        offers: {
          ...state.offers,
          Initialized: true,
          IsProcessing: false,
          Error: action.error,
          Completed: true,
        },
      };
    }
    case Consts.GET_PAYMENT_INVOICES: {
      return {
        ...state,
        paymentInvoices: {
          ...state.paymentInvoices,
          IsProcessing: true,
          Error: undefined,
        },
      };
    }
    case Consts.GET_PAYMENT_INVOICES_SUCCESS: {
      return {
        ...state,
        paymentInvoices: {
          Data: action.payload,
          IsProcessing: false,
          Error: undefined,
        },
      };
    }
    case Consts.GET_PAYMENT_INVOICES_FAILURE: {
      return {
        ...state,
        paymentInvoices: {
          ...state.paymentInvoices,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }

    case Consts.GET_SWITCH_OFFER: {
      return {
        ...state,
        switchOffers: {
          Initialized: true,
          Data: undefined,
          Completed: false,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }

    case Consts.GET_SWITCH_OFFER_SUCCESS: {
      return {
        ...state,
        switchOffers: {
          Initialized: true,
          Data: action.payload,
          Completed: true,
          Error: undefined,
          IsProcessing: false,
        },
      };
    }
    case Consts.GET_SWITCH_OFFER_FAILURE: {
      return {
        ...state,
        switchOffers: {
          Initialized: true,
          ...state.switchOffers,
          Completed: true,
          Error: action.error,
          IsProcessing: false,
        },
      };
    }

    case Consts.SWITCH_SUBSCRIPTION: {
      return {
        ...state,
        switchOffers: {
          Initialized: true,
          ...state.switchOffers,
          Completed: false,
          Error: undefined,
          IsProcessing: true,
        },
      };
    }

    case Consts.SWITCH_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        switchOffers: {
          Initialized: true,
          Data: action.payload,
          Completed: true,
          Error: undefined,
          IsProcessing: false,
        },
      };
    }

    case Consts.CANCEL_SUBSCRIPTION_SWITCH_SUCCESS: {
      return {
        ...state,
        switchOffers: {
          Initialized: true,
          Data: action.payload,
          Completed: true,
          Error: undefined,
          IsProcessing: false,
        },
      };
    }

    default:
      return state;
  }
};
