import { useSubscription } from "./useSubscription";
import { eventService } from "@nf/common";
import { ROUTES } from "@nf/constants";
import { useReplacePage } from "./useReplacePage";
import { useGeneratePath } from "./useGeneratePath";
import { useCallback } from "react";

const useGenerateUrl = () => {
  const generatePath = useGeneratePath();
  return useCallback(
    (update: "success" | "error") => ({
      pathname: generatePath(ROUTES.SETTINGS_SUBSCRIPTION),
      query: {
        update,
      },
    }),
    [generatePath]
  );
};

export const useSubscriptionSwitchEvent = () => {
  const replaceTo = useReplacePage();
  const generatePath = useGenerateUrl();
  useSubscription(
    eventService.onSwitchSubscriptionSuccessEvent(() => {
      replaceTo(generatePath("success"), true);
    })
  );
};

export const useSubscriptionSwitchFailureEvent = () => {
  const replaceTo = useReplacePage();
  const generatePath = useGenerateUrl();
  useSubscription(
    eventService.onSwitchSubscriptionFailureEvent(() => {
      replaceTo(generatePath("error"), true);
    })
  );
};
