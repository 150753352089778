import { SEARCH_QUERY_MIN_LENGTH } from "@nf/constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDeepMemo } from "../";
import { IMediaSearchFilterModel } from "../../models";
import { useMediaSearchSelector } from "../../selectors";
import { MediaStore } from "../../store";

export const useSearchMediaDispatch = () => {
  const dispatch = useDispatch();
  return (filter: IMediaSearchFilterModel) =>
    dispatch(
      MediaStore.Actions.searchMedia({
        IncludeCategories: true,
        IncludeImages: true,
        ...filter,
      })
    );
};

export const useSearchMedia = (
  filters: IMediaSearchFilterModel,
  enabled = true
) => {
  const memoizedFilters = useDeepMemo(filters);
  const data = useMediaSearchSelector();
  const searchMedia = useSearchMediaDispatch();

  useEffect(() => {
    const query = memoizedFilters.FullTextSearch;
    if (enabled && query && query?.length >= SEARCH_QUERY_MIN_LENGTH) {
      searchMedia(memoizedFilters);
    }
  }, [enabled, memoizedFilters]);

  return { data: data.Entities, loading: data.IsLoading };
};
