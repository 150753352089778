/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const GET_OFFERS = "GET_OFFERS";
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS";
export const GET_OFFERS_FAILURE = "GET_OFFERS_FAILURE";

export const GET_PAYMENT_INVOICES = "GET_PAYMENT_INVOICES";
export const GET_PAYMENT_INVOICES_SUCCESS = "GET_PAYMENT_INVOICES_SUCCESS";
export const GET_PAYMENT_INVOICES_FAILURE = "GET_PAYMENT_INVOICES_FAILURE";

export const GET_SWITCH_OFFER = "GET_SWITCH_OFFER";
export const GET_SWITCH_OFFER_SUCCESS = "GET_SWITCH_OFFER_SUCCESS";
export const GET_SWITCH_OFFER_FAILURE = "GET_SWITCH_OFFER_FAILURE";

export const SWITCH_SUBSCRIPTION = "SWITCH_SUBSCRIPTION";
export const SWITCH_SUBSCRIPTION_SUCCESS = "SWITCH_SUBSCRIPTION_SUCCESS";
export const SWITCH_SUBSCRIPTION_FAILURE = "SWITCH_SUBSCRIPTION_FAILURE";

export const SWITCH_SUBSCRIPTION_SUCCESS_EVENT =
  "SWITCH_SUBSCRIPTION_SUCCESS_EVENT";
export const SWITCH_SUBSCRIPTION_FAILURE_EVENT =
  "SWITCH_SUBSCRIPTION_FAILURE_EVENT";

export const CANCEL_SUBSCRIPTION_SWITCH = "CANCEL_SUBSCRIPTION_SWITCH";
export const CANCEL_SUBSCRIPTION_SWITCH_SUCCESS =
  "CANCEL_SUBSCRIPTION_SWITCH_SUCCESS";
export const CANCEL_SUBSCRIPTION_SWITCH_FAILURE =
  "CANCEL_SUBSCRIPTION_SWITCH_FAILURE";
