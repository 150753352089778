/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import * as Consts from "./consts";
import * as AuthConsts from "../auth/consts";
import { IUserState, UserActionsTypes } from "./types";

export const initialState: IUserState = {
  action: undefined,
  profile: {},
  subprofiles: {
    Initialized: false,
    IsProcessing: false,
    Error: undefined,
    Completed: false,
  },
  consents: {
    Initialized: false,
    IsProcessing: false,
    Error: undefined,
    Completed: false,
  },
  devices: {},
  consent: {},
  subscriptions: {},
  availabilityKeys: {},
  watchHistory: {},
  favorites: [],
};

export const userReducer = (
  state = initialState,
  action: UserActionsTypes
): IUserState => {
  switch (action.type) {
    case Consts.GET_PROFILE:
    case Consts.UPDATE_PROFILE: {
      return {
        ...state,
        action,
        profile: {
          ...state.profile,
          IsProcessing: true,
          Error: undefined,
        },
      };
    }
    case Consts.GET_PROFILE_SUCCESS:
    case Consts.UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        action,
        profile: {
          Data: { ...state.profile.Data, ...action.payload },
          IsProcessing: false,
          Error: undefined,
        },
      };
    }
    case Consts.GET_USER_SUBPROFILES:
      return {
        ...state,
        action,
        subprofiles: {
          ...state.subprofiles,
          Initialized: true,
          IsProcessing: true,
          Error: undefined,
          Completed: false,
        },
      };
    case Consts.CREATE_USER_SUBPROFILES:
    case Consts.EDIT_USER_SUBPROFILES:
    case Consts.DELETE_USER_SUBPROFILES:
    case Consts.SELECT_USER_SUBPROFILE:
    case Consts.GET_USER_PARENTAL_RATINGS: {
      return {
        ...state,
        action,
        subprofiles: {
          ...state.subprofiles,
          IsProcessing: true,
          Error: undefined,
          Completed: false,
        },
      };
    }

    case Consts.GET_USER_SUBPROFILES_SUCCESS: {
      return {
        ...state,
        action,
        subprofiles: {
          Data: {
            ...state.subprofiles.Data,
            subprofiles: action.payload.subprofiles,
            currentSubprofile: action.payload.currentSubprofile,
            auth: {
              ...state.subprofiles.Data?.auth,
              PinCodeId: action.payload?.pinCodeId,
            },
          },
          Initialized: true,
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };
    }

    case Consts.CREATE_USER_SUBPROFILES_SUCCESS: {
      return {
        ...state,
        action,
        subprofiles: {
          Data: { ...state.subprofiles.Data, subprofiles: action.payload },
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };
    }

    case Consts.EDIT_USER_SUBPROFILES_SUCCESS: {
      return {
        ...state,
        action,
        subprofiles: {
          Data: {
            ...state.subprofiles.Data,
            subprofiles: state.subprofiles.Data?.subprofiles?.map(
              (subprofile) => {
                if (subprofile.UserId === action.payload.UserId)
                  return action.payload;
                return subprofile;
              }
            ),
          },
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };
    }

    case Consts.DELETE_USER_SUBPROFILES_SUCCESS:
      return {
        ...state,
        action,
        subprofiles: {
          Data: {
            ...state.subprofiles.Data,
            subprofiles: state.subprofiles.Data?.subprofiles?.filter(
              (subprofile) => subprofile.UserId !== action.payload
            ),
          },
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };

    case Consts.SELECT_USER_SUBPROFILE_SUCCESS:
      return {
        ...state,
        action,
        subprofiles: {
          Data: {
            ...state.subprofiles.Data,
            subprofiles: state.subprofiles.Data?.subprofiles?.map(
              (subprofile) => {
                if (subprofile.UserId === action.payload.profile.UserId)
                  return { ...subprofile, CurrentProfile: true };

                return { ...subprofile, CurrentProfile: false };
              }
            ),
            currentSubprofile: action.payload.profile,
          },
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };

    case Consts.SELECT_USER_SUBPROFILE_COMPLETED:
      return {
        ...state,
        action,
      };

    case Consts.GET_USER_PARENTAL_RATINGS_SUCCESS:
      return {
        ...state,
        action,
        subprofiles: {
          Data: {
            ...state.subprofiles.Data,
            parentalRatings: action.payload,
          },
        },
      };

    case Consts.GET_USER_SUBPROFILES_FAILURE:
    case Consts.CREATE_USER_SUBPROFILES_FAILURE:
    case Consts.DELETE_USER_SUBPROFILES_FAILURE:
    case Consts.EDIT_USER_SUBPROFILES_FAILURE:
    case Consts.SELECT_USER_SUBPROFILE_FAILURE:
    case Consts.GET_USER_PARENTAL_RATINGS_FAILURE: {
      return {
        ...state,
        action,
        subprofiles: {
          ...state.subprofiles,
          IsProcessing: false,
          Error: action.error,
          Completed: true,
        },
      };
    }

    case Consts.SET_USER_SUBPROFILE_PIN_CODE:
    case Consts.DELETE_USER_SUBPROFILE_PIN_CODE: {
      return {
        ...state,
        action,
        subprofiles: {
          ...state.subprofiles,
          Data: {
            ...state.subprofiles.Data,
            auth: {
              ...state.subprofiles.Data?.auth,
              IsProcessing: true,
              Error: undefined,
            },
          },
        },
      };
    }

    case Consts.SET_USER_SUBPROFILE_PIN_CODE_SUCCESS: {
      return {
        ...state,
        action,
        subprofiles: {
          ...state.subprofiles,
          Data: {
            ...state.subprofiles.Data,
            auth: {
              PinCodeId: action.payload,
              IsProcessing: false,
              Error: undefined,
            },
          },
        },
      };
    }

    case Consts.DELETE_USER_SUBPROFILE_PIN_CODE_SUCCESS: {
      return {
        ...state,
        action,
        subprofiles: {
          ...state.subprofiles,
          Data: {
            ...state.subprofiles.Data,
            auth: {
              PinCodeId: undefined,
              IsProcessing: false,
              Error: undefined,
            },
          },
        },
      };
    }

    case Consts.SET_USER_SUBPROFILE_PIN_CODE_FAILURE:
    case Consts.DELETE_USER_SUBPROFILE_PIN_CODE_FAILURE: {
      return {
        ...state,
        action,
        subprofiles: {
          ...state.subprofiles,
          Data: {
            ...state.subprofiles.Data,
            auth: {
              ...state.subprofiles.Data?.auth,
              IsProcessing: false,
              Error: action.error,
            },
          },
        },
      };
    }

    case Consts.GET_PROFILE_FAILURE:
    case Consts.UPDATE_PROFILE_FAILURE: {
      return {
        ...state,
        action,
        profile: {
          ...state.profile,
          IsProcessing: false,
          Error: action.error,
        },
      };
    }
    case Consts.GET_USER_CONSENTS:
    case Consts.UPDATE_USER_CONSENTS: {
      return {
        ...state,
        action,
        consents: {
          Initialized: true,
          ...state.consents,
          IsProcessing: true,
          Error: undefined,
          Completed: false,
        },
      };
    }
    case Consts.GET_USER_DEVICES:
    case Consts.DELETE_USER_DEVICE:
      return {
        ...state,
        action,
        devices: {
          ...state.devices,
          IsProcessing: true,
          Error: undefined,
        },
      };
    case Consts.GET_USER_DEVICES_SUCCESS:
      return {
        ...state,
        action,
        devices: {
          Data: action.payload,
          IsProcessing: false,
          Error: undefined,
        },
      };
    case Consts.DELETE_USER_DEVICE_SUCCESS:
      return {
        ...state,
        action,
        devices: {
          Data: state.devices.Data?.filter(
            (device) => device.Id !== action.payload
          ),
          IsProcessing: false,
          Error: undefined,
        },
      };
    case Consts.GET_USER_DEVICES_FAILURE:
    case Consts.DELETE_USER_DEVICE_FAILURE:
      return {
        ...state,
        action,
        devices: {
          ...state.devices,
          IsProcessing: false,
          Error: action.error,
        },
      };
    case Consts.GET_WATCH_HISTORY:
    case Consts.DELETE_WATCH_HISTORY:
      return {
        ...state,
        action,
        watchHistory: {
          ...state.watchHistory,
          IsProcessing: true,
          Error: undefined,
        },
      };
    case Consts.GET_WATCH_HISTORY_SUCCESS:
      return {
        ...state,
        action,
        watchHistory: {
          Data: action.payload,
          IsProcessing: false,
          Error: undefined,
        },
      };
    case Consts.DELETE_WATCH_HISTORY_SUCCESS:
      return {
        ...state,
        action,
        watchHistory: {
          Data: state.watchHistory.Data?.filter(
            (item) => item.Id !== action.payload
          ),
          IsProcessing: false,
          Error: undefined,
        },
      };
    case Consts.GET_WATCH_HISTORY_FAILURE:
    case Consts.DELETE_WATCH_HISTORY_FAILURE:
      return {
        ...state,
        action,
        watchHistory: {
          ...state.watchHistory,
          IsProcessing: false,
          Error: action.error,
        },
      };
    case Consts.GET_USER_CONSENTS_SUCCESS: {
      return {
        ...state,
        action,
        consents: {
          Initialized: true,
          Data: action.payload,
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };
    }
    case Consts.UPDATE_USER_CONSENTS_SUCCESS: {
      const updatedConsents = state.consents.Data?.map((consent) => {
        const updatedConsent = action.payload.find(
          (newConsent) => newConsent.ConsentName === consent.ConsentName
        );

        if (updatedConsent) return updatedConsent;
        else return consent;
      });

      return {
        ...state,
        action,
        consents: {
          Data: updatedConsents,
          IsProcessing: false,
          Error: undefined,
        },
      };
    }
    case Consts.GET_USER_CONSENTS_FAILURE:
    case Consts.UPDATE_USER_CONSENTS_FAILURE: {
      return {
        ...state,
        action,
        consents: {
          ...state.consents,
          Initialized: true,
          IsProcessing: false,
          Error: action.error,
          Completed: true,
        },
      };
    }
    case Consts.CANCEL_SUBSCRIPTION:
    case Consts.UPDATE_SUBSCRIPTION:
    case Consts.UPDATE_SUBSCRIPTION_PENDING:
    case Consts.GET_USER_SUBSCRIPTIONS:
    case Consts.PAUSE_SUBSCRIPTION: {
      return {
        ...state,
        action,
        subscriptions: {
          ...state.subscriptions,
          IsProcessing: true,
          Error: undefined,
          Completed: false,
        },
      };
    }
    case Consts.PAUSE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        action,
        subscriptions: {
          ...state.subscriptions,
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };
    }
    case Consts.PAUSE_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        action,
        subscriptions: {
          ...state.subscriptions,
          IsProcessing: false,
          Error: action.error,
          Completed: true,
        },
      };
    }
    case Consts.GET_USER_SUBSCRIPTIONS_SUCCESS: {
      const prevSubscriptions = state.subscriptions.Data;

      if (!!prevSubscriptions?.length) {
        return {
          ...state,
          subscriptions: {
            Data: action.payload.map((subscription) => {
              const oldSubscription = prevSubscriptions.find(
                (prevSubscription) => prevSubscription.Id === subscription.Id
              );

              if (oldSubscription)
                return {
                  ...oldSubscription,
                  ...subscription,
                };
              return subscription;
            }),
            IsProcessing: false,
            Error: undefined,
            Completed: true,
          },
        };
      }
      return {
        ...state,
        subscriptions: {
          Data: action.payload,
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };
    }
    case Consts.CANCEL_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptions: {
          Data: state.subscriptions.Data?.map((subscription) => {
            if (subscription.Id === action.payload.Id)
              return {
                ...subscription,
                IsActive: action.payload.IsActive,
                ExpirationDate: action.payload.ExpirationDate,
              };
            return subscription;
          }),
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
      };
    }
    case Consts.CANCEL_SUBSCRIPTION_FAILURE:
    case Consts.UPDATE_SUBSCRIPTION_FAILURE:
    case Consts.GET_USER_SUBSCRIPTIONS_FAILURE: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          IsProcessing: false,
          Error: action.error,
          Completed: true,
        },
      };
    }
    case Consts.GET_USER_AVAILABILITY_KEYS: {
      return {
        ...state,
        availabilityKeys: {
          ...state.availabilityKeys,
          IsProcessing: true,
          Error: undefined,
        },
      };
    }
    case Consts.GET_USER_AVAILABILITY_KEYS_SUCCESS: {
      return {
        ...state,
        availabilityKeys: {
          Data: action.payload,
          IsProcessing: false,
          Error: undefined,
        },
      };
    }
    case Consts.GET_USER_AVAILABILITY_KEYS_FAILURE: {
      return {
        ...state,
        availabilityKeys: {
          ...state.availabilityKeys,
          IsProcessing: false,
          Error: action.error,
        },
      };
    }
    case Consts.GET_USER_FAVORITES_SUCCESS: {
      return {
        ...state,
        favorites: action.payload,
      };
    }
    case Consts.ADD_TO_USER_FAVORITES: {
      const favorites = state.favorites ? [...state.favorites] : [];
      favorites.push({ AssetId: action.payload });

      return {
        ...state,
        favorites,
      };
    }
    case Consts.REMOVE_FROM_USER_FAVORITES: {
      return {
        ...state,
        favorites: state.favorites?.filter(
          (favorite) => favorite.AssetId !== action.payload
        ),
      };
    }
    case AuthConsts.SYNC_WEB_USER_SUCCESS: {
      return {
        ...state,
        subprofiles: {
          Data: {
            ...state.subprofiles.Data,
            subprofiles: action.payload.subProfiles,
            currentSubprofile: action.payload.currentSubProfile,
            auth: {
              ...state.subprofiles.Data?.auth,
              PinCodeId: action.payload.pinCode,
            },
          },
          Initialized: true,
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
        subscriptions: {
          Data: action.payload.subscriptions,
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
        availabilityKeys: {
          Data: action.payload.availabilityKeys,
          IsProcessing: false,
          Error: undefined,
          Completed: true,
        },
        favorites: action.payload.favorites,
      };
    }
    default:
      return state;
  }
};
