/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer, tap } from "rxjs";
import { HTTP_METHOD } from "@nf/constants";
import {
  ICreateOrderRequestModel,
  IPaymentOrderResponseModel,
  IPaymentOfferListModel,
  IPaymentInvoicesListResponseModel,
  IPaymentDetailsResponseModel,
  IValidateCouponResponseModel,
  IOrderStatusResponseModel,
  ICreatePaymentDetailsRequestModel,
} from "../models";
import { AxiosSubscriber } from "./AxiosSubscriber";

type TCache = {
  offers: IPaymentOfferListModel | null;
};

export class PaymentsService {
  private cache: TCache = {
    offers: null,
  };

  get url(): string {
    return "/v1";
  }

  get urlV2(): string {
    return "/v2";
  }

  public getOffers = (): Observable<IPaymentOfferListModel> => {
    if (this.cache.offers === null) {
      return new Observable(
        (observer: Observer<IPaymentOfferListModel>) =>
          new AxiosSubscriber(observer, {
            method: HTTP_METHOD.GET,
            url: `${this.urlV2}/offers`,
          })
      ).pipe(tap((offers) => (this.cache.offers = offers)));
    } else {
      return new Observable((observer: Observer<IPaymentOfferListModel>) => {
        observer.next(this.cache.offers as IPaymentOfferListModel);
        observer.complete();
      });
    }
  };

  public createOrder = (
    data: ICreateOrderRequestModel
  ): Observable<IPaymentOrderResponseModel> =>
    new Observable(
      (observer: Observer<IPaymentOrderResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.urlV2}/orders`,
        })
    );

  public createPaymentDetails = (
    data: ICreatePaymentDetailsRequestModel
  ): Observable<IPaymentOrderResponseModel> =>
    new Observable(
      (observer: Observer<IPaymentOrderResponseModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${this.urlV2}/payment-details`,
        })
    );

  public paymentInvoices = (): Observable<IPaymentInvoicesListResponseModel> =>
    new Observable(
      (observer: Observer<IPaymentInvoicesListResponseModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/invoices`,
        })
    );

  public getInvoicePDF = (invoiceId: string): Observable<string> =>
    new Observable(
      (observer: Observer<string>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/invoices/pdf/${invoiceId}`,
        })
    );

  public getPaymentDetails = (): Observable<IPaymentDetailsResponseModel[]> =>
    new Observable(
      (observer: Observer<IPaymentDetailsResponseModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.urlV2}/payment-details`,
        })
    );

  public validateCoupon = (
    coupon: string
  ): Observable<IValidateCouponResponseModel> =>
    new Observable(
      (observer: Observer<IValidateCouponResponseModel>) =>
        new AxiosSubscriber(observer, {
          data: {
            code: coupon,
          },
          method: HTTP_METHOD.POST,
          url: `${this.urlV2}/validate-coupon`,
        })
    );

  public getOrderStatus = (
    orderId: string
  ): Observable<IOrderStatusResponseModel> =>
    new Observable(
      (observer: Observer<IOrderStatusResponseModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.urlV2}/order-status/${orderId}`,
        })
    );
}
