import { useSelector } from "react-redux";
import { IAppState } from "../store";

export const useSwitchOfferSelector = () => {
  const {
    Data: switchOffer,
    Error,
    IsProcessing: isLoading,
    Completed,
    Initialized,
  } = useSelector((state: IAppState) => state.payment.switchOffers);

  return { switchOffer, Error, isLoading, Completed, Initialized };
};
