/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { ofType, StateObservable } from "redux-observable";
import {
  catchError,
  forkJoin,
  Observable,
  of,
  takeUntil,
  tap,
  timer,
} from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IAppState } from "../types";
import { IUserSubscriptionModel } from "../../models";
import { DataProvider } from "../../providers";
import * as Actions from "./actions";
import * as Consts from "./consts";
import * as UserConsts from "../user/consts";
import * as Types from "./types";
import { toError, toSuccess } from "../operators";
import {
  IGetUserSwitchOffersAction,
  ISwitchSubscriptionAction,
  ICancelSubscriptionSwitchAction,
  ICancelSubscriptionSwitchSuccessAction,
} from "./types";

import {
  getUserSwitchOffersFailure,
  getUserSwitchOffersSuccess,
  switchSubscriptionFailure,
  switchSubscriptionSuccess,
  cancelSubscriptionSwitchSuccess,
  cancelSubscriptionSwitchFailure,
} from "./actions";
import { eventService } from "../../services";
import {
  getUserSubscriptionsFailure,
  getUserSubscriptionsSuccess,
} from "../user/actions";
import {
  IGetUserSubscriptionsFailureAction,
  IGetUserSubscriptionsSuccessAction,
} from "../user/types";

const getOffersEpic = (action$: Observable<Types.IGetOffersAction>) =>
  action$.pipe(
    ofType(Consts.GET_OFFERS),
    switchMap(() =>
      forkJoin([DataProvider.getOffers()]).pipe(
        map(([listOffers]) => Actions.getOffersSuccess(listOffers)),
        toError(Actions.getOffersFailure)
      )
    )
  );

const getPaymentInvoicesEpic = (
  action$: Observable<Types.IGetPaymentInvoicesAction>
) =>
  action$.pipe(
    ofType(Consts.GET_PAYMENT_INVOICES),
    switchMap(() =>
      DataProvider.getPaymentInvoices().pipe(
        map((response) => response.filter((data) => data.PaymentSuccess)),
        toSuccess(Actions.getPaymentInvoicesSuccess),
        toError(Actions.getPaymentInvoicesFailure)
      )
    )
  );

const getSwitchOfferListEpic = (
  action$: Observable<IGetUserSwitchOffersAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_SWITCH_OFFER),
    map(({ payload }) => {
      const currentSubscription = state$.value.user.subscriptions.Data?.[0];
      return {
        subscription_id: payload.subscription_id,
        currentOfferId: currentSubscription?.OfferId,
      };
    }),
    switchMap(({ subscription_id, currentOfferId }) => {
      return DataProvider.getOffers().pipe(
        map((offerList) => {
          offerList.map((offer) => {
            offer.isCurrent = offer.Id === currentOfferId;
          });

          return {
            subscription_id,
            offerList,
          };
        })
      );
    }),
    switchMap(({ subscription_id, offerList }) =>
      DataProvider.getOfferSwitch(subscription_id).pipe(
        map((offerListSwitch) =>
          offerListSwitch.filter(
            (v) => v.Period === "year" || v.Period === "month"
          )
        ),
        map((offerListSwitch) => {
          return offerList.map((offer) => {
            const suitableItem = offerListSwitch.find(
              (item) => item.ToOfferId === offer.Id
            );

            if (!!suitableItem) {
              offer.Direction = suitableItem.Direction;
              offer.SavingsPrice = suitableItem.SavingsPrice;
              offer.Price = suitableItem.NextPaymentPrice;
            }

            return offer;
          });
        }),
        toSuccess(getUserSwitchOffersSuccess),
        toError(getUserSwitchOffersFailure)
      )
    )
  );

const switchSubscriptionEpic = (
  action$: Observable<ISwitchSubscriptionAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.SWITCH_SUBSCRIPTION),
    map((payload) => {
      return {
        ...payload,
        currentOfferId: state$.value.user.subscriptions.Data?.[0]?.OfferId,
      };
    }),
    switchMap(({ currentOfferId, ...rest }) => {
      return DataProvider.getOffers().pipe(
        map((offerList) => {
          offerList.map((offer) => {
            offer.isCurrent = offer.Id === currentOfferId;
          });

          return {
            ...rest,
            offerList,
          };
        })
      );
    }),
    switchMap(({ data, offerList }) =>
      DataProvider.switchSubscription(data).pipe(
        map((response) =>
          offerList.map((offer) => {
            offer.isCurrent = response.ToOfferId === offer.Id;

            if (!offer.isCurrent) {
              offer.Direction = response.Direction;
              offer.SavingsPrice = response.SavingsPrice;
            }

            return offer;
          })
        ),
        toSuccess(switchSubscriptionSuccess),
        toError(switchSubscriptionFailure)
      )
    )
  );

const switchSubscriptionSuccessEpic = (
  action$: Observable<
    | Types.ISwitchSubscriptionSuccessAction
    | IGetUserSubscriptionsSuccessAction
    | IGetUserSubscriptionsFailureAction
    | Types.ISwitchSubscriptionFailureAction
  >,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.SWITCH_SUBSCRIPTION_SUCCESS),
    map(() => ({
      SwitchToOfferId:
        state$.value.user.subscriptions.Data?.[0]?.SwitchToOfferId,
    })),
    switchMap(({ SwitchToOfferId }) => {
      return timer(500, 2000).pipe(
        switchMap((countAttempts) =>
          DataProvider.getUserSubscriptions()
            .pipe(
              map((data) => data),
              catchError((err) => of(err))
            )
            .pipe(
              map((data: IUserSubscriptionModel[]) => {
                const hasNewSwitchTOOfferId = data
                  .map((item) => item.SwitchToOfferId)
                  .some((item) => item !== undefined);
                return {
                  data,
                  attemptLimitExceeded: countAttempts > 5,
                  hasNewSwitchTOOfferId,
                };
              }),
              catchError((err) => of(err)),
              tap(() => {
                eventService.publish(
                  Consts.SWITCH_SUBSCRIPTION_SUCCESS_EVENT,
                  {}
                );
              }),
              map(({ data, attemptLimitExceeded, hasNewSwitchTOOfferId }) => {
                if (attemptLimitExceeded) {
                  return getUserSubscriptionsFailure({
                    Message: "No subscriptions",
                  });
                }

                if (hasNewSwitchTOOfferId) {
                  return getUserSubscriptionsSuccess(data);
                }

                return { type: "CHECKING_SUBSCRIPTIONS_STATUS" };
              }),
              toError(switchSubscriptionFailure)
            )
        ),
        takeUntil(
          action$.pipe(
            ofType(
              UserConsts.GET_USER_SUBSCRIPTIONS_SUCCESS,
              UserConsts.GET_USER_SUBSCRIPTIONS_FAILURE,
              Consts.SWITCH_SUBSCRIPTION_FAILURE
            )
          )
        )
      );
    })
  );

const switchSubscriptionFailureEpic = (
  action$: Observable<Types.ISwitchSubscriptionFailureAction>
) =>
  action$.pipe(
    ofType(Consts.SWITCH_SUBSCRIPTION_FAILURE),
    tap(() => {
      eventService.publish(Consts.SWITCH_SUBSCRIPTION_FAILURE_EVENT, {});
    }),
    map(() => ({ type: Consts.SWITCH_SUBSCRIPTION_FAILURE_EVENT }))
  );

const cancelSubscriptionSwitchEpic = (
  action$: Observable<ICancelSubscriptionSwitchAction>,
  state$: StateObservable<IAppState>
) =>
  action$.pipe(
    ofType(Consts.CANCEL_SUBSCRIPTION_SWITCH),
    map((payload) => {
      return {
        ...payload,
        currentOfferId: state$.value.user.subscriptions.Data?.[0]?.OfferId,
      };
    }),
    switchMap(({ currentOfferId, ...rest }) => {
      return DataProvider.getOffers().pipe(
        map((offerList) => {
          offerList.map((offer) => {
            offer.isCurrent = offer.Id === currentOfferId;
          });

          return {
            ...rest,
            offerList,
          };
        })
      );
    }),
    switchMap(({ subscription_id, offerList }) =>
      DataProvider.cancelSubscriptionSwitch(subscription_id).pipe(
        toSuccess(() => cancelSubscriptionSwitchSuccess(offerList)),
        toError(cancelSubscriptionSwitchFailure)
      )
    )
  );

const cancelSubscriptionSwitchSuccessEpic = (
  action$: Observable<ICancelSubscriptionSwitchSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.CANCEL_SUBSCRIPTION_SWITCH_SUCCESS),
    switchMap(() =>
      DataProvider.getUserSubscriptions().pipe(
        toSuccess(getUserSubscriptionsSuccess),
        toError(getUserSubscriptionsFailure)
      )
    )
  );

export const paymentsEpics = [
  getOffersEpic,
  getPaymentInvoicesEpic,
  switchSubscriptionEpic,
  getSwitchOfferListEpic,
  switchSubscriptionSuccessEpic,
  switchSubscriptionFailureEpic,
  cancelSubscriptionSwitchEpic,
  cancelSubscriptionSwitchSuccessEpic,
];
