import { IUserProfileCookieModel, IUserSubprofileModel } from "../models";
import { ROUTES } from "@nf/constants";

const cleanUp = (initialHomePage?: string): string | undefined =>
  initialHomePage?.replace("/", "");

const getInitialHomePage = (
  currentProfile?: IUserProfileCookieModel
): string => {
  return cleanUp(currentProfile?.InitialHomePage ?? ROUTES.HOME) as string;
};

const findCurrentProfile = (profiles?: IUserProfileCookieModel[]) =>
  profiles?.find((profile) => profile.CurrentProfile);

const findMainProfile = (profiles?: IUserSubprofileModel[]) =>
  profiles?.find((profile) => profile.Owner);

const sortSubProfiles = (profiles: IUserSubprofileModel[]) => {
  profiles.sort((a, b) => (a.Owner === b.Owner ? 0 : a.Owner ? -1 : 1));
};

export const userProfileHelper = {
  cleanUp,
  getInitialHomePage,
  findCurrentProfile,
  findMainProfile,
  sortSubProfiles,
};
