import { useState } from "react";

const INITIAL_DATA = null;
const INITIAL_ERROR = null;
const INITIAL_LOADING = false;
const INITIAL_SUCCESS = false;

export interface UseMutationOptions<Data, Error, Variables = void> {
  onSuccess?: (data: Data, variables: Variables) => void;
  onError?: (error: Error, variables: Variables) => void;
  onSettled?: (
    data: Data | null,
    error: Error | null,
    variables: Variables
  ) => void;
}

interface UseMutationResult<Data, Error, Variables = void> {
  mutate: (
    variables: undefined extends Variables ? Variables | undefined : Variables
  ) => Promise<void>;
  mutateAsync: (
    variables: undefined extends Variables ? Variables | undefined : Variables
  ) => Promise<Data>;
  reset: () => void;
  data: Data | null;
  error: Error | null;
  isLoading: boolean;
  isSuccess: boolean;
}

// Simple implementation similar to Tanstack Query. It might be
// a good idea to replace it with TanstackQuery if complex usage is needed
export function useMutation<Data, Error, Variables = void>(
  mutationFn: (variables: Variables) => Promise<Data>,
  options: UseMutationOptions<Data, Error, Variables> = {}
): UseMutationResult<Data, Error, Variables> {
  const { onSuccess, onError, onSettled } = options;

  const [data, setData] = useState<Data | null>(INITIAL_DATA);
  const [error, setError] = useState<Error | null>(INITIAL_ERROR);
  const [isLoading, setIsLoading] = useState(INITIAL_LOADING);
  const [isSuccess, setIsSuccess] = useState(INITIAL_SUCCESS);

  const mutate = async (variables: Variables) => {
    setIsLoading(true);
    setError(null);
    setIsSuccess(false);

    try {
      const result = await mutationFn(variables);
      setData(result);
      setIsSuccess(true);

      if (onSuccess) {
        onSuccess(result, variables);
      }
    } catch (err) {
      setError(err as Error);

      if (onError) {
        onError(err as Error, variables);
      }
    } finally {
      setIsLoading(false);

      if (onSettled) {
        onSettled(data, error, variables);
      }
    }
  };

  const mutateAsync = (variables: Variables) => {
    setIsLoading(true);
    setError(null);
    setIsSuccess(false);

    return mutationFn(variables)
      .then((result) => {
        setData(result);
        setIsSuccess(true);

        if (onSuccess) {
          onSuccess(result, variables);
        }
        return result;
      })
      .catch((err) => {
        setError(err as Error);

        if (onError) {
          onError(err as Error, variables);
        }

        throw err;
      })
      .finally(() => {
        setIsLoading(false);

        if (onSettled) {
          onSettled(data, error, variables);
        }
      });
  };

  // Reset to initial state
  const reset = () => {
    setData(INITIAL_DATA);
    setError(INITIAL_ERROR);
    setIsLoading(INITIAL_LOADING);
    setIsSuccess(INITIAL_SUCCESS);
  };

  return {
    mutate,
    mutateAsync,
    reset,
    data,
    error,
    isLoading,
    isSuccess,
  };
}
