/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export const ROUTES = {
  BASE: "/",
  INDEX: "/index.html",
  FAQ: "/faq",
  LOGIN: "/login",
  LOGOUT: "/logout",
  LOGIN_VIA_CODE: "/login-code",
  READ_MORE_SCREEN: "/read-more",
  REGISTER: "/register",
  REGISTER_SUCCESS: "/register-success",
  REGISTER_CONFIRM: "/confirmEmail",
  REGISTER_CONFIRM_ACCOUNT_WITH_PASSWORD: "/confirm-account-password",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_SUCCESS: "/forgot-password-success",
  AUTH_CONFIRM_EMAIL: "/auth/confirmAccount",
  MAIN_SCREEN: "/",
  HOME: "/hjem",
  WELCOME: "/welcome",
  KIDS: "/boern",
  DANSKFILM: "/danskfilmskat",
  MOVIE_DETAILS_SCREEN: "/film",
  SERIES_DETAILS_SCREEN: "/serie",
  PACKAGE_DETAILS_SCREEN: "/package-details",
  COLLECTION_DETAILS_SCREEN: "/filmsamling",
  LIVE_DETAILS_SCREEN: "/live-details",
  PLAYER_SCREEN: "/player",
  PLAYER_CHANNEL_SCREEN: "/player/channel",
  GENRES: "/genre",
  SETTINGS_SUBSCRIPTION: "/settings/subscription",
  SETTINGS_PAYMENT_DETAILS: "/settings/payment-details",
  PAYMENT: "/payment",
  PAYMENT_SUBSCRIPTION: "/payment/choose-subscription",
  PAYMENT_CHANGE_SUBSCRIPTION: "/payment/change-subscription",
  PAYMENT_DETAILS: "/payment/payment-details",
  PAYMENT_VOUCHER: "/payment/payment-voucher",
  PAYMENT_CANCELLED: "/payment/cancelled",
  PAYMENT_UPDATING: "/payment/updating",
  ASSIGNING_SUBSCRIPTION_SCREEN: "/assigning-subscription",
  SIGN_UP_PAYMENT: "/signup/payment",
  SIGN_UP_RECEIPT: "/signup/receipt",

  PLAYLIST_SCREEN: "/playlist",
  SEARCH_SCREEN: "/soeg",
  SEARCH_SMART_SCREEN: "/search",
  SETTINGS_SCREEN: "/settings",
  SETTINGS_PERSONAL_DETAILS_SCREEN: "/settings/personal-details",
  SETTINGS_ABOUT_SCREEN: "/settings/about",
  SETTINGS_MY_CONSENTS_SCREEN: "/settings/my-consents",
  SETTINGS_REGULATIONS_SCREEN: "/settings/regulations",
  SETTINGS_PRIVACY_POLICY_SCREEN: "/settings/privacy-policy",
  SETTINGS_ADD_SUBRPOFILE: "/settings/add-subprofile",
  SETTINGS_EDIT_SUBRPOFILE: "/settings/edit-subprofile",
  SETTINGS_CHANGE_PASSWORD: "/settings/change-password",
  PRIVACY_POLICY_SCREEN: "/privacy-policy",
  SETTINGS_SUBPROFILE_SCREEN: "/settings/profiles",
  SELECT_PROFILE_SCREEN: "/select-profile",
  SETTINGS_MY_ORDERS: "/settings/my-orders",
  EVENTS: "/events",
  MEDIA_CREATOR: "/media-creator",
  MYLIST_SCREEN: "/my-list",
  CATEGORY_SCREEN: "/category",
  CATEGORY_DETAILS_SCREEN: "/category-details",
  LINK_SCREEN: "/link",
  UPDATED_TERMS_SCREEN: "/updated-terms",
  PAGE_NOT_FOUND: "/404",
  SUBPROFILES_FORGOT_PIN: "/forgot-pin",
  TV_CODE_LOGIN: "/tv",
  PERSON_DETAILS_SCREEN: "/person",
  MY_ACCOUNT: "/myaccount",
  API_UPDATE_TOKENS: "/api/token/update-token",
  API_SIGN_IN: "/api/sign-in/sign-in",
  API_LOGOUT: "/api/logout/logout",
  API_SET_CHILD_COOKIE: "/api/profile/setChildCookie",
  API_SYNC_USER: "/api/sync/user",
  API_SYNC_DATA: "/api/sync/data",
  API_SIGN_IN_VIA_TOKEN: "/api/sign-in/sign-token",
  API_CHANGE_PASSWORD: "/api/change-password/change-password",
} as const;
