import { useMemo } from "react";
import { COUNTRY_COOKIE, DEFAULT_COUNTRY_CODE_LOWERCASE } from "@nf/constants";
import { useRouter } from "next/router";
import { getCookie } from "cookies-next";

export const useCountry = () => {
  const router = useRouter();
  const countryCookie = getCookie(COUNTRY_COOKIE);
  const { country: countryQuery } = router.query;

  const country = useMemo(() => {
    if (countryQuery) {
      return countryQuery as string;
    }

    if (countryCookie) {
      const country = countryCookie as string;

      return country || DEFAULT_COUNTRY_CODE_LOWERCASE;
    }

    return DEFAULT_COUNTRY_CODE_LOWERCASE;
  }, [countryQuery, countryCookie]);

  return country;
};
