import { useCallback, useEffect } from "react";
import { applyPreloadStylesheetLinks } from "../helpers/applyPreloadStylesheetLinks";
import { useRouter } from "next/router";

export const usePreloadStylesheet = () => {
  const handleRouteChangeComplete = useCallback(() => {
    applyPreloadStylesheetLinks();
  }, []);

  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [handleRouteChangeComplete, router.events]);
};
