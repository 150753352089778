import { useEffect, useRef, useState } from "react";

type CallbackFunction<T> = () => Promise<T>;

interface UsePollingOptions {
  startOnInit?: boolean;
  interval: number;
  maxRetries: number;
}

export const usePolling = <T>(
  callback: CallbackFunction<T>,
  options: UsePollingOptions
) => {
  const [state, setState] = useState({
    isPolling: options.startOnInit ?? true,
    hasReachedMaxRetries: false,
  });

  const retryCount = useRef(0);
  const timeoutId = useRef(-1);

  const startPolling = () => {
    retryCount.current = 0;
    setState({ isPolling: true, hasReachedMaxRetries: false });
  };

  const stopPolling = () => {
    setState((prev) => ({ ...prev, isPolling: false }));
  };

  useEffect(() => {
    if (!state.isPolling || retryCount.current >= options.maxRetries) return;

    const executeCallback = async () => {
      try {
        await callback();
      } catch (error) {
        console.warn(
          `Polling attempt ${retryCount.current + 1} failed:`,
          error
        );
      } finally {
        retryCount.current += 1;
        if (retryCount.current >= options.maxRetries) {
          setState({ isPolling: false, hasReachedMaxRetries: true });
        }
      }
    };

    const poll = () => {
      executeCallback();
      timeoutId.current = window.setTimeout(poll, options.interval);
    };

    poll();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [state.isPolling, options, callback]);

  return { startPolling, stopPolling, ...state };
};

export default usePolling;
